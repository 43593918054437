import { createSlice } from "@reduxjs/toolkit";
import AUTH_API from "api/auth";
import HELPERS from "utils/helper";
import roles from "utils/roles";
import { message } from "antd";

export const targetRolesSlice = createSlice({
  name: "targetRoles",
  initialState: {
    roles: [],
  },
  reducers: {
    updateRoles: (state, action) => {
      state.roles = action.payload;
    },
    addRole: (state, action) => {
      HELPERS.log("Received action addRole", action);
      let roles = [...state.roles];
      roles.push(action.payload);
      state.roles = roles;
    },
    updateRole: (state, action) => {
      HELPERS.log("Received action updateRole", action);
      let roles = [...state.roles];
      for (let index = 0; index < roles.length; ++index) {
        if (roles[index].id === action.payload.id) {
          HELPERS.log("Found the role to update!");
          roles[index] = action.payload;
          break;
        }
      }
      state.roles = roles;
    },
  },
});

export const { updateRoles, addRole, updateRole } = targetRolesSlice.actions;

export const fetchRolesAsync = () => (dispatch) => {
  AUTH_API.getAllTargetroles().then((resp) => {
    HELPERS.log("Received response for fetchRolesAsync", resp.data);
    dispatch(updateRoles(resp.data));
  });
};

export const addRoleAsync = (data, Stepname) => (dispatch) => {
  AUTH_API.addTargetrole(data, Stepname)
    .then((response) => {
      HELPERS.log("Received response for addRoleAsync", response.data);
      message.success('Your work has been saved');
      dispatch(addRole(response.data));
    })
    .catch((err) => console.log("error", err));
};

export const deleteRoleAsync = (key, Stepname) => (dispatch) => {
  AUTH_API.targetRolesPatch({ learn_more: false }, key, Stepname)
    .then((response) => {
      HELPERS.log("Received response for deleteRoleAsync", response.data);
      message.success('Your work has been saved');
      dispatch(updateRole(response.data));
    })
    .catch((err) => console.log("error", err));
};

export const updateRoleAsync = (key, data, Stepname) => (dispatch) => {
  AUTH_API.targetRolesPatch(data, key, Stepname)
    .then((response) => {
      HELPERS.log("Received response for updateRoleAsync", response);
      // message.success('Your work has been saved');
      dispatch(updateRole(response.data));
    })
    .catch((err) => console.log("error", err));
};

export const selectTargetRoles = (state) => state.targetRoles.roles;

export const selectTargetRolesToLearnMore = (state) =>
  state.targetRoles.roles.filter((role) => role.learn_more === true);

export const selectAllRoles = (state) => {
  const allRoles = [];
  const addedRoles = new Set();

  for (var role of state.targetRoles.roles) {
    if (!addedRoles.has(role.title)) {
      addedRoles.add(role.title);
      allRoles.push({
        id: role.id,
        value: role.title,
        selected_for: role.selected_for,
      });
    }
  }

  for (var role of roles) {
    if (!addedRoles.has(role.value)) {
      addedRoles.add(role.value);
      allRoles.push({
        id: 0,
        value: role.value,
        selected_for: [],
      });
    }
  }

  return allRoles.sort((a, b) => {
    var res = 0;
    if (a.value < b.value) res = -1;
    else if (a.value > b.value) res = 1;
    return res;
  });
};

export const selectTargetRolesCount = (state) =>
  state.targetRoles.roles.filter((role) => role.learn_more === true).length;

export default targetRolesSlice.reducer;
