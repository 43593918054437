import { Button, Col, Input, Popconfirm, Row } from "antd";
import React from "react";
import { connect } from "react-redux";
import { updateQuickReflection } from "slices/dashboardSlice";
import {
  addIndustryAsync,
  deleteIndustryAsync,
  selectTargetIndustries,
} from "slices/targetIndustriesSlice";
import HELPERS from "utils/helper";
// import "antd/dist/antd.css";
import "./Researchindustries.css";
import TestcardsTI from "./TestcardsTI";
// const { Step } = Steps;

class ResearchIndustriesSteps extends React.Component {
  state = {
    current: 0,
    add_key: 999,
    submitted: false,
    steps: [
      {
        title: "Industry 1",
        content: <TestcardsTI key="1" industry="Industry 1" />,
        key: 999,
        color: "",
      },
    ],
    loading: true,
  };
  yellowstep = (index) => {
    console.log("yellow", index, this.state.steps[index]);
    const newsteps = [...this.state.steps];
    console.log("newsteps:", newsteps);
    const step = { ...newsteps[index] };
    step.color = "#ffca3e";
    newsteps[index] = step;
    // this.setState({steps:newsteps});
    console.log("newsteps", newsteps, "state steps", this.state.steps);
    this.setState({ steps: newsteps });

    // this.setState({...this.state.steps,})
  };

  updateSteps = (prevProps) => {
    if (
      !prevProps ||
      prevProps.industries.filter((ind) => ind.learn_more === true).length !==
        this.props.industries.filter((ind) => ind.learn_more === true).length
    ) {
      console.log("ti !!", this.props.industries);
      const req_industries = this.props.industries.filter(
        (item) => item.learn_more === true
      );
      req_industries.sort((a, b) => {
        //sort acc to priority
        return a.priority - b.priority;
      });
      const industries = req_industries.map((item, index) => {
        return {
          title: item.title,
          content: (
            <TestcardsTI
              key={item.id}
              id={item.id}
              yellowstep={() => this.yellowstep(index)}
              industry={item.title}
            />
          ),
          key: item.id,
          color:
            item.research_questions.length > 0 ||
            item.additional_questions.length > 0 ||
            item.reflections.length > 0
              ? "#ffca3e"
              : "",
        };
      });

      HELPERS.log("sorted industries", industries);
      let current = this.state.current;

      while (current > 0 && current >= industries.length) --current;

      this.setState({ steps: industries, loading: false, current: current });
    }
  };
  y;

  // componentDidMount(previousProps, previousState) {
  //   // console.log('didupdate', previousState, this.state);
  //   AUTH_API.getAllTargetindustries()
  //     .then((response) => {
  //       console.log("ti !!", response);
  //       if (response.data.length > 0) {
  //         const req_ind = response.data.filter(
  //           (item) => item.learn_more === true
  //         );
  //         req_ind.sort((a, b) => {
  //           //sort acc to priority
  //           return a.priority - b.priority;
  //         });
  //         const ind = req_ind.map((item, index) => {
  //           return {
  //             title: item.title,
  //             content: (
  //               <TestcardsTI
  //                 key={item.id}
  //                 id={item.id}
  //                 yellowstep={() => this.yellowstep(index)}
  //                 industry={item.title}
  //               />
  //             ),
  //             key: item.id,
  //             color:
  //               item.research_questions.length > 0 ||
  //               item.additional_questions.length > 0 ||
  //               item.reflections.length > 0
  //                 ? "#ffca3e"
  //                 : "",
  //           };
  //         });
  //         // if(response.data.)
  //         console.log("sorted ind", ind);
  //         this.setState({ steps: ind, loading: false });
  //       }
  //     })
  //     .catch((err) => console.log("no industries found!", err));
  // }

  componentDidUpdate(prevProps) {
    this.updateSteps(prevProps);
  }

  componentDidMount() {
    this.updateSteps();
  }

  next() {
    const current = this.state.current + 1;
    this.setState({ ...this.state, current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ ...this.state, current });
  }

  //adds new step in stepper
  addNewRole = (e) => {
    const temp = [...this.state.steps];

    if (
      e.target.value !== "" &&
      !temp.some((el) => el.title === e.target.value)
    ) {
      //prevents adding duplicate or empty string
      // temp.push({
      //     title: e.target.value.toUpperCase(),
      //     key: this.state.add_key,
      //     content: <Testcards key={this.state.steps.length+1} yellowstep={()=>this.yellowstep((temp.length)-1)} rolename={e.target.value.toUpperCase()} />,
      //     color:"",
      //     //this is added in state, not posted yet
      // });
      const data = {
        title: e.target.value,
        priority: temp.length + 1,
        learn_more: "True",
        selected_for: [],
        research_questions: [],
        additional_questions: [],
        reflections: [],
      };

      const formdata = HELPERS.converToFormData(data);
      this.props.addIndustryAsync(data);
    }
  };

  //take care of title here
  deleteRole = (key) => {
    if (this.state.steps.length > 1) {
      this.props.deleteIndustryAsync(key);
    }
  };

  onSubmit = () => {
    this.setState({ ...this.state, submitted: true, current: 0 });
    this.props.updateQuickReflection({
      stepId: this.props.stepId,
      show: true,
    });
  };

  onChange = (current) => {
    // console.log('onChange:', current);
    this.setState({ current });
  };

  render() {
    // console.log('rrsteps rendered', this.state);

    if (this.state.steps.length === 0) {
      return null;
    }

    const { current } = this.state;
    var total = 100 / this.state.steps.length;
    return (
      <>
        {!this.state.loading ? (
          <div
            style={
              this.props.fade ? {} : { opacity: "0.4", pointerEvents: "none" }
            }
          >
            {/* stepper after submission */}
            {this.state.submitted ? (
              <Row style={{ padding: "2% 0 3%", overflow: "scroll" }}>
                <Col span={22}>
                  <div className="line-between">&nbsp;</div>
                  <divf
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {this.state.steps.map((item, index) => {
                      return (
                        <div
                          className="item-div"
                          // style={{ maxWidth: total + "%" }}
                        >
                          {/* {index === 0 ? <input name='vd' checked className='cr' type='radio' id={index} onClick={()=>{this.setState({...this.state, current: index})}} />
                                    : */}
                          <input
                            name="vd"
                            className="cr"
                            type="radio"
                            id={index}
                            onClick={() => {
                              this.setState({ ...this.state, current: index });
                            }}
                            checked={index === this.state.current}
                          />

                          <label for={index} className="cr-label">
                            <span className="cr-span-submit">{index + 1}</span>
                            <div className="span-name"> {item.title}</div>
                          </label>
                          {/* {(index+1) !== this.state.steps.length && <hr className='hr-gap'/>} */}
                        </div>
                      );
                    })}
                  </divf>
                </Col>
                <Col span={2}>
                  <Input
                    onPressEnter={this.addNewRole}
                    placeholder="Add new"
                    bordered={false}
                    style={{
                      margin: "0 0 0 0px",
                      padding: "3px 0 0 8px",
                      color: "rgb(147 158 171)",
                    }}
                  />
                </Col>
              </Row>
            ) : (
              // <div style={{padding:'2% 0 6% 0', fontSize:14, display:'flex'}}>
              //             <Steps current={current} style={{width:'87%'}} onChange={this.onChange} >
              //                 {this.state.steps.map(item => (<Step style={{fontSize:12}} key={item.title} title={item.title} />))}
              //             </Steps>
              //         <hr style={{width:'2%', marginLeft:'7px', marginRight:'7px'}}></hr>
              //             <span style={{border:'1px solid #00000042', borderRadius:'30px', width:'30px', margin:'3px 0px 3px 0px'}}></span>

              //             {/* input for adding step */}
              //             <Input onPressEnter={this.addNewRole} placeholder="Type new" bordered={false}
              //             style={{margin:'0 0 0 0px', padding:'0 0 0 8px', color:'rgb(147 158 171)',width:'8%'}}/>
              // </div>
              // }
              <Row style={{ padding: "2% 0 3%",overflow: "scroll" }}>
                <Col span={22}>
                  <div className="line-between">&nbsp;</div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {this.state.steps.map((item, index) => {
                      return (
                        <div
                          className="item-div"
                          // style={{ maxWidth: total + "%" }}
                        >
                          {/* {index === 0 ? <input name='vd' checked className='cr' type='radio' id={index} onClick={()=>{this.setState({...this.state, current: index})}} />
                                    : */}
                          <input
                            name="vd"
                            className="cr"
                            type="radio"
                            id={index}
                            onClick={() => {
                              this.setState({ ...this.state, current: index });
                            }}
                            checked={index === this.state.current}
                          />

                          <label for={index} className="cr-label">
                            <span
                              className="cr-span"
                              style={{
                                backgroundColor: this.state.steps[index].color,
                                color: this.state.steps[index].color
                                  ? "white"
                                  : "",
                                border: this.state.steps[index].color
                                  ? "0px"
                                  : "",
                              }}
                            >
                              {index + 1}
                            </span>
                            <div className="span-name"> {item.title}</div>
                          </label>
                          {/* {(index+1) !== this.state.steps.length && <hr className='hr-gap'/>} */}
                        </div>
                      );
                    })}
                  </div>
                </Col>
                <Col span={2}>
                  {React.createElement(() => (
                    <Input
                      onPressEnter={this.addNewRole}
                      placeholder="Add new"
                      bordered={false}
                      style={{
                        margin: "0 0 0 0px",
                        padding: "3px 0 0 8px",
                        color: "rgb(147 158 171)",
                      }}
                    />
                  ))}
                </Col>
              </Row>
            )}

            {/* single role rendered here */}
            <div className="steps-content">
              {this.state.steps[current].content}
            </div>

            {this.state.submitted ? (
              <div className="steps-action">
                <Popconfirm
                  title="Are you sure you want to delete?"
                  onConfirm={() => {
                    this.deleteRole(this.state.steps[current].title);
                  }}
                >
                  <Button
                    size="large"
                    className="del-submitted"
                    onClick={() => {
                      this.deleteRole(this.state.steps[current].title);
                    }}
                    style={{ color: "#49CDE1" }}
                    block
                  >
                    Delete Industry
                  </Button>
                </Popconfirm>
              </div>
            ) : (
              <div className="steps-action">
                <Row>
                  <Col span={5} style={{ paddingTop: "5%" }}>
                    {current > 0 && (
                      <Button
                        size="large"
                        style={{ margin: "0 8px", color: "#49CDE1" }}
                        onClick={() => this.prev()}
                        block
                      >
                        Previous Industry
                      </Button>
                    )}
                    {/* {current === 1 && (
                                <Button size= 'large' style={{ margin: '0 8px'}}  block>
                                    Back
                                </Button>
                            )} */}
                  </Col>
                  <Col span={8}></Col>

                  <Col
                    span={11}
                    style={{ textAlign: "right", padding: "5% 4% 5% 1%" }}
                  >
                    {current < this.state.steps.length - 1 && (
                      <Row gutter={32}>
                        <Col span={12}>
                          <Popconfirm
                            title="Are you sure you want to delete?"
                            onConfirm={() => {
                              this.deleteRole(this.state.steps[current].key);
                            }}
                          >
                            <Button
                              size="large"
                              style={{ margin: "0 8px", color: "#49CDE1" }}
                              block
                            >
                              Delete
                            </Button>
                          </Popconfirm>
                        </Col>

                        <Col span={12}>
                          <Button
                            size="large"
                            type="primary"
                            onClick={() => this.next()}
                            block
                          >
                            Next Industry
                          </Button>
                        </Col>
                      </Row>
                    )}
                    {current === this.state.steps.length - 1 && (
                      <Row gutter={16}>
                        <Col span={12}>
                          <Popconfirm
                            title="Are you sure you want to delete?"
                            onConfirm={() => {
                              this.deleteRole(this.state.steps[current].key);
                            }}
                          >
                            <Button
                              size="large"
                              style={{ margin: "0 8px", color: "#49CDE1" }}
                              block
                            >
                              Delete
                            </Button>
                          </Popconfirm>
                        </Col>

                        <Col span={12}>
                          <Button
                            size="large"
                            type="primary"
                            onClick={() => {
                              this.onSubmit();
                            }}
                            block
                          >
                            Submit All Industries
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  industries: selectTargetIndustries(state),
});

const mapDispatchToProps = () => ({
  updateQuickReflection,
  deleteIndustryAsync,
  addIndustryAsync,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(ResearchIndustriesSteps);
