import { Form, Input, message } from "antd";
import React from "react";

export default class Filterdropdown extends React.Component {
  addCol = (e, col) => {
    if (e.target.value !== "") {
      // console.log('column added', e.target.value, col)
      let stopped = this.props.addNewCol(e.target.value, col);

      console.log("keeps running", stopped);
      if (!stopped) this.props.confirm();
    } else {
      message.error("enter a column name");
    }
  };

  render() {
    // console.log('val', this.state)
    return (
      <div style={{ padding: 15, width: "312px" }}>
        <p>Add column</p>
        {React.createElement(() => (
          <Form.Item
            name="addcol"
            rules={[{ required: true, message: "Column Name is required" }]}
          >
            <Input
              placeholder={"Enter Column name and press enter"}
              onPressEnter={(e) => this.addCol(e, this.props.val)}
            />
          </Form.Item>
        ))}
      </div>
    );
  }
}
