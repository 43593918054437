import { Button, Col, Drawer, message, Progress, Row, Table } from "antd";
import Addcontacts from "Components/Networking/Tracker/Addcontacts";
import React from "react";
import { connect } from "react-redux";
import {
  selectConfigurations,
  updateProfileAsync,
} from "slices/dashboardSlice";
import { selectTargetIndustries } from "slices/targetIndustriesSlice";
import { selectTargetRoles } from "slices/targetRolesSlice";
import HELPERS from "utils/helper";
import AUTH_API from "../../../api/auth";
import Contactdrawer from "./Contactdrawer";
import Renamecol from "./Renamecol";
import "./Tracker.css";
import {
  baseColumns,
  category_mapping,
  extraColumnTemplate,
} from "./utilities";

class Tracker extends React.Component {
  state = {
    rename_col: { show: false, curr: {} },
    show_drawer: { show: false, curr: {} },
    loading: false,
    contacts: [],
    show_select: false,
  };

  defaultColumns = () => {
    const columns = {
      contactinfo: [
        { title: "Name", key: "name" },
        { title: "Type", key: "type" },
        { title: "Role", key: "role" },
        { title: "Industry", key: "industry" },
      ],
      reachout: [
        { title: "Initial reach out date", key: "initial" },
        { title: "Follow up date", key: "follow" },
      ],
      intro: [{ title: "New Contacts", key: "contact" }],
      beforecall: [
        { title: "Scheduled Call Date", key: "contact" },
        { title: "Call Prep", key: "callprep" },
        { title: "Call prep done?", key: "callprepdone" },
      ],
      call: [{ title: "Call notes", key: "callnotes" }],
      aftercall: [{ title: "Follow up and next steps", key: "followup" }],
    };

    const configurations = this.props.configurations.networking_tracker;
    // console.log('config test', configurations)

    for (let colKey in columns) {
      if (configurations) {
        if (configurations[colKey]) {
          for (var column of configurations[colKey]) {
            if (!column.deleted) {
              columns[colKey].push({ title: column.title, key: column.key });
            }
          }
        }
      }
    }

    return columns;
  };

  columns = () => {
    const columns = baseColumns(
      this.addNewCol,
      this.onChange,
      this.showDrawer,
      this.getRoles,
      this.getIndustries
    );

    if (
      this.props.configurations &&
      this.props.configurations.networking_tracker
    ) {
      const configurations = this.props.configurations.networking_tracker;

      for (let index = 0; index < columns.length; ++index) {
        if (configurations[columns[index].key]) {
          for (var column of configurations[columns[index].key]) {
            if (!column.deleted) {
              columns[index].children.push(
                extraColumnTemplate(
                  column.key,
                  column.title,
                  columns[index].key,
                  this.onChange,
                  this.onHeaderCell
                )
              );
            }
          }
        }
      }
    }

    return columns;
  };

  addNewCol = (child, parent) => {
    // HELPERS.log("add new in parent", child, parent);
    let configurations = this.configurations();

    if (!configurations.networking_tracker) {
      if (Array.isArray(configurations)) {
        configurations = {};
      }
      configurations.networking_tracker = {};
    }

    if (!configurations.networking_tracker[parent]) {
      configurations.networking_tracker[parent] = [];
    }

    configurations.networking_tracker[parent].push({
      title: child,
      key: `col_${
        configurations.networking_tracker[parent].length + 1
      }-${parent}`,
      deleted: false,
    });

    this.props.updateProfileAsync(
      {
        configurations,
      },
      "NT"
    );
  };

  onChange = HELPERS.debounceFormChanges((val, rec, type) => {
    // console.log('val', val, typeof val);
    if (val) {
      // console.log(val, rec, type);
      const patch_data = {};
      let tracker_data = {};
      patch_data[type] = val;
      // console.log(patch_data)
      let outside_tracker = [
        "title",
        "contact_type",
        "roles",
        "industries",
        "email",
        "phone",
      ];
      if (outside_tracker.filter((item) => item === type).length) {
        tracker_data = patch_data;
      } else {
        tracker_data = { ...rec.tracker_info, ...patch_data };
        tracker_data = { tracker_info: tracker_data };
      }
      AUTH_API.patchContact(tracker_data, rec.id, "NT")
        .then((response) => {
          console.log("patch res", response);
          this.getAllData();
          message.success('Your work has been saved');
        })
        .catch((err) => console.log("not patched", err));
    }
  });

  getIndustries = () => {
    return this.props.industries
      ? this.props.industries
          .filter((item) => item.learn_more === true)
          .sort((a, b) => a.priority - b.priority)
      : [];
  };

  getRoles = () => {
    return this.props.roles
      ? this.props.roles
          .filter((item) => item.learn_more === true)
          .sort((a, b) => a.priority - b.priority)
      : [];
  };

  getAllData = (out_state) => {
    //get all contacts and map them for table
    AUTH_API.getAllContacts()
      .then((response) => {
        // HELPERS.log("get contacts !!", response.data);
        let contacts = response.data.filter((item) => item.selected === true);
        HELPERS.log("get contacts !!", contacts);
        if (contacts.length) {
          contacts = contacts.map((item, index) => {
            let row_data = {
              ...item,
              callprep: item.call_prep.length,
              // callprepdone: item.call_prep.length,
              callnotes: item.notes.length,
              ...item.tracker_info,
              // sno: index+1
            };
            return row_data;
          });
          contacts.sort((a, b) => {
            return a.id - b.id;
          });
          HELPERS.log("contacts !!", contacts);
          let reach_out_count = 0;
          let call_count = 0;
          contacts.forEach((item, index) => {
            if (item.tracker_info["scheduled"]) call_count += 1;
            if (item.tracker_info["initial"]) reach_out_count += 1;
            item["sno"] = index + 1;
          });

          this.setState({
            contacts: contacts,
            loading: false,
            reach_out_count,
            call_count,
            ...out_state,
            // show_drawer: {show: false, curr: {}},
          });
        }
      })
      .catch((err) => HELPERS.log("no contacts found!", err));
  };

  componentDidMount() {
    const configurations = this.configurations();
    console.log("in tracker didmount", configurations);
    if (!configurations.networking_tracker) {
      configurations.networking_tracker = {};
    }
    this.props.updateProfileAsync(
      {
        configurations,
      },
      "NT"
    );

    this.getAllData();
  }

  showDrawer = (record) => {
    HELPERS.log({ record });
    this.setState({
      show_drawer: { show: true, curr: record },
    });
  };

  onClose = () => {
    // this.setState({
    //   show_drawer: {show: false, curr: {}},
    // });
    //check, maybe
    // console.log('onclose')
    this.getAllData({
      show_drawer: { show: false, curr: {} },
    });
  };

  onHeaderCell = (record) => ({
    onContextMenu: (event) => {
      event.preventDefault();
      // console.log('record', record)
      this.setState({
        rename_col: { curr: record, show: true },
      });
    },
  });

  configurations = () => JSON.parse(JSON.stringify(this.props.configurations));

  renameCol = (newVal) => {
    let configurations = this.configurations();
    let parent =
      configurations.networking_tracker[this.state.rename_col.curr.parent_col];

    for (let index = 0; index < parent.length; ++index) {
      if (parent[index].key === this.state.rename_col.curr.dataIndex) {
        parent[index].title = newVal.rename;
        break;
      }
    }

    this.closeRenameModal();
    this.props.updateProfileAsync(
      {
        configurations,
      },
      "NT"
    );
  };

  deleteCol = () => {
    let configurations = this.configurations();
    let parent =
      configurations.networking_tracker[this.state.rename_col.curr.parent_col];
    // console.log('del parent', parent)

    for (let index = 0; index < parent.length; ++index) {
      // console.log('infor',parent[index].key, this.state.rename_col.curr.dataIndex)
      if (parent[index].key === this.state.rename_col.curr.dataIndex) {
        // console.log('in if', parent[index].deleted)
        parent[index].deleted = true;
        break;
      }
    }

    this.closeRenameModal();
    this.props.updateProfileAsync(
      {
        configurations,
      },
      "NT"
    );
  };

  closeRenameModal = () => {
    this.setState({
      rename_col: false,
      curr: {},
    });
  };

  addRowBelow = () => {
    const data = {
      title: "New Contact",
      country_code: "",
      phone: "",
      company_name: "",
      email: "",
      linkedin: "",
      roles: "",
      industries: "",
      contact_type: "aq",
      selected: true,
      tracker_info: [],
      call_prep: [],
      notes: "",
      reflections: [],
    };
    AUTH_API.setNewContact(data, "NT")
      .then((res) => {
        console.log("contact post", res);
        this.getAllData();
        message.success('Your work has been saved');
      })
      .catch((res) => console.log("np post", res));
  };

  onShowSelect = () => {
    this.setState({
      show_select: true,
    });
  };

  onCloseSelect = () => {
    this.getAllData({ show_select: false });
  };

  render() {
    const reachout_cnt = this.state.reach_out_count;
    const call_cnt = this.state.call_count;
    // HELPERS.log("table state", this.state, this.configurations(),
    //   (call_cnt *100)
    //   /
    // parseInt((6===0)?((Math.floor(call_cnt / 5)) * 5):
    //   (((Math.floor(call_cnt / 5)) * 5) + 5))
    //   )
    return (
      <div>
        <p className="tracker-head">
          Reach out to your connections to gain introductions, hold
          informational networking calls and track the status of your
          conversations.
        </p>
        <Row>
          <Col span={7}></Col>
          <Col span={10} className="curr-mission">
            <Row gutter={32}>
              <Col span={8} className="col1-text">
                YOUR CURRENT MISSION
              </Col>
              <Col span={8}>
                <p className="col2-head">
                  {reachout_cnt === 0
                    ? parseInt(Math.floor(reachout_cnt / 10)) * 10
                    : parseInt(Math.floor(reachout_cnt / 10)) * 10 + 10}{" "}
                  reach outs
                </p>
                <p className="col2-text">
                  {reachout_cnt} out of &nbsp;
                  {reachout_cnt === 0
                    ? parseInt(Math.floor(reachout_cnt / 10)) * 10 + 10
                    : parseInt(Math.floor(reachout_cnt / 10)) * 10 + 10}
                </p>
                <Progress
                  percent={
                    (reachout_cnt * 100) /
                    parseInt(
                      reachout_cnt === 0
                        ? Math.floor(reachout_cnt / 10) * 10
                        : Math.floor(reachout_cnt / 10) * 10 + 10
                    )
                  }
                  showInfo={false}
                />
              </Col>
              <Col span={8}>
                <p className="col2-head">
                  {call_cnt === 0
                    ? parseInt(Math.floor(call_cnt / 5)) * 5
                    : parseInt(Math.floor(call_cnt / 5)) * 5 + 5}{" "}
                  calls
                </p>
                <p className="col2-text">
                  {call_cnt} out of &nbsp;
                  {call_cnt === 0
                    ? parseInt(Math.floor(call_cnt / 5)) * 5 + 5
                    : parseInt(Math.floor(call_cnt / 5)) * 5 + 5}
                </p>
                <Progress
                  percent={
                    (call_cnt * 100) /
                    parseInt(
                      call_cnt === 0
                        ? Math.floor(call_cnt / 5) * 5
                        : Math.floor(call_cnt / 5) * 5 + 5
                    )
                  }
                  showInfo={false}
                />
              </Col>
            </Row>
          </Col>
          <Col span={7}></Col>
        </Row>

        {/* =============================================================table here======================================================= */}
        <div className="minimized">
          {!this.state.loading ? (
            <Table
              dataSource={this.state.contacts}
              onRow={this.onRow}
              columns={this.columns()}
              rowClassName="row-style"
              scroll={{ x: "calc(700px + 50%)", y: 640 }}
              pagination={false}
              showSorterTooltip={false}
              filterDropdownVisible={true}
              // components={this.components}

              bordered
            />
          ) : (
            ""
          )}
          <Row>
            <Col span={12}>
              <Button
                onClick={this.addRowBelow}
                style={{ margin: "30px 0", float: "left", color: "#49CDE1" }}
                size="large"
              >
                Add New Contact
              </Button>
            </Col>
            <Col span={12}>
              <Button
                style={{ margin: "30px 0", float: "right" }}
                type="primary"
                size="large"
                onClick={this.onShowSelect}
              >
                Select Contacts
              </Button>
            </Col>
          </Row>

          {this.state.show_drawer.show ? (
            <Contactdrawer
              onClose={this.onClose}
              show_drawer={this.state.show_drawer.show}
              record={this.state.show_drawer.curr}
              columns={this.defaultColumns()}
              roles={this.getRoles()}
              industries={this.getIndustries()}
              category_mapping={category_mapping}
              onChange={this.onChange}
            />
          ) : (
            ""
          )}
          {this.state.show_select ? (
            <Drawer
              title={<div style={{ padding: 20 }}></div>}
              placement="right"
              onClose={this.onCloseSelect}
              visible={this.state.show_select}
              width={640}
              footer={null}
            >
              <Addcontacts start_text={"1"} next={this.onCloseSelect} />
            </Drawer>
          ) : (
            ""
          )}

          {this.state.rename_col ? (
            <Renamecol
              visible={this.state.rename_col.show}
              col_name={this.state.rename_col.curr}
              closeRenameModal={this.closeRenameModal}
              handleOk={this.renameCol}
              deleteCol={this.deleteCol}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  roles: selectTargetRoles(state),
  industries: selectTargetIndustries(state),
  configurations: selectConfigurations(state),
});

const mapDispatchToProps = () => ({
  updateProfileAsync,
});

export default connect(mapStateToProps, mapDispatchToProps())(Tracker);
