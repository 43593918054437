import { createSlice } from "@reduxjs/toolkit";
import DASHBOARD_API from "api/dashboard";
import AUTH_API from "api/auth";
import HELPERS from "utils/helper";
import { STEP_KEY } from "utils/steps";
import Helper from "bizcharts/lib/g-components/Base/Helper";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    tasks: [],
    stepReflections: {},
    fetched: false,
    profile: null,
    quickReflection: {
      show: false,
    },
    showThankYou: false,
    show_feedback: false,

  },
  reducers: {
    updateTasks: (state, action) => {
      HELPERS.log("sorted tasks", HELPERS.orderTasks(action.payload));
      state.tasks = HELPERS.orderTasks(action.payload);
    },
    updateStepReflections: (state, action) => {
      let stepReflections = {};

      for (var reflection of action.payload) {
        stepReflections[reflection.step] = reflection;
      }

      state.stepReflections = stepReflections;
    },
    updateFetched: (state, action) => {
      state.fetched = action.payload;
    },
    updateStepReflection: (state, action) => {
      HELPERS.log(`Received some payload for updateStepReflection`, action);
      let stepReflections = state.stepReflections;

      HELPERS.log(`Updating`, stepReflections[action.payload.stepKey]);
      stepReflections[action.payload.stepKey] = {
        ...stepReflections[action.payload.stepKey],
        ...action.payload.data,
      };

      state.stepReflections = stepReflections;
    },
    updateTask: (state, action) => {
      HELPERS.log(`Received some payload for updateTask`, action);
      let tasks = state.tasks;
      let add = true;

      for (var index = 0; index < tasks.length; ++index) {
        if (tasks[index].id === action.payload.id) {
          tasks[index] = action.payload;

          if (action.payload.step_reflection) {
            let stepReflections = state.stepReflections;
            for (let stepReflection in stepReflections) {
              if (
                stepReflections[stepReflection].id ===
                action.payload.step_reflection
              ) {
                stepReflections[stepReflection].complete_by =
                  action.payload.due_date;
                break;
              }
            }
          }

          add = false;
          break;
        }
      }

      if (add) {
        tasks.push(action.payload);
      }

      HELPERS.log("sorted tasks", HELPERS.orderTasks(tasks));
      state.tasks = HELPERS.orderTasks(tasks);

      HELPERS.log("updateTask", state.tasks);
    },
    deleteTask: (state, action) => {
      HELPERS.log(`Received some payload for updateTask`, action);
      let tasks = state.tasks;

      for (var index = 0; index < tasks.length; ++index) {
        if (tasks[index].id === action.payload) {
          tasks.splice(index, 1);
          break;
        }
      }

      state.tasks = HELPERS.orderTasks(tasks);

      HELPERS.log("updateTask", state.tasks);
    },
    updateProfile: (state, action) => {
      state.profile = { ...state.profile, ...action.payload };
    },
    updateQuickReflection: (state, action) => {
      HELPERS.log(`updateQuickReflection called with payload`, action);
      state.quickReflection = action.payload;
    },
    setShowThankYou: (state, action) => {
      state.showThankYou = action.payload;
    },
    setShowFeedback: (state, action) => {
      state.show_feedback = action.payload;
    },
    addTask: (state, action) => {
      HELPERS.log(`Received some payload for addTask`, action);
      let tasks = state.tasks;
      tasks.push(action.payload);
      HELPERS.log("sorted tasks", HELPERS.orderTasks(tasks));
      state.tasks = HELPERS.orderTasks(tasks);
    },
  },
});

export const {
  updateTasks,
  updateStepReflections,
  updateFetched,
  updateStepReflection,
  updateProfile,
  updateTask,
  deleteTask,
  updateQuickReflection,
  setShowThankYou,
  setShowFeedback,
  addTask,
} = dashboardSlice.actions;

export const fetchDataAsync = () => (dispatch) => {
  DASHBOARD_API.get().then((resp) => {
    if (resp && resp.data) {
      console.log("$$woken$$", "dashboard data", resp.data);
      HELPERS.log("updateFetched");
      dispatch(updateFetched(true));
      HELPERS.log("updateTasks");
      dispatch(updateTasks(resp.data.tasks));
      HELPERS.log("updateStepReflections");
      dispatch(setShowFeedback(resp.data.show_feedback))
      dispatch(updateStepReflections(resp.data.stepReflections));
      dispatch(
        updateProfile({
          first_name: resp.data.first_name,
          last_name: resp.data.last_name,
          hours_per_week_commitment: resp.data.hours_per_week_commitment,
          daily_commitment: resp.data.daily_commitment,
          country: resp.data.country,
          country_code: resp.data.country_code,
          dob: resp.data.dob,
          email: resp.data.email,
          phone: resp.data.phone,
          city: resp.data.city,
          state: resp.data.state,
          configurations: resp.data.configurations,
          date_joined: resp.data.date_joined,
          impersonated: resp.data.impersonated,
        })
      );
    }
  });
};

export const fetchTasksAsync = () => (dispatch) => {
  DASHBOARD_API.fetchTasksAsync().then((resp) => {
    if (resp && resp.data) {
      HELPERS.log("updateTasks");
      dispatch(updateTasks(resp.data));
    }
  });
};

export const setStepGoalAsync = (stepId, stepKey, target, taskTitle) => (
  dispatch
) => {
  return DASHBOARD_API.setGoal(stepId, target, taskTitle).then((resp) => {
    HELPERS.log(`Received response for setStepGoalAsync`, resp);
    if (resp && resp.data && resp.data.complete_by) {
      dispatch(
        updateStepReflection({
          stepId,
          stepKey,
          data: { complete_by: resp.data.complete_by },
        })
      );
      dispatch(updateTask(resp.data.task));
    }
  });
};

export const setStepNotifiedAsync = (stepId, stepKey) => (dispatch) => {
  return DASHBOARD_API.setStepNotified(stepId).then((resp) => {
    HELPERS.log(`Received response for setStepNotifiedAsync`, resp);
    if (resp && resp.data && resp.data.notified) {
      dispatch(
        updateStepReflection({
          stepId,
          stepKey,
          data: { notified: resp.data.notified, status: resp.data.status },
        })
      );
    }
  });
};

export const updateStepReflectionAsync = (stepId, data) => (dispatch) => {
  return DASHBOARD_API.updateStepReflection(stepId, data).then((resp) => {
    HELPERS.log(`Received response for updateStepReflectionAsync`, resp);
    dispatch(
      updateStepReflection({
        data: resp.data,
      })
    );
  });
};

export const updateStepReflectionKPIAsync = (stepId, data) => (dispatch) => {
  return DASHBOARD_API.updateStepReflection(stepId, data).then((resp) => {
    HELPERS.log(`Received response for updateStepReflectionKPIAsync`, resp);
    dispatch(
      updateStepReflection({
        data: resp.data,
      })
    );
    dispatch(updateQuickReflection({ show: false, stepId: null }));
    dispatch(setShowThankYou(true));
  });
};

const updateYourCommitmentAsync = (data) => (dispatch) => {
  return AUTH_API.updateYourCommitment(data).then((resp) => {
    HELPERS.log(`Received response for updateYourCommitmentAsync`, resp);
    if (resp && resp.data) {
      dispatch(updateProfile(data));
    }
  });
};

export const updateHoursPerWeekCommitmentAsync = (hours) =>
  updateYourCommitmentAsync({
    hours_per_week_commitment: hours,
  });

export const updateProfileAsync = (data, Stepname) => (dispatch) => {
  HELPERS.log("Executing updateProfileAsync...", data, Stepname);
  DASHBOARD_API.updateProfile(data, Stepname).then((resp) => {
    HELPERS.log("Received response for updateProfileAsync", resp, Stepname);
    dispatch(updateProfile(resp.data));
  });
};

export const addTaskAsync = (data) => (dispatch) => {
  HELPERS.log("Executing addTaskAsync...");
  DASHBOARD_API.addTask(data).then((resp) => {
    HELPERS.log("Received response for addTaskAsync", resp.data);
    dispatch(addTask(resp.data));
  });
};

export const updateTaskAsync = (id, data) => (dispatch) => {
  HELPERS.log("Executing updateTaskAsync...");
  DASHBOARD_API.updateTask(id, data).then((resp) => {
    HELPERS.log("Received response for updateTaskAsync", resp.data);
    dispatch(updateTask(resp.data));
  });
};

export const deleteTaskAsync = (id) => (dispatch) => {
  HELPERS.log("Executing deleteTaskAsync...");
  DASHBOARD_API.deleteTask(id)
    .then((resp) => {
      HELPERS.log("Received response for deleteTaskAsync", resp.data);
      dispatch(deleteTask(id));
    })
    .catch((err) => {
      HELPERS.log("error in API");
    });
};

export const selectTasks = (state) => state.dashboard.tasks;
export const selectFetched = (state) => state.dashboard.fetched;
export const selectProfile = (state) => state.dashboard.profile;
export const selectQuickReflectionInfo = (state) =>
  state.dashboard.quickReflection;
export const selectShowThankYou = (state) => state.dashboard.showThankYou;
export const selectConfigurations = (state) =>
  state.dashboard.profile ? state.dashboard.profile.configurations : {};
export const selectStepReflections = (state) => state.dashboard.stepReflections;
export const selectStepReflection = (state) => (stepKey) =>
  state.dashboard.stepReflections[stepKey];
export const selectAssessmentStepInfo = (state) =>
  state.dashboard.stepReflections[STEP_KEY.Assessment];
export const selectTargetRolesStepInfo = (state) =>
  state.dashboard.stepReflections[STEP_KEY.TargetRoles];
export const selectTargetIndustriesStepInfo = (state) =>
  state.dashboard.stepReflections[STEP_KEY.TargetIndustries];
export const selectResearchRolesStepInfo = (state) =>
  state.dashboard.stepReflections[STEP_KEY.ResearchRoles];
export const selectResearchIndustriesStepInfo = (state) =>
  state.dashboard.stepReflections[STEP_KEY.ResearchIndustries];
export const selectNetworkingStepInfo = (state) => [
  state.dashboard.stepReflections[STEP_KEY.Contacts],
  state.dashboard.stepReflections[STEP_KEY.EmailDrafts],
  state.dashboard.stepReflections[STEP_KEY.Tracker],
  state.dashboard.stepReflections[STEP_KEY.CallPrep],
  state.dashboard.stepReflections[STEP_KEY.CallNotes],
];
export const selectNetworkingReflectionsStepInfo = (state) =>
  state.dashboard.stepReflections[STEP_KEY.NetworkingReflections];
export const selectLearningOpportunityStepInfo = (state) =>
  state.dashboard.stepReflections[STEP_KEY.LearningOpportunities];
export const selectMaterialsStepInfo = (state) => [
  state.dashboard.stepReflections[STEP_KEY.ProfessionalSummary],
  state.dashboard.stepReflections[STEP_KEY.Resume],
  state.dashboard.stepReflections[STEP_KEY.CoverLetter],
  state.dashboard.stepReflections[STEP_KEY.Linkedin],
];
export const selectJobSearchStepInfo = (state) => [
  state.dashboard.stepReflections[STEP_KEY.ApplicationTracker],
  state.dashboard.stepReflections[STEP_KEY.InterviewResearch],
  state.dashboard.stepReflections[STEP_KEY.InterviewPractice],
];
export const selectJobReflectionsStepInfo = (state) =>
  state.dashboard.stepReflections[STEP_KEY.Reflections];

export default dashboardSlice.reducer;
