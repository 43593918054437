import React, { Component, useState } from "react";
import moment from "moment";
import { Row, Col, Input, Button, Form, Slider, message } from "antd";
import HELPERS from "utils/helper";
import AUTH_API from "./../../../api/auth";
import Img from "../../../Assets/noun_think_2157497.svg";

// import  "./WeeklyReflection.css"
const { TextArea } = Input;

export default class ReflectionAboutYourProcess extends Component {
  formRef = React.createRef();

  syncForm = () => {
    console.log(
      this.formRef.current.getFieldValue("stress_level"),
      this.props.data.stress_level
    );
    if (
      this.formRef.current
      // && this.formRef.current.getFieldValue("stress_level") !==
      //   this.props.data.stress_level
    ) {
      HELPERS.log("We need change, process", this.props.data);
      this.formRef.current.setFieldsValue(this.props.data);
    }
  };

  componentDidMount = () => {
    console.log("in didmnt process");
    this.syncForm();
  };

  componentDidUpdate = () => {
    HELPERS.log("We got update", this.props.data);
    this.syncForm();
  };

  onFinish = (values) => {
    HELPERS.log(values);
    this.props.onSubmit(this.props.reflectionId, values);
    this.setState({ showThanks: true });
  };

  isOverdue = () => {
    console.log(this.props.data);
    const releasedOn = moment(this.props.data.released_on).add(7, "days");
    return moment(new Date()).isAfter(releasedOn);
  };


  
  onKeyPressed = () => {
    HELPERS.resetPing();
  };

  OnFormChange = (change_values, all_values) => {
    // if (!this.props.data.completed) {
      const currentStep = HELPERS.getCurrentStep();
      const id = all_values.id;

      AUTH_API.patchReflectionsProcess(all_values, id, currentStep)
        .then((response) => {
          console.log(response.data);
          message.success("Your work has been saved successfully");
        })
        .catch((error) => {
          message.error(
            "We could not save your work due to an error. Kindly be patient as we work to resolve it. "
          );
          console.log("Reflections About Process NOT Patched", error);
        });
    // }
  };

  render() {
    console.log("is overdue", this.props.data, this.isOverdue());
    return (
      <Form
        ref={this.formRef}
        name="weekly-reflection-about-your-process"
        onFinish={this.onFinish}
        onFinishFailed={() => {
          message.error("Please fill all the required fields first!");
        }}
        onValuesChange={HELPERS.debounceFormChanges(this.OnFormChange)}
      >
        <div>
          <Row>
            <Col>
              <img
                style={{ marginLeft: -20 }}
                src={
                  "https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/noun_think_2157497.ad0d2dc4.svg"
                }
                width="100"
                height="50"
                alt=""
              />
            </Col>
            <p
              style={{
                fontSize: 20,
                marginLeft: -5,
                marginTop: 25,
                font: "normal normal bold 20px/12px Montserrat",
                color: "#6A7ACB",
                textAlign: "left",
              }}
            >
              Reflection about your Process
            </p>
          </Row>
          <div className="" onKeyDown={this.onKeyPressed}>
            <p style={{ marginTop: 50 }}>1. What went well this week?</p>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              name={["reflections", "q_1"]}
              noStyle
            >
              <TextArea
                style={{ marginTop: 16, height: 44, width: "96%" }}
              ></TextArea>
            </Form.Item>
          </div>
          <div className="">
            <p style={{ marginTop: 90 }}>2. What didn't go well this week?</p>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              name={["reflections", "q_2"]}
              noStyle
            >
              <TextArea
                style={{ marginTop: 16, height: 44, width: "96%" }}
              ></TextArea>
            </Form.Item>
          </div>
          <div className="">
            <p style={{ marginTop: 90 }}>
              3. Based on what didn’t go so well, how will you improve your
              process next week?
            </p>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              name={["reflections", "q_3"]}
              noStyle
            >
              <TextArea
                style={{ marginTop: 16, height: 44, width: "96%" }}
              ></TextArea>
            </Form.Item>
          </div>
          <div className="">
            <p style={{ marginTop: 90 }}>
              4. What did you learn from online research this week?
            </p>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              name={["reflections", "q_4"]}
              noStyle
            >
              <TextArea
                style={{ marginTop: 16, height: 44, width: "96%" }}
              ></TextArea>
            </Form.Item>
          </div>
          <div className="">
            <p style={{ marginTop: 90 }}>
              5. What did you learn from your networking this week?
            </p>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              name={["reflections", "q_5"]}
              noStyle
            >
              <TextArea
                style={{ marginTop: 16, height: 44, width: "96%" }}
              ></TextArea>
            </Form.Item>
          </div>
          <div className="">
            <p style={{ marginTop: 90 }}>
              6. What experiential learning opportunities did you learn about
              this week?
            </p>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              name={["reflections", "q_6"]}
              noStyle
            >
              <TextArea
                style={{ marginTop: 16, height: 44, width: "96%" }}
              ></TextArea>
            </Form.Item>
          </div>
          <div className="">
            <p style={{ marginTop: 90 }}>
              7. What questions do you have for your coach?
            </p>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              name={["reflections", "q_7"]}
              noStyle
            >
              <TextArea
                style={{ marginTop: 16, height: 44, width: "96%" }}
              ></TextArea>
            </Form.Item>
          </div>
          <p
            style={{
              marginTop: 72,
              font: "normal normal bold 20px/12px Montserrat",
              color: "#606368",
            }}
          >
            Additional Reflections
          </p>
          <div className="">
            <p
              style={{
                marginTop: 40,
                textAlign: "left",
                font: "normal normal normal 16px/44px Montserrat",
                color: "#414141",
              }}
            >
              How stressed are you about figuring out your next career step?
            </p>
            <Form.Item name="stress_level" noStyle>
              <Slider style={{ width: "50%" }} min={0} max={10} />
            </Form.Item>
            <Row style={{ width: "50%" }}>
              <Col span={12}>
                <p style={{ textAlign: "left", color: "#898E8E" }}>
                  I’m very relaxed
                </p>
              </Col>
              <Col span={12}>
                <p style={{ textAlign: "right", color: "#898E8E" }}>
                  I’m feeling extremely stressed
                </p>
              </Col>
            </Row>
          </div>
          <div className="">
            <p
              style={{
                marginTop: 40,
                textAlign: "left",
                font: "normal normal normal 16px/44px Montserrat",
                color: "#414141",
              }}
            >
              What is your level of clarity on your ideal career path?
            </p>
            <Form.Item name="clarity_level" noStyle>
              <Slider style={{ width: "50%" }} min={0} max={10} />
            </Form.Item>
            <Row style={{ width: "50%" }}>
              <Col span={12}>
                <p style={{ textAlign: "left", color: "#898E8E" }}>
                  I have no idea what I want
                </p>
              </Col>
              <Col span={12}>
                <p style={{ textAlign: "right", color: "#898E8E" }}>
                  I know exactly what I want
                </p>
              </Col>
            </Row>
          </div>
          <div className="">
            <p
              style={{
                marginTop: 40,
                textAlign: "left",
                font: "normal normal normal 16px/44px Montserrat",
                color: "#414141",
              }}
            >
              How confident are you in how to approach the career exploration
              process?
            </p>
            <Form.Item name="confidence_level" noStyle>
              <Slider style={{ width: "50%" }} min={0} max={10} />
            </Form.Item>
            <Row style={{ width: "50%" }}>
              <Col span={12}>
                <p style={{ textAlign: "left", color: "#898E8E" }}>
                  I have no clue what to do
                </p>
              </Col>
              <Col span={12}>
                <p style={{ textAlign: "right", color: "#898E8E" }}>
                  I know exactly what to do
                </p>
              </Col>
            </Row>
          </div>

          <Button
            style={{
              width: 255,
              height: 42,
              marginTop: 35,
              marginLeft: "80%",
              background: "#49CDE1 0% 0% no-repeat padding-box",
              font: "normal normal bold 16px/44px Montserrat",
              paddingTop: 0,
            }}
            type="primary"
            htmlType="submit"
            disabled={this.props.data.completed || this.isOverdue()}
          >
            Submit to Coach
          </Button>
        </div>
      </Form>
    );
  }
}
