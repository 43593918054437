import React from "react";
import { Link } from "react-router-dom";
import { Modal, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";

export default function Sorry(props) {
  const icon = (

      <CloseOutlined className="close-icon" style={{background: "red"}} onClick={props.onClose} />
  );
  return (
    <Modal
      error
      title=""
      visible={props.visible}
      onCancel={props.onClose}
      footer={null}
      width="40%"
      style={{ textAlign: "center" }}
      closeIcon={icon}
    >
      <div>
        <p
          style={{
            font: "normal normal bold 24px/12px Montserrat",
            textAlign: "center",
            margin: "80px 0 60px 0",
          }}
        >
          Sorry!
        </p>

        <p style={{ margin: "50px 0 90px 0" }}>
         {props.message}
        </p>


          <Button
            type="primary"
            style={{ marginBottom: "90px", width: "200px" }}
            size="large"
            onClick={props.onClose}
          >
            Okay
          </Button>
      </div>
    </Modal>
  );
}
