import React from "react";
import {
  Row,
  Col,
  Drawer,
  Input,
  Select,
  Tag,
  DatePicker,
  Button,
  Popconfirm,
} from "antd";
import { PlusOutlined, AlignLeftOutlined } from "@ant-design/icons";
import "./Tracker.css";
import AUTH_API from "../../../api/auth";
import moment from "moment";
import { Link } from "react-router-dom";

const { Option } = Select;

export default class Contactdrawer extends React.Component {
  state = {
    contact: { ...this.props.record },
  };

  onDelete = () => {
    // console.log('delete')
    // AUTH_API.deleteContact(this.props.record.id).then(
    //   res=>{
    //     console.log('deleted!!', res)
    //     this.onClose()
    //   }
    // ).catch(
    //   err=>console.log('del err', err)
    // )
    AUTH_API.patchContact({ selected: false }, this.props.record.id, "NT")
      .then((res) => {
        console.log("deleted!!", res);
        this.props.onClose();
      })
      .catch((err) => console.log("del err", err));
  };

  onChange = (val, type) => {
    console.log("val", val, typeof val);
    let contact = { ...this.state.contact };
    if (val) {
      console.log(val, type);
      const patch_data = {};
      let tracker_data = {};
      patch_data[type] = val;
      console.log(patch_data);
      let outside_tracker = [
        "title",
        "contact_type",
        "roles",
        "industries",
        "email",
        "phone",
      ];
      if (outside_tracker.filter((item) => item === type).length) {
        contact = { ...contact, ...patch_data };
      } else {
        // contact={...contact, ...patch_data}
        tracker_data = { ...contact.tracker_info, ...patch_data };
        contact.tracker_info = tracker_data;
      }
      console.log("contact now!", contact);
      this.setState({
        contact,
      });
    }
  };

  onSave = () => {
    console.log("save", this.state.contact);
    AUTH_API.patchContact({ ...this.state.contact }, this.props.record.id, "NT")
      .then((res) => {
        console.log("saved!!", res);
        this.props.onClose();
      })
      .catch((err) => console.log("patch err", err));
  };

  render() {
    const CONTACT = { ...this.state.contact };
    const TRACKER = { ...this.state.contact.tracker_info };
    console.log("drawer!!!", CONTACT, TRACKER);

    const title = (
      <div className="drawer-title">
        <p className="contact-name-head">{this.props.record.title}</p>
        <p className="drawer-subhead">
          You can edit information about your contact here.
        </p>
      </div>
    );

    const footer = (
      <Row style={{ padding: 20 }}>
        <Col span={8}>
          <Popconfirm
            title="Are you sure you want to delete ?"
            onConfirm={this.onDelete}
          >
            <Button
              // onClick={this.onDelete}
              block
              size="large"
              style={{ color: "#49CDE1" }}
            >
              Delete Contact
            </Button>
          </Popconfirm>
        </Col>
        <Col span={8}></Col>
        <Col span={8}>
          <Button type="primary" onClick={this.onSave} block size="large">
            Save changes
          </Button>
        </Col>
      </Row>
    );

    return (
      <Drawer
        title={title}
        placement="right"
        onClose={this.props.onClose}
        visible={this.props.show_drawer}
        width={640}
        footer={footer}
      >
        <div style={{ padding: "5px 10px" }}>
          <div>
            <p className="contact-head-dr">Contact Info</p>
            <hr className="col-gap"></hr>
            <div style={{ padding: "5px 10px" }}>
              <Row style={{ padding: "10px 0" }}>
                <Col span={6}>
                  <p className="info-type">{"Name"}</p>
                </Col>
                <Col span={18}>
                  <Input
                    defaultValue={CONTACT.title}
                    placeholder="Enter a name"
                    size={"small"}
                    bordered={false}
                    onBlur={(val) => this.onChange(val.target.value, "title")}
                  />
                </Col>
              </Row>

              <Row style={{ padding: "10px 0" }}>
                <Col span={6}>
                  <p className="info-type">{"Phone number"}</p>
                </Col>
                <Col span={18}>
                  <Input
                    placeholder="Type Something"
                    defaultValue={CONTACT.phone}
                    placeholder="Enter a phone number"
                    size={"small"}
                    bordered={false}
                    onBlur={(val) => this.onChange(val.target.value, "phone")}
                  />
                </Col>
              </Row>

              <Row style={{ padding: "10px 0" }}>
                <Col span={6}>
                  <p className="info-type">{"Email Adress"}</p>
                </Col>
                <Col span={18}>
                  <Input
                    placeholder="Type Something"
                    defaultValue={CONTACT.email}
                    placeholder="Enter an email"
                    size={"small"}
                    bordered={false}
                    onBlur={(val) => this.onChange(val.target.value, "email")}
                  />
                </Col>
              </Row>

              <Row style={{ padding: "10px 0" }}>
                <Col span={6}>
                  <p className="info-type">{"Type"}</p>
                </Col>
                {/* {console.log('category', this.props.category_mapping[CONTACT.contact_type])} */}
                <Col span={18}>
                  <Select
                    key={`type-f`}
                    onChange={(val) => this.onChange(val, "contact_type")}
                    defaultValue={
                      this.props.category_mapping[CONTACT.contact_type]
                    }
                    style={{ maxWidth: "100%", minWidth: "100%" }}
                    bordered={false}
                  >
                    {[...Object.values(this.props.category_mapping)].map(
                      (item, ind) => (
                        <Option
                          key={ind + "type"}
                          value={
                            Object.keys(this.props.category_mapping)[
                              Object.values(
                                this.props.category_mapping
                              ).indexOf(item)
                            ]
                          }
                        >
                          {item}
                        </Option>
                      )
                    )}
                  </Select>
                </Col>
              </Row>

              <Row style={{ padding: "10px 0" }}>
                <Col span={6}>
                  <p className="info-type">{"Role"}</p>
                </Col>
                <Col span={18}>
                  <Select
                    key={`role-f`}
                    onChange={(val) => this.onChange(val, "roles")}
                    defaultValue={CONTACT.roles}
                    placeholder="Select role"
                    style={{ maxWidth: "100%", minWidth: "100%" }}
                    bordered={false}
                  >
                    {this.props.roles.map((item, ind) => (
                      <Option key={ind + "role"} value={item.title}>
                        {item.title}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </Row>

              <Row style={{ padding: "10px 0" }}>
                <Col span={6}>
                  <p className="info-type">{"Industry"}</p>
                </Col>
                <Col span={18}>
                  <Select
                    key={`ind-f`}
                    onChange={(val) => this.onChange(val, "industries")}
                    defaultValue={CONTACT.industries}
                    placeholder="Select industry"
                    style={{ maxWidth: "100%", minWidth: "100%" }}
                    bordered={false}
                  >
                    {this.props.industries.map((item, ind) => (
                      <Option key={ind + "ind"} value={item.title}>
                        {item.title}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </Row>

              {this.props.columns.contactinfo.map(
                (col, index) =>
                  index > 3 && (
                    <Row style={{ padding: "10px 0" }}>
                      {/* {console.log(col)} */}
                      <Col span={6}>
                        <p className="info-type">{col.title}</p>
                      </Col>
                      <Col span={18}>
                        <Input
                          placeholder="Type Something"
                          defaultValue={TRACKER[col.key]}
                          size={"small"}
                          bordered={false}
                          onBlur={(val) => {
                            this.onChange(val.target.value, col.key);
                          }}
                        />
                      </Col>
                    </Row>
                  )
              )}
            </div>
          </div>

          <div>
            <p className="contact-head-dr">Reach Out</p>
            <hr className="col-gap"></hr>
            <div style={{ padding: "5px 10px" }}>
              {this.props.columns.reachout.map((col, index) =>
                index > 1 ? (
                  <Row style={{ padding: "10px 0" }}>
                    {/* {console.log(col)} */}
                    <Col span={6}>
                      <p className="info-type">{col.title}</p>
                    </Col>
                    <Col span={18}>
                      <Input
                        placeholder="Type Something"
                        defaultValue={TRACKER[col.key]}
                        size={"small"}
                        bordered={false}
                        onBlur={(val) => {
                          this.onChange(val.target.value, col.key);
                        }}
                      />
                    </Col>
                  </Row>
                ) : (
                  <Row style={{ padding: "10px 0" }}>
                    {/* {console.log(col)} */}
                    <Col span={6}>
                      <p className="info-type">{col.title}</p>
                    </Col>
                    <Col span={18}>
                      <DatePicker
                        size="small"
                        onChange={(date, dateString) =>
                          this.onChange(dateString, col.key)
                        }
                        defaultValue={
                          TRACKER[col.key] ? moment(TRACKER[col.key]) : null
                        }
                        bordered={false}
                      />
                    </Col>
                  </Row>
                )
              )}
            </div>
          </div>

          <div>
            <p className="contact-head-dr">Introductions</p>
            <hr className="col-gap"></hr>
            <div style={{ padding: "5px 10px" }}>
              {this.props.columns.intro.map((col, index) => (
                <Row style={{ padding: "10px 0" }}>
                  {/* {console.log(col)} */}
                  <Col span={6}>
                    <p className="info-type">{col.title}</p>
                  </Col>
                  <Col span={18}>
                    <Input
                      placeholder="Type Something"
                      defaultValue={TRACKER[col.key]}
                      size={"small"}
                      bordered={false}
                      onBlur={(val) => {
                        this.onChange(val.target.value, col.key);
                      }}
                    />
                  </Col>
                </Row>
              ))}
            </div>
          </div>

          <div>
            <p className="contact-head-dr">Before call</p>
            <hr className="col-gap"></hr>
            <div style={{ padding: "5px 10px" }}>
              <Row style={{ padding: "10px 0" }}>
                {/* {console.log(col)} */}
                <Col span={6}>
                  <p className="info-type">Scheduled Call Date</p>
                </Col>
                <Col span={18}>
                  <DatePicker
                    size="small"
                    bordered={false}
                    onChange={(date, dateString) =>
                      this.onChange(dateString, "scheduled")
                    }
                    defaultValue={
                      TRACKER["scheduled"] ? moment(TRACKER["scheduled"]) : null
                    }
                  />
                </Col>
              </Row>

              <Row style={{ padding: "10px 0" }}>
                {/* {console.log(col)} */}
                <Col span={6}>
                  <p className="info-type">Call Prep</p>
                </Col>
                <Col span={18}>
                  {CONTACT.call_prep.length ? (
                    <Link
                      to={{
                        pathname: "/networking/call-prep",
                        id: this.state.contact.id,
                      }}
                    >
                      <AlignLeftOutlined className="row-btn" />
                    </Link>
                  ) : (
                    <Link
                      to={{
                        pathname: "/networking/call-prep",
                        id: this.state.contact.id,
                      }}
                    >
                      <PlusOutlined className="row-btn" />
                    </Link>
                  )}
                </Col>
              </Row>

              <Row style={{ padding: "10px 0" }}>
                {/* {console.log(col)} */}
                <Col span={6}>
                  <p className="info-type">Call Prep Done</p>
                </Col>
                <Col span={18}>
                  <Select
                    key={CONTACT.id + "callprepdone"}
                    defaultValue={
                      CONTACT.callprepdone
                        ? CONTACT.callprepdone
                        : "Not Started"
                    }
                    onSelect={(val) => this.onChange(val, "callprepdone")}
                    bordered={false}
                  >
                    <Option
                      key={CONTACT.id + "callpdone11"}
                      value="Not Started"
                    >
                      Not Started
                    </Option>
                    <Option
                      key={CONTACT.id + "callpdone22"}
                      value="In Progress"
                    >
                      In Progress
                    </Option>
                    <Option key={CONTACT.id + "callpdone33"} value="Done">
                      Done
                    </Option>
                  </Select>
                </Col>
              </Row>
              {this.props.columns.beforecall.map(
                (col, index) =>
                  index > 2 && (
                    <Row style={{ padding: "10px 0" }}>
                      {/* {console.log(col)} */}
                      <Col span={6}>
                        <p className="info-type">{col.title}</p>
                      </Col>
                      <Col span={18}>
                        <Input
                          placeholder="Type Something"
                          defaultValue={TRACKER[col.key]}
                          size={"small"}
                          bordered={false}
                          onBlur={(val) => {
                            this.onChange(val.target.value, col.key);
                          }}
                        />
                      </Col>
                    </Row>
                  )
              )}
            </div>
          </div>

          <div>
            <p className="contact-head-dr">Call</p>
            <hr className="col-gap"></hr>
            <div style={{ padding: "5px 10px" }}>
              <Row style={{ padding: "10px 0" }}>
                {/* {console.log(col)} */}
                <Col span={6}>
                  <p className="info-type">Call notes</p>
                </Col>
                <Col span={18}>
                  {CONTACT.notes.length ? (
                    <Link
                      to={{
                        pathname: "/networking/call-notes",
                        id: this.state.contact.id,
                      }}
                    >
                      <AlignLeftOutlined className="row-btn" />
                    </Link>
                  ) : (
                    <Link
                      to={{
                        pathname: "/networking/call-notes",
                        id: this.state.contact.id,
                      }}
                    >
                      <PlusOutlined className="row-btn" />
                    </Link>
                  )}
                </Col>
              </Row>
              {this.props.columns.call.map(
                (col, index) =>
                  index > 0 && (
                    <Row style={{ padding: "10px 0" }}>
                      {/* {console.log(col)} */}
                      <Col span={6}>
                        <p className="info-type">{col.title}</p>
                      </Col>
                      <Col span={18}>
                        <Input
                          placeholder="Type Something"
                          defaultValue={TRACKER[col.key]}
                          size={"small"}
                          bordered={false}
                          onBlur={(val) => {
                            this.onChange(val.target.value, col.key);
                          }}
                        />
                      </Col>
                    </Row>
                  )
              )}
            </div>
          </div>

          <div>
            <p className="contact-head-dr">After Call</p>
            <hr className="col-gap"></hr>
            <div style={{ padding: "5px 10px" }}>
              {this.props.columns.aftercall.map((col, index) => (
                <Row style={{ padding: "10px 0" }}>
                  {/* {console.log(col)} */}
                  <Col span={6}>
                    <p className="info-type">{col.title}</p>
                  </Col>
                  <Col span={18}>
                    <Input
                      placeholder="Type Something"
                      defaultValue={TRACKER[col.key]}
                      size={"small"}
                      bordered={false}
                      onBlur={(val) => {
                        this.onChange(val.target.value, col.key);
                      }}
                    />
                  </Col>
                </Row>
              ))}
            </div>
          </div>
        </div>
      </Drawer>
    );
  }
}
