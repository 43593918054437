import { Button, Checkbox, Col, Form, Row, message } from "antd";
import React from "react";
import { connect } from "react-redux";
import { updateQuickReflection } from "slices/dashboardSlice";
import AUTH_API from "../../../api/auth";
import "./LinkedIn.css";

// const { TextArea } = Input;

class LinkedIn extends React.Component {
  formRef = React.createRef();
  inputRef = React.createRef();

  state = {
    submitted: false,
    Api_response: [],
    headline: "",
    line_through: false,
  };
  componentDidMount() {
    AUTH_API.getMaterials()
      .then((response) => {
        console.log("get Api data", response);
        const form_values =
          response.data.length > 0 ? response.data[0].linked_in : "";
        this.setState({
          Api_response: response.data.length > 0 ? response.data[0] : "",
          headline: response.data[0].linked_in.headline
            ? response.data[0].linked_in.headline
            : "",
        });
        // if(form_values.GS){
        //     const arr = form_values.GS;
        //     console.log("arr",arr)
        //     if(arr.includes('GS5')){
        //         this.setState({line_through:true})
        //         console.log("state set for line through")
        //     }
        //     else{
        //         this.setState({line_through:false})

        //     }
        // }
        this.formRef.current.setFieldsValue({
          ...form_values,
        });
      })
      .catch((err) => console.log("LinkedIn get Api error!", err));
  }
  onFormChange = (changed_values, all_values) => {
    console.log("changed_values:", changed_values, "all values:", all_values);
    // if(all_values.GS){
    //     const arr = all_values.GS;
    //     console.log("arr",arr)
    //     if(arr.includes('GS5')){
    //         this.setState({line_through:true})
    //         console.log("state set for line through")
    //     }
    //     else{
    //         this.setState({line_through:false})

    //     }
    // }
    let form_values = all_values;
    form_values["headline"] = this.inputRef.current.value;
    console.log("form data:", form_values);
    const data = {
      linked_in: form_values,
    };
    const id = this.state.Api_response.id;
    AUTH_API.patchMaterials(data, id, "LK")
      .then((response) => {
        message.success('Your work has been saved')
        console.log("LinkedIn patch Api data", response);
        const form_values =
          response.data.length > 0 ? response.data[0].linked_in : "";
        this.formRef.current.setFieldsValue({
          ...form_values,
        });
      })
      .catch((err) => console.log("LinkedIn patch Api error!", err));
  };
  HeadlineChange = (e) => {
    this.setState({ headline: e.target.value });
    let form_values = this.formRef.current.getFieldsValue();
    console.log("form_values:", form_values, e.target.value);
    form_values["headline"] = e.target.value;
    console.log("form_values:", form_values);
    const data = {
      linked_in: form_values,
    };
    const id = this.state.Api_response.id;
    AUTH_API.patchMaterials(data, id, "LK")
      .then((response) => {
        console.log("LinkedIn patch Api data", response);
        const form_values =
          response.data.length > 0 ? response.data[0].linked_in : "";
        this.formRef.current.setFieldsValue({
          ...form_values,
        });
      })
      .catch((err) => console.log("LinkedIn patch Api error!", err));
  };

  onSubmit = () => {
    this.setState({ ...this.state, submitted: true });
  };

  render() {
    console.log("linkedin rendered", this.state.headline);
    return (
      <div>
        <h3 className="resume-head">
          Follow this guide while you edit your LinkedIn to enhance your
          profile.
        </h3>

        <Form
          name="linkedIn-form"
          ref={this.formRef}
          onValuesChange={this.onFormChange}
        >
          <div className="res-div">
            <h3 className="res-div-head">Getting Started</h3>

            <Form.Item name="GS">
              <Checkbox.Group style={{ width: "100%" }}>
                <Row>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="GS1">
                        Include a clear professional picture
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="GS2">
                        Include a background picture to replace the generic blue
                        background, it will give your profile a brand
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="GS3">
                        Use keywords that represent who you are as as
                        professional (which both represents your background and
                        where you want to go next)
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="GS4">
                        You can also list one prior role in the headline
                        <br />
                        &nbsp; &nbsp; &nbsp; &nbsp; Example: Human Resources &
                        Workforce Strategy Professional | Prior Consultant at
                        XYZ Firm
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={5}>
                    <div className="custom-cb">
                      <Checkbox value="GS5">Write your headline: </Checkbox>
                    </div>
                  </Col>
                  <Col span={17}>
                    <div>
                      <input
                        // prefix='Write your headline:  '
                        value={this.state.headline}
                        ref={this.inputRef}
                        onChange={(e) => this.HeadlineChange(e)}
                        className="linkedIn-inp"
                        // className={this.state.line_through ? 'line-inp' :'linkedIn-inp'}
                      />
                    </div>
                  </Col>

                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="GS6">
                        Include your professional summary in the about section
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="GS7">
                        Be sure to use keywords you want to be picked up in the
                        algorithm when others search for you in the about
                        section and the headline
                      </Checkbox>
                    </div>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </div>

          <div className="res-div">
            <h3 className="res-div-head">Work Experience Section</h3>

            <Form.Item name="Work Experience">
              <Checkbox.Group style={{ width: "100%" }}>
                <Row>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="WE1">
                        Choose how much detail you want to give for each
                        experience (none, a 1-2 sentence summary about each of
                        your roles, or include all the bullets on your resume)
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="WE2">
                        This is a subjective choice - the more details you
                        provide, the more chance people will know your
                        capabilities and pickup on relevant keywords
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="WE3">
                        I typically suggest to write a 1-2 sentence summary of
                        each role, which gives enough detail for someone to get
                        to know your background but leaves them intrigued enough
                        to reach out and want to learn more
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="WE4">
                        Be sure to use keywords you want to be picked up in the
                        algorithm when others search for you
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="WE6">
                        Add any relevant URL’s or links that relate to your
                        projects
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="WE7">
                        Ensure there is consistency between the resume regarding
                        your employers and dates
                      </Checkbox>
                    </div>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </div>
          <div className="res-div">
            <h3 className="res-div-head">Rounding Out the Profile</h3>

            <Form.Item name="RProfile">
              <Checkbox.Group style={{ width: "100%" }}>
                <Row>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="RP1">
                        Include relevant degrees in your Education section; add
                        details about any clubs or activities you participated
                        in during school, your GPA/Honors, scholarships, etc.
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="RP2">
                        Add any professional groups/organizations you are a part
                        of to the “organizations” section
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="RP3">
                        Add any awards you’ve received to the “awards” section
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="RP4">
                        Add any volunteering experience you have done to the
                        “volunteer” section
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="RP6">
                        Add any relevant projects to the “projects” section
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="RP7">
                        Add relevant skills, i.e. hard skills, soft skills,
                        technical systems that you are proficient in
                      </Checkbox>
                    </div>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </div>
          <div className="res-div">
            <h3 className="res-div-head">
              Utilizing LinkedIn to Your Advantage:
            </h3>

            <Form.Item name="Utilizing">
              <Checkbox.Group style={{ width: "100%" }}>
                <Row>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="U1">
                        Get someone to write you a recommendation so that others
                        know what it’s like to work with you
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="U2">
                        Be as comprehensive as you can, there’s no page limit
                        here!
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="U3">
                        Ensure your contact information is public so that
                        someone can find your email address
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="U4">
                        Review all of your settings to ensure recruiters know
                        you’re available for new opportunities
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="U6">
                        Stay active (post an article or like others’ posts) so
                        others see you pop up on their newsfeed
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="U7">
                        Secure at least 500 connections; connect with all of
                        your peers, past colleagues, family, friends
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="U8">
                        But...don’t just connect on Linkedin for the sake of
                        adding connections - LinkedIn connections should
                        represent your real professional network.
                      </Checkbox>
                    </div>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </div>
          <a
            href="mailto: team@iamwoken.com?subject=WOKEN client inquiry "
            target="_blank"
            className="submit-coach"
            style={{ padding: "12px 0", textAlign: "center" }}
            onClick={() =>
              this.props.updateQuickReflection({
                stepId: this.props.stepId,
                show: true,
              })
            }
          >
            Submit To Coach
          </a>
        </Form>
      </div>
    );
  }
}

const mapDispatchToProps = () => ({
  updateQuickReflection,
});

export default connect(null, mapDispatchToProps())(LinkedIn);
