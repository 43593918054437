import React from "react";
import { Row, Col, Input, Button, Popconfirm, Form, message } from "antd";
import "./email.css";
import AUTH_API from "./../../../api/auth";
import { EditOutlined } from "@ant-design/icons";
import HELPERS from "utils/helper";

const { TextArea } = Input;

export default class AddEmail extends React.Component {
  formRef = React.createRef();

  state = {};

  componentDidMount() {
    this.formRef.current.setFieldsValue({
      ...this.props.obj,
    });
  }

  onFormChange = HELPERS.debounceFormChanges((changed_values, all_values, id) => {
    console.log(
      "changed_values:",
      changed_values,
      "all values:",
      all_values,
      "id is:",
      id
    );
    const data = all_values;
    AUTH_API.patchEmailDrafts(data, id)
      .then((response) => {
        console.log("patch Api data", response);
        message.success("Your work has been saved")
      })
      .catch((err) => console.log("email drafts patch Api error!", err));
  });

  deleteDraft = (id) => {
    AUTH_API.deleteEmailDraft(id)
      .then((response) => {
        console.log("draft deleted", response);
      })
      .catch((err) => console.log("email drafts delete Api error!", err));
  };

  render() {
    console.log("add title", this.props.obj);
    return (
      <>
        <Form
          ref={this.formRef}
          onValuesChange={(c, a) => this.onFormChange(c, a, this.props.obj.id)}
        >
          <Form.Item name="title" style={{ margin: "0" }}>
            <Input
              className="e-card-head"
              style={{ margin: "0" }}
              placeholder="Enter Email Scenario Title Here"
              bordered={false}
              suffix={<EditOutlined />}
            />
          </Form.Item>

          <Form.Item name="description" style={{ margin: "0" }}>
            <Input
              className="e-card-subhead"
              bordered={false}
              style={{ margin: "0" }}
              placeholder="Describe the scenario for this email template"
              suffix={<EditOutlined />}
            />
          </Form.Item>

          <div className="e-card">
            <Row className="e-row">
              <Col span={2}>
                {" "}
                <p className="to">To :</p>
              </Col>
              <Col span={22}>
                <Form.Item name="email_to">
                  <Input className="person" bordered={false} />
                </Form.Item>
              </Col>
            </Row>
            <Row className="e-row">
              <Col span={2}>
                {" "}
                <p className="to">Subject</p>
              </Col>
              <Col span={22}>
                <Form.Item name="subject">
                  <Input className="sub-c" bordered={false} />
                </Form.Item>
              </Col>
            </Row>
            <div className="e-draft">
              <Form.Item name="email_text">
                <TextArea className="intro-draft" rows={6} bordered={false} />
              </Form.Item>
            </div>
            <Popconfirm
              title="Are you sure you want to delete ?"
              onConfirm={() => this.props.deleteDraft(this.props.obj.id)}
            >
              <Button
                style={{
                  float: "right",
                  width: "150px",
                  margin: "50px 0",
                  height: "40px",
                  color: "#49CDE1",
                }}
                block
              >
                Delete
              </Button>
            </Popconfirm>
          </div>
        </Form>
      </>
    );
  }
}
