import React, {useState} from "react";
import {DatePicker, TimePicker, Button, message} from "antd";
import {useDispatch} from "react-redux";
import moment from "moment";
import {CloseCircleFilled} from "@ant-design/icons";
import "./DueDate.css";
import HELPERS from "utils/helper";
import {setStepGoalAsync} from "slices/dashboardSlice";
import AddToCalendar from "react-add-to-calendar";

const closeiconstyle = {
    position: "relative",
    float: "right",
    margin: "-40px",
    color: "#49CDE1",
    fontSize: "25px",
};

const DueDate = (props) => {
    const [isSet, setIsSet] = useState(false); // loads next screen on save button click
    const [visible, setVisible] = useState(true);
    const [date, setDate] = useState(
        props.targetDate && props.targetDate.isValid
            ? props.targetDate.format("YYYY-MM-DD")
            : ""
    );
    const [time, setTime] = useState(
        props.targetDate && props.targetDate.isValid
            ? props.targetDate.format("HH:mm:ss")
            : ""
    );
    const dispatch = useDispatch();

    const sendDispatch = () => {
        //console.log('in sendDispatch')
        dispatch(
            setStepGoalAsync(
                props.stepId,
                props.stepKey,
                `${date}T${time}${timeZoneFormatted}`,
                props.stepName
            )
        )
            .then(() => {
                //console.log('exec onDateset');
                props.onDateSet();
            })
            .catch((err) => HELPERS.error("$$woken$$", err));
    };

    const save = () => {
        HELPERS.log(`Setting target for ${props.stepName} ${date} ${time}.`);

        if (date && time) {
            setIsSet(true);
            //console.log('before delay')
            setTimeout(sendDispatch, 6000);
        } else {
            message.error("Select appropriate goal date & time!");
        }
    };

    // const close = () => {
    //   setVisible(false);
    // };

    const saveDate = (e) => {
        if (e) {
            setDate(e.format("YYYY-MM-DD"));
        }
    };

    const saveTime = (e, time) => {
        if (e) {
            setTime(e.format("HH:mm:ss"));
        }
    };

    HELPERS.log(`Due Date for ${props.stepName}.`, props);
    var timeZoneFormatted = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
    let event = {
        title: props.excludeFinish ? props.stepName : `Finish ${props.stepName}`,
        description: "",
        location: "",
        startTime: `${date}T${time}${timeZoneFormatted}`,
        endTime: `${date}T${time}${timeZoneFormatted}`,
    };

    return (
        <div>
            {visible &&
            (!isSet ? (
                <div
                    style={{
                        background: "#FCF5FD 0% 0% no-repeat padding-box",
                        padding: "30px",
                        margin: "20px 0",
                    }}
                >
                    <div>
                        <CloseCircleFilled
                            onClick={props.onCloseClick}
                            style={closeiconstyle}
                        />
                    </div>
                    <p
                        style={{
                            font: "normal normal bold 20px/12px Montserrat",
                            color: "#C677E3",
                            padding: "20px 0 0px",
                        }}
                    >
                        First, let’s set a goal date to complete {props.stepName}
                    </p>

                    <div style={{display: "flex", alignItems: "baseline"}}>
                        <p
                            style={{
                                font: "normal normal normal 16px/22px Montserrat",
                                padding: "20px 0 0px",
                            }}
                        >
                            I would like to complete this step by
                        </p>
                        <DatePicker
                            style={{margin: "0 10px"}}
                            bordered={false}
                            onChange={saveDate}
                            defaultValue={date ? moment(date, "YYYY-MM-DD") : null}
                        />
                        <TimePicker
                            use12Hours
                            format="h:mm a"
                            style={{margin: "0 10px"}}
                            bordered={false}
                            onChange={saveTime}
                            defaultValue={time ? moment(time, "HH:mm:ss") : null}
                        />


                    </div>
                    {
                        date && time && <AddToCalendar
                        event={event}
                        listItems={[{google: "Add to Google Calendar"}]}
                        optionsOpen={true}

                    />
                    }

                    <div style={{textAlign: "right"}}>
                        <Button
                            type="primary"
                            style={{width: "22%"}}
                            onClick={save}
                            size="large"
                        >
                            {" "}
                            Save
                        </Button>
                    </div>
                </div>
            ) : (
                <div
                    style={{
                        background: "#FCF5FD 0% 0% no-repeat padding-box",
                        padding: "30px",
                    }}
                    className={`${visible ? "alert-shown" : "alert-hidden"}`}
                >
                    <div>
                        <CloseCircleFilled
                            onClick={props.onCloseClick}
                            style={closeiconstyle}
                        />
                    </div>
                    <p
                        style={{
                            font: "normal normal bold 20px/12px Montserrat",
                            color: "#C677E3",
                            paddingBottom: "20px",
                        }}
                    >
                        Nicely done!
                    </p>
                    <p style={{font: "normal normal normal 16px/22px Montserrat"}}>
                        Your Due Date is {date}
                    </p>
                    <p>
                        {/* <a href="#">Add to Calendar&nbsp;</a> */}
                        <AddToCalendar
                            event={event}
                            listItems={[{google: "Add to Google Calendar"}]}
                            optionsOpen={true}
                        />
                        {/* <a href="#">Outlook</a> */}
                    </p>
                </div>
            ))}
        </div>
    );
};

export default DueDate;
