import { CheckCircleFilled } from "@ant-design/icons";
import { Button, Col, Form, Radio, Row, message, Select } from "antd";
import React from "react";
import HELPERS from "utils/helper";
import AUTH_API from "./../../api/auth";

const { Option } = Select;

class Step4 extends React.Component {
  formRef = React.createRef();
  componentDidMount() {
    window.scrollTo(0, 0);
    AUTH_API.ourCommitmentGet()
      .then((response) => {
        const data = response.data;
        console.log("data", data);
        this.formRef.current.setFieldsValue({
          ...data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onFormChange = (values) => {
    console.log(values);
    const formdata = HELPERS.converToFormData(values);
    AUTH_API.ourCommitment(formdata)
      .then((response) => {
        console.log("otp response !!", response);
        message.success('Your work has been saved')
      })
      .catch((err) => {
        console.log(err);
      });
  };
  render() {
    return (
      <div style={{ margin: "5% 0 7% 0" }}>
        <div
          className="h"
          style={{
            fontSize: "30px",
            font: "normal normal 600 24px/44px Montserrat",
          }}
        >
          Our Commitment to You
        </div>
        <Row>
          <Col span={5}></Col>
          <Col span={14} style={{ textAlign: "left" }}>
            <p
              style={{
                font: "normal normal bold 16px/22px Montserrat",
                margin: "25px 0",
              }}
            >
              As your career exploration guide, we vouch to:
            </p>

            <div style={{ display: "flex" }}>
              <CheckCircleFilled
                style={{
                  color: "#49CDE1",
                  fontSize: "16px",
                  marginRight: "16px",
                  marginTop: "5px",
                }}
              />
              <p style={{ font: "normal normal normal 14px/22px Montserrat" }}>
                guide you through proven process of learning and reflection to
                create clarity on your ideal career path
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <CheckCircleFilled
                style={{
                  color: "#49CDE1",
                  fontSize: "16px",
                  marginRight: "16px",
                  marginTop: "5px",
                }}
              />
              <p style={{ font: "normal normal normal 14px/22px Montserrat" }}>
                take the guesswork out of career exploration and job search
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <CheckCircleFilled
                style={{
                  color: "#49CDE1",
                  fontSize: "16px",
                  marginRight: "16px",
                  marginTop: "5px",
                }}
              />
              <p style={{ font: "normal normal normal 14px/22px Montserrat" }}>
                provide you with a linear, step-by-step process so you can focus
                on one thing at a time
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <CheckCircleFilled
                style={{
                  color: "#49CDE1",
                  fontSize: "16px",
                  marginRight: "16px",
                  marginTop: "5px",
                }}
              />
              <p style={{ font: "normal normal normal 14px/22px Montserrat" }}>
                teach you what steps to take and the best practices so you can
                be effective and strategic
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <CheckCircleFilled
                style={{
                  color: "#49CDE1",
                  fontSize: "16px",
                  marginRight: "16px",
                  marginTop: "5px",
                }}
              />
              <p style={{ font: "normal normal normal 14px/22px Montserrat" }}>
                review your efforts, provide honest feedback, and help you
                process what you're learning
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <CheckCircleFilled
                style={{
                  color: "#49CDE1",
                  fontSize: "16px",
                  marginRight: "16px",
                  marginTop: "5px",
                }}
              />
              <p style={{ font: "normal normal normal 14px/22px Montserrat" }}>
                proactively check in throughout the week for support and respond
                promptly to questions
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <CheckCircleFilled
                style={{
                  color: "#49CDE1",
                  fontSize: "16px",
                  marginRight: "16px",
                  marginTop: "5px",
                }}
              />
              <p style={{ font: "normal normal normal 14px/22px Montserrat" }}>
                keep your best interest in mind and ensure you stay true to
                yourself through the process
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <CheckCircleFilled
                style={{
                  color: "#49CDE1",
                  fontSize: "16px",
                  marginRight: "16px",
                  marginTop: "5px",
                }}
              />
              <p style={{ font: "normal normal normal 14px/22px Montserrat" }}>
                provide holistic motivation, support, accountability and
                guidance
              </p>
            </div>

            <p
              style={{
                font: "normal normal bold 16px/22px Montserrat",
                paddingTop: "20px",
              }}
            >
              How we will do it:
            </p>
            <p style={{ font: "normal normal normal 12px/24px Montserrat" }}>
              We will check in with you throughout the week and you should
              proactively reach out to us whenever you have a question or
              updates.
            </p>

            <p>What is your preference for communicating with your coach?</p>
            <Form
              name="our_comm_form"
              onFinish={this.props.onclicknext}
              onValuesChange={HELPERS.debounceFormChanges(this.onFormChange)}
              ref={this.formRef}
            >
              <Form.Item
                name="coach_communication_mode"
                // initialValue='TE'
              >
                <Radio.Group>
                  <Radio
                    style={{ display: "block", paddingBottom: "14px" }}
                    value="TE"
                  >
                    I'm good receiving text or email (Suggested)
                  </Radio>
                  <Radio
                    style={{ display: "block", paddingBottom: "14px" }}
                    value="EM"
                  >
                    I'd prefer to only receive email
                  </Radio>
                </Radio.Group>
              </Form.Item>

              <p style={{ font: "normal normal normal 14px/22px Montserrat" }}>
                How frequently would you like your coach to check in with you?
              </p>
              <Form.Item name="communication_frequency">
                <Select
                  defaultValue={3}
                  style={{
                    width: 200,
                    color: "#32AAB0",
                    margin: "0 80px 20px 80px",
                  }}
                  bordered={false}
                >
                  <Option value={3}>Very frequently</Option>
                  <Option value={2}>Somewhat frequently</Option>
                  <Option value={1}>Not frequently</Option>
                </Select>
              </Form.Item>

              <p style={{ font: "normal normal normal 14px/22px Montserrat" }}>
                When your coach releases the next step to you in the platform,
                how do you want to be notified?
              </p>
              <Form.Item name="communication_mode">
                <Radio.Group defaultValue="TE">
                  <Radio
                    style={{ display: "block", paddingBottom: "14px" }}
                    value="EM"
                  >
                    Email
                  </Radio>
                  <Radio
                    style={{ display: "block", paddingBottom: "14px" }}
                    value="TX"
                  >
                    Text
                  </Radio>
                  <Radio
                    style={{ display: "block", paddingBottom: "14px" }}
                    value="TE"
                  >
                    Both Email + Text
                  </Radio>
                </Radio.Group>
              </Form.Item>

              <Row style={{ paddingTop: "40px", marginBottom: "100px" }}>
                <Col span={12}>
                  <Button
                    style={{ width: "80%", color: "#49CDE1" }}
                    size="large"
                    onClick={this.props.onclickback}
                  >
                    Back
                  </Button>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  <Button
                    style={{ width: "80%" }}
                    size="large"
                    type="primary"
                    htmlType="submit"
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col span={5}></Col>
        </Row>
      </div>
    );
  }
}
export default Step4;
