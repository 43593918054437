import { CloseOutlined } from "@ant-design/icons";
import { Button, message, Modal } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import {
  addTaskAsync,
  selectStepReflection,
  updateStepReflectionAsync,
} from "slices/dashboardSlice";

// import Thanks from './../../Assets/thankyou.svg'

class Midwaycall extends React.Component {
  state = {
    visible: this.props.visible,
  };
  handleCancel = (e) => {
    console.log("stepref:", this.props.obj);
    const id = this.props.obj.id;
    const data = {
      ...this.props.obj,
      status: "LO",
    };
    this.props.updateStepReflectionAsync(id, data);
    this.setState({
      visible: false,
    });
  };
  scheduleCall = () => {
    // var timeZoneFormatted = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
    const task = {
      title: "Schedule Call with Coach",
      due_date: moment(),
    };
    this.props.addTaskAsync(task);
    this.handleCancel();
    message.success("Task Added to schedule a call");
  };

  render() {
    const icon = <CloseOutlined className="close-icon" />;
    return (
      <>
        <Modal
          title=""
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
          width="40%"
          style={{ textAlign: "center", padding: 0 }}
          closeIcon={icon}
        >
          <div>
            <div
              style={{
                background: "#C677E3 0% 0% no-repeat padding-box",
                margin: "-24px",
              }}
            >
              <img
                alt=""
                style={{ width: "100%" }}
                src="https://django-woken-static.s3.amazonaws.com/static/media/thankyou.5dd024a1.svg"
              ></img>
              <p
                style={{
                  font: "normal normal bold 24px/12px Montserrat",
                  padding: "24px 0 70px 0",
                  color: "white",
                }}
              >
                Your next step is ready!
              </p>
            </div>

            <p style={{ margin: "80px 40px 90px" }}>
              Your next step is a call with your coach. Reach out directly to
              your coach to schedule a call.
            </p>
            <Button
              type="primary"
              style={{ marginBottom: "80px", width: "200px" }}
              size="large"
              onClick={this.scheduleCall}
            >
              Schedule a call
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  stepReflection: selectStepReflection(state),
});

const mapDispatchToProps = () => ({
  updateStepReflectionAsync,
  addTaskAsync,
});

export default connect(mapStateToProps, mapDispatchToProps(), null, {
  forwardRef: true,
})(Midwaycall);
