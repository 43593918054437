import { Card, Col, Row } from "antd";
import "antd/dist/antd.css";
import React from "react";
// import Rachel from './../../../Assets/rachel.png';
// import Testimonials from './../../../Assets/testimonial.svg';
import Cards from "../../Staticcontent/Staticcontentforcards";

export default class TargetrolesC1 extends React.Component {
  getSubtext = (text) => {
    switch (text) {
      case "Rachel Serwetz":
        return "Your coach";
      case "Resume Lab":
        return "Research Statistic";
      case "LinkedIn":
        return "Research Statistic";
      default:
        return "Woken Client";
    }
  };

  render() {
    console.log(this.props);
    // let arr=["Online research is your first key goal. It should comprise 15-20% of your time. You’ll find critical new information online about different roles and industries.","One thing I learned through this process was: if you want a certain industry, why remain complacent? Go for it now."]
    let card1 = [];
    let card = [];
    // let count=0;
    // let n =1;

    for (let i = 0; i < Cards[this.props.stepname].card1Content.length; i++) {
      card.push(
        <Col span={12} style={{}}>
          <Card bordered={false} style={{ height: "100%" }}>
            <div
              style={{
                paddingBottom: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img src={Cards[this.props.stepname].card1Content[i].image}></img>
              <div style={{ paddingLeft: "5%" }}>
                <p style={{ font: "normal normal bold 14px/18px Montserrat" }}>
                  {Cards[this.props.stepname].card1Content[i].name}
                </p>
                <p
                  style={{
                    font: "normal normal normal 12px/18px Montserrat",
                    color: "#898E8E",
                    margin: 0,
                  }}
                >
                  {this.getSubtext(
                    Cards[this.props.stepname].card1Content[i].name
                  )}
                </p>
              </div>
            </div>
            {Cards[this.props.stepname].card1Content[i].text}
          </Card>
        </Col>
      );
    }
    let k = 0;
    for (let j = 0; j < card.length; j++) {
      card1.push(
        <Row gutter={32} style={{ marginTop: 10 }}>
          {card[k]}
          {card[k + 1]}
        </Row>
      );
      k = k + 2;
    }

    return (
      <div>
        <p
          style={{
            font: "normal normal 600 20px/12px Montserrat",
            color: "#414141",
            padding: "40px 0",
          }}
        >
          Why is this important?
        </p>
        {card1.map((val, index) => {
          return val;
        })}
      </div>
    );
  }
}
