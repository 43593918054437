import React from "react";
import { Row, Col, Checkbox } from "antd";

export default class Singlecontactselect extends React.Component {
  state = {
    checked: this.props.checked,
  };
  render() {
    return (
      <div>
        <Row
          gutter={8}
          style={{ color: "#606368", font: "", margin: "0 0 17px 0" }}
        >
          <Col span={10} style={{ textAlign: "left" }}>
            {this.props.name}
          </Col>
          <Col span={10} style={{ textAlign: "left" }}>
            {this.props.type}
          </Col>
          <Col span={4} style={{ textAlign: "left" }}>
            <Checkbox
              defaultChecked={this.props.checked}
              onChange={(e) =>
                this.props.onCheck(e.target.checked, this.props.id)
              }
            ></Checkbox>
          </Col>
        </Row>
        <hr style={{}} />
      </div>
    );
  }
}
