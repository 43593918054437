import React from "react";
import { Card, Input, Typography, Form } from "antd";
import "./Researchroles.css";
const { TextArea } = Input;
const { Paragraph } = Typography;

export default class EditablecardTR extends React.Component {
  state = {
    text: this.props.additional_q_text,
    greencard: this.props.gc,
  };

  onChangeText = (e) => {
    this.setState({
      text: e,
      greencard: true,
    });
  };

  render() {
    console.log(this.state.greencard);
    return (
      <Card.Grid
        bordered={false}
        hoverable={false}
        className={
          this.state.greencard === false
            ? "research-card"
            : "research-card-focus-within "
        }
      >
        <Paragraph
          editable={true}
          className="card-heading"
          editable={{ onChange: this.onChangeText }}
        >
          {this.state.text}
        </Paragraph>
        <Form.Item name={this.state.text}>
          <TextArea
            className="text-underline"
            bordered={false}
            autoSize={{ minRows: 1, maxRows: 13 }}
          ></TextArea>
        </Form.Item>
      </Card.Grid>
    );
  }
}
