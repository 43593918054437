import { EditOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, Popconfirm } from "antd";
import React from "react";
import "./InterviewPrep.css";

// const { TextArea } = Input;
// const {Option} = Select;

const layout = {
  labelCol: { span: 2 },
  wrapperCol: { span: 22 },
};

export default class FormComponent extends React.Component {
  render() {
    console.log("addprep rendered");
    return (
      <div className="ip-div" style={{ margin: "0" }}>
        <Form.List {...layout} name={this.props.q_no}>
          {(fields, { add, remove }) => (
            <>
              {Array.from(Array(1).keys()).map((it, index) => (
                <div style={{ marginBottom: 15 }}>
                  <Form.Item
                    style={{ margin: "-7px 0 0 0", width: "100%" }}
                    name="ques-head"
                  >
                    <Input
                      className="ip-div-head ip-div-head-editable"
                      prefix={this.props.q_no + "."}
                      style={{ margin: "0", padding: 0 }}
                      placeholder="  Enter Question Here"
                      bordered={false}
                      suffix={<EditOutlined className="ques-editable" />}
                    />
                  </Form.Item>

                  <div style={{ marginBottom: 15, marginTop: 30 }}>
                    <div className="inp-dot">
                      <span className="dot"></span>
                      <Form.Item
                        style={{ margin: "-7px 0 0 0", width: "100%" }}
                        name="1"
                      >
                        <Input
                          suffix={<EditOutlined className="ques-editable" />}
                          className="ques-input-edit"
                          bordered={false}
                          placeholder="Enter response here"
                        />
                      </Form.Item>
                    </div>
                    <div className="inp-dot">
                      <span className="dot"></span>
                      <Form.Item
                        style={{ margin: "-7px 0 0 0", width: "100%" }}
                        name="2"
                      >
                        <Input
                          suffix={<EditOutlined className="ques-editable" />}
                          className="ques-input-edit"
                          bordered={false}
                          placeholder="Enter response here"
                        />
                      </Form.Item>
                    </div>
                    <div className="inp-dot">
                      <span className="dot"></span>
                      <Form.Item
                        style={{ margin: "-7px 0 0 0", width: "100%" }}
                        name="3"
                      >
                        <Input
                          suffix={<EditOutlined className="ques-editable" />}
                          className="ques-input-edit"
                          bordered={false}
                          placeholder="Enter response here"
                        />
                      </Form.Item>
                    </div>
                    <div className="inp-dot">
                      <span className="dot"></span>
                      <Form.Item
                        style={{ margin: "-7px 0 0 0", width: "100%" }}
                        name="4"
                      >
                        <Input
                          suffix={<EditOutlined className="ques-editable" />}
                          className="ques-input-edit"
                          bordered={false}
                          placeholder="Enter response here"
                        />
                      </Form.Item>
                    </div>

                    {/* Additional Response Code */}

                    <Form.List name="additional_responses">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map((field, index) => (
                            <Form.Item required={false} key={field.key}>
                              <div className="inp-dot" style={{ margin: 0 }}>
                                <span className="dot"></span>
                                <Form.Item
                                  {...field}
                                  validateTrigger={["onChange", "onBlur"]}
                                  style={{
                                    margin: "-7px 0 0 0",
                                    width: "100%",
                                  }}
                                >
                                  <Input
                                    suffix={
                                      <EditOutlined className="ques-editable" />
                                    }
                                    className="ques-input-edit"
                                    bordered={false}
                                    placeholder="Enter response here"
                                    style={{ width: "90%", marginTop: "-20px" }}
                                  />
                                </Form.Item>
                                <MinusCircleOutlined
                                  style={{ fontSize: "14px" }}
                                  className="dynamic-delete-button"
                                  onClick={() => remove(field.name)}
                                />
                              </div>
                            </Form.Item>
                          ))}
                          <Form.Item>
                            <div
                              style={{ textAlign: "left" }}
                              onClick={() => add()}
                            >
                              <p
                                style={{ color: "#32AAB0", cursor: "pointer" }}
                              >
                                &#43; &nbsp; Add field
                              </p>
                            </div>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>

                    {/* Additional Response Code ends */}
                  </div>
                </div>
              ))}
            </>
          )}
        </Form.List>
        <div style={{ textAlign: "right" }}>
          <Popconfirm
            title="Are you sure you want to delete?"
            onConfirm={() => this.props.deleteDraft()}
          >
            <Button
              style={{
                width: "150px",
                margin: "50px 0",
                height: "40px",
                color: "#49CDE1",
              }}
              block
            >
              Delete
            </Button>
          </Popconfirm>
        </div>
      </div>
    );
  }
}
