import { createSlice } from "@reduxjs/toolkit";
import WEEKLY_REFLECTION_API from "api/weeklyReflection";
import HELPERS, { WEEKLY_REFLECTION_TYPES } from "utils/helper";

const weeklyReflectionSlice = createSlice({
  name: "networking",
  initialState: {
    networkingReflections: [],
    jobSearchReflections: [],
  },
  reducers: {
    updateWeeklyReflections: (state, action) => {
      if (action.payload.type === WEEKLY_REFLECTION_TYPES.NETWORKING) {
        state.networkingReflections = action.payload.data;
      } else {
        state.jobSearchReflections = action.payload.data;
      }
    },
    updateWeeklyReflection: (state, action) => {
      const weeklyReflections =
        action.payload.type === WEEKLY_REFLECTION_TYPES.NETWORKING
          ? state.networkingReflections
          : state.jobSearchReflections;

      for (let reflection of weeklyReflections) {
        if (reflection.id === action.payload.data.id) {
          reflection = { ...reflection, ...action.payload.data };
          break;
        }
      }

      if (action.payload.type === WEEKLY_REFLECTION_TYPES.NETWORKING) {
        state.networkingReflections = weeklyReflections;
      } else {
        state.jobSearchReflections = weeklyReflections;
      }
    },
  },
});

const {
  updateWeeklyReflections,
  updateWeeklyReflection,
} = weeklyReflectionSlice.actions;

export const fetchWeeklyReflectionsAsync = (type) => (dispatch) => {
  return new Promise((resolve, reject) => {
    try {
      let api =
        type === WEEKLY_REFLECTION_TYPES.NETWORKING
          ? WEEKLY_REFLECTION_API.getNetworkingWeeklyReflections
          : WEEKLY_REFLECTION_API.getJobSearchWeeklyReflections;
      HELPERS.log(type, api, "=======+++++");

      api().then((resp) => {
        HELPERS.log(resp, "+++++++++++++");
        if (resp === undefined) {
          console.log(resp, "==========");
        } else {
          dispatch(updateWeeklyReflections({ type, data: resp.data }));
          resolve();
        }
      });
    } catch (error) {
      reject();
    }
  });
};

export const updateWeeklyReflectionAsync = (type, id, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    try {
      WEEKLY_REFLECTION_API.updateWeeklyReflection(id, data).then((resp) => {
        HELPERS.log(resp);
        dispatch(updateWeeklyReflection({ type, data: resp.data }));
        resolve();
      });
    } catch (error) {
      reject();
    }
  });
};

export const selectNetworkingWeeklyReflections = (state) =>
  state.weeklyReflection.networkingReflections;

export const selectJobSearchWeeklyReflections = (state) =>
  state.weeklyReflection.jobSearchReflections;

export default weeklyReflectionSlice.reducer;
