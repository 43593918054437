// import "antd/dist/antd.css";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Col, Input, message, Modal, Row, Select, Table } from "antd";
import COACH_API from "api/coach";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import React from "react";
import AUTH_API from "./../../../api/auth";
import Charts from "./Chart.js";
import "./DrillDown.css";

const { TextArea } = Input;
const { Option } = Select;

const steps = [
  "ON",
  "AS",
  "TR",
  "TI",
  "RR",
  "RI",
  "CO",
  "ED",
  "MI",
  "NT",
  "CP",
  "NF",
  "LO",
  "NP",
  "PS",
  "RE",
  "CL",
  "LK",
  "AT",
  "IR",
  "BP",
  "JR",
];
const step_names = [
  "Onboarding",
  "Assessment",
  "Target Roles",
  "Target Industries",
  "Research Roles",
  "Research Industries",
  "Contacts",
  "Email Drafts",
  "Setup Call with Coach",
  "Networking Tracker",
  "Call Prep & Call Notes",
  "Networking Reflections",
  "Learning Opportunities",
  "Next Phase",
  "Professional Summary",
  "Resume",
  "Cover Letter",
  "LinkedIn",
  "Application Tracker",
  "Interview Research",
  "Behavioral Interview Prep",
  "Job Search Reflections",
];

const steps_for_reflections = [
  "AS",
  "TR",
  "TI",
  "RR",
  "RI",
  "CO",
  "ED",
  "PS",
  "RE",
  "CL",
  "LK",
  "BP",
];

export default class DrillDown extends React.Component {
  state = {
    modalVisible: false,
    loading: true,
    Api_res: "",
  };

  componentDidMount() {
    if (this.props.location.id || localStorage.getItem("current_drilldown")) {
      const userId = this.props.location.id
        ? this.props.location.id
        : localStorage.getItem("current_drilldown");
      COACH_API.userDrillDown(userId)
        .then((response) => {
          console.log("response for drilldown:", response.data);
          const cp_id = response.data.stepreflection_set.filter(
            (item) => item.step === "CP"
          )[0].id;
          const cn_id = response.data.stepreflection_set.filter(
            (item) => item.step === "CN"
          )[0].id;
          const ir_id = response.data.stepreflection_set.filter(
            (item) => item.step === "IR"
          )[0].id;
          this.sortSteps(response.data.stepreflection_set);
          this.filterSteps(
            response.data.stepreflection_set,
            response.data.weeklyreflection_set
          );
          this.getAdditionalReflections(response.data.stepreflection_set);
          this.setState({
            loading: false,
            Api_res: response.data,
            cp_id: cp_id,
            cn_id: cn_id,
            ir_id: ir_id,
          });
        })
        .catch((err) => console.log(err));
    } else {
      this.props.history.push("/coach");
    }
    console.log("id of user", this.props.location);
  }

  setModalVisible(modalVisible) {
    this.setState({ modalVisible });
  }
  handleCancel = () => {
    this.setState({ modalVisible: false });
  };

  commFreq = (freq) => {
    switch (freq) {
      case 1:
        return "Not Frequently";
      case 2:
        return "Somewhat Frequently";
      case 3:
        return "Very Frequently";
      default:
        return "Somewhat Frequently";
    }
  };
  workStatus = (status) => {
    switch (status) {
      case "SU":
        return "Undergraduate Student";
      case "SG":
        return "Graduate Student";
      case "WP":
        return "Working Part Time";
      case "WF":
        return "Working Full Time";
      case "UP":
        return "Unemployed";
      default:
        return "Unemployed";
    }
  };

  sortSteps = (step_reflection_set) => {
    let obj = [];
    steps.forEach((element) => {
      let temp = [...step_reflection_set].filter(
        (item) => item.step === element
      );
      temp[0] && obj.push(temp[0]);
    });
    console.log("obj for reflections:", obj);
    this.setState({ sortedSteps: obj });
  };

  filterSteps = (data, wrefs) => {
    const arr = [...data];
    let filteredSteps = [];
    steps_for_reflections.forEach((element) => {
      let obj = arr.filter(
        (item) =>
          item.step === element &&
          (item.status === "RE" || item.status === "DO")
      );
      obj[0] && filteredSteps.push(obj[0]);
    });
    filteredSteps.sort(
      (a, b) =>
        steps_for_reflections.indexOf(a.step) -
        steps_for_reflections.indexOf(b.step)
    );
    if (wrefs.length > 0) {
      const filtered_wrefs = wrefs.filter((item) => item.completed === true);
      console.log("steps for refl in charts:", filteredSteps, filtered_wrefs);
      filteredSteps = filteredSteps.concat(filtered_wrefs);
      // filteredSteps.sort(this.sortFunction);
      console.log("sorted steps for chart :", filteredSteps);
    }
    this.setState({ filteredSteps: filteredSteps });
  };

  nextPhaseResponse = () => {
    const job_search_step = this.state.sortedSteps.filter(
      (item) => item.step === "PS"
    );
    const learning_step = this.state.sortedSteps.filter(
      (item) => item.step === "LO"
    );
    console.log("steps status for next phase:", job_search_step, learning_step);
    if (
      job_search_step[0].status !== "LO" &&
      learning_step[0].status !== "LO"
    ) {
      return " I want to do both simultaneously.";
    } else {
      if (job_search_step[0].status !== "LO")
        return " I want to jump right into the job hunt.";
      if (learning_step[0].status !== "LO")
        return " I want to pursue an experiential learning opportunity to help me pivot into my desired role and industry.";
      else return "No response";
    }
  };

  sortFunction = (a, b) => {
    let dateA = new Date(
      a.last_updated ? a.last_updated : a.released_on
    ).getTime();
    let dateB = new Date(
      b.last_updated ? b.last_updated : b.released_on
    ).getTime();
    return dateA > dateB ? 1 : -1;
  };
  getAdditionalReflections = (data) => {
    const arr = [...data];
    const additional_ref = [];
    steps_for_reflections.forEach((element) => {
      let obj = arr.filter(
        (item) =>
          item.step === element &&
          item.status !== "LO" &&
          item.additional_reflections != null &&
          item.additional_reflections.length > 0
      );
      obj[0] && additional_ref.push(obj[0].additional_reflections);
    });
    console.log("additional_reflections:", additional_ref);
    this.setState({ additional_ref: additional_ref });
  };

  additionalRefComp = () => {
    this.state.additional_ref.map((item) => {
      return (
        <Col span={11}>
          <div className="ref-div">
            <p>Date</p>
            <p>item</p>
          </div>
        </Col>
      );
    });
  };

  time_convert = (num) => {
    var hours = Math.floor(num / 60);
    var minutes = num % 60;
    return hours + " hrs " + minutes + " mins";
  };
  tableData = () => {
    console.log("raw table data:", this.state.sortedSteps);
    const data = this.state.sortedSteps.map((item, index) => ({
      id: item.id,
      step: item.step,
      activity: step_names[steps.indexOf(item.step)],
      key: index + 1,
      status: item.step === "ON" ? "UN" : item.status,
      sr: index + 1,
      add_ref: item.additional_reflections,
      time_spent:
        item.duration > 59
          ? this.time_convert(item.duration)
          : item.duration + " mins",
      last_updated_date: moment(item.last_updated).format("MMM-DD"),
      last_updated_time: moment(item.last_updated).format("hh:mm a"),
    }));
    console.log(data, "table data :");
    return data;
  };

  setupCall = (obj) => {
    const data = {
      id: obj.id,
      step: obj.step,
      status: "UN",
    };
    const id = obj.id;
    AUTH_API.CoachStepsPatch(data, id)
      .then((response) => {
        console.log("patch api response for popups", response);
      })
      .catch((err) => {
        console.log("patch api error", err);
      });
  };

  onchange = (value, obj) => {
    const data = {
      id: obj.id,
      step: obj.step,
      status: value,
    };
    const id = obj.id;
    AUTH_API.CoachStepsPatch(data, id)
      .then((response) => {
        console.log("patch api response", response);
      })
      .catch((err) => {
        console.log("patch api error", err);
      });
    if (obj.step === "NT" && value === "UN") {
      // Release Call Prep
      let cp_data = {
        id: this.state.cp_id,
        step: "CP",
        status: "UN",
      };
      const cp_id = this.state.cp_id;
      AUTH_API.CoachStepsPatch(cp_data, cp_id)
        .then((response) => {
          console.log("patch api response for call prep", response);
        })
        .catch((err) => {
          console.log("patch api error", err);
        });

      // Release Call Notes
      let cn_data = {
        id: this.state.cn_id,
        step: "CN",
        status: "UN",
      };
      const cn_id = this.state.cn_id;
      AUTH_API.CoachStepsPatch(cn_data, cn_id)
        .then((response) => {
          console.log("patch api response for call notes", response);
        })
        .catch((err) => {
          console.log("patch api error", err);
        });
    }
    if (obj.step === "CP") {
      // Release Call Notes
      let cn_data = {
        id: this.state.cn_id,
        step: "CN",
        status: "UN",
      };
      const cn_id = this.state.cn_id;
      AUTH_API.CoachStepsPatch(cn_data, cn_id)
        .then((response) => {
          console.log("patch api response for call notes", response);
        })
        .catch((err) => {
          console.log("patch api error", err);
        });
    }
    if (obj.step === "AT" && value === "UN") {
      // Release Interview Research
      let ir_data = {
        id: this.state.ir_id,
        step: "IR",
        status: "UN",
      };
      const ir_id = this.state.ir_id;
      AUTH_API.CoachStepsPatch(ir_data, ir_id)
        .then((response) => {
          console.log("patch api response for call notes", response);
        })
        .catch((err) => {
          console.log("patch api error", err);
        });
    }
  };

  patchNotes = (e) => {
    const data = {
      coach_notes: e.target.value,
    };
    const id = this.state.Api_res.id;
    AUTH_API.CoachListPatch(data, id)
      .then((response) => {
        console.log("patch api response for coach notes", response);
      })
      .catch((err) => {
        console.log("patch api error for coach notes", err);
      });
  };

  disableUser = () => {
    const id = this.state.Api_res.id;
    AUTH_API.CoachListPatch({ is_active: false }, id)
      .then((response) => {
        console.log("patch api response for coach notes", response);
        // this.handleCancel()
        message.success("User disabled!");
        window.location.href = "/coach/dashboard/";
      })
      .catch((err) => {
        console.log("patch api error for coach notes", err);
      });
  };

  hideUser = () => {
    const id = this.state.Api_res.id;
    AUTH_API.CoachListPatch({ status: "HO" }, id)
      .then((response) => {
        console.log("patch api response for coach notes", response);
        // this.handleCancel()
        message.success("User on hold!");
        window.location.href = "/coach/dashboard/";
      })
      .catch((err) => {
        console.log("patch api error for coach notes", err);
      });
  };

  render() {
    const columns = [
      {
        title: "Sr.No",
        dataIndex: "sr",
        key: "sr",
        width: "65px",
        render: (text, record) => <span>{text}</span>,
      },
      {
        title: "Activity",
        dataIndex: "activity",
        key: "activity",
        width: "350px",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: "300px",
        render: (text, record) =>
          record.step === "MI" || record.step === "NP" ? (
            <Button
              className="midway-btn"
              onClick={() => this.setupCall(record)}
            >
              {record.step === "MI" ? "Setup A Call" : "Release"}
            </Button>
          ) : (
            <Select
              labelInValue
              defaultValue={{ value: text }}
              style={{ width: "200px", backgroundColor: "transparent" }}
              onChange={(value) => this.onchange(value.value, record)}
              bordered={false}
              className="status-select"
            >
              <Option value="LO">Locked</Option>
              <Option value="UN">Unlocked</Option>
              <Option value="RE">Review</Option>
              <Option value="ON">Ongoing</Option>
              <Option value="DO">Done</Option>
            </Select>
          ),
      },
      {
        title: "Last Update",
        dataIndex: "details",
        key: "details",
        width: "300px",
        render: (text, record) => {
          return (
            <div>
              {record.status !== "LO" &&
                record.step !== "MI" &&
                record.step !== "NP" && (
                  <div>
                    <span>{record.last_updated_date}</span>
                    <span style={{ marginLeft: "30px" }}>
                      {record.last_updated_time}
                    </span>
                  </div>
                )}
            </div>
          );
        },
      },
      {
        title: "Time Spent",
        dataIndex: "time_spent",
        key: "time",
        width: "200px",
        render: (text, record) => {
          return (
            <div>
              {record.status !== "LO" &&
                record.step !== "MI" &&
                record.step !== "NP" && (
                  <div>
                    <span>{text}</span>
                  </div>
                )}
            </div>
          );
        },
      },
    ];

    // if(!this.state.loading){
    //   const data = (this.state.Api_res.stepreflection_set).map((item,index)=>
    //     ({
    //         id: item.id,
    //         step:item.step,
    //         key:index+1,
    //         status:item.status,
    //         sr:index+1

    //     })
    //   )
    // }

    return (
      <>
        {!this.state.loading ? (
          <div>
            <div className="dd-title">
              {this.state.Api_res &&
                this.state.Api_res.first_name + "'s Drill Down"}
            </div>
            <div className="dd-div">
              <div className="div-1">
                <Row justify="space-between" align="top">
                  <Col span={3}>
                    <span className="attr">Coach :</span>
                    <span className="value">
                      &nbsp; {this.state.Api_res.coach}
                    </span>
                  </Col>
                  <Col span={4}>
                    <span className="attr">Last Session :</span>
                    <div className="value" style={{ display: "inline-block" }}>
                      <div>
                        {" "}
                        &nbsp;{" "}
                        {this.state.Api_res.drill_down_cohertinfo
                          ? moment(
                              this.state.Api_res.drill_down_cohertinfo
                                .last_session_on
                            ).format("MMM D")
                          : ""}
                      </div>{" "}
                      <span className="sms">
                        {" "}
                        &nbsp;{" "}
                        {this.state.Api_res.drill_down_cohertinfo
                          ? this.state.Api_res.drill_down_cohertinfo.last_session_duration.slice(
                              0,
                              2
                            ) + "hrs"
                          : ""}{" "}
                      </span>
                    </div>
                  </Col>
                  <Col span={6}>
                    <span className="attr">Last Activity :</span>
                    <span className="value">
                      {" "}
                      &nbsp;
                      {step_names[steps.indexOf(this.state.Api_res.activity)]}
                    </span>
                  </Col>
                  <Col
                    span={7}
                    // style={{ textAlign: "right" }}
                  >
                    <span className="attr">Communication Mode :</span>
                    <div className="value" style={{ display: "inline-block" }}>
                      <div>
                        {" "}
                        &nbsp;{" "}
                        {this.state.Api_res.communication_mode === "TE"
                          ? "SMS & Email"
                          : "Email"}
                      </div>{" "}
                      <span className="sms">
                        {" "}
                        &nbsp;{" "}
                        {this.commFreq(
                          this.state.Api_res.communication_frequency
                        )}
                      </span>
                    </div>
                  </Col>
                  <Col span={4}>
                    <div
                      style={{ textAlign: "right", marginTop: "15px" }}
                      className="modal-div"
                    >
                      <a
                        className="disable-usr"
                        onClick={() => this.setModalVisible(true)}
                      >
                        Disable User
                      </a>
                      <Modal
                        title="Alert"
                        centered
                        width={500}
                        // closable={false}
                        visible={this.state.modalVisible}
                        onCancel={this.handleCancel}
                        wrapClassName="disable-modal"
                        footer={[
                          <Button
                            key="for-now"
                            className="modal-btn"
                            onClick={this.disableUser}
                          >
                            Disable User
                          </Button>,
                          <Button
                            key="perm"
                            className="modal-btn"
                            onClick={this.hideUser}
                            style={{ float: "right" }}
                          >
                            Hide user
                          </Button>,
                        ]}
                      >
                        <p
                          style={{
                            fontSize: "20px",
                            opacity: "0.9",
                            paddingLeft: "35px",
                          }}
                        >
                          Are You sure you want to disable this user{" "}
                          <span style={{ color: "#76CACE", opacity: "0.9" }}>
                            {/* Anna{" "} */}
                          </span>{" "}
                          ?
                        </p>
                      </Modal>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="div-com div-2">
                <h3 className="divs-title">Profile KPIs</h3>
                <Charts steps={this.state.filteredSteps} />
              </div>
              {/* <div className="div-com div-3">
                <h3 className="divs-title">Additional Reflections</h3>

                <Row justify="space-between" className="scroll-div">
                  {this.state.additional_ref.map(item=>{
                    return(
                      <Col span={11}>
                        <div className="ref-div">
                            {item}
                        </div>
                      </Col>
                    )
                  })}
                  
                </Row>
              </div> */}
              <div className="div-com div-4">
                <h3 className="divs-title">Usage</h3>
                <Row justify="space-between" align="top">
                  <Col span={6}>
                    <span className="attr">Total Hours :</span>
                    <span className="value">
                      &nbsp; {this.state.Api_res.total_hrs_spent} hrs
                    </span>
                  </Col>
                  <Col span={6}>
                    <span className="attr">Sessions In last Month :</span>
                    <span className="value">
                      &nbsp;{" "}
                      {this.state.Api_res.drill_down_cohertinfo
                        ? this.state.Api_res.drill_down_cohertinfo
                            .session_in_last_month
                        : ""}
                    </span>
                  </Col>
                  <Col span={6} style={{ textAlign: "right" }}>
                    <span className="attr">Avg. Session Length :</span>
                    <span className="value">
                      {" "}
                      &nbsp;{" "}
                      {this.state.Api_res.drill_down_cohertinfo
                        ? this.state.Api_res.drill_down_cohertinfo
                            .avg_session_length
                        : ""}
                    </span>
                  </Col>
                  <Col span={6} style={{ textAlign: "right" }}>
                    <span className="attr">Avg. Time / Week :</span>
                    <span className="value">
                      {" "}
                      &nbsp;{" "}
                      {this.state.Api_res.drill_down_cohertinfo
                        ? this.state.Api_res.drill_down_cohertinfo
                            .avg_time_per_week
                        : ""}
                    </span>
                  </Col>
                </Row>
              </div>
              <div className="div-com div-5">
                <h3 className="divs-title">Progress Tracker</h3>
                <Table
                  dataSource={this.tableData()}
                  columns={columns}
                  expandable={{
                    expandedRowRender: (record) => (
                      <div style={{ marginLeft: "140px" }}>
                        {record.step === "NP" ? (
                          <div>
                            <strong>Next Phase response : </strong>
                            {console.log("record:", record, this.state)}
                            {this.nextPhaseResponse()}
                          </div>
                        ) : (
                          <div>
                            <h3 className="attr" style={{ fontSize: "16px" }}>
                              Additional Reflections
                            </h3>
                            <p classname="value" style={{ margin: 0 }}>
                              {record.add_ref}
                            </p>
                          </div>
                        )}
                      </div>
                    ),
                    rowExpandable: (record) =>
                      record.step === "NP" ||
                      (record.add_ref != null && record.add_ref.length > 0),
                  }}
                  pagination={false}
                />
              </div>
              <div className="div-com div-6">
                <h3 className="divs-title">Demographic Status</h3>
                {this.state.Api_res.work_status === "WP" ||
                this.state.Api_res.work_status === "WF" ||
                this.state.Api_res.work_status === "UP" ? (
                  <Row align="top">
                    <Col span={8} style={{ marginBottom: "40px" }}>
                      <span className="attr">Work Status :</span>
                      <span className="value">
                        &nbsp; {this.workStatus(this.state.Api_res.work_status)}
                      </span>
                    </Col>
                    <Col span={8} style={{ marginBottom: "40px" }}>
                      <span className="attr">Role :</span>
                      <span className="value">
                        &nbsp; {this.state.Api_res.attended}
                      </span>
                    </Col>
                    <Col span={2} style={{ marginBottom: "40px" }}>
                      <span className="attr">Age :</span>
                      <span className="value">
                        {" "}
                        &nbsp; {this.state.Api_res.age}
                      </span>
                    </Col>
                    <Col
                      span={6}
                      style={{ textAlign: "right", marginBottom: "40px" }}
                    >
                      <span className="attr">Agreement Hours :</span>
                      <span className="value">
                        {" "}
                        &nbsp; {this.state.Api_res.agreement_hours} hrs
                      </span>
                    </Col>
                    <Col span={8} style={{ marginBottom: "40px" }}>
                      <span className="attr">Phone :</span>
                      <span className="value">
                        &nbsp;{" "}
                        {this.state.Api_res.country_code +
                          this.state.Api_res.phone}
                      </span>
                    </Col>
                    <Col span={8} style={{ marginBottom: "40px" }}>
                      <span className="attr">Email :</span>
                      <span className="value">
                        &nbsp; {this.state.Api_res.email}
                      </span>
                    </Col>
                  </Row>
                ) : (
                  <Row align="top">
                    <Col span={8} style={{ marginBottom: "40px" }}>
                      <span className="attr">Work Status :</span>
                      <span className="value">
                        &nbsp; {this.workStatus(this.state.Api_res.work_status)}
                      </span>
                    </Col>
                    <Col span={2} style={{ marginBottom: "40px" }}>
                      <span className="attr">Age :</span>
                      <span className="value">
                        {" "}
                        &nbsp; {this.state.Api_res.age}
                      </span>
                    </Col>
                    <Col
                      span={6}
                      style={{ textAlign: "right", marginBottom: "40px" }}
                    >
                      <span className="attr">Agreement Hours :</span>
                      <span className="value">
                        {" "}
                        &nbsp; {this.state.Api_res.agreement_hours} hrs
                      </span>
                    </Col>
                    <Col
                      span={6}
                      style={{ textAlign: "right", marginBottom: "40px" }}
                    >
                      <span className="attr">Phone :</span>
                      <span className="value">
                        &nbsp;{" "}
                        {this.state.Api_res.country_code +
                          this.state.Api_res.phone}
                      </span>
                    </Col>
                    <Col span={8} style={{ marginBottom: "40px" }}>
                      <span className="attr">Email :</span>
                      <span className="value">
                        &nbsp; {this.state.Api_res.email}
                      </span>
                    </Col>
                    <Col span={8} style={{ marginBottom: "40px" }}>
                      <span className="attr">School :</span>
                      <span className="value">
                        &nbsp; {this.state.Api_res.attended}
                      </span>
                    </Col>
                    <Col span={5} style={{ marginBottom: "40px" }}>
                      <span className="attr">Year :</span>
                      <span className="value">
                        &nbsp; {this.state.Api_res.attended_in}
                      </span>
                    </Col>
                  </Row>
                )}
              </div>
              <div className="div-com div-7">
                <h3 className="divs-title">Coach Notes</h3>
                <TextArea
                  rows={10}
                  onBlur={this.patchNotes}
                  defaultValue={this.state.Api_res.coach_notes}
                ></TextArea>
              </div>
            </div>
          </div>
        ) : (
          <LoadingOutlined
            style={{ fontSize: 50, color: "#7fced2", marginTop: "50px" }}
          />
        )}
      </>
    );
  }
}
