import { Form, Input, message, Popover } from "antd";
import moment from "moment";
import React from "react";
import HELPERS from "utils/helper";
import AUTH_API from "../../../api/auth";
import "./CoverLetter.css";

const { TextArea } = Input;
const date = moment().format("MM/DD/YYYY");

export default class EditableCL extends React.Component {
  CLForm = React.createRef();
  state = {
    submitted: false,
    Api_response: this.props.obj,
    inp: "",
  };
  componentDidMount() {
    console.log("props data from cover letter", this.props.obj);

    if (this.props.obj.hasOwnProperty("edited_values")) {
      this.CLForm.current.setFieldsValue({
        ...this.props.obj.edited_values,
      });
    }
  }
  onFormChange = (changed_values, all_values) => {
    console.log("changed_values:", changed_values, "all values:", all_values);
    const data_values = this.props.obj;
    data_values["edited_values"] = all_values;
    const data = {
      cover_letter: data_values,
    };
    console.log("patch data:", data);
    const id = this.props.materials_id;
    AUTH_API.patchMaterials(data, id, "CL")
      .then((response) => {
        console.log("patch Api data", response);
        message.success("Your work has been saved");
      })
      .catch((err) => console.log("cover letter patch Api error!", err));
  };

  render() {
    console.log("generated CL pdf rendered", this.state.Api_response);
    return (
      <div className="editable-cl-wrap">
        <Form
          name="Cl-form"
          ref={this.CLForm}
          onValuesChange={HELPERS.debounceFormChanges(this.onFormChange)}
        >
          <h3 className="cl-pdf-title">{this.props.name}</h3>
          <div className="cl-subtitle">
            <Popover
              placement="bottom"
              overlayStyle={{ maxWidth: "45%" }}
              content="Your City"
              trigger="hover"
            >
              <Form.Item
                className="cl-inline-form_item"
                name="01"
                initialValue={this.state.Api_response["01"]}
              >
                <Input
                  className="cl-inline-inp"
                  suffix=", "
                  bordered={false}
                  style={{
                    width: this.state.Api_response["01"].length + 1 + "ch",
                  }}
                />
              </Form.Item>
            </Popover>
            <Popover
              placement="bottom"
              overlayStyle={{ maxWidth: "45%" }}
              content="Your State"
              trigger="hover"
            >
              <Form.Item
                className="cl-inline-form_item"
                name="02"
                initialValue={this.state.Api_response["02"]}
              >
                <Input
                  className="cl-inline-inp"
                  suffix=" | "
                  bordered={false}
                  style={{
                    width: this.state.Api_response["02"].length + 1 + "ch",
                  }}
                />
              </Form.Item>
            </Popover>
            <Popover
              placement="bottom"
              overlayStyle={{ maxWidth: "45%" }}
              content="Your Email"
              trigger="hover"
            >
              <Form.Item
                className="cl-inline-form_item"
                name="03"
                initialValue={this.state.Api_response["03"]}
              >
                <Input
                  className="cl-inline-inp"
                  suffix=" | "
                  bordered={false}
                  style={{
                    width: this.state.Api_response["03"].length + 1 + "ch",
                  }}
                />
              </Form.Item>
            </Popover>
            <Popover
              placement="bottom"
              overlayStyle={{ maxWidth: "45%" }}
              content="Your LinkedIn Link"
              trigger="hover"
            >
              <Form.Item
                className="cl-inline-form_item"
                name="04"
                initialValue={this.state.Api_response["04"]}
              >
                <Input
                  className="cl-inline-inp"
                  suffix=" | "
                  bordered={false}
                  style={{
                    width: this.state.Api_response["04"].length + 1 + "ch",
                  }}
                />
              </Form.Item>
            </Popover>
            <Popover
              placement="bottom"
              overlayStyle={{ maxWidth: "45%" }}
              content="Your Phone Number"
              trigger="hover"
            >
              <Form.Item
                className="cl-inline-form_item"
                name="05"
                initialValue={this.state.Api_response["05"]}
              >
                <Input
                  className="cl-inline-inp"
                  bordered={false}
                  style={{
                    width: this.state.Api_response["05"].length + 1 + "ch",
                  }}
                />
              </Form.Item>
            </Popover>
          </div>
          <p className="cl-pdf-text">
            <br />
            {date}
          </p>
          <Popover
            placement="bottom"
            overlayStyle={{ maxWidth: "45%" }}
            content="Company Name"
            trigger="hover"
          >
            <Form.Item
              className="cl-form_item"
              name="11"
              initialValue={this.state.Api_response["11"]}
            >
              <Input
                className="cl-inline-inp"
                bordered={false}
                style={{
                  width: this.state.Api_response["11"].length + 1 + "ch",
                }}
              />
            </Form.Item>
          </Popover>
          <Popover
            placement="bottom"
            overlayStyle={{ maxWidth: "45%" }}
            content="Company Address"
            trigger="hover"
          >
            <Form.Item
              className="cl-form_item"
              name="12"
              initialValue={this.state.Api_response["12"]}
            >
              <Input
                className="cl-inline-inp"
                bordered={false}
                style={{
                  width: this.state.Api_response["12"].length + 1 + "ch",
                }}
              />
            </Form.Item>
          </Popover>
          <Popover
            placement="bottom"
            overlayStyle={{ maxWidth: "45%" }}
            content="Company Address"
            trigger="hover"
          >
            <Form.Item
              className="cl-form_item"
              name="13"
              initialValue={this.state.Api_response["13"]}
            >
              <Input
                className="cl-inline-inp"
                bordered={false}
                style={{
                  width: this.state.Api_response["13"].length + 1 + "ch",
                }}
              />
            </Form.Item>
          </Popover>
          <br />
          <p className="cl-pdf-text">
            To Whom it May Concern,
            <br />
          </p>

          <div className="cl-para">
            <Popover
              placement="bottom"
              overlayStyle={{ maxWidth: "45%" }}
              content="Role title, Company, reference, your skills and why are you interested in the company"
              trigger="hover"
            >
              <Form.Item
                name="para1"
                className="cl-form_item"
                initialValue={
                  "I am writing to express my strong interest in the " +
                  this.state.Api_response["21"] +
                  " position at " +
                  this.state.Api_response["22"] +
                  " that I found " +
                  this.state.Api_response["23"] +
                  " I am very interested in this position as it seems to be an ideal match for my experience and skills in " +
                  this.state.Api_response["24"]
                }
              >
                <TextArea
                  autoSize={{ minRows: 1, maxRows: 60 }}
                  bordered={false}
                  className="cl-inline-inp"
                />
              </Form.Item>
            </Popover>
          </div>
          <div className="cl-para">
            <Popover
              placement="bottom"
              overlayStyle={{ maxWidth: "45%" }}
              content="Current/Last company, role title, your overarching responsibilities and/or a core skillset you honed, your projects or the aspects of the work you were doing (what you did, how you did it, and what you achieved). Make this as applicable to the role you’re applying to as possible, use relevant keywords, add metrics and soft skills. If you improved a process, elicited strong outcomes, earned recognition, or overcame a challenge. "
              trigger="hover"
            >
              <Form.Item
                name="para2"
                className="cl-form_item"
                initialValue={
                  "At " +
                  this.state.Api_response["31"] +
                  " as a " +
                  this.state.Api_response["32"] +
                  " I " +
                  this.state.Api_response["33"] +
                  this.state.Api_response["34"]
                }
              >
                <TextArea
                  autoSize={{ minRows: 1, maxRows: 60 }}
                  bordered={false}
                  className="cl-inline-inp"
                />
              </Form.Item>
            </Popover>
          </div>
          <div className="cl-para">
            <Popover
              placement="bottom"
              overlayStyle={{ maxWidth: "45%" }}
              content="Current/Last company, role title, your overarching responsibilities and/or a core skillset you honed, your projects or the aspects of the work you were doing (what you did, how you did it, and what you achieved). Make this as applicable to the role you’re applying to as possible, use relevant keywords, add metrics and soft skills. If you improved a process, elicited strong outcomes, earned recognition, or overcame a challenge. "
              trigger="hover"
            >
              <Form.Item
                name="para3"
                className="cl-form_item"
                initialValue={
                  "Prior to this experience, I was a " +
                  this.state.Api_response["42"] +
                  " at " +
                  this.state.Api_response["41"] +
                  " where I " +
                  this.state.Api_response["43"] +
                  this.state.Api_response["44"]
                }
              >
                <TextArea
                  autoSize={{ minRows: 1, maxRows: 60 }}
                  bordered={false}
                  className="cl-inline-inp"
                />
              </Form.Item>
            </Popover>
          </div>
          <div className="cl-para">
            <Popover
              placement="bottom"
              overlayStyle={{ maxWidth: "45%" }}
              content="Describe your personality, strengths, soft skills, unique approach or mindset that makes you stand out as a strong candidate.Then, reiterate your understanding of the role, your fit for the role, and/or your fit and interest for the company culture. Note: you can use an alternative format for the letter, i.e. call out key skillsets by using bullets, or make the letter more story-like, if you have an authentic story to tell that describes your path, or your fit for and interest in the path. "
              trigger="hover"
            >
              <Form.Item
                name="para4"
                className="cl-form_item"
                initialValue={
                  this.state.Api_response["51"] + this.state.Api_response["52"]
                }
              >
                <TextArea
                  autoSize={{ minRows: 1, maxRows: 60 }}
                  bordered={false}
                  className="cl-inline-inp"
                />
              </Form.Item>
            </Popover>
          </div>
          <div className="cl-para">
            <Popover
              placement="bottom"
              overlayStyle={{ maxWidth: "45%" }}
              content="Write 1 sentence listing how and where the company can reach you for more information."
              trigger="hover"
            >
              <Form.Item
                name="para5"
                className="cl-form_item"
                initialValue={
                  "I would be grateful to discuss this opportunity with you further. Please don’t hesitate to reach out at " +
                  this.state.Api_response["61"] +
                  "Thank you in advance for your time and consideration."
                }
              >
                <TextArea
                  autoSize={{ minRows: 1, maxRows: 60 }}
                  bordered={false}
                  className="cl-inline-inp"
                />
              </Form.Item>
            </Popover>
          </div>

          <p className="cl-pdf-text">
            Best,
            <br />
            {this.props.name}
          </p>
        </Form>
      </div>
    );
  }
}
