import HELPERS from "utils/helper";

const WEEKLY_REFLECTION_API = {
  getNetworkingWeeklyReflections: () => {
    return HELPERS.secureRequest({
      url: `weeklyreflection/?type=networking`,
      method: "GET",
    });
  },
  getJobSearchWeeklyReflections: () => {
    return HELPERS.secureRequest({
      url: `weeklyreflection/?type=jobsearch`,
      method: "GET",
    });
  },
  updateWeeklyReflection: (id, data) => {
    data.completed = true;
    return HELPERS.secureRequest({
      url: `weeklyreflection/${id}/`,
      method: "PATCH",
      data: data,
    });
  },
};

export default WEEKLY_REFLECTION_API;
