import { Button, Col, Form, Input, Row, Slider, message } from "antd";
import moment from "moment";
import React, { Component } from "react";
import HELPERS from "utils/helper";
import AUTH_API from "./../../../api/auth";

const { TextArea } = Input;

class ReflectionAboutYourProcessForJobSearch extends Component {
  formRef = React.createRef();

  syncForm = () => {
    if (
      this.formRef.current &&
      this.formRef.current.getFieldValue("stress_level") !==
        this.props.data.stress_level
    ) {
      HELPERS.log("We need change", this.props.data);
      this.formRef.current.setFieldsValue(this.props.data);
    }

    // if the form is already completed, set the field values to what was saved
    if(this.props.data.completed) {
      this.formRef.current.setFieldsValue(this.props.data);
    }
  };

  componentDidMount = () => {
    this.syncForm();
  };

  componentDidUpdate = () => {
    HELPERS.log("We got update", this.props.data);
    this.syncForm();
  };

  onFinish = (values) => {
    HELPERS.log(values);
    this.props.onSubmit(this.props.reflectionId, values);
  };

  onFinishFailed = () => {
    message.error("Please fill all the required fields first!");
  };

  OnFormChange = (change_values, all_values) => {
    if (!this.props.data.completed) {
      const currentStep = HELPERS.getCurrentStep();
      const id = all_values.id;

      AUTH_API.patchReflectionsProcess(all_values, this.props.reflectionId, currentStep)
        .then((response) => {
          console.log(response.data);
          message.success("Your work has been saved successfully");
        })
        .catch((error) => {
          message.error(
            "We could not save your work due to an error. Kindly be patient as we work to resolve it. "
          );
          console.log("Reflections About Process NOT Patched", error);
        });
    }
  };

  isOverdue = () => {
    console.log(this.props.data);
    const releasedOn = moment(this.props.data.released_on).add(7, "days");
    return moment(new Date()).isAfter(releasedOn);
  };

  render() {
    return (
      <Form
        ref={this.formRef}
        name="weekly-reflection-about-your-process-job-search"
        onFinish={this.onFinish}
        onFinishFailed={this.onFinishFailed}
        onValuesChange={HELPERS.debounceFormChanges(this.OnFormChange)}
      >
        <div>
          <p className="contacts-head">
            Take a moment to pause and reflect on how the process is going.
          </p>
          <Row>
            <Col>
              <img
                style={{ marginLeft: -20 }}
                src={
                  "https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/noun_think_2157497.ad0d2dc4.svg"
                }
                width="100"
                height="50"
                alt=""
              />
            </Col>
            <p
              style={{
                fontSize: 20,
                marginLeft: -5,
                marginTop: 25,
                font: "normal normal bold 20px/12px Montserrat",
                color: "#6A7ACB",
                textAlign: "left",
              }}
            >
              Reflection about your Process
            </p>
          </Row>
          <div className="disabled-div">
            <p style={{ marginTop: 50 }}>
              1. Describe where most of your time is going. Where do you need to
              be spending more time? Less time?
            </p>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              name={["reflections", "q_1"]}
              initialValue=""
              noStyle
            >
              <TextArea
                style={{ marginTop: 16, height: 44, width: "96%" }}
              ></TextArea>
            </Form.Item>
          </div>
          <div className="disabled-div">
            <p style={{ marginTop: 90 }}>
              2. What are you learning about your target roles? Do you need to
              adjust what roles you’re targeting?
            </p>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              name={["reflections", "q_2"]}
              initialValue=""
              noStyle
            >
              <TextArea
                style={{ marginTop: 16, height: 44, width: "96%" }}
              ></TextArea>
            </Form.Item>
          </div>
          <div className="disabled-div">
            <p style={{ marginTop: 90 }}>
              3. What are you learning about your target companies / industries?
              Do you need to adjust what companies you’re targeting?
            </p>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              name={["reflections", "q_3"]}
              initialValue=""
              noStyle
            >
              <TextArea
                style={{ marginTop: 16, height: 44, width: "96%" }}
              ></TextArea>
            </Form.Item>
          </div>
          <div className="disabled-div">
            <p style={{ marginTop: 90 }}>
              4. How are you feeling in the process? What can you do to improve
              your confidence and morale?
            </p>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              name={["reflections", "q_4"]}
              initialValue=""
              noStyle
            >
              <TextArea
                style={{ marginTop: 16, height: 44, width: "96%" }}
              ></TextArea>
            </Form.Item>
          </div>
          <div className="disabled-div">
            <p style={{ marginTop: 90 }}>
              5. Are you staying organized and accountable? Do you need to
              adjust your practical process (to do list, tracking systems,
              calendars)?
            </p>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              name={["reflections", "q_5"]}
              initialValue=""
              noStyle
            >
              <TextArea
                style={{ marginTop: 16, height: 44, width: "96%" }}
              ></TextArea>
            </Form.Item>
          </div>
          <div className="disabled-div">
            <p style={{ marginTop: 90 }}>6. Any other thoughts or questions?</p>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              name={["reflections", "q_6"]}
              initialValue=""
              noStyle
            >
              <TextArea
                style={{ marginTop: 16, height: 44, width: "96%" }}
              ></TextArea>
            </Form.Item>
          </div>
          <p
            style={{
              marginTop: 72,
              font: "normal normal bold 20px/12px Montserrat",
            }}
          >
            Additional Reflections
          </p>
          <div className="">
            <p
              style={{
                marginTop: 40,
                textAlign: "left",
                font: "normal normal normal 16px/44px Montserrat",
                color: "#414141",
              }}
            >
              How stressed are you about figuring out your next job?
            </p>
            <Form.Item name="stress_level" noStyle initialValue={0}>
              <Slider style={{ width: "50%" }} min={0} max={10} />
            </Form.Item>
            <Row style={{ width: "50%" }}>
              <Col span={12}>
                <p style={{ textAlign: "left", color: "#898E8E" }}>
                  I’m very relaxed
                </p>
              </Col>
              <Col span={12}>
                <p style={{ textAlign: "right", color: "#898E8E" }}>
                  I’m feeling extremely stressed
                </p>
              </Col>
            </Row>
          </div>
          <div className="">
            <p
              style={{
                marginTop: 40,
                textAlign: "left",
                font: "normal normal normal 16px/44px Montserrat",
                color: "#414141",
              }}
            >
              What is your level of clarity on your ideal job?
            </p>
            <Form.Item name="clarity_level" noStyle initialValue={0}>
              <Slider style={{ width: "50%" }} min={0} max={10} />
            </Form.Item>
            <Row style={{ width: "50%" }}>
              <Col span={12}>
                <p style={{ textAlign: "left", color: "#898E8E" }}>
                  I have no idea what I want
                </p>
              </Col>
              <Col span={12}>
                <p style={{ textAlign: "right", color: "#898E8E" }}>
                  I know exactly what I want
                </p>
              </Col>
            </Row>
          </div>
          <div className="">
            <p
              style={{
                marginTop: 40,
                textAlign: "left",
                font: "normal normal normal 16px/44px Montserrat",
                color: "#414141",
              }}
            >
              How confident are you in the Job search process?
            </p>
            <Form.Item name="confidence_level" noStyle initialValue={0}>
              <Slider style={{ width: "50%" }} min={0} max={10} />
            </Form.Item>
            <Row style={{ width: "50%" }}>
              <Col span={12}>
                <p style={{ textAlign: "left", color: "#898E8E" }}>
                  I have no clue what to do
                </p>
              </Col>
              <Col span={12}>
                <p style={{ textAlign: "right", color: "#898E8E" }}>
                  I know exactly what to do
                </p>
              </Col>
            </Row>
          </div>
          <Button
            style={{
              width: 255,
              height: 42,
              marginTop: 35,
              marginLeft: "80%",
              background: "#49CDE1 0% 0% no-repeat padding-box",
              font: "normal normal bold 16px/44px Montserrat",
              paddingTop: 0,
            }}
            type="primary"
            htmlType="submit"
            disabled={this.props.data.completed || this.isOverdue()}
          >
            Submit to Coach
          </Button>
        </div>
      </Form>
    );
  }
}

export default ReflectionAboutYourProcessForJobSearch;
