import { Col, Row } from "antd";
import React from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import "./DrillDown.css";
import moment from "moment";

const scale = {
  value: {
    range: [0, 1],
  },
};

const STEPKEYS = [
  { step: "AS", name: "Assessment" },
  { step: "TR", name: "Target-Roles" },
  { step: "TI", name: "Target - Industries" },
  { step: "RR", name: "Research-Roles" },
  { step: "RI", name: "Research-Industries" },
  { step: "CO", name: "Contacts" },
  { step: "ED", name: "Email Drafts" },
  { step: "PS", name: "Professional Summary" },
  { step: "RE", name: "Resume" },
  { step: "CL", name: "Cover Letter" },
  { step: "LK", name: "LinkedIn" },
  { step: "BP", name: "Behavioral Interview Prep" },
];

export default class Charts extends React.Component {
  state = {
    filteredSteps: this.props.steps,
    loading: true,
  };

  chartData = () => {
    const stress_data = this.state.filteredSteps.map((item, index) => ({
      step: item.step ? item.step : item.type,
      value: item.stress_level,
      date: item.last_updated
        ? moment(item.last_updated).format("MM-DD-YY")
        : moment(item.released_on).format("MM-DD-YY"),
    }));
    const clarity_data = this.state.filteredSteps.map((item, index) => ({
      step: item.step ? item.step : item.type,
      value: item.clarity_level,
      date: item.last_updated
        ? moment(item.last_updated).format("MM-DD-YY")
        : moment(item.released_on).format("MM-DD-YY"),
    }));
    const certainity_data = this.state.filteredSteps.map((item, index) => ({
      step: item.step ? item.step : item.type,
      value: item.confidence_level,
      date: item.last_updated
        ? moment(item.last_updated).format("MM-DD-YY")
        : moment(item.released_on).format("MM-DD-YY"),
    }));
    const table_data = [stress_data, clarity_data, certainity_data];

    return table_data;
  };

  getStepNames = (label) => {
    let obj = STEPKEYS.filter((item, key) => item.step === label);
    if (obj.length > 0) return obj[0].name;
    else {
      return label === "NE" ? "Networking Reflection " : "Weekly Reflection ";
    }
  };

  render() {
    const CustomTooltip = (props) => {
      if (props.active && props.payload) {
        return (
          <div className="tooltip-div">
            <p className="label">Step : {this.getStepNames(props.label)}</p>
            <p className="label">
              {props.chart_name} : {props.payload[0].value}
            </p>
            <p className="label">Date : {props.payload[0].payload.date}</p>
          </div>
        );
      }
      return null;
    };

    return (
      <div>
        <Row gutter={8}>
          <Col span={8}>
            <LineChart width={420} height={300} data={this.chartData()[0]}>
              <Line
                type="linear"
                dataKey="value"
                stroke="#69C4C8"
                dot={{ fill: "#000", r: 2 }}
                activeDot={{ stroke: "none", fill: "#000", r: 2 }}
              />
              <CartesianGrid stroke="none" />
              <XAxis
                dataKey="step"
                tick={{ stroke: "#69C4C8", fontSize: "10px" }}
                interval={0}
              />
              <YAxis
                tick={{ stroke: "#69C4C8", fontSize: "10px" }}
                minTickGap={1}
                stroke="none"
                ticks={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
                // tickSize={4}
                domain={[0, 10]}
                interval={0}
              />
              <Tooltip content={<CustomTooltip chart_name="Stress level" />} />
            </LineChart>
            <p style={{ textAlign: "center" }}>Stress</p>
          </Col>
          <Col span={8}>
            <LineChart width={420} height={300} data={this.chartData()[1]}>
              <Line
                type="linear"
                dataKey="value"
                stroke="#69C4C8"
                dot={{ fill: "#000", r: 2 }}
                activeDot={{ stroke: "none", fill: "#000", r: 2 }}
              />
              <CartesianGrid stroke="none" />
              <XAxis
                dataKey="step"
                tick={{ stroke: "#69C4C8", fontSize: "10px" }}
                interval={0}
              />
              <YAxis
                tick={{ stroke: "#69C4C8", fontSize: "10px" }}
                minTickGap={1}
                stroke="none"
                ticks={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
                // tickSize={4}
                domain={[0, 10]}
                interval={0}
              />
              <Tooltip
                content={<CustomTooltip chart_name="Career Certainity" />}
              />
            </LineChart>
            <p style={{ textAlign: "center" }}>Career Certainty</p>
          </Col>
          <Col span={8}>
            <LineChart
              width={420}
              height={300}
              data={this.chartData()[2]}
              fill={"#000"}
            >
              <Line
                type="linear"
                dataKey="value"
                stroke="#69C4C8"
                dot={{ fill: "#000", r: 2 }}
                activeDot={{ stroke: "none", fill: "#000", r: 2 }}
              />
              <CartesianGrid stroke="none" />
              <XAxis
                dataKey="step"
                tick={{ stroke: "#69C4C8", fontSize: "10px" }}
                interval={0}
              />
              <YAxis
                tick={{ stroke: "#69C4C8", fontSize: "10px" }}
                // minTickGap={1}
                stroke="none"
                ticks={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
                // tickSize={4}
                domain={[0, 10]}
                interval={0}
              />
              <Tooltip
                content={<CustomTooltip chart_name="Certainity Process" />}
              />
            </LineChart>
            <p style={{ textAlign: "center" }}>Certainty - Process</p>
          </Col>
        </Row>
      </div>
    );
  }
}
