import React from "react";
import { connect } from "react-redux";
import Moment from "moment";
import { Card, Row, Col } from "antd";
import { LeftCircleFilled, RightCircleFilled } from "@ant-design/icons";
// import "antd/dist/antd.css";

import DueDate from "../Common/DueDate";
import Stepinfo from "../Modals/Stepinfo";
// import Calendaricon from './../../Assets/calendaricon.svg';
// import Mail from './../../Assets/mail.svg';
// import Question from './../../Assets/question.svg';
import Researchindustriesstep from "./Researchindustriesstep";
import {
  selectResearchIndustriesStepInfo,
  setStepNotifiedAsync,
} from "slices/dashboardSlice";

class ResearchIndustries extends React.Component {
  state = {
    collapsed: false, //menu collapse
    showInfo: null, //show/hide infobox
    setTarget: null, //show/hide duedate component
  };

  //opens set date box (pink colored) if date in header is clicked
  setDateAgain = () => {
    this.setState({
      setTarget: true,
    });
  };

  //close button handling
  onCloseClick = () => {
    this.setState({
      setTarget: false,
    });
  };

  onCollapse = () => {
    this.setState({ collapsed: !this.state.collapsed });
    this.props.toggle();
  };

  notifyStep = () => {
    if (
      this.props.stepInfo &&
      !this.props.stepInfo.notified &&
      this.props.impersonated
    ) {
      this.props.setStepNotifiedAsync(
        this.props.stepInfo.id,
        this.props.stepInfo.step
      );
    }
  };

  onDateSet = () => {
    // setTimeout(() => {
    this.onCloseClick();
    // }, 6000);
  };

  newReleased = () => this.props.stepInfo && !this.props.stepInfo.notified;

  showInfo = () =>
    this.state.showInfo === true ||
    (this.state.showInfo === null && this.newReleased());

  targetSet = () =>
    this.state.setTarget === true ||
    (this.state.setTarget === null &&
      !!this.props.stepInfo &&
      this.props.stepInfo.complete_by === null &&
      this.props.stepInfo.status != 'DO');

  stepId = () => (!!this.props.stepInfo ? this.props.stepInfo.id : -1);

  render() {
    return (
      <div style={{ padding: "4% 0" }}>
        <div className="collapse-btn" onClick={this.onCollapse}>
          {this.state.collapsed ? (
            <RightCircleFilled className="make-white" />
          ) : (
            <LeftCircleFilled className="make-white" />
          )}
        </div>

        <Card
          bordered={false}
          style={{
            margin: "20px !important",
            boxShadow: "0px 3px 6px #00000029",
            borderRadius: "13px",
            textAlign: "left",
            marginRight: "6%",
            marginLeft: "20px",
            padding: "0 2%",
          }}
        >
          <div>
            <Row style={{ padding: "4% 0% 3% 0%" }}>
              <Col
                span={18}
                style={{
                  font: "normal normal 300 30px/12px Montserrat",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Research Industries&nbsp;
                <span
                  className={`just-released ${
                    this.newReleased() ? "" : "hidden"
                  }`}
                >
                  NEW
                </span>
                {this.props.stepInfo && (
                  // this.props.stepInfo.complete_by !== null &&
                  <span
                    style={{
                      fontSize: "14px",
                      cursor: "pointer",
                      position: "relative",
                      marginLeft: "20px",
                      whiteSpace: "nowrap",
                    }}
                    onClick={this.setDateAgain}
                  >
                    <img
                      src="https://django-woken-static.s3.amazonaws.com/static/media/calendaricon.c713d4db.svg"
                      style={{ position: "relative", top: "-3px" }}
                    ></img>
                    &nbsp;&nbsp;
                    {this.props.stepInfo.complete_by
                      ? "Due date:"
                      : "Set Due Date"}
                    &nbsp;
                    {this.props.stepInfo &&
                      this.props.stepInfo.complete_by !== null &&
                      Moment(this.props.stepInfo.complete_by).format(
                        "YYYY-MM-DD"
                      )}
                  </span>
                )}
              </Col>
              <Col
                span={6}
                style={{
                  textAlign: "right",
                  fontSize: "26px",
                  marginTop: "-20px",
                }}
              >
                <a
                  href="mailto: team@iamwoken.com?subject=WOKEN client inquiry "
                  target="_blank"
                >
                  <img
                    style={{ padding: "0 10px" }}
                    src="https://django-woken-static.s3.amazonaws.com/static/media/mail.5c666ac1.svg"
                  />
                </a>
                <span
                  onClick={() =>
                    this.setState({
                      showInfo: true,
                    })
                  }
                >
                  <img
                    style={{ cursor: "pointer" }}
                    src="https://django-woken-static.s3.amazonaws.com/static/media/question.74f30bbb.svg"
                  />
                </span>
              </Col>
            </Row>
            <p
              style={{
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                border: "1px solid #F0F0F0",
              }}
            ></p>
          </div>

          {/* starts here__________________________________________________________________________________ */}

          {this.showInfo() ? (
            <Stepinfo
              stepname="Research Industries"
              closeInfo={() => {
                this.notifyStep();
                this.setState({
                  showInfo: false,
                });
              }}
            />
          ) : // this.state.setTarget ? <Duedate setHeaderDate={this.setDate} closeTiming={()=>this.setState({setTarget: false })} />
          // :
          this.targetSet() ? (
            <div>
              <DueDate
                stepName="Research Industries"
                stepKey={this.props.stepInfo.step}
                onDateSet={this.onDateSet}
                onCloseClick={this.onCloseClick}
                stepId={this.props.stepInfo.id}
                targetDate={
                  this.props.stepInfo.complete_by !== null
                    ? Moment(this.props.stepInfo.complete_by)
                    : null
                }
              />
              <Researchindustriesstep stepId={this.stepId()} />
            </div>
          ) : (
            <Researchindustriesstep
              style={{ opacity: "0.4", display: "none" }}
              fade={!this.targetSet()}
              stepId={this.stepId()}
            />
          )}
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  stepInfo: selectResearchIndustriesStepInfo(state),
});

const mapDispatchToProps = () => ({
  setStepNotifiedAsync,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(ResearchIndustries);
