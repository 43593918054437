import React from "react";
import { connect } from "react-redux";
import "./Draggable.css";
import arrayMove from "array-move";
import HELPERS from "utils/helper";
import SortableList from "./SortableList";
import {
  selectTargetIndustries,
  addIndustryAsync,
  deleteIndustryAsync,
  updateIndustryAsync,
} from "slices/targetIndustriesSlice";
import {message} from "antd";

class DraggableCompTI extends React.Component {
  state = {
    count: 999,
    dataSource: [],
    loading: false,
  };

  getIndustries = (all) => {
    return this.props.industries
      ? this.props.industries
          .filter((item) => all === true || item.learn_more === true)
          .sort((a, b) => a.priority - b.priority)
      : [];
  };

  addNew = () => {
    const data = {
      title: `Enter New Industry`,
      priority: this.getIndustries(true).length + 1,
      learn_more: true,
      selected_for: [],
      research_questions: [],
      additional_questions: [],
      reflections: [],
    };
    const formdata = HELPERS.converToFormData(data);
    this.props.addIndustryAsync(formdata);
  };

  deleteItem = (index) => {
    this.props.deleteIndustryAsync(this.getIndustries()[index].id);
  };

  changeText = (e, index) => {
    HELPERS.log(e.target.value, index, this.getIndustries()[index].title);
    if (this.getIndustries()[index].title !== e.target.value) {
      this.props.updateIndustryAsync(this.getIndustries()[index].id, {
        title: e.target.value,
      });
      message.success('Your work has been saved');
    }
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      var oldData = [...this.getIndustries()];
      const newData = arrayMove(oldData, oldIndex, newIndex);
      HELPERS.log(newData);
      let priority = 1;

      newData.forEach((item) => {
        const data = {
          priority: priority,
        };
        this.props.updateIndustryAsync(item.id, data);
        priority += 1;
      });
      message.success('Your work has been saved');
    }
  };

  getListItems = () => {
    const items = [];

    this.getIndustries().forEach((item) => {
      items.push({
        id: item.id,
        title: item.title,
      });
    });

    return items;
  };

  render() {
    return (
      <>
        {!this.state.loading && (
          <SortableList
            isDone={this.props.isDone}
            items={this.getListItems()}
            onSortEnd={this.onSortEnd}
            onChangeText={this.changeText}
            onDeleteItem={this.deleteItem}
            onAddItem={this.addNew}
            addTitle={"Add a New Industry"}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  industries: selectTargetIndustries(state),
});

const mapDispatchToProps = () => ({
  addIndustryAsync,
  deleteIndustryAsync,
  updateIndustryAsync,
});

export default connect(mapStateToProps, mapDispatchToProps())(DraggableCompTI);
