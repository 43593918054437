import { Form, Input, message } from "antd";
import React from "react";
import HELPERS from "utils/helper";
import AUTH_API from "../../../api/auth";
import "./ProfSummary.css";

const { TextArea } = Input;

export default class EditableSummary extends React.Component {
  ProfInput = React.createRef();

  state = {
    saved: false,
    Api_response: "",
    loading: true,
  };
  componentDidMount() {
    console.log("props data from prof summary", this.props.summary);
    let object = { ...this.props.summary };

    // if(object.edited)
    // {
    //     this.ProfInput.current.setFieldsValue({
    //         ...object.edited
    //     });
    // }
    if (!object.edited) {
      Object.keys(object).forEach((item) => {
        object[item] = Object.values(object[item]);
      });
      object[2] = object["2_added"]
        ? object[2].concat(object["2_added"])
        : object[2];
      object[3] = object["3_added"]
        ? object[3].concat(object["3_added"])
        : object[3];
      object[6] = object["6_added"]
        ? object[6].concat(object["6_added"])
        : object[6];
      object[8] = object["8_added"]
        ? object[8].concat(object["8_added"])
        : object[8];
      delete object["2_added"];
      delete object["3_added"];
      delete object["6_added"];
      delete object["8_added"];
      delete object["0"];
      console.log("api text", object);

      let c_obj = object;
      let str = "";
      for (let i = 1; i < 9; i++) {
        object[i].map((item, index) => {
          if (item !== "" && item !== null) {
            if (object[i].length === 1) {
              str = str.concat(item);
            }
            if (index !== object[i].length - 1) {
              str = str.concat(item, " , ");
            }
            if (index === object[i].length - 1 && object[i].length > 1) {
              str = str.concat(" and ", item);
            }
          }
        });
        c_obj[i] = str;
        str = "";
      }
      console.log("converted object", c_obj);

      this.setState({ loading: false, Api_response: c_obj });
    } else {
      this.setState({
        loading: false,
        Api_response: object.edited,
        saved: true,
      });
    }
  }

  onFormChange2 = HELPERS.debounceFormChanges((changed_values, all_values) => {
    let form_data = {};
    this.setState({saved: true});
    form_data["edited"] = all_values.summary;
    const data = {
      professional_summary: form_data,
    };
    const id = this.props.materials_id;
    console.log("data for patch Api:", data);
    AUTH_API.patchMaterials(data, id, "PS")
      .then((response) => {
        console.log("patch Api data", response);
        message.success('Your work has been saved');
      })
      .catch((err) => console.log("prof summary patch Api error!", err));
  });

  render() {
    console.log("editable generated summary rendered");
    return (
      <Form name="editableSummary"
        onValuesChange={this.onFormChange2}>
      <div>
        {this.state.loading ? (
          "....generating"
        ) : (
          <div className="sum-box">
            <Form.Item
              className="editable-ps-textarea"
              name="summary"
              initialValue={
                this.state.saved ? 
                  this.state.Api_response : 
                  this.state.Api_response[1] +
                  " professional with experience in " +
                  this.state.Api_response[2] +
                  " across " +
                  this.state.Api_response[3] +
                  " industries. Strengths in " +
                  this.state.Api_response[4] +
                  " in order to " +
                  this.state.Api_response[5] +
                  " by utilizing " +
                  this.state.Api_response[6] +
                  " . Motivated by " +
                  this.state.Api_response[7] +
                  " opportunities in the " +
                  this.state.Api_response[8] +
                  " industry . "
                }
              ref={this.ProfInput}
            >
            {this.state.saved ? (
              <TextArea
                autoSize={{ minRows: 2, maxRows: 60 }}
                bordered={false}
                className="editable-ps-textarea"
              />
            ) : (
              <TextArea
                autoSize={{ minRows: 4, maxRows: 60 }}
                bordered={false}
                className="editable-ps-textarea"
              />
            )}
            </Form.Item>
          </div>
        )}
      </div>
      </Form>
    );
  }
}
