import React from "react";
import { Checkbox, Input, Form, Popover, Row, Col } from "antd";
import { EditOutlined, PlusCircleFilled } from "@ant-design/icons";

const { TextArea } = Input;

export default class Editableq extends React.Component {
  state = {
    text: "",
  };
  onChangeText = (e) => {
    // console.log(e);
    this.setState({
      text: e.target.value,
    });
  };
  checkboxChange = (checkedValues) => {
    console.log("checked values:", checkedValues);
  };

  render() {
    console.log(this.state);
    return (
      <>
        <Form.List name={this.props.q_number}>
          {(fields, { add, remove }) => (
            <>
              {Array.from(Array(1).keys()).map((item, index) => (
                <div>
                  <Row>
                    <Col span={1}>
                      <Form.Item
                        name={index.toString() + "1"}
                        style={{ margin: "0" }}
                      >
                        <Checkbox.Group
                          style={{ width: "100%" }}
                          onChange={this.checkboxChange}
                        >
                          <Row>
                            <Col span={24}>
                              <div className="custom-cb">
                                <Checkbox value="check"></Checkbox>
                              </div>
                            </Col>
                          </Row>
                        </Checkbox.Group>
                      </Form.Item>
                    </Col>
                    <Col span={23}>
                      <Form.Item
                        name={index.toString() + "2"}
                        style={{ margin: "0" }}
                      >
                        <TextArea
                          autoSize={{ minRows: 1, maxRows: 60 }}
                          bordered={false}
                          className="custom-textarea"
                        >
                          {" "}
                        </TextArea>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              ))}
            </>
          )}
        </Form.List>
        <Form.List name={this.props.q_number + "_added"}>
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <div>
                    <Form.List name={index.toString()}>
                      {(fields, { add, remove }) => (
                        <>
                          {Array.from(Array(1).keys()).map((item, ind) => (
                            <Row>
                              <Col span={1}>
                                <Form.Item
                                  name={ind.toString() + "1"}
                                  style={{ margin: "0" }}
                                >
                                  <Checkbox.Group
                                    style={{ width: "100%" }}
                                    onChange={this.checkboxChange}
                                  >
                                    <Row>
                                      <Col span={24}>
                                        <div className="custom-cb">
                                          <Checkbox value="check"></Checkbox>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Checkbox.Group>
                                </Form.Item>
                              </Col>
                              <Col span={23}>
                                <Form.Item
                                  name={ind.toString() + "2"}
                                  style={{ margin: "0" }}
                                >
                                  <TextArea
                                    autoSize={{ minRows: 1, maxRows: 60 }}
                                    bordered={false}
                                    className="custom-textarea"
                                  >
                                    {" "}
                                  </TextArea>
                                </Form.Item>
                              </Col>
                            </Row>
                          ))}
                        </>
                      )}
                    </Form.List>
                  </div>
                ))}

                <Popover
                  placement="bottom"
                  content="Add a new question"
                  trigger="hover"
                >
                  <PlusCircleFilled
                    className="add_q_btn"
                    onClick={() => {
                      add();
                    }}
                  />
                </Popover>
              </div>
            );
          }}
        </Form.List>
      </>
      // <div className='ques-checkbox-div'>
      //     <input className='ques-checkbox' type='checkbox'></input><span class="checkmark"></span>
      //     <Input suffix={<EditOutlined className='ques-editable' />} className='ques-input-edit'
      //      value={this.state.text} onChange= {this.onChangeText}
      //      bordered={false}
      //     />
      // </div>
    );
  }
}
