import { Button, Col, Input, message, Row, Select, Table } from "antd";
import React from "react";
import { connect } from "react-redux";
import { selectTargetIndustries } from "slices/targetIndustriesSlice";
import AUTH_API from "../../../api/auth";
import "./Tracker.css";

const { Option } = Select;
class Addcompany extends React.Component {
  state = {
    companies: [],
    loading: true,
    selected_count: 0,
  };

  componentDidMount() {
    AUTH_API.getAllCompanies()
      .then((response) => {
        console.log("tr !!", response);
        const companies = response.data;
        companies.sort((a, b) => {
          return a.title.localeCompare(b.title);
        });
        console.log("sorted", companies);
        this.setState({
          companies: companies,
          selected_count: companies.length,
        });
      })
      .catch((err) => console.log("no roles found!", err));
  }

  getIndustries = () => {
    return this.props.industries
      ? this.props.industries
          .filter((item) => item.learn_more === true)
          .sort((a, b) => a.priority - b.priority)
      : [];
  };
  getSize = () => {
    return [
      "Small (1-100)",
      "Small-Medium (100-1k)",
      "Medium (1k-5k)",
      "Medium-Large (5k-10k)",
      "Large (10k+)",
    ];
  };

  addCompany = () => {
    const data = {
      title: "Add Company Here",
      industry: "",
      company_size: "",
      tracker_info: {},
      research_notes: [{}],
      call_prep: [{}],
      notes: "abcd",
      reflections: [{}],
      contact: "",
    };
    AUTH_API.postCompany(data)
      .then((response) => {
        console.log("post company !!", response);

        const new_companies = this.state.companies;
        new_companies.push(response.data);

        this.setState({
          companies: new_companies,
          selected_count: this.state.selected_count + 1,
        });
      })
      .catch((err) => console.log("Post Api error", err));
  };
  onGo = () => {
    if (this.state.selected_count >= 10) this.props.next();
    else message.error("Select atleast 10 companies!");
  };

  onChange = (val, id, type) => {
    console.log(val, id, type);
    let data = {};
    data[type] = val;
    AUTH_API.patchCompany(data, id)
      .then((response) => {
        console.log("patch company !!", response);
      })
      .catch((err) => console.log("Patch Api error", err));
  };
  render() {
    console.log("all companies in state:", this.state.companies);
    const columns = [
      {
        title: "Company name",
        dataIndex: "name",
        width: 250,
        render: (text, record) => (
          <Input
            defaultValue={text === "new" ? "" : text}
            bordered={false}
            className="at-input"
            placeholder="Enter Company Name"
            onBlur={(e) => this.onChange(e.target.value, record.id, "title")}
          />
        ),
      },
      {
        title: "Industry",
        dataIndex: "industry",
        render: (text, record) => (
          <Select
            key={`ind-for-${text}-${record.id}`}
            onChange={(val) => this.onChange(val, record.id, "industry")}
            defaultValue={text ? text : null}
            placeholder="Select industry"
            style={{ maxWidth: "100%", minWidth: "100%" }}
            className="at-select"
            bordered={false}
            dropdownClassName="option-style"
          >
            {this.getIndustries().map((item, index) => (
              <Option key={index + "ind"} value={item.title}>
                {item.title}
              </Option>
            ))}
          </Select>
        ),
      },
      {
        title: "Company size",
        dataIndex: "company_size",
        render: (text, record) => (
          <Select
            key={`size-for-${text}-${record.id}`}
            onChange={(val) => this.onChange(val, record.id, "company_size")}
            defaultValue={text ? text : null}
            placeholder="Select Size"
            style={{ maxWidth: "100%", minWidth: "100%" }}
            className="at-select"
            bordered={false}
            dropdownClassName="option-style"
          >
            {this.getSize().map((item, index) => (
              <Option key={index + "size"} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        ),
      },
    ];

    const data = this.state.companies.map((item, index) => ({
      name: item.title,
      industry: item.industry,
      company_size: item.company_size,
      id: item.id,
    }));

    return (
      <>
        <p className="contact-sel-heading">
          Fill in your top 10 companies that align with your target industries
          and company size.
        </p>
        <Row>
          <Col span={13}>
            {/* <div className='contact-list-scroll'> */}
            {this.state.loading && (
              <div className="at-div">
                <Table
                  columns={columns}
                  dataSource={data}
                  bordered
                  title={() => "COMPANY INFO"}
                  pagination={false}
                  rowClassName="app-tracker-row"
                />
              </div>
            )}
            {/* </div> */}
            <Row>
              <Col span={8}>
                {/* <div style={{margin:'30px 0 10px'}}> */}
                <div style={{ float: "right", padding: "30px 0 10px" }}>
                  &nbsp;
                </div>
                <Button
                  type="default"
                  size="large"
                  onClick={this.addCompany}
                  style={{ color: "#49CDE1" }}
                  className="go-btn"
                >
                  Add Company
                </Button>
                {/* </div> */}
              </Col>
              <Col span={8}></Col>
              <Col span={8}>
                <div style={{ float: "right", padding: "30px 0 10px" }}>
                  {this.state.selected_count} companies selected
                </div>
                <Button
                  type="primary"
                  className="go-btn"
                  size="large"
                  onClick={this.onGo}
                >
                  Next
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={11}></Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  industries: selectTargetIndustries(state),
});

export default connect(mapStateToProps, null)(Addcompany);
