import React from "react";
import { connect } from "react-redux";
import { Row, Col, Steps, Button, message } from "antd";
// import "antd/dist/antd.css";
import Experiences from "./Experiences";
import Contentareas from "./Contentareas";
import Environment from "./Environment";
import { updateQuickReflection } from "slices/dashboardSlice";
import { selectAssessment, fetchAssessmentAsync } from "slices/assessmentSlice";
import "./Assessment.css";
import AUTH_API from "./../../api/auth";

const { Step } = Steps;

class AssessmentSteps extends React.Component {
  state = {
    current: this.props.currStep ? this.props.currStep : 0,
    should_go: true,
  };

  getRef = (formref) => {
    console.log(
      "form values"
      //  formref.current.getFieldValue(),
      // formref.current.validateFields()
      // .then(res=>{console.log('promise res')})
      // .catch(err=>{console.log('err',err)}),
      // formref.current.getFieldsError(),
      // formref.current.isFieldValidating()
    );
    // formref.current.validateFields()
    // .then(
    //   res=>{
    //     console.log('promise res')
    //     this.setState({ should_go: true });
    //   })
    // .catch(
    //   err=>{
    //     console.log('err',err)
    //     this.setState({ should_go: false });
    //     // message.error('complete all the questions first')
    //   })
  };

  componentDidMount = () => {
    // this.props.fetchAssessmentAsync();
    // console.log('assessment12',this.assess(), this.props.assessment)
    // let temp=this.props.fetchAssessmentAsync()
    // console.log('assessment',temp)
    // AUTH_API.getAssessment()
    //   .then((response) => {
    //     console.log('get assessment', response);
    //   })
    //   .catch((err) => console.log("err", err));
  };

  onSubmit = () => {
    if (this.state.should_go) {
      this.props.updateQuickReflection({
        stepId: this.props.stepId,
        show: true,
      });
    } else {
      message.error("Fill all the required fields first");
    }
  };

  next(curr) {
    console.log("next", this.state);
    // if(this.state.should_go){
    // const current = this.state.current + 1;
    this.setState({ current: curr + 1 });
    this.props.setStep(curr + 1);
    // }
    // else{
    //   message.error('Fill all the required fields first')
    // }
  }

  prev(curr) {
    console.log("prev", this.state);
    // console.log('check assess', this.props.assessment)
    // if(this.state.should_go){
    // const current = this.state.current - 1;
    this.setState({ current: curr - 1 });
    this.props.setStep(curr - 1);
    // }
    // else{
    //   message.error('Fill all the required fields first')
    // }
  }

  stepChange = (current) => {
    // this.props.fetchAssessmentAsync();
    // if(this.state.should_go){
    console.log("step", this.state);
    this.setState({ current: current });
    this.props.setStep(current);
    // }
    // else{
    //   message.error('Fill all the required fields first')
    // }
  };

  // assess=()=>{
  //   return this.props.assessment
  // }

  render() {
    console.log("ass steps rendered");
    const { current } = this.state;
    const steps = [
      {
        title: "EXPERIENCES",
        content: (
          <Experiences getRef={this.getRef} next={() => this.next(current)} />
        ),
      },
      {
        title: "CONTENT AREAS",
        content: (
          <Contentareas
            getRef={this.getRef}
            next={() => this.next(current)}
            prev={() => this.prev(current)}
          />
        ),
      },
      {
        title: "ENVIRONMENT",
        content: (
          <Environment
            getRef={this.getRef}
            next={this.onSubmit}
            prev={() => this.prev(current)}
          />
        ),
      },
    ];
    return (
      <div
        style={this.props.fade ? {} : { opacity: "0.4", pointerEvents: "none" }}
      >
        <Steps
          current={current}
          style={{ padding: "2% 0 6% 0", fontSize: 14 }}
          onChange={this.stepChange}
        >
          {steps.map((item, index) => (
            <Step
              style={{ fontSize: 12 }}
              key={item.title}
              title={item.title}
              status={index === this.state.current ? "process" : "wait"}
              disabled={this.props.trLocked}
            />
          ))}
        </Steps>

        <div className="steps-content">{steps[current].content}</div>
        <div className="steps-action">
          <Row>
            <Col span={5} style={{ paddingTop: "5%" }}>
              {/* {current > 0 && (
                <Button
                  size="large"
                  style={{ margin: "0 8px", color:'#49CDE1' }}
                  onClick={() => this.prev()}
                  block
                >
                  Back
                </Button>
              )} */}
            </Col>

            <Col span={7}></Col>
            <Col span={7}></Col>

            <Col span={5} style={{ textAlign: "right", padding: "5% 0% 5% 0" }}>
              {/* {current < steps.length - 1 && (
                <Button
                  size="large"
                  type="primary"
                  onClick={() => this.next()}
                  block
                >
                  Next
                </Button>
              )}
              {current === steps.length - 1 && (
                <Button
                  size="large"
                  type="primary"
                  block
                  onClick={() =>
                    this.onSubmit()
                  }
                >
                  Complete Assessment
                </Button>
              )} */}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  assessment: selectAssessment(state),
});

const mapDispatchToProps = () => ({
  updateQuickReflection,
  fetchAssessmentAsync,
});

export default connect(mapStateToProps, mapDispatchToProps())(AssessmentSteps);
