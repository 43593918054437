import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import Singleindustryselect from "./Singleindustryselect";
import {
  selectTargetIndustries,
  updateIndustryAsync,
} from "slices/targetIndustriesSlice";
import {message} from "antd";

class TargetIndustriesSelect extends React.Component {
  industries = () => {
    let res = [];

    if (this.props.industries && this.props.industries.length > 0) {
      res = [...this.props.industries];
      res.sort((a, b) => {
        //sort acc to no. of time selected
        const alen = a.selected_for.length;
        const blen = b.selected_for.length;
        return blen - alen;
      });
    }
    return res;
  };

  onCheck = (e, id) => {
    const data = {
      learn_more: e,
    };
    this.props.updateIndustryAsync(id, data, "TI");
    message.success('Your work has been saved');
  };
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <p
          style={{
            color: "#296B70",
            font: "normal normal 600 14px/22px Montserrat",
          }}
        >
          Below is a summary of your selections from previous page. Select the
          industries you want to learn more about.{" "}
        </p>
        <p
          style={{
            paddingBottom: "10px",
            font: "normal normal 100 14px/22px Montserrat",
            color: "#296B70",
          }}
        >
          Your learning process will evolve organically, so don’t feel that any
          options you exclude are off the table!
        </p>

        <p
          style={{
            paddingBottom: "20px",
            font: "normal normal 100 14px/22px Montserrat",
            color: "#296B70",
          }}
        >
        Please limit your choices to 10 industries. This does not mean you are closing yourself off to any options, but we want to focus your research on what you think is most interesting, just as a starting point.
        </p>
        <Row>
          <Col span={15}>
            <Row gutter={8}>
              <Col
                span={7}
                style={{ textAlign: "center" }}
                className="select-heading"
              >
                # times selected
              </Col>
              <Col span={7} className="select-heading">
                Industries
              </Col>
              <Col span={9} className="select-heading">
                I want to learn more
              </Col>
            </Row>
            <p className="separator"></p>
            {/* industries list mapping here */}
            {console.log(this.industries())}
            {this.industries().map((item) =>
              item.selected_for.length && item.title && item.id ? (
                <Singleindustryselect
                  id={item.id}
                  key={item.id}
                  count={item.selected_for.length}
                  name={item.title}
                  onCheck={(e, id) => this.onCheck(e, id)}
                  checked={item.learn_more}
                />
              ) : (
                ""
              )
            )}
          </Col>
          <Col span={9}></Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  industries: selectTargetIndustries(state),
});

const mapDispatchToProps = () => ({
  updateIndustryAsync,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(TargetIndustriesSelect);
