import { Card } from "antd";
import React from "react";
import DraggablecompTI from "../Common/DraggablecompTI";
import { useSelector } from "react-redux";
import { selectStepReflections } from "slices/dashboardSlice";

const Targetindustriescard = (props) => {
  const stepReflections = useSelector(selectStepReflections);

  const isDone = (step) => 
    stepReflections[step] ? stepReflections[step].status === "DO" || stepReflections[step].status === "RE" : false;
  return (
    <Card
      bordered={false}
      style={{
        boxShadow: "0px 3px 6px #00000029",
        borderRadius: "13px",
        textAlign: "left",
        margin: "20px",
        height: "96%",
      }}
    >
      <div className="h" style={{ fontWeight: "bold", textAlign: "left" }}>
        My Target Industries
      </div>
      <div style={{ padding: "40px 0 0" }}>
        <DraggablecompTI isDone={isDone('TI')} />
      </div>
    </Card>
  );
};

export default Targetindustriescard;
