const industries = [
  {
    value: "Academic Research",
  },
  {
    value: "Advertising",
  },
  {
    value: "Agriculture",
  },
  {
    value: "Airlines",
  },
  {
    value: "Alcohol & Wine",
  },
  {
    value: "Alternative Medicine",
  },
  {
    value: "Animation",
  },
  {
    value: "Apparel & Fashion",
  },
  {
    value: "Apparel & Fashion Tech",
  },
  {
    value: "Architecture & Planning",
  },
  {
    value: "Arms & Weaponry",
  },
  {
    value: "Arts & Crafts",
  },
  {
    value: "Auction",
  },
  {
    value: "Auditing",
  },
  {
    value: "Automotive",
  },
  {
    value: "Aviation & Aerospace",
  },
  {
    value: "BioTech",
  },
  {
    value: "Broadcast Media",
  },
  {
    value: "Business Support Services",
  },
  {
    value: "Child Care Services",
  },
  {
    value: "Civic & Social Organization",
  },
  {
    value: "Civil Engineering",
  },
  {
    value: "Commercial Real Estate",
  },
  {
    value: "Computer Hardware",
  },
  {
    value: "Construction & Equipment",
  },
  {
    value: "Consumer Electronics",
  },
  {
    value: "Consumer Products & Goods",
  },
  {
    value: "Consumer Retail",
  },
  {
    value: "Consumer Services",
  },
  {
    value: "Cosmetics",
  },
  {
    value: "Crop Production",
  },
  {
    value: "Cruise",
  },
  {
    value: "Cybersecurity",
  },
  {
    value: "Dairy",
  },
  {
    value: "Data Processing & Hosting Services",
  },
  {
    value: "Defense",
  },
  {
    value: "Dentistry",
  },
  {
    value: "E-Commerce",
  },
  {
    value: "EdTech (Education Tech)",
  },
  {
    value: "Education",
  },
  {
    value: "Electrical",
  },
  {
    value: "Energy",
  },
  {
    value: "Entertainment",
  },
  {
    value: "Environmental Services",
  },
  {
    value: "Event Services",
  },
  {
    value: "Executive Office",
  },
  {
    value: "Facilities Services",
  },
  {
    value: "Family Services",
  },
  {
    value: "Farming",
  },
  {
    value: "Film & Movies",
  },
  {
    value: "Financial Services",
  },
  {
    value: "Fine Art",
  },
  {
    value: "FinTech (Finance Tech)",
  },
  {
    value: "Fishing",
  },
  {
    value: "Fitness",
  },
  {
    value: "Fitness/Wellness Tech",
  },
  {
    value: "Food & Beverage (Restaurants, Retail, etc.)",
  },
  {
    value: "Food Production",
  },
  {
    value: "FoodTech",
  },
  {
    value: "Foreign Policy",
  },
  {
    value: "Forestry",
  },
  {
    value: "Fundraising",
  },
  {
    value: "Funeral Services",
  },
  {
    value: "Furniture",
  },
  {
    value: "Gambling & Casinos",
  },
  {
    value: "Gaming",
  },
  {
    value: "Glass, Ceramics & Concrete",
  },
  {
    value: "Government Administration",
  },
  {
    value: "Government Relations",
  },
  {
    value: "Graphic Design",
  },
  {
    value: "Healthcare",
  },
  {
    value: "HealthTech",
  },
  {
    value: "Hedge Funds",
  },
  {
    value: "Higher Education",
  },
  {
    value: "Home Building",
  },
  {
    value: "Hospitals",
  },
  {
    value: "Hotels",
  },
  {
    value: "Human Resources",
  },
  {
    value: "Human Resources Tech (HR Tech)",
  },
  {
    value: "Industrial Design",
  },
  {
    value: "Industrial Engineering",
  },
  {
    value: "Information Technology & Services (IT)",
  },
  {
    value: "Insurance",
  },
  {
    value: "Insurance Tech",
  },
  {
    value: "International Affairs",
  },
  {
    value: "International Trade & Development",
  },
  {
    value: "Investment Banking",
  },
  {
    value: "IT Networks",
  },
  {
    value: "Judiciary",
  },
  {
    value: "Law Enforcement",
  },
  {
    value: "Law Practice",
  },
  {
    value: "Legal Services",
  },
  {
    value: "LegalTech",
  },
  {
    value: "Legislative Office",
  },
  {
    value: "Libraries",
  },
  {
    value: "Lobbying",
  },
  {
    value: "Logistics & Supply Chain",
  },
  {
    value: "Luxury Goods",
  },
  {
    value: "Manufacturing",
  },
  {
    value: "Maritime",
  },
  {
    value: "Market Research",
  },
  {
    value: "Meat Processing",
  },
  {
    value: "Mechanical Engineering",
  },
  {
    value: "Media Production",
  },
  {
    value: "Medical Devices & Supplies",
  },
  {
    value: "Medical Practice",
  },
  {
    value: "Mental Healthcare",
  },
  {
    value: "Military",
  },
  {
    value: "Mining, Metals & Equipments",
  },
  {
    value: "Monetary Authorities",
  },
  {
    value: "Museums & Institutions",
  },
  {
    value: "Music",
  },
  {
    value: "Music Tech",
  },
  {
    value: "Nanotechnology",
  },
  {
    value: "Naval Engineering",
  },
  {
    value: "News",
  },
  {
    value: "Non Governmental Organization (NGO's)",
  },
  {
    value: "Non Profit Organization",
  },
  {
    value: "Nursing Homes",
  },
  {
    value: "Oil & Gas",
  },
  {
    value: "Online Media",
  },
  {
    value: "Online Publishing",
  },
  {
    value: "Packaging",
  },
  {
    value: "Paper & Forest Products",
  },
  {
    value: "Performing Arts",
  },
  {
    value: "Personal Care Services",
  },
  {
    value: "Pharmaceutical",
  },
  {
    value: "Philanthropy",
  },
  {
    value: "Photography",
  },
  {
    value: "Plastics",
  },
  {
    value: "Political Organization",
  },
  {
    value: "Printing",
  },
  {
    value: "Private Equity",
  },
  {
    value: "Private Investigations",
  },
  {
    value: "Private Security",
  },
  {
    value: "Professional Training",
  },
  {
    value: "Public Policy",
  },
  {
    value: "Public Relations",
  },
  {
    value: "Public Safety",
  },
  {
    value: "Publishing",
  },
  {
    value: "Radio",
  },
  {
    value: "Railroad",
  },
  {
    value: "Real Estate",
  },
  {
    value: "Real Estate/Property Tech (PropTech)",
  },
  {
    value: "Recreational & Amusement",
  },
  {
    value: "Religious Institutions",
  },
  {
    value: "Renewables",
  },
  {
    value: "Repair & Maintenance",
  },
  {
    value: "Retail Banking",
  },
  {
    value: "Scientific Research",
  },
  {
    value: "Security",
  },
  {
    value: "Security Tech",
  },
  {
    value: "Semiconductors",
  },
  {
    value: "Social Assistance",
  },
  {
    value: "Sports",
  },
  {
    value: "Sports Tech",
  },
  {
    value: "Staffing & Recruiting",
  },
  {
    value: "Telecommunications",
  },
  {
    value: "Textiles",
  },
  {
    value: "Think Tanks",
  },
  {
    value: "Timber & Logging",
  },
  {
    value: "Tobacco",
  },
  {
    value: "Transportation",
  },
  {
    value: "Transportation Tech",
  },
  {
    value: "Travel & Tourism",
  },
  {
    value: "Travel & Tourism Tech",
  },
  {
    value: "Trucking",
  },
  {
    value: "TV",
  },
  {
    value: "Venture Capital",
  },
  {
    value: "Veterinary",
  },
  {
    value: "Warehousing & Storage",
  },
  {
    value: "Waste Management",
  },
  {
    value: "Wealth Management",
  },
  {
    value: "Wellness",
  },
  {
    value: "Wellness Tech",
  },
  {
    value: "Wholesale Trade",
  },
];

export default industries;
