import React from "react";
export const CONTENT1 = (
  <div className="popover-content">
    <h4 className="popover-h">GUIDANCE ON HOW TO ANSWER THIS:</h4>
    <p className="popover-subh">
      This is an opener to the conversation. Be concise and they will dive in to
      ask you more details about each work experience later on. By phrasing
      things this way, you get to show what you’re interested in and passionate
      about, despite your experience. Your experience, whether relevant or not,
      just becomes your experience. You frame things the way you want to frame
      them. If your experience/major is not perfectly in line, it is OK. Let
      them continue the interview to ask you about your other experiences that
      are relevant; let them try to assess your fit and your skills with other
      questions. Of course, you want to include whatever is most relevant in
      your pitch, but I just want you to realize that the entire point of us
      determining your goals was so that now, finally, you can say them
      authentically!
    </p>
  </div>
);

export const CONTENT2 = (
  <div className="popover-content">
    <h4 className="popover-h">GUIDANCE ON HOW TO ANSWER THIS:</h4>
    <p className="popover-subh">
      Here you want to spend 2 minutes briefly telling them about your path.
      They want to know why you chose the experiences you did, how they led you
      to try the next one, getting to know your background and your interests
      and how they've evolved. Briefly explain what the experiences were, what
      you did/learned, and how you found or why you went to start the next
      experience. I suggest starting from the earliest experience and then
      continuing chronologically until you land on the most recent experience.
      Then, you can then explain why your last experience is leaving you curious
      about (insert target job/company here).
    </p>
  </div>
);

export const CONTENT3 = (
  <div className="popover-content">
    <h4 className="popover-h">GUIDANCE ON HOW TO ANSWER THIS:</h4>
    <p className="popover-subh">
      First reflect on what you believe the truest answer to this question is.
      Honest answers always go the longest way in interviews, even if its a
      personal and not a professional example. Using the STAR method allows you
      to structure the story clearly. Be sure to explain why you think this
      accomplishment was the most meaningful or impactful to you.
    </p>
  </div>
);

export const CONTENT4 = (
  <div className="popover-content">
    <h4 className="popover-h">GUIDANCE ON HOW TO ANSWER THIS:</h4>
    <p className="popover-subh">
      They just want to see that you can make reasonable decisions. You can
      explain your thinking process here. For example, if you had to make a
      decision, show how you thought about it on both sides of the coin, and how
      you ultimately came to a decision. Maybe you leverage a peer or mentor for
      advice, or considered the risk/impact/long term effects of each. If you
      don't have an example that you feel was truly an ethical or moral
      challenge, give your closest possible example.
    </p>
  </div>
);

export const CONTENT5 = (
  <div className="popover-content">
    <h4 className="popover-h">GUIDANCE ON HOW TO ANSWER THIS:</h4>
    <p className="popover-subh">
      This is a hypothetical situation, but you can/should give an example if
      you have one. If not, tell them what you would do. Always try to give a
      specific example if you can, to show how you approach working with others.
      They are looking to see that you can effectively collaborate, communicate,
      handle difficult situations, and help others resolve conflict. In "thought
      process" type questions, if you use the STAR method, don't forget to
      include your process and the factors you considered. For instance, upon
      disagreeing, what factors were important to that project that helped you
      figure out how to account for two differing opinions?
    </p>
  </div>
);

export const CONTENT6 = (
  <div className="popover-content">
    <h4 className="popover-h">GUIDANCE ON HOW TO ANSWER THIS:</h4>
    <p className="popover-subh">
      This is a hypothetical situation, but you can/should give an example if
      you have one. If not, tell them what you would do. Always try to give a
      specific example if you can, to show how you approach working with others.
      They are looking to see that you can effectively collaborate, communicate,
      handle difficult situations, and help others resolve conflict. In "thought
      process" type questions, if you use the STAR method, don't forget to
      include your process and the factors you considered. For instance, upon
      disagreeing, what factors were important to that project that helped you
      figure out how to account for two differing opinions?
    </p>
  </div>
);

export const CONTENT7 = (
  <div className="popover-content">
    <h4 className="popover-h">GUIDANCE ON HOW TO ANSWER THIS:</h4>
    <p className="popover-subh">
      This is a hypothetical situation, but you can/should give an example if
      you have one. If not, tell them what you would do. Always try to give a
      specific example if you can. In "thought process" type questions, if you
      use the STAR method, don't forget to include your process and the factors
      you considered. They want to see how you operate, prioritize, ask for
      help, handle stress, etc.
    </p>
  </div>
);

export const CONTENT8 = (
  <div className="popover-content">
    <h4 className="popover-h">GUIDANCE ON HOW TO ANSWER THIS:</h4>
    <p className="popover-subh">
      This is another one where you need to truly reflect. What do you think
      is/was your biggest failure? It can be personal or professional. The
      honest answers always come off the strongest. Share the story, why you
      think it was a failure, and what you did to learn from it.
    </p>
    {/* <p className='popover-subh'>For example: I was an XYZ major at XYZ school with a focus in XYZ, I’m a XYZ professional with background <br/> in XYZ, etc.</p> */}
    {/* <p className='popover-subh'>For example: I was an XYZ major at XYZ school with a focus in XYZ, I’m a XYZ professional with background <br/> in XYZ, etc.</p> */}
  </div>
);

export const CONTENT9 = (
  <div className="popover-content">
    <h4 className="popover-h">GUIDANCE ON HOW TO ANSWER THIS:</h4>
    <p className="popover-subh">
      Give specifics. Explain your honest interest. What makes you
      passionate/curious about this industry/ type of work? What past
      experiences made you interested in learning more about this field? Show
      some proof that you understand the market, that you’re informed. What has
      made you interested in this sector? Why do you care about the problems
      being solved? Why do you think the products/services being built are
      valuable? Do you do any research, writing, projects or hobbies that prove
      your interest in this sector? Which specific areas of the sector do you
      find most interesting?
    </p>
  </div>
);

export const CONTENT10 = (
  <div className="popover-content">
    <h4 className="popover-h">GUIDANCE ON HOW TO ANSWER THIS:</h4>
    <p className="popover-subh">
      Don’t be generic here. This is also a great time to include if you’ve
      spoken to anyone at the firm, touting the fact that you understand XYZ
      about their company culture, and this aligns with you/your values, because
      (insert reasons here). Or perhaps someone you spoke with described the
      work they do, and it really piques your interest because .... — Try to
      lean on specifics you found on their website that differentiate them from
      competitors (or what you learned from someone you networked with). —
      Explain why they lead their industry. Again, show you’re informed by
      giving specifics. — You can also plug the specific initiatives you found
      on their website, showing that they not only focus on XYZ, but they also
      devote time/focus to XYZ initiatives too, which you’re really passionate
      about because (insert why). - Remember, prepare and explain two or three
      specific points rather than ramble. - Ideally if the company’s mission
      aligns well with your career discovery experience (research), telling the
      story of why you’re interested in what they do should not be hard. That
      was the point of all of our learning.
    </p>
  </div>
);
export const CONTENT11 = (
  <div className="popover-content">
    <h4 className="popover-h">GUIDANCE ON HOW TO ANSWER THIS:</h4>
    <p className="popover-subh">
      First, reflect on 3 specific words that describe you. Think: "What 3 words
      describe me best?" When you respond, you'll name one adjective/strength
      that represents what you are great at, and then you'll give a brief
      anecdote for each one to prove or describe how/when that strength plays
      out. You won't be able to tell a full story for each one because there
      won't be time, but share in few words to describe what the strength looks
      like in reality. When does it play out? How does it play out? How does it
      benefit you?
    </p>
  </div>
);
export const CONTENT12 = (
  <div className="popover-content">
    <h4 className="popover-h">GUIDANCE ON HOW TO ANSWER THIS:</h4>
    <p className="popover-subh">
      This is a good way for interviewers to tease out your true interests. You
      may not have this figured out the answer just yet, but it’d be a great way
      to show you know where this role leads or could lead you. What are some
      potential paths after this one that you’re considering/interested in? Are
      there other relevant teams that you could mention interest in? (The latter
      one is tricky because you want to show your interest in the role at hand,
      but if you can casually mention that you’re interested in learning about
      various facets of the firm, and it comes across as genuine, it’ll enhance
      their ability to believe you/see your long term fit there). Also, this is
      a good time to reiterate you interest in this role and mentioning that you
      can see yourself growing within that type of role to become a leader and
      also, reiterate your interest in the industry and continuing to learn and
      contribute to the innovation of that sector. As always, be as genuine and
      honest as you can about what you are interested in pursuing so they can
      tell that you'll be truly motivated by the role because it fits into your
      longer term goals.
    </p>
  </div>
);
export const CONTENT13 = (
  <div className="popover-content">
    <h4 className="popover-h">GUIDANCE ON HOW TO ANSWER THIS:</h4>
    <p className="popover-subh">
      This is an extension of the 'what are your strengths' question but its
      really asking what are your strengths and why do they align well with what
      is required for this role? Prove that you understand what the role
      requires and entails and describe why you believe you would (or are) great
      at those types of tasks/projects.
    </p>
  </div>
);
export const CONTENT14 = (
  <div className="popover-content">
    <h4 className="popover-h">GUIDANCE ON HOW TO ANSWER THIS:</h4>
    <p className="popover-subh">
      Sometimes you’ll get a specific question that isn’t a generic behavioral
      question. The key is always to pause, think about what ideas or factors
      come to mind, try to structure and organize your thoughts, be authentic,
      and give your answer honestly. What do you think would make sense to
      answer this question in a professional, honest way? What skills make a
      smart, strategic employee when it comes to “asking others for help,”
      “asking questions,” etc.?
    </p>
  </div>
);
export const CONTENT15 = (
  <div className="popover-content">
    <h4 className="popover-h">GUIDANCE ON HOW TO ANSWER THIS:</h4>
    <p className="popover-subh">
      This can really be anything that you think represents you and your
      strengths. Are you the leader, timekeeper, strategizer, feedback-giver,
      execution-er, scribe, communicator, or something else that you think is
      the role you tend to play? Again, think of what the honest answer is that
      makes sense/represents you and your strengths, and then explain why that
      skill is a critical role on the team. Again, as long as this makes sense
      and is reasonable, it’s another chance to represent you, your style, your
      personality, and your strengths. Ideally, this fits with the type of role
      you’re going for, but if it doesn’t, I’d encourage you to think about why.
    </p>
  </div>
);
export const CONTENT16 = (
  <div className="popover-content">
    <h4 className="popover-h">GUIDANCE ON HOW TO ANSWER THIS:</h4>
    <p className="popover-subh">
      Be honest, what are you looking for in a team/working environment? What’s
      enjoyable about team-related work?
    </p>
  </div>
);
export const CONTENT17 = (
  <div className="popover-content">
    <h4 className="popover-h">GUIDANCE ON HOW TO ANSWER THIS:</h4>
    <p className="popover-subh">
      The trick is there’s a second part of this question that they likely won’t
      ask. The second part is “and how do you work on improving it?” So give one
      weakness and how you work on improving it. Try to not be too fake or
      generic with the weakness, like you don’t have enough experience, or you
      work too hard, etc. Just be honest about what sometimes holds you back,
      and then tell them how you are working on improving it. Remember, honesty
      and genuine answers will go way further than anything else. As long as you
      tell them how you work on the weakness, it will be comforting to them that
      you can and like to improve where you need to.
    </p>
  </div>
);
export const CONTENT18 = (
  <div className="popover-content">
    <h4 className="popover-h">GUIDANCE ON HOW TO ANSWER THIS:</h4>
    <p className="popover-subh">
      This is usually a question towards the end. You get to now take a load
      off. This is a fun question where you can relax. Just be honest. Give them
      some examples of your hobbies. They want to see if you’re a normal person
      that they may enjoy talking to on a coffee or lunch break. Try to build
      rapport here. Of course, keep it professional.
    </p>
  </div>
);
export const CONTENT19 = (
  <div className="popover-content">
    <h4 className="popover-h">GUIDANCE ON HOW TO ANSWER THIS:</h4>
    <p className="popover-subh">
      Refer back to your research. Genuinely ask them what you want to know
      about the role, team, company, state of the industry, etc. Imagine
      yourself joining the company, what is important to you to learn before you
      get there? The next chapter will reiterate why this is so important. As
      you do your interview, jot down shorthand notes. Likely, something they’ll
      bring up during the conversation is something you want to ask more about
      or come back to. If you don’t ask it at that given moment in the
      interview, come back to it. It’ll show even more that you’re really
      engaged, curious, attentive, and interested in the role.
    </p>
  </div>
);
export const CONTENT20 = (
  <div className="popover-content">
    <h4 className="popover-h">GUIDANCE ON HOW TO ANSWER THIS:</h4>
    <p className="popover-subh">
      They want to see that you can be professional but also be willing to have
      hard conversations that will benefit your colleague, the team and the
      company in the end if you can be courageous enough to share that feedback.
      Try to use the closest example or story that you can that is relevant.
      They also will see your emotional intelligence and that you can
      effectively help others grow and develop by navigating and delivering
      feedback in an effective way. This isn't easy to do! You can also share
      what you've done in the past but what you would do differently in the
      future.
    </p>
  </div>
);
export const CONTENT21 = (
  <div className="popover-content">
    <h4 className="popover-h">GUIDANCE ON HOW TO ANSWER THIS:</h4>
    <p className="popover-subh">
      Differentiate between when you believe or think you failed versus a
      challenge you faced. Perhaps a project didn't go as planned, or there were
      changing priorities, or very senior stakeholders, or anything that caused
      an obstacle at work. Prove that you can be composed and creative in these
      situations. You can also share what you did, what you learned, and perhaps
      what you would've done differently next time.
    </p>
  </div>
);
export const CONTENT22 = (
  <div className="popover-content">
    <h4 className="popover-h">GUIDANCE ON HOW TO ANSWER THIS:</h4>
    <p className="popover-subh">
      The key here is to structure your answer. Perhaps you start with a high
      level overview of your key responsibilities, which teams you worked
      closely with, major focus areas/projects, and perhaps some key
      metrics/outcomes. This structure is just an example but you can use ANY
      structure that helps organize your thoughts and clearly, concisely
      outlines your role at a high level.
    </p>
  </div>
);
export const CONTENT23 = (
  <div className="popover-content">
    <h4 className="popover-h">GUIDANCE ON HOW TO ANSWER THIS:</h4>
    <p className="popover-subh">
      They want to see that you can be humble and open to really hear and digest
      feedback, and use it as fuel for growth. As always, be honest with your
      example and show what you did after receiving the feedback to improve on
      it. Whenever sharing how you improved, be practical and believable that
      you actually did something tactical and spent time to improve.
    </p>
  </div>
);
