import { QuestionCircleOutlined } from "@ant-design/icons";
import { Form, Input, Popover } from "antd";
import React from "react";
import "./CoverLetter.css";

const { TextArea } = Input;

const labelCol = { span: 10 };
const labelCol1 = { span: 6 };

const Items = [
  {
    title: "1. Information for the Header ",
    subtext: "",
    q1: "What city do you live in?",
    q2: "What state do you live in?",
    q3: "What is your email address?",
    q4: "What is your LinkedIn URL?",
    q5: "What is your phone number?",
  },
  {
    title: "2. Company Information",
    subtext:
      "If you are writing an example cover letter, find an open job posting to use so you can tailor the body of the letter. Then, list the company information here.",
    q1: "Which company are you applying to?",
    q2: "What is the company's street address?",
    q3: "What is the company's city, state, and zip code?",
  },
  {
    title: "3. Initial Background and Interest",
    subtext:
      "For example: hospitality, advertising, finance, healthcare, education, tech, real estate, etc.",
    q1: "Which role are you applying to?",
    ph1: "List role title here",
    // q2:'Which company are you applying to?',
    // ph2:'List company name here',
    q2: "Where did you find out about the role?",
    ph2: "List where you found the role or who referred you",
    q3:
      "Briefly describe your background, i.e. your high-level skillsets or core areas of expertise.]",
    ph3: "My core areas of expertise are: ",
    q4:
      "Write one sentence about why you’re particularly interested in the company; be specific and authentic.",
  },
  {
    title: "4. Tie Your Background to the Role",
    subtext: (
      <div>
        <p>
          We'll now have two core paragraphs where you can illustrate work
          projects or experiences that relate to the role you are applying to.
        </p>
        <p>For your first work experience:</p>
      </div>
    ),
    q1: "Which company was it at?",
    q2: "Which role was it?",
    q3:
      "Describe your overarching responsibilities and/or the core skills you honed in this role.",
    q4: (
      <span>
        Write 2-3 sentences here that describes either one story/project, or the
        aspects of the work you were doing. &nbsp;&nbsp;
        <Popover
          trigger="hover"
          placement="bottomLeft"
          overlayStyle={{ maxWidth: "45%" }}
          content="Describe (what you did, how you did it, and what you achieved). Make this as applicable to the role you’re applying to as possible, use relevant keywords, add metrics and soft skills. If you improved a process, elicited strong outcomes, earned recognition, or overcame a challenge, this is where you can give us the in-between-the-lines story of your resume. Also, if you\'re telling a story, try using the STAR method (list out the task/problem at hand, the actions you took, and then the overall outcomes/results)."
        >
          <QuestionCircleOutlined
            style={{ color: "#296B70", fontSize: "16px" }}
          />
        </Popover>
      </span>
    ),
    help_text:
      "Make this as applicable to the role you’re applying to as possible, use relevant keywords, add metrics and soft skills. If you improved a process, elicited strong outcomes, earned recognition, or overcame a challenge, this is where you can give us the in-between-the-lines story of your resume. Also, if you're telling a story, try using the STAR method (list out the task/problem at hand, the actions you took, and then the overall outcomes/results).",
  },
  {
    title: "5. Give a Second Relevant Experience",
    subtext: (
      <div>
        <p>
          List a second work experience that is relevant to the role you are
          applying to.
        </p>
        <p>For your second work experience:</p>
      </div>
    ),
    q1: "Which company was it as?",
    q2: "Which role was it?",
    q3:
      "Describe your overarching responsibilities and/or the core skills you honed in this role.",
    q4: (
      <span>
        Write 2-3 sentences here that describes either one story/project, or the
        aspects of the work you were doing. &nbsp;&nbsp;
        <Popover
          trigger="hover"
          placement="bottomLeft"
          overlayStyle={{ maxWidth: "45%" }}
          content="Describe (what you did, how you did it, and what you achieved). Make this as applicable to the role you’re applying to as possible, use relevant keywords, add metrics and soft skills. If you improved a process, elicited strong outcomes, earned recognition, or overcame a challenge, this is where you can give us the in-between-the-lines story of your resume. Also, if you\'re telling a story, try using the STAR method (list out the task/problem at hand, the actions you took, and then the overall outcomes/results)."
        >
          <QuestionCircleOutlined
            style={{ color: "#296B70", fontSize: "16px" }}
          />
        </Popover>
      </span>
    ),
    help_text:
      "Make this as applicable to the role you’re applying to as possible, use relevant keywords, add metrics and soft skills. If you improved a process, elicited strong outcomes, earned recognition, or overcame a challenge, this is where you can give us the in-between-the-lines story of your resume. Also, if you're telling a story, try using the STAR method (list out the task/problem at hand, the actions you took, and then the overall outcomes/results).",
  },
];

export default class FreeForm extends React.Component {
  CLForm = React.createRef();

  state = {
    submitted: false,
    Api_response: [],
  };
  componentDidMount() {}
  onFormChange = (changed_values, all_values, id) => {
    console.log(
      "changed_values:",
      changed_values,
      "all values:",
      all_values,
      "id is:",
      id
    );
    // const data = all_values;
    // AUTH_API.patchEmailDrafts(data,id).then(
    //     response=>{
    //         console.log("patch Api data",response);
    //     }
    // )
    // .catch(
    //     (err)=>console.log('email drafts patch Api error!', err)
    // )
  };

  render() {
    console.log("CL form comp rendered");
    return (
      <div>
        {Items.map((item, index) => {
          return (
            <div className="ip-div">
              <h4 className="cl-title">{item.title}</h4>
              <p className="cl-subtext">{item.subtext}</p>
              <Form.Item
                className="inline-block-label"
                rules={[{ required: true, message: "Required Field" }]}
                label={
                  <label style={{ whiteSpace: "break-spaces" }}>
                    {item.q1}
                  </label>
                }
                labelAlign="left"
                labelCol={index === 0 ? labelCol1 : labelCol}
                colon={false}
                name={index.toString() + "1"}
              >
                <TextArea
                  autoSize={{ minRows: 2, maxRows: 60 }}
                  placeholder={item.ph1 ? item.ph1 : ""}
                ></TextArea>
              </Form.Item>
              <Form.Item
                className="inline-block-label"
                rules={[{ required: true, message: "Required Field" }]}
                label={
                  <label style={{ whiteSpace: "break-spaces" }}>
                    {item.q2}
                  </label>
                }
                labelAlign="left"
                labelCol={index === 0 ? labelCol1 : labelCol}
                colon={false}
                name={index.toString() + "2"}
              >
                <TextArea
                  autoSize={{ minRows: 2, maxRows: 60 }}
                  placeholder={item.ph2 ? item.ph2 : ""}
                ></TextArea>
              </Form.Item>
              <Form.Item
                className="inline-block-label"
                rules={[{ required: true, message: "Required Field" }]}
                label={
                  <label style={{ whiteSpace: "break-spaces" }}>
                    {item.q3}
                  </label>
                }
                labelAlign="left"
                labelCol={index === 0 ? labelCol1 : labelCol}
                colon={false}
                name={index.toString() + "3"}
              >
                <TextArea
                  autoSize={{ minRows: 2, maxRows: 60 }}
                  placeholder={item.ph3 ? item.ph3 : ""}
                ></TextArea>
              </Form.Item>
              {item.q4 && (
                <Form.Item
                  className="inline-block-label"
                  rules={[{ required: true, message: "Required Field" }]}
                  label={
                    <label style={{ whiteSpace: "break-spaces" }}>
                      {item.q4}
                    </label>
                  }
                  labelAlign="left"
                  labelCol={index === 0 ? labelCol1 : labelCol}
                  colon={false}
                  name={index.toString() + "4"}
                >
                  <TextArea autoSize={{ minRows: 2, maxRows: 60 }}></TextArea>
                </Form.Item>
              )}
              {item.q5 && (
                <Form.Item
                  className="inline-block-label"
                  rules={[{ required: true, message: "Required Field" }]}
                  label={
                    <label style={{ whiteSpace: "break-spaces" }}>
                      {item.q5}
                    </label>
                  }
                  labelAlign="left"
                  labelCol={index === 0 ? labelCol1 : labelCol}
                  colon={false}
                  name={index.toString() + "5"}
                >
                  <TextArea autoSize={{ minRows: 2, maxRows: 60 }}></TextArea>
                </Form.Item>
              )}
            </div>
          );
        })}
      </div>
    );
  }
}
