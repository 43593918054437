import React from "react";
import { Slider, Row, Col, Button } from "antd";
// import "antd/dist/antd.css";

export default class Assessmentstart extends React.Component {
  render() {
    return (
      <div>
        <p
          style={{
            font: "normal normal 600 14px/22px Montserrat",
            color: "#296B70",
            padding: "3% 1.5%",
          }}
        >
          Let's begin your assessment!
        </p>

        <Row>
          <Col span={6}></Col>
          <Col span={12}>
            <p
              style={{
                textAlign: "center",
                font: "normal normal bold 18px/22px Montserrat",
                padding: "11% 0",
              }}
            >
              How ready are you to determine your ideal career path?
            </p>
            <Slider defaultValue={6} max={10} tooltipVisible />

            <Row>
              <Col span={12}>
                <p style={{ textAlign: "left", color: "#898E8E" }}>
                  Not ready at all
                </p>
              </Col>
              <Col span={12}>
                <p style={{ textAlign: "right", color: "#898E8E" }}>
                  Super eager and ready!
                </p>
              </Col>
            </Row>
          </Col>

          <Col span={6}></Col>
        </Row>

        <div style={{ textAlign: "right", padding: "13% 2% 2% 0" }}>
          <Button
            type="primary"
            style={{ textAlign: "right" }}
            onClick={this.props.start}
            size="large"
          >
            Start Assessment
          </Button>
        </div>
      </div>
    );
  }
}
