import React from "react";
import { Card, Input, Slider, Row, Col, Typography, Form, message } from "antd";
import "./Researchroles.css";
import EditablecardTR from "./EditablecardTR";
import AUTH_API from "./../../api/auth";
import HELPERS from "../../utils/helper";
const { TextArea } = Input;
const { Paragraph } = Typography;

export default class Testcards extends React.Component {
  researchRef = React.createRef();
  reflectionRef = React.createRef();
  AdditionalRef = React.createRef();

  state = {
    additional_card: [],
    get_api_add_cards: false,
    id: this.props.id,
    additional_q_text: [""],
    greencards: [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
    bluecards: [false, false, false, false],
  };

  componentDidMount() {
    console.log("id in get api is:", this.props.id);
    window.scrollTo(0, 0);
    AUTH_API.getTargetrole()
      .then((response) => {
        console.log("get api response", response);
        const role = response.data.filter((item) => item.id === this.props.id);
        const research_data =
          role[0].research_questions.length > 0
            ? role[0].research_questions[0]
            : {};
        const reflection_data =
          role[0].reflections.length > 0 ? role[0].reflections[0] : {};
        // console.log("get api data:",data)
        this.researchRef.current.setFieldsValue({
          ...research_data,
        });
        this.reflectionRef.current.setFieldsValue({
          ...reflection_data,
        });
        const additional_q_data =
          role[0].additional_questions.length > 0
            ? role[0].additional_questions[0]
            : {};
        const key_arr = Object.keys(additional_q_data);
        // this.setState({additional_q_text:key_arr,additional_card:key_arr})
        if (key_arr.length > 0) {
          console.log("get_api_add cards");
          this.setState({
            additional_q_text: key_arr,
            additional_card: key_arr,
            get_api_add_cards: true,
          });
          // this.setState({})
          console.log("get_api add cards state:", this.state.get_api_add_cards);
        }
        this.AdditionalRef.current.setFieldsValue({
          ...additional_q_data,
        });
        //   Code for coloring cards
        const research_data_keys = Object.keys(research_data);
        const reflection_data_keys = Object.keys(reflection_data);
        const greencardsnew = [
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
        ];
        const bluecardsnew = [false, false, false, false];
        research_data_keys.map((item) => {
          greencardsnew[Number(item) - 1] = true;
        });
        reflection_data_keys.map((item) => {
          bluecardsnew[Number(item) - 1] = true;
        });
        this.setState({ greencards: greencardsnew, bluecards: bluecardsnew });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  addNew = () => {
    const temp = this.state.additional_card;
    // const new_item = ++ this.state.c_item;
    // console.log("new card item", new_item)
    temp.push("");
    this.setState({ additional_card: temp });
  };
  additional_q(e, index) {
    console.log("question of additional_q " + index + " : ", e);
  }
  research_form_onchange = (changed_values, all_values) => {
    console.log("changed values:", changed_values, "all values:", all_values);
    this.props.yellowstep();
    const index = Number(Object.keys(changed_values)[0]);
    const newgreencards = [...this.state.greencards];
    // console.log("index",index,typeof index)
    newgreencards[index - 1] = true;
    // console.log("newgreencards",newgreencards)
    this.setState({ greencards: newgreencards });
    // console.log(this.state.greencards)
    const id = this.props.id;
    const data = { research_questions: [all_values] };
    AUTH_API.targetRolesPatch(data, id, "RR")
      .then((response) => {
        console.log("response !!", response);
        message.success("Your work has been saved")
      })
      .catch((err) => {
        console.log(err);
      });
  };
  Additional_form_onchange = (changed_values, all_values) => {
    console.log("changed values:", changed_values, "all values:", all_values);
    this.props.yellowstep();
    const id = this.props.id;
    const data = { additional_questions: [all_values] };
    AUTH_API.targetRolesPatch(data, id, "RR")
      .then((response) => {
        console.log("additional_q response !!", response);
        message.success("Your work has been saved")
      })
      .catch((err) => {
        console.log(err);
      });
  };
  reflection_form_onchange = (changed_values, all_values) => {
    console.log("changed values:", changed_values, "all values:", all_values);
    this.props.yellowstep();
    const index = Number(Object.keys(changed_values)[0]);
    const newbluecards = [...this.state.bluecards];
    // console.log("index",index,typeof index)
    newbluecards[index - 1] = true;
    // console.log("newgreencards",newgreencards)
    this.setState({ bluecards: newbluecards });
    // console.log(this.state.greencards)
    const id = this.props.id;
    const data = { reflections: [all_values] };
    AUTH_API.targetRolesPatch(data, id, "RR")
      .then((response) => {
        console.log("reflection response !!", response);
        message.success("Your work has been saved")
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    console.log("testcards rendered");
    return (
      <div>
        <div style={{ padding: "1%" }}>
          <p
            style={{
              font: "normal normal 600 14px/22px Montserrat",
              color: "#296B70",
              margin: 0,
            }}
          >
            Do some light online research to gain a quick overview and snapshot
            of the {this.props.rolename} role.{" "}
          </p>
          <p
            style={{
              font: "normal normal 100 14px/22px Montserrat",
              color: "#296B70",
            }}
          >
            This will ensure you have a baseline understanding before you start
            networking and come up with questions.
          </p>
        </div>
        <Card bordered={false} className="research-card-wrapper">
          <Form
            ref={this.researchRef}
            onValuesChange={HELPERS.debounceFormChanges(this.research_form_onchange)}
          >
            <Card.Grid
              bordered={false}
              className={
                this.state.greencards[0] === false
                  ? "research-card"
                  : "research-card-focus-within"
              }
            >
              <p className="card-heading">
                What are the key responsibilities of a {this.props.rolename}?
              </p>
              <Form.Item style={{ margin: "0" }} name={1}>
                <TextArea
                  className="text-underline"
                  bordered={false}
                  autoSize={{ minRows: 1, maxRows: 13 }}
                  onChange={() => {
                    console.log("changed");
                  }}
                ></TextArea>
              </Form.Item>
            </Card.Grid>

            <Card.Grid
              bordered={false}
              className={
                this.state.greencards[1] === false
                  ? "research-card"
                  : "research-card-focus-within"
              }
            >
              <p className="card-heading">
                What types of projects does a {this.props.rolename} do?
              </p>
              <Form.Item style={{ margin: "0" }} name={2}>
                <TextArea
                  className="text-underline"
                  bordered={false}
                  autoSize={{ minRows: 1, maxRows: 13 }}
                ></TextArea>
              </Form.Item>
            </Card.Grid>

            <Card.Grid
              bordered={false}
              hoverable={false}
              className=" half-card-container"
            >
              <Card
                bordered={false}
                className={
                  this.state.greencards[2] === false
                    ? "research-card half-card"
                    : "research-card-focus-within half-card"
                }
              >
                <p className="card-heading">
                  What are the potential career paths for {this.props.rolename}?
                </p>
                <Form.Item style={{ margin: "0" }} name={3}>
                  <TextArea
                    className="text-underline"
                    bordered={false}
                    autoSize={{ minRows: 1, maxRows: 3 }}
                  ></TextArea>
                </Form.Item>
              </Card>

              <Card
                bordered={false}
                className={
                  this.state.greencards[3] === false
                    ? "research-card half-card"
                    : "research-card-focus-within half-card"
                }
                style={{ marginTop: "24px" }}
              >
                <p className="card-heading">
                  What types of job titles are associated with{" "}
                  {this.props.rolename}?
                </p>
                <Form.Item style={{ margin: "0" }} name={4}>
                  <TextArea
                    className="text-underline"
                    bordered={false}
                    autoSize={{ minRows: 1, maxRows: 3 }}
                  ></TextArea>
                </Form.Item>
              </Card>
            </Card.Grid>

            <Card.Grid
              bordered={false}
              className={
                this.state.greencards[4] === false
                  ? "research-card"
                  : "research-card-focus-within"
              }
            >
              <p className="card-heading">
                What’s the work environment? (office or remote, pace, travel)
              </p>
              <Form.Item style={{ margin: "0" }} name={5}>
                <TextArea
                  className="text-underline"
                  bordered={false}
                  autoSize={{ minRows: 1, maxRows: 13 }}
                ></TextArea>
              </Form.Item>
            </Card.Grid>

            <Card.Grid
              bordered={false}
              className={
                this.state.greencards[5] === false
                  ? "research-card"
                  : "research-card-focus-within"
              }
            >
              <p className="card-heading">
                What does it take to break into {this.props.rolename}?
              </p>
              <Form.Item style={{ margin: "0" }} name={6}>
                <TextArea
                  className="text-underline"
                  bordered={false}
                  autoSize={{ minRows: 1, maxRows: 13 }}
                ></TextArea>
              </Form.Item>
            </Card.Grid>

            <Card.Grid
              bordered={false}
              className={
                this.state.greencards[6] === false
                  ? "research-card"
                  : "research-card-focus-within"
              }
            >
              <p className="card-heading">
                How might {this.props.rolename} change in the next 5-10 years?
              </p>
              <Form.Item style={{ margin: "0" }} name={7}>
                <TextArea
                  className="text-underline"
                  bordered={false}
                  autoSize={{ minRows: 1, maxRows: 13 }}
                ></TextArea>
              </Form.Item>
            </Card.Grid>

            <Card.Grid
              bordered={false}
              className={
                this.state.greencards[7] === false
                  ? "research-card"
                  : "research-card-focus-within"
              }
            >
              <p className="card-heading">
                Place for any interesting links about {this.props.rolename}
              </p>
              <Form.Item style={{ margin: "0" }} name={8}>
                <TextArea
                  className="text-underline"
                  bordered={false}
                  autoSize={{ minRows: 1, maxRows: 13 }}
                ></TextArea>
              </Form.Item>
            </Card.Grid>

            <Card.Grid
              bordered={false}
              className={
                this.state.greencards[8] === false
                  ? "research-card"
                  : "research-card-focus-within"
              }
            >
              <p className="card-heading">
                What questions do I have about {this.props.rolename}?
              </p>
              <Form.Item style={{ margin: "0" }} name={9}>
                <TextArea
                  className="text-underline"
                  bordered={false}
                  autoSize={{ minRows: 1, maxRows: 13 }}
                ></TextArea>
              </Form.Item>
            </Card.Grid>

            <Card.Grid
              bordered={false}
              className={
                this.state.greencards[9] === false
                  ? "research-card"
                  : "research-card-focus-within"
              }
            >
              <p className="card-heading">Place for any additional notes</p>
              <Form.Item style={{ margin: "0" }} name={10}>
                <TextArea
                  className="text-underline"
                  bordered={false}
                  autoSize={{ minRows: 1, maxRows: 13 }}
                ></TextArea>
              </Form.Item>
            </Card.Grid>
          </Form>

          <Form
            ref={this.AdditionalRef}
            onValuesChange={HELPERS.debounceFormChanges(this.Additional_form_onchange)}
          >
            {this.state.additional_card.map((item, index) => {
              return (
                <EditablecardTR
                  key={index}
                  additional_q_text={this.state.additional_q_text[index]}
                  gc={this.state.get_api_add_cards}
                />
              );
            })}
          </Form>
        </Card>

        <div style={{ textAlign: "right", padding: "46px 70px" }}>
          <p
            onClick={() => {
              this.addNew();
            }}
            style={{
              color: "#32AAB0",
              cursor: "pointer",
              width: "fit-content",
              float: "right",
            }}
          >
            &#43; &nbsp; Add more research
          </p>
        </div>

        <p
          style={{
            font: "normal normal 600 20px/12px Montserrat",
            textAlign: "left",
            padding: "1%",
          }}
        >
          My reflections about {this.props.rolename}
        </p>
        <Card bordered={false}>
          <Form
            ref={this.reflectionRef}
            onValuesChange={HELPERS.debounceFormChanges(this.reflection_form_onchange)}
          >
            <Card.Grid
              bordered={false}
              hoverable={false}
              className={
                this.state.bluecards[0] === false
                  ? "research-card-low"
                  : "research-card-low-focus-within"
              }
            >
              <p className="card-heading-low">
                What interests me about {this.props.rolename}?
              </p>
              <Form.Item style={{ margin: "0" }} name={1}>
                <TextArea
                  className="text-underline"
                  bordered={false}
                  autoSize={{ minRows: 1, maxRows: 13 }}
                  onChange={() => {
                    console.log("changed");
                  }}
                ></TextArea>
              </Form.Item>
            </Card.Grid>

            <Card.Grid
              bordered={false}
              hoverable={false}
              className={
                this.state.bluecards[1] === false
                  ? "research-card-low"
                  : "research-card-low-focus-within"
              }
            >
              <p className="card-heading-low">
                What worries me about {this.props.rolename}?
              </p>
              <Form.Item style={{ margin: "0" }} name={2}>
                <TextArea
                  className="text-underline"
                  bordered={false}
                  autoSize={{ minRows: 1, maxRows: 13 }}
                ></TextArea>
              </Form.Item>
            </Card.Grid>

            <Card.Grid
              bordered={false}
              hoverable={false}
              className="half-card-container"
            >
              <Card
                bordered={false}
                className={
                  this.state.bluecards[2] === false
                    ? "research-card-low half-card-low"
                    : "research-card-low-focus-within half-card-low"
                }
              >
                <p className="card-heading-low">
                  Do I know anyone in {this.props.rolename}?
                </p>
                <Form.Item style={{ margin: "0" }} name={3}>
                  <TextArea
                    className="text-underline"
                    bordered={false}
                    autoSize={{ minRows: 1, maxRows: 3 }}
                  ></TextArea>
                </Form.Item>
              </Card>

              <Card
                bordered={false}
                style={{ marginTop: "24px" }}
                className={
                  this.state.bluecards[3] === false
                    ? "research-card-low half-card-low"
                    : "research-card-low-focus-within half-card-low"
                }
              >
                <p className="card-heading-low">My current level of interest</p>
                {/* <p className='card-heading-low'>My current level of interest</p> */}
                <Form.Item style={{ margin: "0" }} name="interest_level">
                  <Slider
                    max={10}
                    tooltipVisible
                    style={{ marginTop: "45px" }}
                  />
                </Form.Item>

                <Row justify="space-between">
                  <Col span={12}>
                    <p
                      style={{
                        textAlign: "left",
                        color: "#898E8E",
                        font: "normal normal bold 12px Montserrat",
                        marginTop: "-5px",
                      }}
                    >
                      No interest
                    </p>
                  </Col>
                  <Col span={12}>
                    <p
                      style={{
                        textAlign: "right",
                        color: "#898E8E",
                        font: "normal normal bold 12px Montserrat",
                        marginTop: "-5px",
                      }}
                    >
                      Extremely interested
                    </p>
                  </Col>
                </Row>
              </Card>
            </Card.Grid>
          </Form>
        </Card>
      </div>
    );
  }
}
