import React from "react";
import { message, Form, Input, Button, Row, Col, Select } from "antd";
import { Link } from "react-router-dom";
import OtpInput from "react-otp-input";
import HELPERS from "utils/helper";
import "./Signinform.css";
import AUTH_API from "./../../api/auth";
import DASHBOARD_API from "api/dashboard";
import { COUNTRYCODES } from "../Constants/COUNTRYCODES";
import ReactGA from "react-ga";

const { Option } = Select;
const OPTIONS = COUNTRYCODES.map((item, index) => (
  <Option key={index} value={item.code + " " + item.dial_code}>
    {item.code + " " + item.dial_code}
  </Option>
));

const rules = {
  required: "Required Field",
  types: {
    number: "This is not a valid number, enter only numbers",
    integer: "This is not a valid number, enter only numbers",
    email: "not a valid email",
    regexp: "not a valid number",
  },
};

export default class Signinform extends React.Component {
  state = {
    enter_otp: false,
    country_code: "",
    phone_no: "",
    current_otp: "",
    phone: "",
  };

  // handleChange = otp => this.setState({ otp })

  otpVerified = "";

  onSendOTP = (values) => {
    values = {
      ...values,
      country_code: this.state.country_code,
      phone: this.state.phone_no,
    };
    console.log("OTP: ", values);
    const formdata = HELPERS.converToFormData(values);

    AUTH_API.verifyOTP(formdata)
      .then((response) => {
        console.log("otp response !!", response);
        if (response.data.Status === "Success") {
          message.success("Logged in");
          localStorage.setItem("token", response.data.Response.token);
          // console.log(localStorage.getItem('token'));
          localStorage.setItem("user_id", response.data.Response.id);

          if (!this.otpVerified) {
            const assess_data = {
              ideal_career_path_score: 0,
              experiences: [],
              content_areas: [],
              environment: [],
            };
            console.log("token");
            AUTH_API.postAssessment(assess_data)
              .then((res) => console.log("assessment added", res))
              .catch((err) => console.log("assessment post error", err));
            this.postEmailDrafts("intro");
            this.postEmailDrafts("phone call");
            this.postEmailDrafts("linkedIn");
            this.postMaterials();
          }

          const username = response.data.Response.first_name + ' ' + response.data.Response.last_name;
          window.heap.identify(username);
          ReactGA.set({ userId: username });

          if (response.data.Response.is_coach) {
            localStorage.setItem("isCoach", true);
            ReactGA.event({
              category: 'Coach',
              label: 'Sign In',
              action: 'Logged In'
            });
            setTimeout(function(){window.location.href = "/coach";}, 6000)
          } else {
            ReactGA.event({
              category: 'User',
              label: 'Sign In',
              action: 'Logged In'
            });
            setTimeout(function(){window.location.href = "/";}, 6000);
          } 
        } else message.error("Incorrect OTP");
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  postEmailDrafts = (title) => {
    const data = {
      title: title,
    };
    AUTH_API.postEmailDraft(data)
      .then((response) => {
        console.log("post Api data", response);
      })
      .catch((err) => console.log("email drafts post Api error!", err));
  };
  postMaterials = () => {
    DASHBOARD_API.get()
      .then((response) => {
        const profile = response.data;
        const profile_val = {
          "01": profile.city,
          "02": profile.state,
          "03": profile.email,
          "05": profile.country_code + profile.phone,
        };
        const data = {
          professional_summary: [{ aa: 11 }],
          resume_best_practices: [{}],
          cover_letter: { ...profile_val },
          linked_in: [{}],
        };
        AUTH_API.postMaterials(data)
          .then((response) => {
            console.log("Materials post Api data", response);
          })
          .catch((err) => console.log("Materials post Api error!", err));
      })
      .catch((err) => console.log("dashboard get Api error!", err));
  };

  sendPhone = (value) => {
    console.log(value);
    value.country_code = value.country_code.split(" ")[1];
    const formdata = HELPERS.converToFormData(value);

    AUTH_API.sendOTP(formdata)
      .then((response) => {
        console.log("login", response, !this.state.enter_otp);
        if (!response.data.Response.is_active) {
          message.error("User has been disabled!");
        } else {
          // if (!this.state.enter_otp) {
          this.setState({
            enter_otp: true,
            country_code: value.country_code,
            phone_no: value.phone,
          });
          // }
          const channel = response.data.Response.otp_channel
          message.success("OTP is sent to your " + channel);
          this.otpVerified = response.data.Response.is_validated;
        }
      })
      .catch((err) => {
        message.error("User is not registered! Create an account first");
        console.log(err);
      });
  };

  onPhoneInput = (e) => {
    console.log("input number:", e.target.value);
    let valu = e.target.value;
    Number(valu) ? console.log("number ") : console.log("alpha");

    if (!Number(valu) && valu.length > 0) {
      return;
    }

    this.setState({ phone: valu });
  };

  render() {
    return (
      <div style={{ width: "47%", marginLeft: "29%", marginTop: "10%" }}>
        <div className="h" style={{ fontSize: "30px" }}>
          Sign In
        </div>

        <Form
          style={{ marginTop: "18%" }}
          name="normal_login"
          onFinish={this.sendPhone}
          // validateMessages={validateMessages}
        >
          <Row gutter={16}>
            <Col span={6}>
              <p
                style={{
                  textAlign: "left",
                  fontSize: "13px",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                }}
              >
                Country Code
              </p>
              <Form.Item name="country_code">
                <Select
                  style={{}}
                  size="large"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {OPTIONS}
                </Select>
              </Form.Item>
            </Col>

            <Col span={18}>
              <p
                style={{
                  textAlign: "left",
                  fontSize: "13px",
                  fontWeight: "bold",
                }}
              >
                Phone Number
              </p>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    pattern: /^\d+$/,
                    message: "Input only numbers",
                  },
                ]}
              >
                {/* {getFieldDecorator("value", {
                rules
              })} */}
                <Input size="large" />
              </Form.Item>
            </Col>
          </Row>

          {!this.state.enter_otp ? (
            <Button size="large" type="primary" htmlType="submit" block>
              Next
            </Button>
          ) : (
            <div>
              <Button
                size="small"
                type="primary"
                htmlType="submit"
                className="resend"
              >
                Resend OTP
              </Button>
            </div>
          )}
        </Form>

        <Form initialValues={{ remember: true }} onFinish={this.onSendOTP}>
          {this.state.enter_otp ? (
            <div>
              <p
                style={{
                  textAlign: "left",
                  fontSize: "13px",
                  fontWeight: "bold",
                }}
              >
                OTP
              </p>
              <Form.Item name="otp" rules={[{ required: true }]}>
                <OtpInput
                  inputStyle={{ width: "70%", height: "180%" }}
                  numInputs={6}
                  separator={<span style={{ width: "3%" }}></span>}
                />
              </Form.Item>

              <Form.Item>
                <Button size="large" type="primary" htmlType="submit" block>
                  Log in
                </Button>
              </Form.Item>
            </div>
          ) : (
            ""
          )}
          <p style={{ marginTop: "7%" }}>
            New user?
            <Link to={{ pathname: "/onboarding", stepProps: { curr: 1 } }}>
              &nbsp;Create an account
            </Link>
          </p>
        </Form>
      </div>
    );
  }
}

// export default Form.create()(Signinform);
