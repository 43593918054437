const ROLES = [
  {
    value: "Academic Researcher",
  },
  {
    value: "Account Management",
  },
  {
    value: "Accounting",
  },
  {
    value: "Actor",
  },
  {
    value: "Actuarial Scientist",
  },
  {
    value: "Advertising",
  },
  {
    value: "Anthropologist",
  },
  {
    value: "Archaeologist",
  },
  {
    value: "Architect",
  },
  {
    value: "Art Director",
  },
  {
    value: "Artist",
  },
  {
    value: "Astronomer",
  },
  {
    value: "Biologist",
  },
  {
    value: "Brand Development/Marketing",
  },
  {
    value: "Budget analyst",
  },
  {
    value: "Building Management",
  },
  {
    value: "Business Analyst",
  },
  {
    value: "Business Development",
  },
  {
    value: "CEO",
  },
  {
    value: "CFO",
  },
  {
    value: "Chef",
  },
  {
    value: "Chemist",
  },
  {
    value: "Chief of Staff",
  },
  {
    value: "Civil Engineer",
  },
  {
    value: "CMO",
  },
  {
    value: "Coach",
  },
  {
    value: "Compliance manager/analyst",
  },
  {
    value: "Construction",
  },
  {
    value: "Consultant",
  },
  {
    value: "Controller",
  },
  {
    value: "COO",
  },
  {
    value: "Creative Director",
  },
  {
    value: "Criminal Justice",
  },
  {
    value: "CRM Manager",
  },
  {
    value: "CTO",
  },
  {
    value: "Curator (Art)",
  },
  {
    value: "Customer Service",
  },
  {
    value: "Data Analyst",
  },
  {
    value: "Data Engineer",
  },
  {
    value: "Data Scientist",
  },
  {
    value: "Designer - Fashion",
  },
  {
    value: "Designer - Game",
  },
  {
    value: "Designer - Graphic",
  },
  {
    value: "Designer - Interior",
  },
  {
    value: "Designer - UI/UX/Web",
  },
  {
    value: "Digital Marketing Manager",
  },
  {
    value: "Doctor",
  },
  {
    value: "Economist",
  },
  {
    value: "Editor",
  },
  {
    value: "Education Administrator",
  },
  {
    value: "Educational Counselor",
  },
  {
    value: "Electrical Engineer",
  },
  {
    value: "Entrepreneur",
  },
  {
    value: "Environmental Analyst/Scientist",
  },
  {
    value: "Event Management / Planner",
  },
  {
    value: "Event Marketing",
  },
  {
    value: "Executive assistant",
  },
  {
    value: "Film Director",
  },
  {
    value: "Financial advisor",
  },
  {
    value: "Financial Analyst",
  },
  {
    value: "Firefighter",
  },
  {
    value: "Fitness Instructor",
  },
  {
    value: "Food Scientist",
  },
  {
    value: "Forensic scientist",
  },
  {
    value: "Fundraising",
  },
  {
    value: "Geneticist",
  },
  {
    value: "Hardware Engineer",
  },
  {
    value: "Historian",
  },
  {
    value: "Human Resources Business Partner",
  },
  {
    value: "Human Resources Generalist",
  },
  {
    value: "Industrial organizational psychologist",
  },
  {
    value: "Intelligence Analyst",
  },
  {
    value: "Internal Communications",
  },
  {
    value: "Investment Banker",
  },
  {
    value: "Investor relations",
  },
  {
    value: "Journalist",
  },
  {
    value: "Judge",
  },
  {
    value: "Law Enforcement",
  },
  {
    value: "Lawyer",
  },
  {
    value: "Logistics manager",
  },
  {
    value: "Market Researcher",
  },
  {
    value: "Marketing Manager",
  },
  {
    value: "Mechanical Engineer",
  },
  {
    value: "Medical Assistant",
  },
  {
    value: "Mental Health Counselor",
  },
  {
    value: "Merchandiser",
  },
  {
    value: "Military",
  },
  {
    value: "Movie Producer",
  },
  {
    value: "Music Director",
  },
  {
    value: "Music Producer",
  },
  {
    value: "Musician",
  },
  {
    value: "News Anchor",
  },
  {
    value: "Nurse",
  },
  {
    value: "Office Management",
  },
  {
    value: "Operations Analyst/Manager",
  },
  {
    value: "Paralegal",
  },
  {
    value: "Paramedic",
  },
  {
    value: "Performer (Dance/Theatre/Singer)",
  },
  {
    value: "Pharmacist",
  },
  {
    value: "Photographer",
  },
  {
    value: "Physicist",
  },
  {
    value: "Podcaster",
  },
  {
    value: "Police",
  },
  {
    value: "Political Scientist",
  },
  {
    value: "Politician",
  },
  {
    value: "Procurement",
  },
  {
    value: "Producer",
  },
  {
    value: "Product Development",
  },
  {
    value: "Product Management",
  },
  {
    value: "Product Marketing Manager",
  },
  {
    value: "Product Owner",
  },
  {
    value: "Professor",
  },
  {
    value: "Program Management",
  },
  {
    value: "Project Management",
  },
  {
    value: "Public Government Role",
  },
  {
    value: "Public Relations",
  },
  {
    value: "Public Service",
  },
  {
    value: "Public Speaker",
  },
  {
    value: "Quality control",
  },
  {
    value: "Real Estate Agent",
  },
  {
    value: "Recruiter",
  },
  {
    value: "Research & Development",
  },
  {
    value: "Researcher",
  },
  {
    value: "Risk Management",
  },
  {
    value: "Safety Manager",
  },
  {
    value: "Sales Manager",
  },
  {
    value: "Scientist",
  },
  {
    value: "Scrum Master",
  },
  {
    value: "Security Manager",
  },
  {
    value: "Social Media Influencer",
  },
  {
    value: "Social Media Manager",
  },
  {
    value: "Social Scientist",
  },
  {
    value: "Social Worker",
  },
  {
    value: "Sociologist",
  },
  {
    value: "Software Engineer",
  },
  {
    value: "Sports Analyst",
  },
  {
    value: "Sports Broadcasting",
  },
  {
    value: "Supply chain management",
  },
  {
    value: "Talent Development",
  },
  {
    value: "Talent Management (HR)",
  },
  {
    value: "Talent Management (Music)",
  },
  {
    value: "Teacher",
  },
  {
    value: "Therapist",
  },
  {
    value: "Therapist Mental",
  },
  {
    value: "Therapist Physical",
  },
  {
    value: "Treasurer",
  },
  {
    value: "University Professional/Management",
  },
  {
    value: "Urban Planner",
  },
  {
    value: "Veterinarian",
  },
  {
    value: "Videographer",
  },
  {
    value: "Web Engineer",
  },
  {
    value: "Writer",
  },
];

export default ROLES;
