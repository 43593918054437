import React from "react";
import { connect } from "react-redux";
import arrayMove from "array-move";
import HELPERS from "utils/helper";
import {
  selectTargetRoles,
  addRoleAsync,
  deleteRoleAsync,
  updateRoleAsync,
} from "slices/targetRolesSlice";
import SortableList from "./SortableList";
import { message } from "antd";
class DraggableCompTR extends React.Component {
  state = {
    loading: false,
  };

  getRoles(all) {
    return this.props.roles
      ? this.props.roles
          .filter((item) => all === true || item.learn_more === true)
          .sort((a, b) => a.priority - b.priority)
      : [];
  }

  addNew = () => {
    const data = {
      title: `Enter New Role`,
      priority: this.getRoles(true).length + 1,
      learn_more: true,
      selected_for: [],
      research_questions: [],
      additional_questions: [],
      reflections: [],
    };
    const formdata = HELPERS.converToFormData(data);
    this.props.addRoleAsync(formdata);
  };

  deleteItem = (index) => {
    this.props.deleteRoleAsync(this.getRoles()[index].id);
  };

  changeText = (e, index) => {
    HELPERS.log(e.target.value, index, this.getRoles()[index].title);
    if (this.getRoles()[index].title !== e.target.value) {
      this.props.updateRoleAsync(this.getRoles()[index].id, {
        title: e.target.value,
      });
      message.success('Your work has been saved');
    }
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      var oldData = [...this.getRoles()];
      const newData = arrayMove(oldData, oldIndex, newIndex);
      HELPERS.log(newData);
      let priority = 1;

      newData.forEach((item) => {
        const data = {
          priority: priority,
        };
        this.props.updateRoleAsync(item.id, data);
        priority += 1;
      });
      message.success('Your work has been saved');
    }
  };

  getListItems = () => {
    const items = [];

    this.getRoles().forEach((item) => {
      items.push({
        id: item.id,
        title: item.title,
      });
    });

    return items;
  };

  render() {
    HELPERS.log("Got some updates", this.getRoles());
   
    return (
      <>
        {!this.state.loading && (
          <SortableList
            useDragHandle
            isDone={this.props.isDone}
            items={this.getListItems()}
            onSortEnd={this.onSortEnd}
            onChangeText={this.changeText}
            onDeleteItem={this.deleteItem}
            onAddItem={this.addNew}
            addTitle={"Add a New Role"}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  roles: selectTargetRoles(state),
});

const mapDispatchToProps = () => ({
  addRoleAsync,
  deleteRoleAsync,
  updateRoleAsync,
});

export default connect(mapStateToProps, mapDispatchToProps())(DraggableCompTR);
