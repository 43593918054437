import React from "react";
import "./Signin.css";
import { Layout } from "antd";
// import "antd/dist/antd.css";
import Signinform from "./Signinform";

const { Sider } = Layout;
const { Content } = Layout;

export default class Signin extends React.Component {
  state = {
    current: 1,
  };

  render() {
    console.log("signinbase rendered");
    return (
      <Layout className="parent-comp">
        <Sider
          width="33%"
          style={{
            backgroundImage:
              "linear-gradient(to bottom right, #90D6DA, #00949A)",
          }}
        >
          <p
            style={{
              fontWeight: "bold",
              color: "white",
              fontSize: "40px",
              padding: "20% 0",
              margin: 0,
            }}
          >
            WOKEN
          </p>
          <p
            style={{
              fontWeight: "bold",
              color: "white",
              fontSize: "40px",
              padding: "10% 10%",
            }}
          >
            Helping you find a job you will
            <span style={{ color: "#296B70", fontStyle: "italic" }}>
              &nbsp;love
            </span>
            .
          </p>
        </Sider>

        <Layout style={{ backgroundColor: "white" }}>
          <Content style={{ backgroundColor: "white", height: "100vh" }}>
            <Signinform />
          </Content>
        </Layout>
      </Layout>
    );
  }
}
