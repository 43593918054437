import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import HELPERS from "utils/helper";
import { selectTasks } from "../../slices/dashboardSlice";
import "./calendar.css";
import "./style.css";
import { Link } from "react-router-dom";

const Calendar = () => {
  const currentDate = moment();
  const tasks = useSelector(selectTasks);
  const [dateObject, setDateObject] = useState(moment());
  const [selectedDay, setSelectedDay] = useState(null);
  const weekdayshort = moment.weekdaysShort();

  const getDaysInMonth = () => {
    return dateObject.daysInMonth();
  };

  const getCurrentDay = () => {
    return parseInt(currentDate.format("D"));
  };

  const getCurrentMonth = () => {
    return currentDate.format("MMMM");
  };

  const firstDayOfMonth = () => {
    return moment(dateObject).startOf("month").format("d"); // Day of week 0...1..5...6;
  };

  const month = () => {
    return dateObject.format("MMMM");
  };

  const onPrev = () => {
    setDateObject(dateObject.clone().subtract(1, "month"));
  };

  const onNext = () => {
    setDateObject(dateObject.clone().add(1, "month"));
  };

  const coachEvents = tasks.map((task) => {
    const dueDate = moment(task.due_date);
    if (
      (task.weekly_reflection > 0 || task.step_reflection > 0) &&
      dueDate.month() === dateObject.month()
    ) {
      HELPERS.log(
        `Coach Task ${task.title} is due by ${dueDate} and currentDate is ${dateObject}`
      );
      return dueDate.date();
    }

    return 0;
  });

  const personalEvents = tasks.map((task) => {
    const dueDate = moment(task.due_date);
    if (
      !task.weekly_reflection &&
      !task.step_reflection &&
      dueDate.month() === dateObject.month()
    ) {
      HELPERS.log(
        `Personal Task ${task.title} is due by ${dueDate} and currentDate is ${dateObject}`
      );
      return dueDate.date();
    }

    return 0;
  });

  const onDayClick = (e, d) => {
    setSelectedDay(parseInt(d));
  };

  let weekdayshortname = weekdayshort.map((day, i) => {
    return <th key={`calendar-week-${i}`}>{day}</th>;
  });

  let blanks = [];
  for (let i = 0; i < firstDayOfMonth(); i++) {
    blanks.push(
      <td key={`calendar-day-empty-${i}`} className="calendar-day empty">
        {""}
      </td>
    );
  }

  HELPERS.log("Current day", getCurrentDay());

  let daysInMonth = [];
  for (let d = 1; d <= getDaysInMonth(); d++) {
    let currentDay =
      d === getCurrentDay() && month() === getCurrentMonth() ? "today" : "";
    let selectedDayClass = d === selectedDay ? " selected-day " : "";
    let personalEvent = personalEvents.indexOf(d) !== -1;
    let coachEvent = coachEvents.indexOf(d) !== -1;
    let events = null;
    if (personalEvent || coachEvent) {
      events = [];

      if (personalEvent && coachEvent) {
        events.push(
          <span key={`coach-event-${d}-1`} className="personal event-dot" />
        );
        events.push(
          <span key={`coach-event-${d}-2`} className="coach event-dot" />
        );
      } else if (personalEvent) {
        events.push(
          <span key={`coach-event-${d}-1`} className="personal event-dot" />
        );
        if (personalEvents.filter((e) => e === d).length > 1) {
          events.push(
            <span key={`coach-event-${d}-2`} className="personal event-dot" />
          );
        }
      } else {
        events.push(
          <span key={`coach-event-${d}-1`} className="coach event-dot" />
        );
        if (coachEvents.filter((e) => e === d).length > 1) {
          events.push(
            <span key={`coach-event-${d}-2`} className="coach event-dot" />
          );
        }
      }

      events = (
        <span key={`coach-events-${d}`} className="events">
          {events}
        </span>
      );
    }

    daysInMonth.push(
      <td
        key={d}
        className={`calendar-day ${currentDay} ${selectedDayClass}`}
        onClick={(e) => {
          onDayClick(e, d);
        }}
      >
        <span>{d}</span>
        {events}
      </td>
    );
  }

  let totalSlots = [...blanks, ...daysInMonth];
  let rows = [];
  let cells = [];

  totalSlots.forEach((row, i) => {
    if (i % 7 !== 0) {
      cells.push(row);
    } else {
      rows.push(cells);
      cells = [];
      cells.push(row);
    }
    if (i === totalSlots.length - 1) {
      // let insertRow = cells.slice();
      rows.push(cells);
    }
  });

  daysInMonth = rows.map((d, i) => {
    return <tr key={`calendar-day-${i}`}>{d}</tr>;
  });

  HELPERS.log(`Rendering calendar with events`, personalEvents);

  return (
    <>
      <div
        className="tail-datetime-calendar-wrapper"
        style={{ display: "grid" }}
      >
        <div className="tail-datetime-calendar">
          <div className="calendar-navi">
            <span onClick={onPrev} className="calendar-button button-prev" />
            <span className="calendar-label">{month()}</span>
            <span onClick={onNext} className="calendar-button button-next" />
          </div>
          <div className="calendar-date">
            <table className="calendar-day">
              <thead>
                <tr>{weekdayshortname}</tr>
              </thead>
              <tbody>{daysInMonth}</tbody>
            </table>
          </div>
        </div>
        <Link
          to="/calendar"
          style={{ textAlign: "left", paddingBottom: "10px" }}
        >
          See full calendar
        </Link>
      </div>
    </>
  );
};

export default Calendar;
