import HELPERS from "utils/helper";
// const roleCode = HELPERS.getCookie(‘roleCode’);
const AUTH_API = {
  sendOTP: (params) => {
    return HELPERS.request({
      url: `signin`,
      method: "POST",
      data: params,
    });
  },
  verifyOTP: (params) => {
    return HELPERS.request({
      url: `verify`,
      method: "POST",
      data: params,
    });
  },
  registerUser: (params) => {
    return HELPERS.request({
      url: `register`,
      method: "POST",
      data: params,
    });
  },
  aboutYourself: (params) => {
    return HELPERS.secureRequest({
      url: `profile/aboutyourself/${HELPERS.getUserId()}/`,
      method: "PATCH",
      data: params,
    });
  },
  aboutYourselfGet: () => {
    return HELPERS.secureRequest({
      url: `profile/aboutyourself/`,
      method: "GET",
    });
  },
  ourCommitment: (params) => {
    return HELPERS.secureRequest({
      url: `profile/ourcommitmenttoyou/${HELPERS.getUserId()}/`,
      method: "PATCH",
      data: params,
    });
  },
  ourCommitmentGet: () => {
    return HELPERS.secureRequest({
      url: `profile/ourcommitmenttoyou/`,
      method: "GET",
    });
  },
  updateYourCommitment: (params) => {
    return HELPERS.secureRequest({
      url: `profile/yourcommitmenttoyourself/${HELPERS.getUserId()}/`,
      method: "PATCH",
      data: params,
    });
  },
  postFeedback: (data) => {
    return HELPERS.secureRequest({
      url: `feedback/${HELPERS.getUserId()}/`,
      method: "PATCH",
      data,
    });
  },
  getYourCommitment: () => {
    return HELPERS.secureRequest({
      url: `profile/yourcommitmenttoyourself/`,
      method: "GET",
    });
  },
  getAllTargetroles: () => {
    return HELPERS.secureRequest({
      url: `target/roles/`,
      method: "GET",
    });
  },
  getTargetrole: (id) => {
    return HELPERS.secureRequest({
      url: `target/roles/`,
      method: "GET",
    });
  },
  addTargetrole: (params, Stepname) => {
    return HELPERS.secureRequest({
      url: `target/roles/`,
      method: "POST",
      data: params,
      Stepname,
    });
  },
  targetRolesPatch: (params, id, Stepname) => {
    return HELPERS.secureRequest({
      url: `target/roles/` + id + `/`,
      method: "PATCH",
      data: params,
      Stepname,
    });
  },
  targetRolesDelete: (id, Stepname) => {
    return HELPERS.secureRequest({
      url: `target/roles/` + id + `/`,
      method: "DELETE",
      Stepname,
      // data: params,
    });
  },
  getAllTargetindustries: () => {
    return HELPERS.secureRequest({
      url: `target/industries/`,
      method: "GET",
    });
  },
  getTargetindustry: (id) => {
    return HELPERS.secureRequest({
      url: `target/industries/`,
      method: "GET",
    });
  },
  setTargetindustry: (params, Stepname) => {
    return HELPERS.secureRequest({
      url: `target/industries/`,
      method: "POST",
      data: params,
      Stepname,
    });
  },
  targetIndustriesPatch: (params, id, Stepname) => {
    return HELPERS.secureRequest({
      url: `target/industries/` + id + `/`,
      method: "PATCH",
      data: params,
      Stepname,
    });
  },
  targetIndustriesDelete: (id) => {
    return HELPERS.secureRequest({
      url: `target/industries/` + id + `/`,
      method: "DELETE",
      // data: params,
    });
  },
  getAllContacts: () => {
    return HELPERS.secureRequest({
      url: `contacts/`,
      method: "GET",
    });
  },
  getContact: (id) => {
    return HELPERS.secureRequest({
      url: `contacts/` + id + `/`,
      method: "GET",
    });
  },
  setNewContact: (params, Stepname) => {
    return HELPERS.secureRequest({
      url: `contacts/`,
      method: "POST",
      data: params,
      Stepname,
    });
  },
  patchContact: (params, id, Stepname) => {
    return HELPERS.secureRequest({
      url: `contacts/` + id + `/`,
      method: "PATCH",
      data: params,
      Stepname,
    });
  },
  deleteContact: (id, Stepname) => {
    return HELPERS.secureRequest({
      url: `contacts/` + id + `/`,
      method: "DELETE",
      Stepname,
      // data: params,
    });
  },
  patchReflectionsProcess: (params, id, Stepname) => {
    return HELPERS.secureRequest({
      url: `reflectionsprocess/` + id + `/`,
      method: "PATCH",
      data: params,
      Stepname,
    });
  },
  CoachListView: () => {
    return HELPERS.secureRequest({
      url: `coachlistview/`,
      method: "GET",
    });
  },
  CoachStepsPatch: (params, id) => {
    return HELPERS.secureRequest({
      url: `stepreflection/` + id + `/setstepstatus/`,
      method: "PATCH",
      data: params,
    });
  },
  CoachListPatch: (params, id) => {
    return HELPERS.secureRequest({
      url: `coachlistview/` + id + `/setuserdetails/`,
      method: "PATCH",
      data: params,
    });
  },
  postAssessment: (params) => {
    return HELPERS.secureRequest({
      url: `assessment/`,
      method: "POST",
      data: params,
    });
  },
  getAssessment: () => {
    return HELPERS.secureRequest({
      url: `assessment/`,
      method: "GET",
    });
  },
  patchAssessment: (params, id) => {
    return HELPERS.secureRequest({
      url: `assessment/` + id + `/`,
      method: "PATCH",
      data: params,
    });
  },
  getAllEmailDrafts: () => {
    return HELPERS.secureRequest({
      url: `emaildraft/`,
      method: "GET",
    });
  },
  patchEmailDrafts: (params, id) => {
    return HELPERS.secureRequest({
      url: `emaildraft/` + id + `/`,
      method: "PATCH",
      data: params,
    });
  },
  postEmailDraft: (params) => {
    return HELPERS.secureRequest({
      url: `emaildraft/`,
      method: "POST",
      data: params,
    });
  },
  deleteEmailDraft: (id) => {
    return HELPERS.secureRequest({
      url: `emaildraft/` + id + `/`,
      method: "DELETE",
    });
  },
  postMaterials: (params) => {
    return HELPERS.secureRequest({
      url: `materials/`,
      method: "POST",
      data: params,
    });
  },
  getMaterials: () => {
    return HELPERS.secureRequest({
      url: `materials/`,
      method: "GET",
    });
  },
  patchMaterials: (params, id, Stepname) => {
    return HELPERS.secureRequest({
      url: `materials/` + id + "/",
      method: "PATCH",
      data: params,
      Stepname,
    });
  },
  getAllCompanies: () => {
    return HELPERS.secureRequest({
      url: `company/`,
      method: "GET",
    });
  },
  getCompany: (id) => {
    return HELPERS.secureRequest({
      url: `company/` + id,
      method: "GET",
    });
  },
  patchCompany: (params, id, Stepname) => {
    return HELPERS.secureRequest({
      url: `company/` + id + "/",
      method: "PATCH",
      data: params,
      Stepname,
    });
  },
  postCompany: (params) => {
    return HELPERS.secureRequest({
      url: `company/`,
      method: "POST",
      data: params,
    });
  },

  deleteCompany: (id) => {
    return HELPERS.secureRequest({
      url: `company/` + id + "/",
      method: "DELETE",
    });
  },

  getLearning: () => {
    return HELPERS.secureRequest({
      url: `learning/`,
      method: "GET",
    });
  },
  postLearning: (params) => {
    return HELPERS.secureRequest({
      url: `learning/`,
      method: "POST",
      data: params,
    });
  },
  patchLearning: (params, id) => {
    return HELPERS.secureRequest({
      url: `learning/` + id + `/`,
      method: "PATCH",
      data: params,
    });
  },
  deleteLearning: (id) => {
    return HELPERS.secureRequest({
      url: `learning/` + id + `/`,
      method: "DELETE",
    });
  },
  // getFilters: () => {
  //     return HELPERS.request({
  //         url: `/activity/activity-filter-list/`,
  //         method: ‘GET’,
  //     });
  // },
  // createFilter: (data) => {
  //     return HELPERS.request({
  //         url: `/activity/create-activity-filter/`,
  //         method: ‘POST’,
  //         data: data
  //     });
  // },
  // getComments: (id) => {
  //     return HELPERS.request({
  //         url: `/activity/get-activity-notes/`,
  //         method: ‘GET’,
  //         params: { activity_id: id }
  //     });
  // },
  // getActivityDetails : (id) => {
  //     return HELPERS.request({
  //         url: `/activity/get-activity/${id}`,
  //         method: ‘GET’,
  //     });
  // },
  // getMilestones : (id) => {
  //     return HELPERS.request({
  //         url: `/activity/activity-milestone-list/`,
  //         method: ‘GET’,
  //         params: { activity_id: id }
  //     });
  // },
  // createMilestone : (data) => {
  //     return HELPERS.request({
  //         url: `/activity/insert-milestone/`,
  //         // url: `/activity/milestone-create/`,
  //         method: ‘POST’,
  //         data: data
  //     });
  // },
  // getMilestone : (id) => {
  //     return HELPERS.request({
  //         url: `/activity/get-milestone/${id}`,
  //         method: ‘GET’,
  //     });
  // },
  // updateActivity : (data) => {
  //     return HELPERS.request({
  //         url: `/activity/update-activity/${data.id}`,
  //         method: ‘PATCH’,
  //         data: data
  //     });
  // },
  // resolveConflict : (data) => {
  //     return HELPERS.request({
  //         url: `/activity/resolve-activity-conflict/${data.id}`,
  //         method: ‘PATCH’,
  //         data: data
  //     });
  // },
  // addComment : (data) => {
  //     return HELPERS.request({
  //         url: `/activity/create-activity-notes/`,
  //         method: ‘POST’,
  //         data: data
  //     });
  // },
  // closeMileStone : (data ,id) => {
  //     return HELPERS.request({
  //         url: `/activity/close-milestone/${id}`,
  //         method: ‘PATCH’,
  //         data: data
  //     });
  // },
  // deleteMilestone : (id) => {
  //     return HELPERS.request({
  //         url: `/activity/delete-milestone/${id}`,
  //         method: ‘DELETE’,
  //     });
  // },
  // createActivity : (data) => {
  //     console.log(‘in call’, data)
  //     return HELPERS.request({
  //         url: `/activity/activity-create/`,
  //         method: ‘POST’,
  //         data: data
  //     });
  // },
  // createActivityExtension :(data) => {
  //     return HELPERS.request({
  //         url: `/activity/create-activity-extension//`,
  //         method: ‘POST’,
  //         data: data
  //     });
  // },
  // deleteFilter: (id) => {
  //     return HELPERS.request({
  //         url: `/activity/activity-filter-delete/${id}/`,
  //         method: ‘DELETE’,
  //     });
  // },
  // closeActivity: (id) => {
  //     return HELPERS.request({
  //         url: `/activity/close-activity/${id}`,
  //         method: ‘PATCH’,
  //     });
  // },
  // fetchActivityMilestones: (id) => {
  //     return HELPERS.request({
  //         url: `/search/get-activity-milestone-list/`,
  //         method: ‘GET’,
  //         params: {
  //             activity_id: id
  //         }
  //     });
  // }
};
export default AUTH_API;
