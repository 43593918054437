import {
  AlignLeftOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Col, DatePicker, Input, Popconfirm, Popover, Row, Select } from "antd";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import Filterdropdown from "./Filterdropdown";

const { Option } = Select;

const category_mapping = {
  fr: "Friends",
  aq: "Acquaintances",
  pc: "Past Colleagues",
  so: "Social Organizations",
  al: "Alumni",
  tr: "Target Roles",
  ti: "Target Industries",
  tc: "Target Companies",
  rc: "Recruiters",
  cc: "Cold Connections",
};

const contactRender = (
  text,
  record,
  type,
  onChangeContact,
  onDeleteContact
) => {
  let children = [];
  // console.log('type',type)
  if (!text.length) {
    children.push(<div style={{ opacity: "0.4", textAlign: "center" }}></div>);
  } else {
    switch (type) {
      case "name":
        text.map((item, index) =>
          children.push(
            <Row style={{ padding: "4px 0" }}>
              {/* {console.log(record.id + type + item.id)} */}
              <Col span={20}>
                <>
                  {React.createElement(() => (
                    <Input
                      defaultValue={item.title}
                      key={record.id + type + item.id + index}
                      onChange={(e) =>
                        onChangeContact(e.target.value, item, "title")
                      }
                    />
                  ))}
                </>
              </Col>
              <Col span={4} style={{ textAlign: "center" }}>
                <Popconfirm
                  title="Are you sure you want to delete ?"
                  onConfirm={() => onDeleteContact(item.id)}
                >
                  <DeleteOutlined className="row-btn-del" />
                </Popconfirm>
              </Col>
            </Row>
          )
        );
        break;

      case "scheduled_date":
        text.map((item) =>
          children.push(
            <Row style={{ padding: "4px 0" }}>
              <>
                {React.createElement(() => (
                  <DatePicker
                    key={record.id + type + item.id}
                    defaultValue={
                      item.tracker_info.scheduled_date
                        ? moment(item.tracker_info.scheduled_date)
                        : null
                    }
                    onChange={(date, dateString) =>
                      onChangeContact(dateString, item, "scheduled_date")
                    }
                  />
                ))}
              </>
            </Row>
          )
        );
        break;

      case "callprep":
        text.map((item) =>
          !item.call_prep.length
            ? children.push(
                <Link to={{ pathname: "/networking/call-prep", id: item.id }}>
                  <PlusOutlined
                    className="row-btn-add"
                    style={{ display: "block", padding: 10 }}
                  />
                </Link>
              )
            : children.push(
                <Link to={{ pathname: "/networking/call-prep", id: item.id }}>
                  <AlignLeftOutlined
                    className="row-btn"
                    style={{ display: "block", padding: 10 }}
                  />
                </Link>
              )
        );
        break;

      default:
        text.map((item) =>
          children.push(
            <Row>
              <Input />
            </Row>
          )
        );
    }
  }
  return { children };
};

const baseColumns = (
  addNewCol,
  onChange,
  showDrawer,
  getRoles,
  getSize,
  getIndustries,
  onChangeContact,
  onPostContact,
  onDeleteContact
) => {
  return [
    {
      title: "sno",
      dataIndex: "sno",
      key: "sno",
      fixed: "left",
      colSpan: 0,
      width: 50,
      render: (text, rec, index) => index + 1,
    },
    {
      title: "Company Name",
      dataIndex: "title",
      key: "title",
      className: "no-space",
      width: 200,
      colSpan: 2,
      fixed: "left",
      // onHeaderCell: (column) => ({
      //   width: column.width,
      //   onResize: this.handleResize(0),
      // }),
      sorter: (a, b) => a.title.localeCompare(b.title),
      render: (text, record) => (
        <a
          style={{ color: "#32AAB0", wordBreak: "break-all" }}
          onClick={() => showDrawer(record)}
        >
          {text}
        </a>
      ),
    },

    {
      title: "COMPANY INFO",
      key: "companyinfo",
      filterIcon: <PlusOutlined />,
      // fixed: 'left',
      filterDropdown: ({ confirm }) => (
        <Filterdropdown
          confirm={() => confirm()}
          addNewCol={addNewCol}
          className="filter-dropdown"
          val={"companyinfo"}
        />
      ),
      className: "col-1",
      children: [
        // {
        //   title: "Company name",
        //   dataIndex: "title",
        //   key: "title",
        //   className: "no-space",
        //   width: 230,
        //   // fixed: 'left',
        //   // onHeaderCell: (column) => ({
        //   //   width: column.width,
        //   //   onResize: this.handleResize(0),
        //   // }),
        //   sorter: (a, b) => a.title.localeCompare(b.title),
        //   render: (text, record) => (
        //     <a
        //       style={{ color: "#32AAB0", wordBreak: "break-all" }}
        //       onClick={() => showDrawer(record)}
        //     >
        //       {text}
        //     </a>
        //   ),
        // },
        {
          title: "Industry",
          dataIndex: "industry",
          key: "industry",
          className: "no-space",
          width: 250,
          sorter: (a, b) =>
            a.industry ? a.industry.localeCompare(b.industry) : true,
          render: (text, record) => (
            <Select
              key={`ind-for2-${text}-${record.id}`}
              onSelect={(val) => onChange(val, record, "industry")}
              defaultValue={text ? text : null}
              className="at-select"
              placeholder="Select Industry"
              style={{ maxWidth: "100%", minWidth: "100%" }}
              bordered={false}
              dropdownClassName="option-style"
            >
              {getIndustries().map((item) => (
                <Option key={record.id + "ind2"} value={item.title}>
                  {item.title}
                </Option>
              ))}
            </Select>
          ),
        },
        {
          title: "Company size",
          dataIndex: "company_size",
          key: "company_size",
          className: "no-space",
          width: 210,
          sorter: (a, b) =>
            a.company_size
              ? a.company_size.localeCompare(b.company_size)
              : true,
          render: (text, record) => (
            <Select
              key={`size-for-${text}-${record.id}`}
              onSelect={(val) => onChange(val, record, "company_size")}
              defaultValue={text ? text : null}
              placeholder="Select Size"
              style={{ maxWidth: "100%", minWidth: "100%" }}
              className="at-select"
              bordered={false}
              dropdownClassName="option-style"
            >
              {getSize().map((item, index) => (
                <Option key={index + "size"} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          ),
        },
      ],
    },
    {
      title: "MY CONTACT AT COMPANY",
      key: "contact_at_company",
      filterIcon: <PlusOutlined />,
      filterDropdown: ({ confirm }) => (
        <Filterdropdown
          confirm={() => confirm()}
          addNewCol={addNewCol}
          className="filter-dropdown"
          val={"contact_at_company"}
        />
      ),
      className: "col-1",
      children: [
        {
          title: "Actions",
          colSpan: 0,
          dataIndex: "actions",
          key: "actions",
          render: (text, rec) => (
            <Popover
              placement="right"
              content={<div>Add Contact</div>}
              trigger="hover"
            >
              <PlusCircleOutlined
                className="row-btn-add"
                onClick={() => onPostContact(rec)}
              />
            </Popover>
          ),
          width: 50,
        },
        {
          title: "Contact Name",
          dataIndex: "contact_name",
          key: "contact_name",
          colSpan: 2,
          sorter: true,
          // sorter: (a, b) =>
          //   a.contact_name.toString().localeCompare(b.contact_name.toString()),
          className: "no-space",
          width: 290,
          //----------------------------here--------------------------------------------------------
          render:
            // ()=><p></p>
            (text, record) =>
              contactRender(
                text,
                record,
                "name",
                onChangeContact,
                onDeleteContact
              ),
        },
        {
          title: "Scheduled Call Date",
          dataIndex: "scheduled_date",
          key: "scheduled_date",
          sorter: true,
          // sorter: (a, b) => a.scheduled_date.toString().localeCompare(b.scheduled_date.toString()),
          className: "no-space",
          width: 140,
          //----------------------------here--------------------------------------------------------
          render:
            // ()=><p></p>
            (text, record) =>
              contactRender(
                text,
                record,
                "scheduled_date",
                onChangeContact,
                onDeleteContact
              ),
        },
        {
          title: "Call Prep",
          dataIndex: "callprep",
          key: "callprep",
          width: 90,
          sorter: true,
          // className: "no-space",
          //----------------------------here--------------------------------------------------------
          render:
            //  ()=><p></p>
            (text, record) =>
              contactRender(
                text,
                record,
                "callprep",
                onChangeContact,
                onPostContact
              ),
        },
      ],
    },
    {
      title: "ROLE",
      key: "role",
      filterIcon: <PlusOutlined />,
      filterDropdown: ({ confirm }) => (
        <Filterdropdown
          confirm={() => confirm()}
          addNewCol={addNewCol}
          val={"role"}
          className="filter-dropdown"
        />
      ),
      className: "col-1",

      children: [
        {
          title: "Source",
          dataIndex: "source",
          key: "source",
          className: "no-space",
          sorter: (a, b) =>
            a.source || b.source
              ? !a.source
                ? -1
                : !b.source
                ? 1
                : a.source[0].localeCompare(b.source[0])
              : 0,
          width: 230,
          render: (text, record) => (
            <Select
              key={`source-for-${text}-${record.id}`}
              mode="tags"
              maxTagCount={1}
              onChange={(val) => onChange(val, record, "source")}
              defaultValue={text ? text : []}
              placeholder="Select Source"
              style={{ maxWidth: "100%", minWidth: "100%" }}
              className="at-select"
              bordered={false}
              dropdownClassName="option-style"
            >
              <Option key="s1" value="networking">
                Found via networking
              </Option>
              <Option key="s2" value="online">
                Found online
              </Option>
            </Select>
          ),
        },
        {
          title: "Title",
          dataIndex: "role_title",
          key: "role_title",
          className: "no-space",
          sorter: (a, b) =>
            a.role_title ? a.role_title.localeCompare(b.role_title) : true,
          width: 200,
          // ---------------------------Not a dropdown??-------------------------------------------
          render: (text, record) => (
            <Select
              key={`role-for2-${text}-${record.id}`}
              onChange={(val) => onChange(val, record, "role_title")}
              defaultValue={text}
              placeholder="Select role"
              style={{ maxWidth: "100%", minWidth: "100%" }}
              bordered={false}
            >
              {getRoles().map((item) => (
                <Option value={item.title}>{item.title}</Option>
              ))}
            </Select>
          ),
        },
        {
          title: "Job Posting Link",
          dataIndex: "job_link",
          key: "job_link",
          className: "no-space",
          sorter: (a, b) =>
            a.job_link ? a.job_link.localeCompare(b.job_link) : true,
          width: 250,
          render: (text, record) => (
            <Input
              placeholder="Type something"
              key={`job_link-${text}-${record.id}`}
              onChange={(val) => onChange(val.target.value, record, "job_link")}
              defaultValue={text}
            />
            // <a
            //   href={text}
            //   target="_blank"
            //   title={text}
            //   style={{ wordBreak: "break-all" }}
            //   rel="noopener noreferrer"
            // >
            //   {text}
            // </a>
          ),
        },
      ],
    },
    {
      title: "APPLICATIONS & INTERVIEWS",
      key: "application_interviews",
      filterIcon: <PlusOutlined />,
      filterDropdown: ({ confirm }) => (
        <Filterdropdown
          confirm={() => confirm()}
          addNewCol={addNewCol}
          val={"application_interviews"}
          className="filter-dropdown"
        />
      ),
      className: "col-1",
      children: [
        {
          title: "Date Applied",
          dataIndex: "date_applied",
          key: "date_applied",
          sorter: (a, b) =>
            a.date_applied
              ? a.date_applied.localeCompare(b.date_applied)
              : true,
          className: "no-space",
          width: 140,
          render: (text, record) => (
            <>
              {React.createElement(() => (
                <DatePicker
                  onChange={(date, dateString) =>
                    onChange(dateString, record, "date_applied")
                  }
                  defaultValue={text ? moment(text) : null}
                  bordered={false}
                  key={"datea" + record.id}
                ></DatePicker>
              ))}
            </>
          ),
        },
        {
          title: "Phone Screening",
          dataIndex: "phone_screening",
          width: 140,
          key: "phone_screening",
          sorter: (a, b) =>
            a.phone_screening
              ? a.phone_screening.localeCompare(b.phone_screening)
              : true,
          className: "no-space",
          render: (text, record) => (
            <>
              {React.createElement(() => (
                <DatePicker
                  onChange={(date, dateString) =>
                    onChange(dateString, record, "phone_screening")
                  }
                  defaultValue={text ? moment(text) : null}
                  bordered={false}
                  key={"phonesc" + record.id}
                ></DatePicker>
              ))}
            </>
          ),
        },
        {
          title: "Interview Round #2",
          dataIndex: "interview_2",
          key: "interview_2",
          sorter: (a, b) =>
            a.interview_2 ? a.interview_2.localeCompare(b.interview_2) : true,
          className: "no-space",
          width: 140,
          render: (text, record) => (
            <>
              {React.createElement(() => (
                <DatePicker
                  onChange={(date, dateString) =>
                    onChange(dateString, record, "interview_2")
                  }
                  defaultValue={text ? moment(text) : null}
                  bordered={false}
                  key={"int2" + record.id}
                ></DatePicker>
              ))}
            </>
          ),
        },
        {
          title: "Interview Round #3",
          dataIndex: "interview_3",
          key: "interview_3",
          width: 140,
          sorter: (a, b) =>
            a.interview_3 ? a.interview_3.localeCompare(b.interview_3) : true,
          className: "no-space",
          render: (text, record) => (
            <>
              {React.createElement(() => (
                <DatePicker
                  onChange={(date, dateString) =>
                    onChange(dateString, record, "interview_3")
                  }
                  defaultValue={text ? moment(text) : null}
                  bordered={false}
                  key={"int3" + record.id}
                ></DatePicker>
              ))}
            </>
          ),
        },
      ],
    },
    {
      title: "LEVEL OF INTEREST",
      key: "interest_level",
      filterIcon: <PlusOutlined />,
      filterDropdown: ({ confirm }) => (
        <Filterdropdown
          confirm={() => confirm()}
          addNewCol={addNewCol}
          val={"interest_level"}
          className="filter-dropdown"
        />
      ),
      className: "col-1",
      children: [
        {
          title: "Role",
          dataIndex: "role_interest",
          key: "role_interest",
          className: "no-space",
          width: 100,
          sorter: (a, b) =>
            a.role_interest || b.role_interest
              ? !a.role_interest
                ? -1
                : !b.role_interest
                ? 1
                : a.role_interest.localeCompare(b.role_interest)
              : 0,
          // a.role_interest && a.role_interest.length ?
          //   a.role_interest.toString().localeCompare(b.role_interest.toString())
          // : true,
          render: (text, record) => (
            <Select
              key={`role-${text}-${record.id}`}
              onSelect={(val) => onChange(val, record, "role_interest")}
              defaultValue={text ? text : null}
              // placeholder="Select"
              style={{ maxWidth: "100%", minWidth: "100%" }}
              className="at-select"
              bordered={false}
              dropdownClassName="option-style"
            >
              {Array.from(Array(10).keys()).map((item, index) => (
                <Option key={index + "role"} value={(item + 1).toString()}>
                  {item + 1}
                </Option>
              ))}
              ;
            </Select>
          ),
        },
        {
          title: "Industry",
          dataIndex: "industry_interest",
          key: "industry_interest",
          className: "no-space",
          width: 100,
          sorter: (a, b) =>
            a.industry_interest || b.industry_interest
              ? !a.industry_interest
                ? -1
                : !b.industry_interest
                ? 1
                : a.industry_interest.localeCompare(b.industry_interest)
              : 0,
          // a.industry_interest ?
          //   a.industry_interest
          //     .toString()
          //     .localeCompare(b.industry_interest.toString())
          //   : true,
          render: (text, record) => (
            <Select
              key={`industryy-${text}-${record.id}`}
              onSelect={(val) => onChange(val, record, "industry_interest")}
              defaultValue={text ? text : null}
              // placeholder="Select"
              style={{ maxWidth: "100%", minWidth: "100%" }}
              className="at-select"
              bordered={false}
              dropdownClassName="option-style"
            >
              {Array.from(Array(10).keys()).map((item, index) => (
                <Option key={index + "industryy"} value={(item + 1).toString()}>
                  {item + 1}
                </Option>
              ))}
              ;
            </Select>
          ),
        },
      ],
    },
    {
      title: "AFTER CALL",
      key: "aftercall",
      filterIcon: () => <PlusOutlined className="filter-dropdown" />,
      filterDropdown: ({ confirm }) => (
        <Filterdropdown
          confirm={() => confirm()}
          addNewCol={addNewCol}
          val={"aftercall"}
        />
      ),
      className: "col-1",
      children: [
        {
          title: "Follow up and next steps",
          dataIndex: "followup",
          key: "followup",
          className: "no-space",
          width: 250,
          sorter: (a, b) =>
            a.followup ? a.followup.localeCompare(b.followup) : true,
          render: (text, record) => (
            <Input
              // placeholder="Type something"
              key={`${text}-${record.id}`}
              onChange={(val) => onChange(val.target.value, record, "followup")}
              defaultValue={text}
            />
          ),
        },
      ],
    },
  ];
};

const extraColumnTemplate = (
  key,
  title,
  parent_col,
  onChange,
  onHeaderCell
) => ({
  title: (
    <Popover
      placement="topLeft"
      content={<div>Right click to edit</div>}
      trigger="hover"
    >
      {title}
    </Popover>
  ),
  col_name: title,
  parent_col: parent_col,
  dataIndex: key,
  key: `${title}-${key}`,
  className: "no-space",
  width: 250,
  onHeaderCell: onHeaderCell,
  sorter: (a, b) =>
    a[`${key}`] ? a[`${key}`].localeCompare(b[`${key}`]) : true,
  render: (text, record) =>
    !(text instanceof Array) ? (
      <Input
        key={`${text}-${record.id}`}
        onChange={(val) => {
          val && onChange(val.target.value, record, key);
        }}
        defaultValue={text}
      />
    ) : (
      contactRender(text, record, key)
    ),
});

export { baseColumns, category_mapping, extraColumnTemplate };
