import { Select } from "antd";
import React from "react";
import AUTH_API from "./../../api/auth";
// import 'antd/dist/antd.css';
import "./Coach.css";
const { Option } = Select;

export default class Selectbutton extends React.Component {
  state = {
    color: "585858",
    bg: "#ffffff",
  };

  componentDidMount() {
    this.Option_style(this.props.step_obj.status);
  }

  Option_style = (value) => {
    if (value === "LO") {
      this.setState({
        color: "#585858",
        bg: "#ffffff",
      });
    }
    if (value === "UN") {
      this.setState({
        color: "#3EDEFF",
        bg: "#3EDEFF1e",
      });
    }
    if (value === "RE") {
      this.setState({
        color: "#FFEC3E",
        bg: "#FFEC3E1e",
      });
    }
    if (value === "ON") {
      this.setState({
        color: "#FFCA3E",
        bg: "#FFCA3E1e",
      });
    }
    if (value === "DO") {
      this.setState({
        color: "#51E34C",
        bg: "#51E34C1e",
      });
    }
    if (value === "CO") {
      this.setState({
        color: "#864ce3",
        bg: "#864CE32D",
      });
    }
  };

  onchange = ({ value }) => {
    // console.log(value);
    this.Option_style(value);
    const data = {
      id: this.props.step_obj.id,
      step: this.props.step_obj.step,
      status: value,
    };
    const id = this.props.step_obj.id;
    console.log("patch api data:", data, "step_id", id);
    AUTH_API.CoachStepsPatch(data, id)
      .then((response) => {
        console.log("patch api response", response);
      })
      .catch((err) => {
        console.log("patch api error", err);
      });
    if (this.props.cn_obj && !this.props.cp_obj) {
      const data = {
        id: this.props.cn_obj.id,
        step: this.props.cn_obj.step,
        status: value,
      };
      const id = this.props.cn_obj.id;
      console.log("patch api data:", data, "step_id", id);
      AUTH_API.CoachStepsPatch(data, id)
        .then((response) => {
          console.log("patch api response", response);
        })
        .catch((err) => {
          console.log("patch api error", err);
        });
    }
    if (this.props.cp_obj && this.props.cn_obj && value === "UN") {
      // Release Call Prep
      const data = {
        id: this.props.cp_obj.id,
        step: this.props.cp_obj.step,
        status: value,
      };
      const id = this.props.cp_obj.id;
      console.log("patch api data:", data, "step_id", id);
      AUTH_API.CoachStepsPatch(data, id)
        .then((response) => {
          console.log("patch api response", response);
        })
        .catch((err) => {
          console.log("patch api error", err);
        });
      // Release Call Notes
      const cn_data = {
        id: this.props.cn_obj.id,
        step: this.props.cn_obj.step,
        status: value,
      };
      const cn_id = this.props.cn_obj.id;
      console.log("patch api data:", cn_data, "step_id", cn_id);
      AUTH_API.CoachStepsPatch(cn_data, cn_id)
        .then((response) => {
          console.log("patch api response", response);
        })
        .catch((err) => {
          console.log("patch api error", err);
        });
    }
    if (this.props.ir_obj && value === "UN") {
      // Release Interview Research
      let ir_data = {
        id: this.props.ir_obj.id,
        step: this.props.ir_obj.step,
        status: value,
      };
      const ir_id = this.props.ir_obj.id;
      console.log("patch api data:", ir_data, "step_id", ir_id);
      AUTH_API.CoachStepsPatch(ir_data, ir_id)
        .then((response) => {
          console.log("patch api response for call notes", response);
        })
        .catch((err) => {
          console.log("patch api error", err);
        });
    }
  };

  render() {
    // console.log(this.props.step_obj)
    return (
      <>
        <Select
          labelInValue
          defaultValue={{ value: this.props.step_obj.status }}
          style={{
            width: "100%",
            color: this.state.color,
            backgroundColor: this.state.bg,
            fontSize: "13px",
          }}
          onChange={this.onchange}
          bordered={false}
          className="dropdown-select"
        >
          <Option value="LO" className="lock">
            Locked
          </Option>
          <Option value="UN" className="unlock">
            Unlocked
          </Option>
          <Option value="RE" className="rev">
            Review
          </Option>
          <Option value="ON" className="ongo">
            Ongoing
          </Option>
          <Option value="DO" className="done">
            Done
          </Option>
          {
            this.props.step_obj.step === 'NF' &&
            <Option value="CO" className="continuous">
              Continuous
            </Option>
          }
        </Select>
      </>
    );
  }
}
