import React from "react";
import { Modal, Button } from "antd";
import { Link } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import { subSteps } from "utils/steps";

export default class NewStep extends React.Component {
  render() {
    let linkTo = "";
    let title = "";
    if (!!this.props.stepKey) {
      for (var stepKey in subSteps) {
        for (var subStep of subSteps[stepKey]) {
          if (subStep.stepKey === this.props.stepKey) {
            linkTo = subStep.linkTo;
            title =
              subStep.title === "Roles"
                ? "Research-Roles"
                : subStep.title === "Industries"
                ? "Research-Industries"
                : subStep.title === "Tracker"
                ? subStep.linkTo === "/networking/tracker"
                  ? "Networking Tracker"
                  : "Application Tracker"
                : subStep.title;
            break;
          }
        }

        if (title.length > 0) break;
      }
    }

    return (
      <Modal
        title=""
        visible={this.props.visible && !!this.props.stepKey}
        onCancel={this.props.onClose}
        footer={null}
        mask={this.props.mask}
        width="40%"
        style={{ textAlign: "center", padding: 0 }}
        closeIcon={<CloseOutlined className="close-icon" />}
      >
        <div>
          <div
            style={{
              background: "#C677E3 0% 0% no-repeat padding-box",
              margin: "-24px",
            }}
          >
            <img
              style={{ width: "100%" }}
              src="https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/thank_you.svg"
            ></img>
            <p
              style={{
                font: "normal normal bold 24px/12px Montserrat",
                padding: "24px 0 70px 0",
                color: "white",
              }}
            >
              Your next step is ready!
            </p>
          </div>
          <p style={{ margin: "80px 40px 90px" }}>
            Wahoo! Your coach has released the next step in your career platform
            -
            <Link
              to={linkTo}
              style={{ color: "#00949A" }}
              onClick={this.props.onClose}
            >
              &nbsp;{title}&nbsp;
            </Link>
            .
            <br />
            As always, let your coach know if you have any questions.
          </p>

          <Link to={linkTo}>
            <Button
              type="primary"
              style={{ marginBottom: "80px", width: "200px" }}
              size="large"
              onClick={this.props.onClose}
            >
              Go to step
            </Button>
          </Link>
        </div>
      </Modal>
    );
  }
}
