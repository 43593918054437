import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import Addcontacts from "./Addcontacts";
import Trackertablewrapper from "./Tracker";
import {
  selectConfigurations,
  updateProfileAsync,
} from "slices/dashboardSlice";

class Trackerbase extends React.Component {
  state = {
    current_step: 0,
  };
  componentDidMount() {
    const configurations = this.props.configurations;
    console.log("has config at first", configurations);
    if (configurations.networking_tracker) {
      this.setState({ current_step: 1 });
    }
  }

  next = () => {
    this.setState({
      current_step: 1,
    });
    let configurations = this.props.configurations;
    if (Array.isArray(configurations)) {
      console.log("is array");
      configurations = {};
    }
    configurations.networking_tracker = {};
    configurations.networking_tracker[1] = "1";
    console.log("next!!", configurations);
    if (configurations === {}) {
      this.props.updateProfileAsync({ configurations }, "NT");
    }
  };

  render() {
    return (
      <>
        {this.state.current_step ? (
          <Trackertablewrapper />
        ) : (
          <Row>
            <Col span={13}>
              <Addcontacts next={this.next} />
            </Col>
            <Col span={11}></Col>
          </Row>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  configurations: selectConfigurations(state),
});

const mapDispatchToProps = () => ({
  updateProfileAsync,
});

export default connect(mapStateToProps, mapDispatchToProps())(Trackerbase);
