import { Popover } from "antd";
import React from "react";
import "./ProfSummary.css";

export default class GeneratedSummary extends React.Component {
  SummaryForm = React.createRef();

  state = {
    summary_data: "",
    loading: true,
    saved: false,
  };
  componentDidMount() {
    console.log("props data from prof summary", this.props.summary);
    let object = { ...this.props.summary };

    // if(object.edited)
    // {
    //     this.SummaryForm.current.setFieldsValue({
    //         ...object.edited
    //     });
    // }
    if (!object.edited) {
      Object.keys(object).forEach((item) => {
        object[item] = Object.values(object[item]);
      });
      object[2] = object["2_added"]
        ? object[2].concat(object["2_added"])
        : object[2];
      object[3] = object["3_added"]
        ? object[3].concat(object["3_added"])
        : object[3];
      object[6] = object["6_added"]
        ? object[6].concat(object["6_added"])
        : object[6];
      object[8] = object["8_added"]
        ? object[8].concat(object["8_added"])
        : object[8];
      delete object["2_added"];
      delete object["3_added"];
      delete object["6_added"];
      delete object["8_added"];
      delete object["0"];
      console.log("api text", object);

      let c_obj = object;
      let str = "";
      for (let i = 1; i < 9; i++) {
        object[i].forEach((item, index) => {
          if (item !== "" && item !== null) {
            if (object[i].length === 1) {
              str = str.concat(item);
            }
            if (index !== object[i].length - 1) {
              str = str.concat(item, " , ");
            }
            if (index === object[i].length - 1 && object[i].length > 1) {
              str = str.concat(" and ", item);
            }
          }
        });
        c_obj[i] = str;
        str = "";
      }
      console.log("converted object", c_obj);

      this.setState({ loading: false, summary_data: c_obj });
    } else {
      this.setState({
        loading: false,
        summary_data: object.edited,
        saved: true,
      });
    }
  }
  content = () => {
    return (
      <div>
        <p className="popover-saved-content">
          The suggested framework for your professional summary is:
        </p>
        <ol type="1">
          <li className="popover-saved-content">
            Your background including functional skillsets and industries
          </li>
          <li className="popover-saved-content">
            {" "}
            Your strengths and what you love doing
          </li>
          <li className="popover-saved-content">
            Your direction - ideal roles and companies
          </li>
        </ol>
      </div>
    );
  };

  render() {
    console.log("generated summary rendered", this.props.summary);
    return (
      <div>
        {this.state.loading ? (
          "....generating"
        ) : (
          <div className="sum-box">
            {this.state.saved ? (
              <Popover
                placement="bottom"
                content={this.content}
                trigger="hover"
              >
                <span className="inline-text">{this.state.summary_data}</span>
              </Popover>
            ) : (
              <div>
                <Popover
                  placement="bottom"
                  content="your professional title"
                  trigger="hover"
                >
                  <span className="inline-text">
                    {this.state.summary_data[1]}
                  </span>
                </Popover>
                <span className="inline-text">
                  {" "}
                  professional with experience in{" "}
                </span>
                <Popover
                  placement="bottom"
                  content="your areas of expertise"
                  trigger="hover"
                >
                  <span className="inline-text">
                    {this.state.summary_data[2]}
                  </span>
                </Popover>
                <span className="inline-text"> across </span>
                <Popover
                  placement="bottom"
                  content="industries you've worked in"
                  trigger="hover"
                >
                  <span className="inline-text">
                    {this.state.summary_data[3]}
                  </span>
                </Popover>
                <span className="inline-text"> industries . Strengths in </span>
                <Popover
                  placement="bottom"
                  content="what you love doing"
                  trigger="hover"
                >
                  <span className="inline-text">
                    {this.state.summary_data[4]}
                  </span>
                </Popover>
                <span className="inline-text"> in order to </span>
                <Popover
                  placement="bottom"
                  content="outcome / goal of your activity"
                  trigger="hover"
                >
                  <span className="inline-text">
                    {this.state.summary_data[5]}
                  </span>
                </Popover>
                <span className="inline-text"> by utilizing </span>
                <Popover
                  placement="bottom"
                  content="your soft skills"
                  trigger="hover"
                >
                  <span className="inline-text">
                    {this.state.summary_data[6]}
                  </span>
                </Popover>
                <span className="inline-text"> . Motivated by </span>
                <Popover
                  placement="bottom"
                  content="role you want to pursue"
                  trigger="hover"
                >
                  <span className="inline-text">
                    {this.state.summary_data[7]}
                  </span>
                </Popover>
                <span className="inline-text"> opportunities in the </span>
                <Popover
                  placement="bottom"
                  content="industries you are interested in"
                  trigger="hover"
                >
                  <span className="inline-text">
                    {this.state.summary_data[8]}
                  </span>
                </Popover>
                <span className="inline-text"> industry . </span>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
