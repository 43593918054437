import { Card } from "antd";
import AUTH_API from "api/auth";
import React from "react";
import Addlearningmodal from "./Addlearningmodal";
import Categorycomp from "./Categorycomp";
import "./Learning.css";

const CONTENT = [
  {
    head: "Networking:",
    subhead: "Professional Groups",
    sub:
      "Widely known organizations that create a community, resources and regulations around a job role or industry (example: Society for Human Resource Management)",
    label: "PG",
  },
  {
    head: "Skill Development: ",
    subhead: "Further Education",
    sub:
      "Courses, certifications, bootcamps, diplomas, graduate school, or any learning-based skill development program",
    label: "PE",
  },
  {
    head: "Learning: ",
    subhead: "Resources",
    sub:
      "Save any key links that are relevant to your target roles and industries (informative websites, articles, video channels, podcasts, blogs, etc.) ",
    label: "PP",
  },
  {
    head: "Getting Involved: ",
    subhead: "Personal Projects",
    sub:
      "Blogs, personal websites, or any project relevant to your roles or industries of interest that you can start and build on your own",
    label: "VI",
  },
  {
    head: "Getting Involved: ",
    subhead: "Paid or Pro-bono Work",
    sub:
      "Freelance, side gigs, contract/part time work, consulting, pro-bono work, job shadowing, volunteer programs, board involvement, etc.",
    label: "R",
  },
];

export default class Learningbase extends React.Component {
  state = {
    loading: true,
    visible: false,
    learning: {
      PG: [],
      PE: [],
      PP: [],
      VI: [],
      R: [],
    },
    selected_category: "def",
    selected_id: null,
    current_data: {},
  };

  getLearning = () => {
    AUTH_API.getLearning()
      .then((response) => {
        console.log("learning", response.data);
        let all_learning = response.data;
        let state_data = {
          PG: [],
          PE: [],
          PP: [],
          VI: [],
          R: [],
        };
        all_learning.forEach((item) => {
          state_data[item.learning_type].push({
            name: item.title,
            notes: item.additional_notes,
            links: item.useful_links,
            interest_level: item.interest_level,
            roles: item.roles,
            industries: item.industries,
            id: item.id,
          });
        });
        this.setState({
          learning: state_data,
          loading: false,
          current_data: {},
          selected_id: null,
        });
      })
      .catch((err) => console.log("learning err", err));
  };

  componentDidMount() {
    this.getLearning();
  }

  openModal = (category) => {
    // console.log('modal visible', category)
    this.setState({
      visible: true,
      selected_category: category,
      selected_id: null,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      current_data: {},
      selected_id: null,
    });
  };

  onFinish = (val, id) => {
    console.log("val", val, id);
    const data = {
      title: val.name,
      learning_type: val.category,
      roles: val.roles,
      industries: val.industries,
      useful_links: val.links,
      additional_notes: val.notes,
      interest_level: val.interest_level,
    };
    if (id === null) {
      AUTH_API.postLearning(data)
        .then((response) => {
          // console.log('learning post', response)
          this.getLearning();
        })
        .catch((err) => console.log("learning error", err));
    } else {
      AUTH_API.patchLearning(data, id)
        .then((res) => {
          // console.log('patch res', res)
          this.getLearning();
        })
        .catch((err) => console.log("learning error", err));
    }
  };

  openModalWithData = (category, data) => {
    //   console.log('open with data', category, data)
    this.setState({
      visible: true,
      selected_category: category,
      selected_id: data.id,
      current_data: data,
    });
  };

  handleDelete = (id) => {
    AUTH_API.deleteLearning(id)
      .then((res) => {
        // console.log('deleted!', res)
        this.getLearning();
      })
      .catch((err) => console.log("learning error", err));
  };

  render() {
    console.log("statedata", this.state.learning);
    return (
      <div
        style={this.props.fade ? {} : { opacity: "0.4", pointerEvents: "none" }}
      >
        <p className="learning-header">
          Track your options for hands-on learning opportunities.
        </p>
        <Card style={{ border: "1px solid white", margin: "-32px" }}>
          {CONTENT.map((item, index) => (
            <Categorycomp
              heading={item.head}
              subhead={item.subhead}
              subheading={item.sub}
              openModal={this.openModal}
              categorized_learning={this.state.learning[item.label]}
              category={item.label}
              openModalWithData={this.openModalWithData}
            />
          ))}
        </Card>
        {this.state.visible && (
          <Addlearningmodal
            visible={this.state.visible}
            handleCancel={this.handleCancel}
            onFinish={this.onFinish}
            category={this.state.selected_category}
            selected_id={this.state.selected_id}
            current_data={this.state.current_data}
            handleDelete={this.handleDelete}
          />
        )}
      </div>
    );
  }
}
