import { Button, Checkbox, Col, Form, Row, message } from "antd";
import React from "react";
import { connect } from "react-redux";
import { updateQuickReflection } from "slices/dashboardSlice";
import HELPERS from "utils/helper";
import AUTH_API from "./../../../api/auth";
import "./Resume.css";

// const { TextArea } = Input;

class Resume extends React.Component {
  formRef = React.createRef();

  state = {
    submitted: false,
    Api_response: [],
  };
  componentDidMount() {
    AUTH_API.getMaterials()
      .then((response) => {
        console.log("get Api data", response);
        const form_values =
          response.data.length > 0
            ? response.data[0].resume_best_practices
            : "";
        this.setState({
          Api_response: response.data.length > 0 ? response.data[0] : "",
        });
        this.formRef.current.setFieldsValue({
          ...form_values,
        });
      })
      .catch((err) => console.log("Resume get Api error!", err));
  }
  onFormChange = (changed_values, all_values) => {
    console.log("changed_values:", changed_values, "all values:", all_values);
    const data = {
      resume_best_practices: all_values,
    };
    const id = this.state.Api_response.id;
    AUTH_API.patchMaterials(data, id, "RE")
      .then((response) => {
        console.log("Resume patch Api data", response);
        message.success('Your work has been saved');
        const form_values =
          response.data.length > 0
            ? response.data[0].resume_best_practices
            : "";
        this.formRef.current.setFieldsValue({
          ...form_values,
        });
      })
      .catch((err) => console.log("Resume patch Api error!", err));
  };

  onSubmit = () => {
    this.setState({ ...this.state, submitted: true });
  };

  render() {
    console.log("resume rendered");
    return (
      <div>
        <h3 className="resume-head">
          Follow this guide while you edit your resume to apply best practices
          and strengthen your resume.
        </h3>

        <Form
          name="resume-form"
          ref={this.formRef}
          onValuesChange={HELPERS.debounceFormChanges(this.onFormChange)}
        >
          <div className="res-div">
            <h3 className="res-div-head">Header</h3>
            <p className="res-div-subhead">
              This is the section at the very top of your resume.
            </p>

            <Form.Item name="Header">
              <Checkbox.Group style={{ width: "100%" }}>
                <Row>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="H1">
                        Your name should be center alilgned and in a bigger font
                        (something around 20). I suggest including your middle
                        initial to add some uniqueness.
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="H2">
                        Under your name, put: [email address] | [phone number] |
                        City, State | LinkedIn URL | Any portfolio URL's
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="H3">
                        Keep all of your contact information in one row to save
                        space in other areas
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="H4">
                        The font for the 2nd row of information should be no
                        smaller than 8
                      </Checkbox>
                    </div>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </div>
          <div className="res-div">
            <h3 className="res-div-head">Resume Formatting</h3>
            <p className="res-div-subhead">
              Set up your formatting structure before diving into any content
            </p>

            <Form.Item name="RF">
              <Checkbox.Group style={{ width: "100%" }}>
                <Row>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="RF1">
                        Include organization name, city, state, months/year you
                        begun and ended the experience, role title and also
                        department title
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="RF2">
                        Ensure there is clean and consistent alignment on the
                        left and right side margins
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="RF3">
                        Use bold/italics to emphasize company names or role
                        titles but be consistent with which items you bold or
                        italicize across all experiences
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="RF4">
                        The cleaner the formatting, the quicker a recruiter will
                        jump to focus on the content; you want as little
                        distraction as possible
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="RF5">
                        List each experience in chronological order; ensure that
                        each experience is formatted the same way to show
                        consistency
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="RF6">
                        Take a look at the document's format by zooming out on
                        it to ensure it looks clean and neat aesthetically
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="RF7">
                        If you have progressed or got promoted within a work
                        experience, separate each role title within that
                        organization to show your growth
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="RF8">
                        Generic/professional templates work well for most roles;
                        if you are in the design world, you can spruce it up but
                        keep it simple
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="RF9">
                        Always spell out acronyms fully and then put them in
                        parenthesis after (USA) so your keywords get picked up
                        in ATS systems
                      </Checkbox>
                    </div>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </div>
          <div className="res-div">
            <h3 className="res-div-head">Professional Summary</h3>
            <p className="res-div-subhead">
              This is the objective you can put underneath your contact
              information.
            </p>

            <Form.Item name="PS">
              <Checkbox.Group style={{ width: "100%" }}>
                <Row>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="PS1">
                        The benefit to this is that you can summarize, frame,
                        and brand your background in a way that you can own
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="PS2">
                        You can help others see you, your skills, and your
                        potential more clearly when you draft your own summary
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="PS3">
                        You can also include keywords for where you want to go
                        so that the ATS systems can pick it up
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="PS4">
                        Use our professionall summary tab to draft this properly
                        (include your background, strengths, and direction)
                      </Checkbox>
                    </div>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </div>
          <div className="res-div">
            <h3 className="res-div-head">Professional Experience</h3>
            <p className="res-div-subhead">
              This is where you can list any prior work experience in reverse
              chronological order.
            </p>

            <Form.Item name="PE">
              <Checkbox.Group style={{ width: "100%" }}>
                <Row>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="PE1">
                        Start your first bullet by describing generally what you
                        were doing and where (the type of organization / what
                        they do / team size
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Example:
                        “Managed 3 interns at a boutique consulting firm to
                        advise retail clients on financial, operational and
                        marketing strategies"
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="PE2">
                        Bullets 2 and 3 should describe further what you did /
                        what you were in charge of /w hat you created / improved
                        / how you did it
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="PE3">
                        Each bullet should be just ONE idea; by keeping each
                        bullet to one line, it forces you to keep each bullet
                        focused on one task
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Ex:
                        “Analyzed the firm's current industry position and
                        future strategy by scouring 100 competitors’
                        achievements via 10K reports”
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="PE4">
                        Show the results/impacts/outcomes/metrics of what you
                        accomplished; include as many numeric/quantitative
                        metrics as you can
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="PE5">
                        Use strong verbs to start each bullet; Incorporate
                        metrics soft skills where you can; add more bullets for
                        meaningful experiences
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="PE6">
                        Include key details like: timeframes, improving any
                        processes, presenting to senior folks, if/where your
                        work was implemented
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="PE7">
                        Comprehensively represent everything you did, at a high
                        level but also with specific details to be clear about
                        what you did
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="PE8">
                        If you have other activities, you can add a new section
                        for “leadership activities,” “project experience,”
                        "volunteer experience," "technical experience,"
                        "entrepreneurial experience" or anything you want!{" "}
                      </Checkbox>
                    </div>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </div>
          <div className="res-div">
            <h3 className="res-div-head">Education Section</h3>
            <p className="res-div-subhead">
              This should go towards the bottom to list out your degrees,
              certifications, and overall educational background.
            </p>

            <Form.Item name="ES">
              <Checkbox.Group style={{ width: "100%" }}>
                <Row>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="ES1">
                        List each school name, location, and date you graduated
                        (no need to include start date){" "}
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="ES2">
                        List the exact, formal title of the degree (i.e.
                        Bachelor of Science in Human Development, Minor in
                        Global Studies){" "}
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="ES3">
                        Include GPA if you are within ~10 years from graduation
                        or remove if you are not proud of the GPA{" "}
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="ES4">
                        Include any honors earned, scholarships, study abroad,
                        or even clubs and activities as separate lines under
                        this section{" "}
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="ES5">
                        Clubs and activities can be fleshed out as experiences
                        earlier in the resume if you are more junior in your
                        career{" "}
                      </Checkbox>
                    </div>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </div>
          <div className="res-div">
            <h3 className="res-div-head">Skills Section</h3>
            <p className="res-div-subhead">
              This goes all the way at the bottom or if you are very technical,
              it can be moved to the top.
            </p>

            <Form.Item name="SS">
              <Checkbox.Group style={{ width: "100%" }}>
                <Row>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="SS1">
                        First include your "Computer" or "Technical" skills by
                        including any computer systems or languages you are
                        familiar with; be comprehensive{" "}
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="SS2">
                        This is another great place to include keywords that you
                        want to get picked up by the ATS systems{" "}
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="SS3">
                        Include Microsoft Office Suite and Google Suite as these
                        are common for most people to have basic familiarity
                        with{" "}
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="SS4">
                        You can include your level of proficiency in these
                        skills, i.e. Microsoft Excel (Advanced){" "}
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="SS5">
                        You can include certifications earned in this section or
                        in the education section{" "}
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="SS6">
                        Include any languages you speak and your level of
                        proficiency{" "}
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="SS7">
                        Include a line for "interests"; oftentimes interviewers
                        love to talk about what your hobbies are and can be a
                        major point of rapport building!{" "}
                      </Checkbox>
                    </div>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </div>
          <div className="res-div">
            <h3 className="res-div-head">
              Other Resume Reminders & Best Practices
            </h3>
            {/* <p className='res-div-subhead'>This goes all the way at the bottom or if you are very technical, it can be moved to the top.</p> */}

            <Form.Item name="Others">
              <Checkbox.Group style={{ width: "100%" }}>
                <Row>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="O1">
                        Remember to always send this as a pdf; export it as a
                        test to ensure the formatting remains and nothing gets
                        cut off
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="O2">
                        Job search engines and companies search for keywords
                        when you apply to jobs; look at target job
                        description(s), work in keywords
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="O3">
                        We believe in one strong resume versus multiple
                        versions; it will take you less time to worry about it;
                        you are one person, one story
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="O4">
                        You want to let others know that you've already done
                        what they’re going to ask you to do; use transferrable
                        terms/skills/keywords
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="O5">
                        Iterate until your resume “sings” (i.e. until you feel
                        it’s representative of your accomplishments and
                        strengths)
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="O6">
                        Take a fresh look at your documents either once a week
                        or once a month to see if you notice anything you can
                        update
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="O7">
                        Use the professional summary as a tool that will help
                        you to figure out who you are and what you want by
                        continuing to update it
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="O8">
                        This one page should scream how amazing you are; make
                        each word count; let's enable others to see what you are
                        capable of!
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="custom-cb">
                      <Checkbox value="O9">
                        Networking is your key to job search so don’t need to
                        spend too much time on this; just create a clean,
                        simple, strong resume
                      </Checkbox>
                    </div>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </div>
          <a
            href="mailto: team@iamwoken.com?subject=WOKEN Client Resume Submission"
            target="_blank"
            className="submit-coach"
            style={{ padding: "12px 0", textAlign: "center" }}
            onClick={() =>
              this.props.updateQuickReflection({
                stepId: this.props.stepId,
                show: true,
              })
            }
          >
            Submit To Coach
          </a>
        </Form>
      </div>
    );
  }
}

const mapDispatchToProps = () => ({
  updateQuickReflection,
});

export default connect(null, mapDispatchToProps())(Resume);
