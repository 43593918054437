import React from "react";

export default class PrivacyTermsContent extends React.Component {
  render() {
    return (
      <div className="pp-text">
        <p>
          <strong>WOKEN, LLC Privacy Policy</strong>
        </p>
        <p>Last Modified: December 12, 2020&nbsp;</p>
        <p>
          <strong>Introduction</strong>
        </p>
        <p>
          This Privacy Policy describes the types of information that Woken, LLC
          (&ldquo;<strong>WOKEN&rdquo;</strong>, &ldquo;<strong>we</strong>
          &rdquo; or &ldquo;<strong>us</strong>&rdquo;) may collect from you,
          the user, when you visit (i) the WOKEN website{" "}
          <a href="https://www.iamwoken.com/">https://www.iamwoken.com/</a>{" "}
          and/or (ii) the WOKEN platform that WOKEN clients utilize for career
          exploration and job search (the &ldquo;<strong>Platform</strong>
          &rdquo;; the WOKEN website and the Platform are collectively referred
          to herein as the &ldquo;<strong>Website</strong>&rdquo;). This policy
          also describes our practices for collecting, using, maintaining,
          protecting, and disclosing that information. We respect your privacy
          and are committed to protecting it through our compliance with this
          policy.
        </p>
        <p>This policy applies to information we collect:</p>
        <ul>
          <li>On this Website.</li>
          <li>
            In email, text, during career coaching meetings, and other
            electronic messages between you and this Website.
          </li>
        </ul>
        <p>It does not apply to information collected by:</p>
        <ul>
          <li>
            Us through offline means or through any other means, including on
            any other website operated by Company or any third party (including
            our affiliates and subsidiaries); or
          </li>
          <li>
            Any third party (including our affiliates and subsidiaries),
            including through any application or content (including advertising)
            that may link to or be accessible from or on the Website.&nbsp;
          </li>
        </ul>
        <p>
          Please read this policy carefully to understand our policies and
          practices regarding your information and how we will treat it. If you
          do not agree with our policies and practices, your choice is not to
          use our Website. By accessing or using this Website, you agree to this
          privacy policy. This policy may change from time to time. Your
          continued use of this Website after we make changes is deemed to be
          acceptance of those changes, so please check the policy periodically
          for updates.
        </p>
        <p>
          <strong>Children under the Age of 13</strong>
        </p>
        <p>
          Our Website is not intended for children under 13 years of age. No one
          under age 13 may provide any personal information to or on the
          Website. We do not knowingly collect personal information from
          children under 13. If you are under 13, do not use or provide any
          information on this Website, or register on the Website. If we learn
          we have collected or received personal information from a child under
          13 without verification of parental consent, we will delete that
          information. If you believe we might have any information from or
          about a child under 13, please contact us at{" "}
          <a href="mailto:team@iamwoken.com">team@iamwoken.com</a>.
        </p>
        <p>
          <strong>
            Information We Collect About You and How We Collect It
          </strong>
        </p>
        <p>
          We collect several types of information from and about users of our
          Website, including information:
        </p>
        <ul>
          <li>
            By which you may be personally identified, such as name, postal
            address, e-mail address, telephone number, age, and demographics or
            other personal information collected (&ldquo;
            <strong>personal information</strong>&rdquo;);&nbsp;
          </li>
          <li>
            About your internet connection, the equipment you use to access our
            Website, and usage details.
          </li>
        </ul>
        <p>We collect this information:</p>
        <ul>
          <li>
            Directly from you when you provide it to us. This includes
            information provided:
          </li>
          <ul>
            <li>Upon a user&rsquo;s creation of an account on the Platform;</li>
            <li>Upon interaction on the Website; and&nbsp;</li>
            <li>When you communicate with WOKEN.&nbsp;</li>
          </ul>
          <li>
            Automatically as you navigate through the site. Information
            collected automatically may include usage details and IP
            addresses.&nbsp;
          </li>
        </ul>
        <p>
          <strong>Information You Provide To Us</strong>
        </p>
        <p>
          The information we collect on or through our Website may
          include:&nbsp;
        </p>
        <ul>
          <li>
            Information that you provide by filling in forms on our Website.
            This includes:
          </li>
          <ul>
            <li>
              User Account Information, meaning information that identifies you
              to WOKEN&rsquo;s Website.&nbsp;
            </li>
            <li>
              Contact Information, meaning (i) your name, (ii) your physical
              address, (iii) your email address, and (iv) your telephone
              number.&nbsp;
            </li>
          </ul>
          <li>
            Transaction Information, meaning all information related to
            transactions that customers conduct via the Website.&nbsp;
          </li>
          <li>Financial Information, including:</li>
          <ul>
            <li>
              Your credit card number, credit card expiration date, and credit
              card verification code, all with respect to one or more credit
              card that you have the lawful right to use; and
            </li>
            <li>
              Your bank account number, bank account title, bank name, branch
              location and routing number, all with respect to one or more
              accounts that you have the lawful right to access.
            </li>
          </ul>
        </ul>
        <p>
          <strong>
            Information We Collect Through Automatic Data Collection
            Technologies
          </strong>
        </p>
        <p>
          As you navigate through and interact with our Website, we may use
          automatic data collection technologies to collect certain information
          about your equipment, browsing actions, and patterns, including:
        </p>
        <ul>
          <li>
            Details of your visits to our Website, including traffic data,
            location data, logs, and other communication data and the resources
            that you access and use on the Website.&nbsp;
          </li>
          <li>
            Information about your computer and internet connection, including
            your IP address, MAC address, operating system, browser type, device
            type, and device identifier.&nbsp;
          </li>
        </ul>
        <p>
          The information we collect automatically may include personal
          information, or we may maintain it or associate it with personal
          information we collect in other ways. It helps us to improve our
          Website and to deliver a better and more personalized service,
          including by enabling us to:
        </p>
        <ul>
          <li>Estimate our audience size and usage patterns.&nbsp;</li>
          <li>
            Store information about your preferences, allowing us to customize
            our Website according to your individual interests.&nbsp;
          </li>
          <li>Improve our services.&nbsp;</li>
          <li>Recognize you when you return to our Website.</li>
        </ul>
        <p>
          <strong>How We Use Your Information</strong>
        </p>
        <p>WOKEN may use Personal Information to:&nbsp;</p>
        <ul>
          <li>Operate and improve the Platform.&nbsp;</li>
          <li>Present our Website and its contents to you.</li>
          <li>Target services to Users.</li>
          <li>Facilitate communications with Users.</li>
          <li>Provide customer support.</li>
          <li>Communicate with Users regarding support or technical issues.</li>
          <li>Facilitate surveys.</li>
          <li>
            Carry out our obligations and enforce our rights arising from any
            contracts entered into between you and us, including for billing and
            collection, as well as to comply with the law.
          </li>
          <li>
            Notify you about changes to our Website, or any products or services
            we offer or provide though it.
          </li>
          <li>
            In any other way we may describe when you provide the
            information.&nbsp;
          </li>
          <li>For any other purpose with your consent.</li>
        </ul>
        <p>
          <strong>Disclosure of Personal Information</strong>
        </p>
        <p>
          We may disclose personal information that we collect or you provide as
          described in this privacy policy to:
        </p>
        <ul>
          <li>
            WOKEN Affiliates, which may use the personal information for the
            purposes described in the section above. &ldquo;WOKEN
            Affiliates&rdquo; means&nbsp;
          </li>
          <ul>
            <li>
              (i) any parent, subsidiary, member, officer, director, employee,
              or agent of WOKEN or any company under common control with WOKEN,
              or
            </li>
            <li>
              (ii) any third party with which WOKEN has a commercial
              relationship.
            </li>
          </ul>
          <li>
            Service providers, contractors, and other third parties which act
            for us or provide services for us, such as for marketing or for the
            processing of payments, and as to such service providers their use
            of personal information is subject to our agreements with them and
            any applicable laws.
          </li>
          <li>
            To a buyer or other successor in the event of a merger, divestiture,
            restructuring, reorganization, dissolution, or other sale or
            transfer of some or all of WOKEN's assets, whether as a going
            concern or as part of bankruptcy, liquidation, or similar
            proceeding, in which personal information held by WOKEN about our
            Website users is among the assets transferred.
          </li>
          <li>
            Providers arrange transactions with Customers via the Platform.
          </li>
          <li>
            For any other purpose disclosed by us when you provide the
            information.&nbsp;
          </li>
          <li>With your consent.</li>
        </ul>
        <p>We may also disclose your personal information:</p>
        <ul>
          <li>
            To comply with any court order, law, or legal process, including to
            respond to any government or regulatory request.&nbsp;
          </li>
          <li>To enforce or apply our Terms of Service.</li>
          <li>
            If we believe disclosure is necessary or appropriate to protect the
            rights, property, or safety of WOKEN, our customers, or others. This
            includes exchanging information with other companies and
            organizations for the purposes of fraud protection and credit risk
            reduction.
          </li>
          <li>
            WOKEN&rsquo;s employees and outside professionals (such as lawyers
            and accountants) for the purpose of auditing, compliance, and
            corporate governance.
          </li>
        </ul>
        <p>
          <strong>Emails</strong>
        </p>
        <p>
          WOKEN reserves the right to send you emails. You agree to receive such
          emails, but may opt out by emailing{" "}
          <a href="mailto:team@iamwoken.com">team@iamwoken.com</a>
        </p>
        <p>
          <strong>Payment</strong>
        </p>
        <p>
          Before you begin to use our service under your WOKEN account, you will
          be required to select a method of payment (&ldquo;
          <strong>Payment Method</strong>&rdquo;). To access the details of your
          WOKEN account, including Payment Method, visit our Site at
          www.iamwoken.com and go to your account page.&nbsp;
        </p>
        <p>
          <strong>
            <br />
          </strong>
          <strong>Termination</strong>
        </p>
        <p>
          You may cease your process but WOKEN will retain your personal
          information so as to protect the business interests. <br />
          <br />
          <strong>Changes to Our Privacy Policy</strong>
        </p>
        <p>
          WOKEN reserves the right to change this Privacy Policy. Such changes
          may be announced to you either by email or by the posting of the
          revised policy on the Website. The date the privacy policy was last
          revised is identified at the top of the page. You are responsible for
          ensuring we have an up-to-date active and deliverable email address
          for you, and for periodically visiting our Website, Platform, and this
          Privacy Policy to check for any changes.&nbsp;
        </p>
        <p>
          <strong>
            No Responsibility for the Privacy Practices of Third Parties
          </strong>
        </p>
        <p>
          This Privacy Policy does not apply to third-party websites or
          platforms that have linked or are redirected to or from WOKEN&rsquo;s
          Website regardless of whether or not such link or redirection is
          authorized by WOKEN. Third-party websites may have their own policies
          regarding privacy, or no policy at all. WOKEN is not responsible for
          these third-parties. We encourage you to read third-parties&rsquo;
          policies before using their services.&nbsp;
        </p>
        <p>
          <strong>Accessing and Correcting Personal Information</strong>
        </p>
        <p>
          You may correct or update your personal information via your &ldquo;My
          Account&rdquo; screens on the Platform.
        </p>
        <p>
          You may close an account but WOKEN will nevertheless retain your
          personal information so as to protect the business interests of WOKEN,
          WOKEN Affiliates, Customers, Providers and other Users. Those
          interests include the completion of transactions, maintaining records
          for financial reporting purposes, complying with our legal
          obligations, resolving disputes, and enforcing agreements.&nbsp;
        </p>
        <p>
          <strong>Your California Privacy Rights</strong>
        </p>
        <p>
          California's "Shine the Light" law (Civil Code Section &sect; 1798.83)
          permits users that are California residents to request certain
          information regarding our disclosure of personal information to third
          parties for their direct marketing purposes. To make such a request,
          please send an email to{" "}
          <a href="mailto:team@iamwoken.com">team@iamwoken.com</a>.&nbsp;
        </p>
        <p>
          <strong>Contact Information</strong>
        </p>
        <p>
          To ask questions or comment about this privacy policy and our privacy
          practices, contact us at{" "}
          <a href="mailto:team@iamwoken.com">team@iamwoken.com</a>.
        </p>
        <p>
          ______________________________________________________________________________
        </p>
        <p>
          <strong>WOKEN, LLC Terms of Service</strong>
        </p>
        <p>Last Modified: December 11, 2020</p>
        <p>
          <strong>
            PLEASE READ THESE TERMS CAREFULLY. YOU ARE AGREEING TO BE BOUND BY
            THESE TERMS.
          </strong>
        </p>
        <p>
          <strong>Definitions</strong>
        </p>
        <ol>
          <li>
            a) &ldquo;Contact Information&rdquo; means (i) your name, (ii) your
            physical address, (iii) your email address and (iv) your telephone
            number.
          </li>
          <li>
            b) &ldquo;Last Modified&rdquo; means the date that we have last
            edited this policy.
          </li>
          <li>
            c) &ldquo;WOKEN Affiliates&rdquo; means (i) any parent, subsidiary,
            member, officer, director, employee, or agent of WOKEN or any
            company under common control with WOKEN, or (ii) any third party
            with which WOKEN has a commercial relationship.
          </li>
          <li>
            d) &ldquo;Customer&rdquo; is a person that opens a Customer Account.
          </li>
          <li>
            e) &ldquo;Privacy Policy&rdquo; means the set of policies set forth
            in this document.
          </li>
          <li>
            f) &ldquo;Financial Account Information&rdquo; means your credit
            card number, credit card expiration date and credit card
            verification code, all with respect to one or more credit cards that
            you have the lawful right to use, and your bank account number, bank
            account title, bank name, branch location and routing number, all
            with respect to one or more accounts that you have the lawful right
            to access.
          </li>
          <li>
            g) &ldquo;Platform&rdquo; means the WOKEN platform that WOKEN
            clients utilize for career exploration and job search.
          </li>
          <li>
            h) &ldquo;Provider&rdquo; is a service Provider or other
            professional that opens a Provider Account.
          </li>
          <li>
            i) &ldquo;Transaction Information&rdquo; means all information
            related to transactions that Customers conduct via the Platform.
          </li>
          <li>
            j) &ldquo;User&rdquo; is a person 18 years and older that opens a
            Customer Account or a Provider Account.&nbsp;
          </li>
          <li>
            k) &ldquo;User Account&rdquo; means an account permitting access to
            the Platform (which may be designated by you as either a
            &ldquo;Customer Account&rdquo; or a &ldquo;Provider Account&rdquo;).
          </li>
          <li>
            l) &ldquo;User Account Information&rdquo; means information that
            identifies you to WOKEN&rsquo;s Platform, including your User name,
            phone number, internet protocol address, MAC address, device type
            and device identifier.
          </li>
          <li>
            m) &ldquo;User Data/Material&rdquo; means any data or information
            the user creates, owns, or submits on WOKEN&rsquo;s Website.&nbsp;
          </li>
          <li>
            n) &ldquo;User Terms&rdquo; means WOKEN&rsquo;s Terms of Service, as
            applicable to a particular User.
          </li>
          <li>
            o) &ldquo;Website&rdquo; shall refer to WOKEN&rsquo;s website,{" "}
            <a href="https://www.iamwoken.com/">https://www.iamwoken.com/</a>,
            and the Platform, collectively.
          </li>
        </ol>
        <p>
          <strong>Acceptance of the Terms of Service</strong>
        </p>
        <p>
          These terms of service are entered into by and between You and WOKEN
          ("<strong>Company</strong>," "<strong>we</strong>," or "
          <strong>us</strong>"). The following terms and conditions, "Terms of
          Service,&rdquo; govern your access to and use of iamwoken.com,
          including any content, functionality, and services (the &ldquo;
          <strong>Services</strong>&rdquo;) offered on or through iamwoken.com
          and the Platform (which, as indicated above, are collectively referred
          to as the &ldquo;<strong>Website</strong>&rdquo;), whether as a guest
          or a registered user.&nbsp;
        </p>
        <p>
          Please read the Terms of Service carefully before you start to use the
          Website. By using the Website or by clicking to accept or agree to the
          Terms of Service when this option is made available to you, you accept
          and agree to be bound and abide by these Terms of Service (and our
          Privacy Policy, incorporated herein by reference). If you do not want
          to agree to these Terms of Service or the Privacy Policy, you must not
          access or use the Website.
        </p>
        <p>
          Our Website is offered and available to users who are 18 years of age
          or older. By using this Website, you represent and warrant that you
          are of legal age to form a binding contract with the Company and meet
          all of the foregoing eligibility requirements. If you do not meet all
          of these requirements, you must not access or use the Website.
        </p>
        <p>
          <strong>Changes to the Terms of Service</strong>
        </p>
        <p>
          We may revise and update these Terms of Service from time to time in
          our sole discretion. All changes are effective immediately when we
          post them, and apply to all access to and use of the Website
          thereafter. However, any changes to the dispute resolution provisions
          set out in Governing Law and Jurisdiction will not apply to any
          disputes for which the parties have actual notice on or before the
          date the change is posted on the Website. Your continued use of the
          Website following the posting of revised Terms of Service means that
          you accept and agree to the changes. You are expected to check this
          page frequently so you are aware of any changes, as they are binding
          on you.
        </p>
        <p>
          <strong>Accessing the Website, and Account Security</strong>
        </p>
        <p>
          WOKEN reserves the right to withdraw or amend this Website, and any
          service or material we provide on the Website, in our sole discretion
          without notice. We will not be liable if for any reason all or any
          part of the Website is unavailable at any time or for any period. From
          time to time, we may restrict access to some parts of the Website, or
          the entire Website, to users, including registered users.
        </p>
        <p>You are responsible for both:</p>
        <ul>
          <li>
            Making all arrangements necessary for you to have access to the
            Website.
          </li>
          <li>
            Ensuring that all persons who access the Website through your
            internet connection are aware of these Terms of Service and comply
            with them.
          </li>
        </ul>
        <p>
          To access the Website, you may be asked to provide certain
          registration details or other information. To register, you will have
          to create a WOKEN account. You will be asked to register with WOKEN.
          You may not (i) select or use a name of another person with the intent
          to impersonate that person; (ii) use a name subject to any rights of a
          person other than you without appropriate authorization; or (iii) use
          a name that is otherwise offensive, vulgar or obscene. WOKEN has the
          right to disable any user name or other identifier, whether chosen by
          you or provided by us, at any time in our sole discretion for any or
          no reason, including if, in our opinion, you have violated any
          provision of these Terms of Service.
        </p>
        <p>
          It is a condition of your use of the Website that all the information
          you provide on the Website is correct, current, and complete. You
          agree that all information you provide to register with this Website,
          or otherwise, including, but not limited to, through the use of any
          interactive features on the Website, is governed by our Privacy Polic
          <strong>y</strong> and you consent to all actions we take with respect
          to your information consistent with our Privacy Policy.
        </p>
        <p>
          If you choose, or are provided with, a user name or any other piece of
          information as part of our security procedures, you must treat such
          information as confidential, and you must not disclose it to any other
          person or entity. You also acknowledge that your account is personal
          to you and agree not to provide any other person with access to this
          Website or portions of it using your user name, password, or other
          security information. You agree to notify us immediately of any
          unauthorized access to or use of your user name or password or any
          other breach of security. You also agree to ensure that you exit from
          your account at the end of each session. You should use particular
          caution when accessing your account from a public or shared computer
          so that others are not able to view or record personal information.
        </p>
        <p>
          <strong>Reliance on Information Posted</strong>
        </p>
        <p>
          The information presented on or through the Website is made available
          solely for general information purposes. We do not warrant the
          accuracy, completeness, or usefulness of this information. Any
          reliance you place on such information is strictly at your own risk.
          We disclaim all liability and responsibility arising from any reliance
          placed on such materials by you or any other visitor to the Website,
          or by anyone who may be informed of any of its contents.
        </p>
        <p>
          This Website may include content provided by third parties, including
          materials provided by other users, bloggers, and third-party
          licensors, syndicators, aggregators, and/or reporting services. All
          statements and/or opinions expressed in these materials, and all
          articles and responses to questions and other content, other than the
          content provided by the Company, are solely the opinions and the
          responsibility of the person or entity providing those materials.
          These materials do not necessarily reflect the opinion of the Company.
          We are not responsible, or liable to you or any third party, for the
          content or accuracy of any materials provided by any third parties.
        </p>
        <p>
          <strong>Intellectual Property Rights</strong>
          <strong>
            <br />
          </strong>
          <strong>
            <br />
          </strong>
          The content accessible through the Website, including without
          limitation, the text, software, scripts, graphics, photos, sounds,
          music, videos, interactive features (collectively, the &ldquo;
          <strong>WOKEN Content</strong>&rdquo;) and the trademarks, service
          marks and logos contained therein (the &ldquo;<strong>Marks</strong>
          &rdquo;), are owned by or licensed to WOKEN, and subject to copyright
          and other intellectual property rights under United States and foreign
          laws. Except as set forth in these Terms, the WOKEN Content, Marks,
          and User Data/Material are provided to you for your information and
          non-commercial, personal use only and may not be used, copied,
          reproduced, distributed, transmitted, broadcast, displayed, sold,
          licensed, or otherwise exploited for any other purposes whatsoever
          except as set forth in these Terms or without the prior written
          consent of the respective owners. You agree not to circumvent, disable
          or otherwise interfere with security related features of the Website
          or features that prevent or restrict use or copying of any WOKEN
          Content, Marks or User Data/Material.
        </p>
        <p>
          By submitting, posting, uploading, or otherwise providing User
          Data/Material to WOKEN, you hereby grant WOKEN a worldwide,
          non-exclusive, royalty-free, perpetual, irrevocable, sublicensable and
          transferable license to use, reproduce, modify, distribute, prepare
          derivative works of, display, publish, perform and transmit the User
          Data/Material in connection with the Website and WOKEN (and its
          successors) business as permitted hereunder. You also hereby grant, to
          each authorized user of the Website whom you authorize to receive your
          User Data/Material, a non-exclusive license to access your User
          Data/Material through the Website, and to use, reproduce, distribute,
          prepare derivative works of, display and perform such User
          Data/Material as permitted through the functionality of the Website
          and under these Terms.
        </p>
        <p>
          <strong>Prohibited Uses and User Conduct</strong>
        </p>
        <p>
          As a condition of use, you agree not to use the Website for any
          purpose that is unlawful. You agree to abide by all applicable local,
          state, national, and international laws and regulations, including,
          without limitation, all intellectual property laws. Any unauthorized
          use of the Website is expressly prohibited.
        </p>
        <p>Expressly, you agree not to use the website to:</p>
        <p>
          &nbsp;(a) take any action or upload, download, post, submit or
          otherwise distribute or facilitate distribution of any User
          Data/Material using any communications service or other service
          available on or through the Website, that:&nbsp;
        </p>
        <ol>
          <li>
            Infringes any patent, trademark, trade secret, copyright, or other
            intellectual property or other rights of any other person.
          </li>
          <li>
            Contains any material that is defamatory, obscene, indecent,
            abusive, offensive, harassing, violent, hateful, inflammatory, or
            otherwise objectionable.
          </li>
          <li>
            Promotes sexually explicit or pornographic material, violence, or
            discrimination based on race, sex, religion, nationality,
            disability, sexual orientation, or age.
          </li>
          <li>
            Misrepresents the source or identity of any content or is likely to
            deceive any person.
          </li>
          <li>
            Constitutes unauthorized or unsolicited advertising, junk or bulk
            e-mail (&ldquo;spamming&rdquo;) or a chain letter, a pyramid scheme
            or any other similar solicitation.&nbsp;
          </li>
          <li>
            Contains software viruses or any other computer codes, files, or
            programs that are designed or intended to disrupt, damage, limit or
            interfere with the proper function of any software, hardware, or
            telecommunications equipment or to damage or obtain unauthorized
            access to any system, data or other information of WOKEN or any
            third party.&nbsp;
          </li>
          <li>
            Impersonates, or falsely indicates an affiliation with, any person
            or entity, including, without limitation, any employee or
            representative of WOKEN.&nbsp;
          </li>
          <li>
            Constitutes an offer, for sale or otherwise, of firearms,
            explosives, weapons, tobacco products, controlled substances,
            pharmaceutical products, counterfeit or stolen articles, registered
            or unregistered securities, or any items that would cause WOKEN to
            violate any applicable law or regulation.&nbsp;
          </li>
          <li>
            Interferes with the ability of any other user to access and make use
            of the Website.&nbsp;
          </li>
        </ol>
        <p>
          WOKEN may, but has no obligation to, monitor your use of the Website
          and terminate your account for violation of the above.
        </p>
        <p>Additionally, you agree that you will not:&nbsp;</p>
        <ol>
          <li>
            Use the Website in any manner that could disable, overburden,
            damage, or impair the site or interfere with any other party's use
            of the Website, including their ability to engage in real time
            activities through the Website.&nbsp;
          </li>
          <li>
            Bypass any measures WOKEN may use to prevent or restrict access to
            the Website, other accounts, or computer systems or networks
            connected to the Website.
          </li>
          <li>
            Interfere with any other user&rsquo;s enjoyment of the Website,
            including, without limitation, accessing a WOKEN account of a WOKEN
            user that is not yours.
          </li>
          <li>
            Use any robot, spider, or other automatic device, process, or means
            to access the Website for any purpose, including monitoring or
            copying any of the material on the Website.&nbsp;
          </li>
          <li>
            Harvest or collect information from the Website, including, without
            limitation, information about other users of the Website.&nbsp;
          </li>
          <li>
            Use any manual process to monitor or copy any of the material on the
            Website, or for any other purpose not expressly authorized in these
            Terms of Service, without our prior written consent.&nbsp;
          </li>
          <li>
            Use any device, software, or routine that interferes with the proper
            working of the Website.
          </li>
          <li>
            Directly or indirectly, modify, translate, decompile, disassemble,
            or reverse engineer any part of the Website or any content available
            through it (except to the limited extent applicable laws
            specifically prohibit such restriction), copy, rent, lease,
            distribute, or otherwise transfer or sublicense any or the rights
            that you receive hereunder, or remove any proprietary notices or
            labels.
          </li>
          <li>
            Introduce any viruses, Trojan horses, worms, logic bombs, or other
            material that is malicious or technologically harmful.&nbsp;
          </li>
          <li>
            Attempt to gain unauthorized access to, interfere with, damage, or
            disrupt any parts of the Website, the server on which the Website is
            stored, or any server, computer, or database connected to the
            Website.&nbsp;
          </li>
          <li>
            Attack the Website via a denial-of-service attack or a distributed
            denial-of-service attack.&nbsp;
          </li>
          <li>
            Otherwise attempt to interfere with the proper working of the
            Website.
          </li>
        </ol>
        <p>
          In addition, the use of any information learned through the Website is
          limited to the express purposes set forth in these Terms; all other
          uses are strictly prohibited.
        </p>
        <p>
          Notwithstanding the foregoing, we grant the operators of search
          engines permission to use robots to copy materials from the site for
          the sole purpose of creating publicly-available searchable indexes of
          the materials, but not caches or archives of the materials; provided,
          that we reserve the right to revoke these exceptions either generally
          or in specific cases.&nbsp;
        </p>
        <p>
          <strong>Termination</strong>
        </p>
        <p>
          WOKEN may terminate your access to all or any part of the Service at
          any time, with or without cause, effective immediately. You may
          terminate your use of the Service at any time, provided that all
          provisions of these Terms, which by their nature should survive
          termination, shall survive termination, including, without limitation,
          ownership provisions, warranty disclaimers, indemnity and limitation
          of liability. If you terminate your account, you may permanently lose
          access to all User Data/Materials you submitted to or through the
          Service.
        </p>
        <p>
          <strong>Links from the Website</strong>
        </p>
        <p>
          If the Website contains links to other sites and resources provided by
          third parties, these links are provided for your convenience only.
          This includes links contained in advertisements, including banner
          advertisements and sponsored links. We have no control over the
          contents of those sites or resources, and accept no responsibility for
          them or for any loss or damage that may arise from your use of them.
          If you decide to access any of the third-party websites linked to this
          Website, you do so entirely at your own risk and subject to the terms
          and conditions of use for such websites.
        </p>
        <p>
          <strong>Disclaimer of Warranties</strong>
        </p>
        <p>
          You understand that we cannot and do not guarantee or warrant that
          files available for downloading from the internet or the Website will
          be free of viruses or other destructive code. You are responsible for
          implementing sufficient procedures and checkpoints to satisfy your
          particular requirements for anti-virus protection and accuracy of data
          input and output, and for maintaining a means external to our site for
          any reconstruction of any lost data. TO THE FULLEST EXTENT PROVIDED BY
          LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A
          DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER
          TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER
          EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE
          TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH
          THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON
          ANY WEBSITE LINKED TO IT.
        </p>
        <p>
          YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS
          OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS
          CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE
          PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY
          WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY
          NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR
          REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
          RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE WEBSITE.
          WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE
          ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE,
          ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE
          WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS
          WILL BE CORRECTED, THAT OUR WEBSITE, PLATFORM, OR THE SERVER THAT
          MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR
          THAT THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE
          WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
        </p>
        <p>
          TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS
          ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR
          OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
          MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
        </p>
        <p>
          THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED
          OR LIMITED UNDER APPLICABLE LAW.
        </p>
        <p>
          <strong>Limitation of Liability</strong>
        </p>
        <p>
          TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY,
          ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES,
          AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND,
          UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE,
          OR INABILITY TO USE, THE WEBSITE, THE PLATFORM ANY WEBSITES LINKED TO
          IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY
          DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE
          DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND
          SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS
          OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL,
          LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE),
          BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.
        </p>
        <p>
          <strong>Indemnification</strong>
        </p>
        <p>
          You agree to defend, indemnify and hold harmless WOKEN and its owners,
          managers, affiliates, subsidiaries and distribution partners and their
          respective owners, officers, managers, directors, employees and/or
          agents (collectively, &ldquo;WOKEN Indemnitees&rdquo;) from and
          against any and all claims, damages, obligations, losses, liabilities,
          costs or debt, and expenses (including but not limited to
          attorney&rsquo;s fees) arising from:&nbsp;
        </p>
        <ol>
          <li>Your use of and access to the Website;</li>
          <li>Your violation of any term of these Terms;&nbsp;</li>
          <li>
            Your violation, alleged or actual, of any third party right,
            including without limitation any copyright, property, or privacy
            right;
          </li>
          <li>
            Any claim that any of User Data/Material you upload and publish on
            the Website caused damage to a third party; or&nbsp;
          </li>
          <li>
            Your gross negligence or willful malfeasance (collectively,
            &ldquo;Claims&rdquo;).&nbsp;
          </li>
        </ol>
        <p>
          You are solely responsible for defending any such Claims, and for
          payment of losses, costs, damages or expenses resulting from the
          foregoing to both a third party and to the WOKEN Indemnitees. WOKEN
          shall have the right, in its sole discretion, to select its own legal
          counsel to defend the WOKEN Indemnitees from any Claims (but by doing
          so shall not waive your indemnity obligations), and you shall be
          solely responsible for the payment of all reasonable attorney&rsquo;s
          fees incurred by the WOKEN Indemnitees in connection therewith. You
          shall not, without the prior express written approval of WOKEN,
          settle, dispose or enter into any proposed settlement or resolution of
          any Claim (whether having been finally adjudicated or otherwise)
          brought against you, if such settlement or resolution results in any
          obligation or liability for any WOKEN Indemnitee. This defense and
          indemnification obligation will survive the termination or expiration
          of these Terms and your use of the Website. Although WOKEN will not be
          liable for your losses caused by any unauthorized use of your WOKEN
          account, you may be liable for the losses of WOKEN or others due to
          such unauthorized use.
        </p>
        <p>
          <strong>
            <br />
          </strong>
          <strong>Governing Law and Jurisdiction</strong>
        </p>
        <p>
          These Terms shall be governed by and construed in accordance with the
          laws of the State of New York without regard to or application of
          choice of law rules or principles. You agree that the Website shall be
          deemed to be solely based in the State of New York, and that the Site
          shall be deemed a passive website that does not give rise to personal
          jurisdiction over WOKEN, either specific or general, in jurisdictions
          other than the State of New York.
        </p>
      </div>
    );
  }
}
