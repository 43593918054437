import PrivacyTermsContent from "Components/Staticcontent/PrivacyPolicyContent";
import React from "react";
import "./Onboardingbase.css";

export default class PrivacyTerms extends React.Component {
  state = {
    show_more: false,
  };

  render() {
    return (
      <div style={{ margin: "0 0 0 -35%", width: "165%" }}>
        <div
          style={{
            margin: "40% 0 30px",
            display: this.state.show_more ? "none" : "block",
          }}
        >
          <span className="privacy-head">
          By creating a Woken account, you are agreeing to:
            <div>&nbsp;</div>
            <ul>
              <li>
                Accept the{" "}
                <strong
                  className="read-all"
                  onClick={() => this.setState({ show_more: true })}
                >
                  Woken Terms of Service
                </strong>{" "}
                and{" "}
                <strong
                  className="read-all"
                  onClick={() => this.setState({ show_more: true })}
                >
                  Privacy Policy
                </strong>.
              </li>
              <li>
                Receive one-time passwords sent via text (SMS/WhatsApp) whenever you login to the platform.
              </li>
              <li>
                Receive reminders from your career coach via text (SMS/WhatsApp). You can opt out any time by replying STOP to the message.
              </li>
            </ul>
          </span>
          <span
            className="read-all"
            style={{ float: "right" }}
            onClick={() => this.setState({ show_more: true })}
          >
            Read Policy
          </span>
        </div>
        <div
          style={{
            marginBottom: "30px",
            display: this.state.show_more ? "block" : "none",
          }}
        >
          <span className="privacy-head">
          By creating a Woken account, you are agreeing to:
            <div>&nbsp;</div>            
            <ul>
              <li>
                Accept the{" "}
                <strong
                  className="read-all"
                  onClick={() => this.setState({ show_more: true })}
                >
                  Woken Terms of Service
                </strong>{" "}
                and{" "}
                <strong
                  className="read-all"
                  onClick={() => this.setState({ show_more: true })}
                >
                  Privacy Policy
                </strong>.
              </li>
              <li>
                Receive one-time passwords sent via text (SMS/WhatsApp) whenever you login to the platform.
              </li>
            </ul>
          </span>
          <div className="pp-scrollable-div">
            <PrivacyTermsContent />
          </div>
          <p
            className="read-all"
            style={{ float: "right" }}
            onClick={() => this.setState({ show_more: false })}
          >
            Show less
          </p>
        </div>
      </div>
    );
  }
}
