import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, Select } from "antd";
import moment from "moment";
import "./Dashboard.css";
import "./Timeline.css";
import {
  selectProfile,
  updateHoursPerWeekCommitmentAsync,
} from "slices/dashboardSlice";
import HELPERS from "utils/helper";
import {
  TIMELINE_AREAS,
  getWidth,
  monthsBeforeToday,
  getMonths,
} from "utils/timeline";

const { Option } = Select;

const Timeline = () => {
  const profile = useSelector(selectProfile);
  const dispatch = useDispatch();
  const today = moment();
  const sectionRef = React.createRef();
  const [marginLeft, setMarginLeft] = useState("0");
  const hoursPerWeekCommitment = profile
    ? profile.hours_per_week_commitment
    : 0;
  const joinedOn = profile ? moment(new Date(profile.date_joined)) : today;

  HELPERS.log("Updated Timeline", profile);

  useEffect(() => {
    HELPERS.log(
      "Updated Timeline",
      sectionRef.current ? sectionRef.current.offsetWidth : null,
      marginLeft,
      joinedOn.date(),
      joinedOn.daysInMonth()
    );
    if (sectionRef.current) {
      let margin = `${
        (joinedOn.date() * sectionRef.current.offsetWidth) /
        joinedOn.daysInMonth()
      }px`;
      if (margin !== marginLeft) {
        setMarginLeft(margin);
      }
    }
  }, []);

  const updateHoursPerWeekCommitment = (value) => {
    dispatch(updateHoursPerWeekCommitmentAsync(value));
  };

  return (
    <div>
      <div style={{ margin: "20px" }}>
        <Card
          bordered={false}
          style={{
            boxShadow: "0px 3px 6px #00000029",
            borderRadius: "13px",
            textAlign: "left",
          }}
        >
          <div className="h" style={{ fontWeight: "bold", textAlign: "left" }}>
            My Planned Timeline
          </div>

          <div className="woken-progress">
            <div className="section">
              <div
                className="today"
                style={{
                  marginLeft: `${
                    monthsBeforeToday(today.month(), joinedOn) * 11.11111
                  }%`,
                }}
              >
                <span
                  className="title"
                  style={{
                    paddingLeft: `${
                      (today.date() * 100) / today.daysInMonth()
                    }%`,
                  }}
                >
                  Today
                </span>
                <span
                  className="marker"
                  style={{
                    left: `${(today.date() * 100) / today.daysInMonth()}%`,
                  }}
                ></span>
              </div>
            </div>
            <div className="timeline title section">
              <span
                ref={sectionRef}
                className="space"
                style={{
                  marginLeft,
                }}
              ></span>
              <div
                className="portion"
                style={{
                  width: `${getWidth(
                    TIMELINE_AREAS.EXPLORATION,
                    hoursPerWeekCommitment
                  )}%`,
                }}
                title="Career Exploration"
              >
                <span>Career Exploration</span>
              </div>
              <div
                className="portion"
                style={{
                  width: `${getWidth(
                    TIMELINE_AREAS.JOB_SEARCH,
                    hoursPerWeekCommitment
                  )}%`,
                }}
                title="Job Search"
              >
                <span>Job Search</span>
              </div>
            </div>
            <div className="timeline section">
              <span
                className="space"
                style={{
                  marginLeft,
                }}
              ></span>
              <span
                className="exploration portion"
                style={{
                  width: `${getWidth(
                    TIMELINE_AREAS.EXPLORATION,
                    hoursPerWeekCommitment
                  )}%`,
                }}
                title="Career Exploration"
              >
                &nbsp;
              </span>
              <span
                className="job-search portion"
                style={{
                  width: `${getWidth(
                    TIMELINE_AREAS.JOB_SEARCH,
                    hoursPerWeekCommitment
                  )}%`,
                }}
                title="Job Search"
              >
                &nbsp;
              </span>
            </div>
            <div className="months section">
              {getMonths(joinedOn).map((month) => (
                <span className="month" key={month}>
                  {month}
                </span>
              ))}
            </div>
          </div>
          <br />

          <div>
            I commit
            <Select
              defaultValue="1"
              bordered={false}
              style={{ color: "#32AAB0" }}
              listHeight={120}
              value={profile ? profile.hours_per_week_commitment : null}
              onChange={updateHoursPerWeekCommitment}
            >
              {Array.apply(null, { length: 30 }).map((item, index) => (
                <Option key={`option-item-${index}`} value={index + 1}>
                  {index + 1}
                </Option>
              ))}
            </Select>
            hours per week to my process.
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Timeline;
