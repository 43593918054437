import React from "react";
import { Select, Form } from "antd";
import "./Selectinterest.css";
const { Option } = Select;

function handleChange(value) {
  // console.log(`selected ${value}`);
}
const label = {
  10: "Extremely interested",
  8: "Very interested",
  5: "Moderately interested",
  3: "Slightly interested",
  1: "Not at all interested",
};

export default class Selectbutton extends React.Component {
  state = {
    color: "#ffffff",
    bg: "#ACE3E6",
    selectedValue: null,
  };

  options = [
    "1 Not at all interested",
    "2",
    "3 Slightly interested",
    "4",
    "5 Moderately interested",
    "6",
    "7",
    "8 Very interested",
    "9",
    "10 Extremely interested",
  ];

  // componentDidUpdate() {
  //   let newState = this.Option_style(
  //     this.state.selectedValue !== null
  //       ? this.state.selectedValue
  //       : this.props.selectedValue
  //   );
  //   if (newState !== null && newState.bg !== this.state.bg) {
  //     this.setState(newState);
  //   }
  // }

  componentDidMount() {
    console.log("did m", this.props.selectedValue);
    this.Option_style(
      this.props.selectedValue
        ? this.props.selectedValue
        : this.props.initialValue
    );
  }

  Option_style = (value) => {
    let resp = null;
    // console.log({value});
    if (value < 4) {
      resp = {
        color: "#ffffff",
        bg: "#ACE3E6",
        selectedValue: value,
      };
    } else if (value < 7) {
      resp = {
        color: "#ffffff",
        bg: "#32AAB0",
        selectedValue: value,
      };
    } else if (value <= 10) {
      resp = {
        color: "#ffffff",
        bg: "#296B70",
        selectedValue: value,
      };
    }

    this.setState({ ...resp });
  };

  onChange = (value) => {
    // console.log({value});
    this.Option_style(value);
  };

  render() {
    console.log("props in selectinterest", this.props.selectedValue);
    return (
      <Form.Item
        noStyle
        name={this.props.name}
        initialValue={
          this.props.selectedValue
            ? this.props.selectedValue
            : this.props.initialValue
        }
        // initialValue={this.state.selectedValue}
      >
        <Select
          // getPopupContainer={triggerNode => triggerNode.parentElement}
          style={{
            width: "100%",
            color: this.state.color,
            backgroundColor: this.state.bg,
            fontSize: "13px",
            borderRadius: "10px",
            padding: "8px 10px",
          }}
          defaultValue={
            this.props.selectedValue
              ? this.props.selectedValue
              : this.props.initialValue
          }
          listItemHeight={10}
          listHeight={550}
          onChange={this.onChange}
          bordered={false}
          dropdownClassName="dropdown-width"
          optionLabelProp="title"
          key={`select-${this.props.type}-${this.props.id}`}
        >
          {Array.apply(null, { length: 10 }).map((item, index) => (
            <Option
              key={`option-item-${index}`}
              value={index + 1}
              title={(index + 1).toString()}
            >
              {(index + 1).toString() +
                " " +
                (label[index + 1] ? label[index + 1] : "")}
            </Option>
          ))}
        </Select>
      </Form.Item>
    );
  }
}
