import HELPERS from "utils/helper";

const DASHBOARD_API = {
  getTasks: () => {
    return HELPERS.secureRequest({
      url: `tasks/`,
      method: "GET",
    });
  },
  addTask: (task) => {
    return HELPERS.secureRequest({
      url: `tasks/`,
      method: "POST",
      data: task,
    });
  },
  updateTask: (taskId, data) => {
    return HELPERS.secureRequest({
      url: `tasks/${taskId}/`,
      method: "PATCH",
      data: data,
    });
  },
  deleteTask: (taskId) => {
    return HELPERS.secureRequest({
      url: `tasks/${taskId}/`,
      method: "DELETE",
    });
  },
  get: () => {
    return HELPERS.secureRequest({
      url: `users/dashboard/`,
      method: "GET",
    });
  },
  fetchTasksAsync: () => {
    return HELPERS.secureRequest({
      url: `tasks/`,
      method: "GET",
    });
  },
  setGoal: (stepId, complete_by, task_title) => {
    return HELPERS.secureRequest({
      url: `stepreflection/${stepId}/setgoal/`,
      method: "POST",
      data: HELPERS.converToFormData({ complete_by, task_title }),
    });
  },
  setStepNotified: (stepId) => {
    return HELPERS.secureRequest({
      url: `stepreflection/${stepId}/isnotified/`,
      method: "POST",
    });
  },
  updateStepReflection: (stepId, data) => {
    return HELPERS.secureRequest({
      url: `stepreflection/${stepId}/`,
      method: "PATCH",
      data: data,
    });
  },
  updateProfile: (data, Stepname) => {
    return HELPERS.secureRequest({
      url: `users/profile/`,
      method: "PATCH",
      data: data,
      Stepname,
    });
  },
};
export default DASHBOARD_API;
