import { LeftCircleFilled, RightCircleFilled } from "@ant-design/icons";
import { Card, Col, Row, Steps } from "antd";
import React from "react";
import Stepinfo from "../Modals/Stepinfo";
import Scheduler from "./Scheduler";
// const { Step } = Steps;

export class CalendarBase extends React.Component {
  state = {
    collapsed: false, //menu collapse
    showInfo: null, //show/hide infobox
  };

  //close button handling
  onCloseClick = () => {
    this.setState({
      setTarget: false,
    });
  };

  onCollapse = () => {
    this.setState({ collapsed: !this.state.collapsed });
    this.props.toggle();
  };

  changeRoute = () => {
    this.props.history.push("/dashboard");
  };

  showInfo = () => this.state.showInfo === true;

  render() {
    // console.log('state', this.state)
    // {console.log('time', this.state.curr_comp_target_date[this.state.current])}

    return (
      <div style={{ padding: "4% 0" }}>
        <div className="collapse-btn" onClick={this.onCollapse}>
          {this.state.collapsed ? (
            <RightCircleFilled className="make-white" />
          ) : (
            <LeftCircleFilled className="make-white" />
          )}
        </div>

        <Card
          bordered={false}
          style={{
            margin: "20px !important",
            boxShadow: "0px 3px 6px #00000029",
            borderRadius: "13px",
            textAlign: "left",
            marginRight: "6%",
            marginLeft: "20px",
            padding: "0 2%",
          }}
        >
          <div>
            <Row style={{ padding: "4% 0% 3% 0%" }}>
              <Col
                span={18}
                style={{
                  font: "normal normal 300 30px/12px Montserrat",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Calendar
              </Col>
              <Col
                span={6}
                style={{
                  textAlign: "right",
                  fontSize: "26px",
                  marginTop: "-20px",
                }}
              >
                <a href="mailto: rachelserwetz@gmail.com" target="_blank">
                  <img
                    style={{ padding: "0 10px" }}
                    src="https://django-woken-static.s3.amazonaws.com/static/media/mail.5c666ac1.svg"
                  />
                </a>
                {/* <span
                  onClick={() =>
                    this.setState({
                      showInfo: true,
                    })
                  }
                >
                  <img
                    style={{ cursor: "pointer" }}
                    src="https://django-woken-static.s3.amazonaws.com/static/media/question.74f30bbb.svg"
                  />
                </span> */}
              </Col>
            </Row>
            <p
              style={{
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                border: "1px solid #F0F0F0",
              }}
            ></p>
          </div>

          {this.showInfo() ? (
            <Stepinfo
              stepname="Assessment"
              closeInfo={() =>
                this.setState({
                  showInfo: false,
                })
              }
            />
          ) : (
            ""
          )}
          <Scheduler />
        </Card>
      </div>
    );
  }
}

// const mapStateToProps = (state) => ({
//   stepInfo: selectMaterialsStepInfo(state),
// });

// const mapDispatchToProps = () => ({
//   setStepNotifiedAsync,
// });

// export default connect(mapStateToProps, mapDispatchToProps())(CalendarBase);
