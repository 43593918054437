import { createSlice } from "@reduxjs/toolkit";
import AUTH_API from "api/auth";
import HELPERS from "utils/helper";
import industries from "utils/industries";
import {message} from "antd";

export const targetIndustriesSlice = createSlice({
  name: "targetIndustries",
  initialState: {
    industries: [],
  },
  reducers: {
    updateIndustries: (state, action) => {
      state.industries = action.payload;
    },
    addIndustry: (state, action) => {
      HELPERS.log("Received action addIndustry", action);
      let industries = [...state.industries];
      industries.push(action.payload);
      state.industries = industries;
    },
    updateIndustry: (state, action) => {
      HELPERS.log("Received action updateIndustry", action);
      let industries = [...state.industries];
      for (let index = 0; index < industries.length; ++index) {
        if (industries[index].id === action.payload.id) {
          HELPERS.log("Found the industry to update!");
          industries[index] = action.payload;
          break;
        }
      }
      state.industries = industries;
    },
  },
});

export const {
  updateIndustries,
  addIndustry,
  updateIndustry,
} = targetIndustriesSlice.actions;

export const fetchIndustriesAsync = () => (dispatch) => {
  AUTH_API.getAllTargetindustries().then((resp) => {
    HELPERS.log("Received response for fetchIndustriesAsync", resp.data);
    dispatch(updateIndustries(resp.data));
  });
};

export const addIndustryAsync = (data) => (dispatch) => {
  AUTH_API.setTargetindustry(data)
    .then((response) => {
      HELPERS.log("Received response for addIndustryAsync", response.data);
       message.success('Your work has been saved')
      dispatch(addIndustry(response.data));
    })
    .catch((err) => console.log("error", err));
};

export const deleteIndustryAsync = (key) => (dispatch) => {
  AUTH_API.targetIndustriesPatch({ learn_more: false }, key)
    .then((response) => {
      HELPERS.log("Received response for deleteIndustryAsync", response.data);
       message.success('Your work has been saved')
      dispatch(updateIndustry(response.data));
    })
    .catch((err) => console.log("error", err));
};

export const updateIndustryAsync = (key, data, Stepname) => (dispatch) => {
  AUTH_API.targetIndustriesPatch(data, key, Stepname)
    .then((response) => {
      HELPERS.log("Received response for updateIndustryAsync", response);
      // message.success('Your work has been saved');
      dispatch(updateIndustry(response.data));
    })
    .catch((err) => console.log("error", err));
};

export const selectTargetIndustries = (state) =>
  state.targetIndustries.industries;
export const selectTargetIndustriesToLearnMore = (state) =>
  state.targetIndustries.industries.filter(
    (industry) => industry.learn_more === true
  );

export const selectAllIndustries = (state) => {
  const allIndustries = [];
  const addedIndustries = new Set();

  for (var industry of state.targetIndustries.industries) {
    if (!addedIndustries.has(industry.title)) {
      addedIndustries.add(industry.title);
      allIndustries.push({
        id: industry.id,
        value: industry.title,
        selected_for: industry.selected_for,
      });
    }
  }

  for (var industry of industries) {
    if (!addedIndustries.has(industry.value)) {
      addedIndustries.add(industry.value);
      allIndustries.push({
        id: 0,
        value: industry.value,
        selected_for: [],
      });
    }
  }

  return allIndustries.sort((a, b) => {
    var res = 0;
    if (a.value < b.value) res = -1;
    else if (a.value > b.value) res = 1;
    return res;
  });
};

export const selectTargetIndustriesCount = (state) =>
  state.targetIndustries.industries.filter(
    (industry) => industry.learn_more === true
  ).length;

export default targetIndustriesSlice.reducer;
