import React from "react";
// import {Card, Row, Col, Steps} from 'antd';
import { connect } from "react-redux";
import Addcompany from "Components/JobSearch/Tracker/Addcompany";
import Tracker from "./Tracker";
import {
  selectConfigurations,
  updateProfileAsync,
} from "slices/dashboardSlice";

class Trackerbase extends React.Component {
  state = {
    current_step: 0,
  };

  configurations = () => JSON.parse(JSON.stringify(this.props.configurations));

  componentDidMount() {
    const configurations = this.configurations();
    console.log("has config at first", configurations);
    if (configurations.application_tracker) {
      this.setState({ current_step: 1 });
    }
  }

  next = () => {
    this.setState({
      current_step: 1,
    });

    let configurations = this.props.configurations;
    if (Array.isArray(configurations)) {
      console.log("is array");
      configurations = {};
    }
    configurations.application_tracker = {};
    configurations.application_tracker[1] = "1";
    console.log("next!!", configurations);
    if (configurations === {}) {
      this.props.updateProfileAsync({ configurations }, "AT");
    }
  };

  render() {
    return (
      <>
        {this.state.current_step ? (
          <Tracker />
        ) : (
          <Addcompany next={this.next} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  configurations: selectConfigurations(state),
});

const mapDispatchToProps = () => ({
  updateProfileAsync,
});

export default connect(mapStateToProps, mapDispatchToProps())(Trackerbase);
