import { Button, Col, Form, Input, Modal, Row, Slider } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTasksAsync,
  selectProfile,
  updateStepReflectionKPIAsync,
} from "slices/dashboardSlice";
import HELPERS from "utils/helper";

const { TextArea } = Input;

export default function Success(props) {
  const profile = useSelector(selectProfile);
  const formRef = React.createRef();
  const dispatch = useDispatch();

  const handleSubmit = (data) => {
    HELPERS.log("Success", props.stepId, data);
    data.completed = true;
    dispatch(updateStepReflectionKPIAsync(props.stepId, data)).then((resp) => {
      HELPERS.log("Success", resp);
      dispatch(fetchTasksAsync());
    });
  };
  console.log("props!!", props, props.path.charAt(1));
  return (
    <Modal
      title=""
      visible={props.visible}
      onOk={props.handleOk}
      onCancel={props.handleCancel}
      footer={null}
      width="75%"
      style={{ textAlign: "center", transformOrigin: "top ", top: 20 }}
      closable={false}
      maskClosable={false}
      destroyOnClose={true}
    >
      <div style={{}}>
        <div
          style={{
            background: "#49D0BE 0% 0% no-repeat padding-box",
            textAlign: "center",
            margin: "-24px",
          }}
        >
          <img
            style={{ padding: "50px 0 0 0" }}
            alt=""
            src="https://django-woken-static.s3.amazonaws.com/static/media/tick.b9936225.svg"
          ></img>
          <p
            style={{
              font: "normal normal bold 24px/12px Montserrat",
              color: "white",
              padding: "30px 0",
            }}
          >
            Awesome job, {profile !== null ? profile.first_name : "User"} !
          </p>
          <p
            style={{
              font: "normal normal bold 16px/22px Montserrat",
              color: "white",
              padding: "10px 5px 50px ",
            }}
          >
            Great job submitting your step! Let’s do a quick reflection on how
            you’re feeling right now.
          </p>
        </div>
        <Form
          ref={formRef}
          name="quick-reflection"
          initialValues={{
            stress_level: 3,
            clarity_level: 3,
            confidence_level: 3,
            additional_reflections: "",
          }}
          onFinish={handleSubmit}
        >
          <Row>
            <Col span={6}></Col>
            <Col span={12}>
              <p className="slider-question" style={{ paddingTop: 40 }}>
                How stressed are you about figuring out your next{" "}
                {props.path.charAt(1) === "j" ? "job" : "career step"}?
              </p>
              <Form.Item noStyle name="stress_level">
                <Slider
                  max={10}
                  tooltipVisible
                  style={{ paddingTop: "15px" }}
                />
              </Form.Item>
              <Row justify="space-between">
                <Col span={8}>
                  <p
                    style={{
                      textAlign: "left",
                      color: "#898E8E",
                      font: "normal normal bold 12px Montserrat",
                    }}
                  >
                    I’m very relaxed
                  </p>
                </Col>
                <Col span={8}>
                  <p
                    style={{
                      textAlign: "left",
                      color: "#898E8E",
                      font: "normal normal bold 12px Montserrat",
                    }}
                  >
                    I’m moderately stressed
                  </p>
                </Col>
                <Col span={8}>
                  <p
                    style={{
                      textAlign: "right",
                      color: "#898E8E",
                      font: "normal normal bold 12px Montserrat",
                    }}
                  >
                    I’m feeling extremely stressed
                  </p>
                </Col>
              </Row>

              <p className="slider-question">
                What is your level of clarity on your ideal{" "}
                {props.path.charAt(1) === "j" ? "job" : "career path"}?
              </p>
              <Form.Item noStyle name="clarity_level">
                <Slider
                  max={10}
                  tooltipVisible
                  style={{ paddingTop: "15px" }}
                />
              </Form.Item>
              <Row justify="space-between">
                <Col span={12}>
                  <p
                    style={{
                      textAlign: "left",
                      color: "#898E8E",
                      font: "normal normal bold 12px Montserrat",
                    }}
                  >
                    I have no idea what I want
                  </p>
                </Col>
                <Col span={12}>
                  <p
                    style={{
                      textAlign: "right",
                      color: "#898E8E",
                      font: "normal normal bold 12px Montserrat",
                    }}
                  >
                    I know exactly what I want
                  </p>
                </Col>
              </Row>

              <p className="slider-question">
                How confident are you in how to approach the{" "}
                {props.path.charAt(1) === "j"
                  ? "job search"
                  : "career exploration"}{" "}
                process?
              </p>
              <Form.Item noStyle name="confidence_level">
                <Slider
                  max={10}
                  tooltipVisible
                  style={{ paddingTop: "15px" }}
                />
              </Form.Item>
              <Row justify="space-between">
                <Col span={12}>
                  <p
                    style={{
                      textAlign: "left",
                      color: "#898E8E",
                      font: "normal normal bold 12px Montserrat",
                    }}
                  >
                    I have no clue what to do
                  </p>
                </Col>
                <Col span={12}>
                  <p
                    style={{
                      textAlign: "right",
                      color: "#898E8E",
                      font: "normal normal bold 12px Montserrat",
                    }}
                  >
                    I know exactly what to do
                  </p>
                </Col>
              </Row>

              <p className="slider-question" style={{ paddingTop: "30px" }}>
                Any additional reflections?
              </p>
              <Form.Item noStyle name="additional_reflections">
                <TextArea rows={4} autoSize />
              </Form.Item>

              <Button
                type="primary"
                style={{
                  margin: "50px 0 60px 0",
                  width: "200px",
                  textAlign: "center",
                }}
                size="large"
                htmlType="submit"
              >
                Submit
              </Button>
            </Col>
            <Col span={6}></Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
}
