const card = {
  "Target Roles": {
    card1Content: [
      {
        text:
          "Now, you will translate your interests into real world opportunies (specifically, job roles). This ensures that your target roles relate back to your reflections about what you enjoy.",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/rachel.88094393.png",
        name: "Rachel Serwetz",
      },
      {
        text: "I’m getting insight into roles I’ve never even  thought about.",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/testimonial.bcea4f8d.svg",
        name: "Christina",
      },
    ],
    card2Content: [
      {
        mainPoint:
          "Create a comprehensive list of roles based on your interests.",
      },
      {
        mainPoint:
          "In order to create that list, use your knowledge of the world and roles with which you are already familiar. Pick roles based on your current understanding.",
      },
      {
        mainPoint:
          "There is no limit. Pick however many roles seem relevant to each one of your interests.",
      },
      {
        mainPoint:
          "After you have a complete list, you'll narrow it down into a shortlist of options you want to learn more about moving forward.",
      },
      { mainPoint: "Finally, prioritize your shortlist of roles." },
      {
        mainPoint:
          "Try not to think ahead. Focus on the task at hand. We're not making any hard decisions just yet. This process may very well lead you to new outcomes and insights that aren't on your oiginal list. Your list is just a starting point in a process that will become more fluid as you learn more. Trust the process and see where it takes you!",
      },
    ],
    card3Content: [
      {
        text: "Book Chapters 8-9: 'What Do I Want to Do?'",
        link:
          "https://drive.google.com/file/d/11huY322rJiMr0wEPPfLzCTncPoIiNcsc/view?usp=sharing",
      },
      { text: "Video: What is a job?", link: "https://youtu.be/kZnX45UzDoo" },
      {
        text: "Video: Translate your interests into options",
        link: "https://youtu.be/rXLBqjmxhco",
      },
      {
        text: "Podcast: 'Wake Up to Your Amazing Career Possibilities '",
        link:
          "https://blog.accepted.com/wake-up-to-your-amazing-career-possibilities-episode-354/",
      },
      {
        text: "Blog: 'Tell me what you want, what you really really want..'",
        link:
          "https://medium.com/@getwoken/tell-me-what-you-want-what-you-really-really-want-426ef5ecfa03",
      },
    ],
  },
  Assessment: {
    card1Content: [
      {
        text:
          "Open-ended reflection is a critical first step to explore your interests, experiences, and priorities. We'll uncover what you love doing, what content you love, and what environment you thrive in.",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/rachel.88094393.png",
        name: "Rachel Serwetz",
      },
      {
        text:
          "The assessment surveys have been really helpful to get me to think through my skills, interests, and experiences. The questionnaire is really helpful to get me started thinking about what kind of interests I have and where my skills are.",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/testimonial.bcea4f8d.svg",
        name: "Holly",
      },
      {
        text:
          "I enjoyed the explorative nature of the self-assessment and how I was encouraged to dream big.",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/testimonial.bcea4f8d.svg",
        name: "Daniel",
      },
      {
        text: "It was hard but really made me think!!!!",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/testimonial.bcea4f8d.svg",
        name: "Anna",
      },
    ],
    card2Content: [
      {
        mainPoint: "The assessment has three parts:",
        subPoint: [
          "Function: What do you love doing?",
          "Content: What content do you love?",
          "Environment: What environment do you thrive in?",
        ],
      },
      { mainPoint: "Be truthful!" },
      { mainPoint: "Don't think too hard, go with your gut!" },
      {
        mainPoint:
          "The process is fluid, so while this assessment will help you start the journey, your options and clarity will naturally evolve throughout the process. It's only a starting point so don't overthink it. Give it your best shot!",
      },
      {
        mainPoint:
          "Try not to think ahead to the next steps in the process, just focus on one step at a time.",
      },
    ],
    card3Content: [
      { text: "Video: Assessment", link: "https://youtu.be/Oco1E15R2fM" },
      {
        text: "Video: Why our assessment is different",
        link: "https://www.youtube.com/watch?v=2FDczYJyj14&feature=youtu.be",
      },
      {
        text: "Book Chapter 1-7: 'Who am I?'",
        link:
          "https://drive.google.com/file/d/1s_yoA5U5nL4EiOK_VxoQk7BQSCxq6SAt/view?usp=sharing",
      },
      {
        text:
          "Event Recording: ' A conversation on finding and leveraging your career purpose.'",
        link:
          "http://business360.fortefoundation.org/a-conversation-on-finding-and-leveraging-your-career-purpose/?utm_source=forte&utm_medium=email&utm_campaign=webinars",
      },
      {
        text:
          "Blog: 'The Top Patterns I’ve Seen in People Who Pursue their Dream Career Path'",
        link:
          " https://medium.com/@getwoken/the-top-five-patterns-ive-seen-in-people-who-pursue-their-dream-career-path-f210ecc299dd",
      },
    ],
  },
  "Target Industries": {
    card1Content: [
      {
        text:
          "Now, you will translate your interests into real world opportunies (specifically, industries). This ensures that your target industries relate back to your reflections about what you content areas you are interested in.",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/rachel.88094393.png",
        name: "Rachel Serwetz",
      },
      {
        text:
          "One thing I learned through this process was: if you want a certain industry, why remain complacent? Go for it now.",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/testimonial.bcea4f8d.svg",
        name: "Elizabeth",
      },
    ],
    card2Content: [
      {
        mainPoint:
          "Create a comprehensive list of indutries based on your interests.",
      },
      {
        mainPoint:
          "In order to create that list, use your knowledge of the world and industries with which you are already familiar. Pick industries based on your current understanding. ",
      },
      {
        mainPoint:
          "There is no limit. Pick however many industries seem relevant to each one of your interests.",
      },
      {
        mainPoint:
          "After you have a complete list, you'll narrow it down into a shortlist of options you want to learn more about moving forward.",
      },
      { mainPoint: "Finally, prioritize your shortlist of industries." },
      {
        mainPoint:
          "Try not to think ahead. Focus on the task at hand. We're not making any hard decisions just yet. This process may very well lead you to new outcomes and insights that aren't on your oiginal list. Your list is just a starting point in a process that will become more fluid as you learn more. Trust the process and see where it takes you!",
      },
    ],
    card3Content: [
      { text: "Video: What is a job? ", link: "https://youtu.be/kZnX45UzDoo" },
      {
        text: "Book: Chapters 8-9 : 'What Do I Want to Do?'",
        link:
          "https://drive.google.com/file/d/11huY322rJiMr0wEPPfLzCTncPoIiNcsc/view?usp=sharing",
      },
      {
        text: "Video: Translate your interests into options",
        link: "https://youtu.be/rXLBqjmxhco",
      },
      {
        text: "Event Recording : 'Virtual Q&A with WOKEN Founder'",
        link: "https://www.youtube.com/watch?v=D0QgUTgBRCY&feature=youtu.be",
      },
      {
        text: "Blog: 'Which cog in the wheel are you?'",
        link:
          "https://medium.com/@getwoken/which-cog-in-the-wheel-are-you-c161d3f90c34",
      },
    ],
  },
  "Research Roles": {
    card1Content: [
      {
        text:
          "Your role will comprise the functions and tasks of what you do each day, so its critical to understand each of your options in depth in order to compare them. By starting with online research, you'll create a snapshot of each role and come up with key questions to further guide your learning.",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/rachel.88094393.png",
        name: "Rachel Serwetz",
      },
      {
        text:
          "Actively structuring my thoughts and doing research helps a lot.",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/testimonial.bcea4f8d.svg",
        name: "Eric",
      },
    ],
    card2Content: [
      {
        mainPoint:
          "Use prompts to guide what questions you should answer for each role. ",
      },
      {
        mainPoint:
          "Utilize Google to kickoff your research. If you find yourself going down a research rabbit role, see where it leads you.",
      },
      {
        mainPoint:
          "Save any helpful resources and links that you encounter (favorite helpful websites, videos, and blog posts). Subscribe to email lists so you can stay informed and gain further resources moving forward.",
      },
      {
        mainPoint:
          "Find the organization that is the professional 'authority' for the role. The organization will be one that creates standards, certifications, training, or regulations about that role.",
      },
      {
        mainPoint:
          "Job postings can be a helpful resource in learning about various roles.",
      },
      {
        mainPoint:
          "You will likely uncover new roles as you do your research. Create new tabs for any new roles that you are considering pursuing. ",
      },
      {
        mainPoint:
          "Spend 30 minutes to 1 hour researching each role to start. Remember, this is about gaining a basic understanding, not a perfect understanding, of your selected job roles. You will learn a lot more as you move forward with your research. ",
      },
      {
        mainPoint:
          "As you research, jot down the questions that come to your mind about each role.",
      },
      {
        mainPoint:
          "Update these tabs as you move into other stages of career exploration so that you can keep an accurate snapshot of your options at any given time. ",
      },
      {
        mainPoint:
          "Wrap up your research by doing some quick reflections at the bottom of the page. Notice how you feel during the research. Which roles do you want to spend more time learning about? Which do you want to spend less time learning about?",
      },
    ],
    card3Content: [
      { text: "Video: Research", link: "https://youtu.be/EuddYg3OQ7k" },
      {
        text: "Video: It's not hard you just have to know what do ",
        link: "https://youtu.be/VWrZkUHTl14",
      },
      {
        text: "Book: Chapter 11: Your Turn: Go Learn.",
        link:
          "https://drive.google.com/file/d/16r0p4Qo43hAwF2L075gudTPyQqnYcq70/view?usp=sharing",
      },
      {
        text: "Blog: 'Looking for a Job? Consider These Factors'",
        link:
          "https://medium.com/@getwoken/looking-for-a-job-consider-these-factors-e46bafeac7ed",
      },
      {
        text: "Podcast: Finding a Job you will Love",
        link:
          "https://anchor.fm/mattbaxtershow/episodes/Finding-a-Job-you-will-Love-with-Rachel-Serwetz-eetg8m",
      },
    ],
  },
  "Research Industries": {
    card1Content: [
      {
        text:
          "Industries relate to the ultimate mission and purpose of your job, so its critical to understand each of your options in depth in order to compare them. By starting with online research, you'll gain an overview of each industry and generate key questions to further guide your learning.",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/rachel.88094393.png",
        name: "Rachel Serwetz",
      },
      {
        text:
          "It was helpful to research industries and figure out how to transition to different jobs and verify what I actually want to do",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/testimonial.bcea4f8d.svg",
        name: "Holly",
      },
    ],
    card2Content: [
      {
        mainPoint:
          "Use prompts to guide what questions you should answer for each industry.",
      },
      {
        mainPoint:
          "Utilize Google to kickoff your research. If you find yourself going down a research rabbit role, see where it leads you.",
      },
      {
        mainPoint:
          "Save any helpful resources and links that you encounter (favorite helpful websites, videos, and blog posts). Subscribe to email lists so you can stay informed and gain further resources moving forward.",
      },
      {
        mainPoint:
          "Find the organization that is the professional 'authority' for the industry. The organization will be one that creates standards, certifications, training, or regulations about that industry.",
      },
      {
        mainPoint:
          "Company profiles can be a helpful resource in learning about specific players, products and services within each industry.",
      },
      {
        mainPoint:
          "You will likely uncover new industries as you do your research. Create new tabs for any new industries that you are considering pursuing.",
      },
      {
        mainPoint:
          "Spend 30 minutes to 1 hour researching each industry to start. Remember, this is about gaining a basic understanding, not a perfect understanding, of your selected industries. You will learn a lot more as you move forward with your research.",
      },
      {
        mainPoint:
          "As you research, jot down the questions that come to your mind about each industry.",
      },
      {
        mainPoint:
          "Update these tabs as you move into other stages of career exploration so that you can keep an accurate snapshot of your options at any given time.",
      },
      {
        mainPoint:
          "Wrap up your research by doing some quick reflections at the bottom of the page. Notice how you feel during the research. Which industries do you want to spend more time learning about? Which do you want to spend less time learning about?",
      },
    ],
    card3Content: [
      { text: "Video: Research ", link: "https://youtu.be/EuddYg3OQ7k" },
      {
        text: "Video: It's not hard, you just have to know what do",
        link: "https://youtu.be/VWrZkUHTl14",
      },
      {
        text: "Book: Chapter 10 : Figuring Out What You Actually Want to Do ",
        link:
          "https://drive.google.com/file/d/16r0p4Qo43hAwF2L075gudTPyQqnYcq70/view?usp=sharing",
      },
      {
        text: "Blog: 'Start Anywhere'",
        link: " https://medium.com/@getwoken/start-anywhere-e25b99e3f7cf",
      },
    ],
  },
  Contacts: {
    card1Content: [
      {
        text:
          "Before you can gain introductions to people in your target roles and industries, we need to review the different categories of contacts that will be relevant and helpful to the networking process. We'll start by brainstorming who you know (personally and professionally) so that we can later see who they know.",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/rachel.88094393.png",
        name: "Rachel Serwetz",
      },
      {
        text:
          "Thank you for making me take a step back and trust the process!!",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/testimonial.bcea4f8d.svg",
        name: "Davida",
      },
    ],
    card2Content: [
      {
        mainPoint:
          "This is your visual web that will represent your holistic network, both personally and professionally.",
      },
      {
        mainPoint:
          "Each mini web is based on a category or type of connection.",
      },
      {
        mainPoint:
          "Review the 'i' button in the center of each web to learn more about each type of connection.",
      },
      {
        mainPoint:
          "Add a few names for each category so that we can be broad and holistic in building our network.",
      },
    ],
    card3Content: [
      {
        text: "Video: Networking made easy",
        link: " https://youtu.be/fU6zyJ2qptE",
      },
      {
        text: "Book: Chapter 10 : Figuring Out What You Actually Want to Do ",
        link:
          "https://drive.google.com/file/d/16r0p4Qo43hAwF2L075gudTPyQqnYcq70/view?usp=sharing",
      },
      // {text:"Video: Networking made easy",
      // link:"https://youtu.be/fU6zyJ2qptE"},
      {
        text: "Document: Effective Networking",
        link:
          "https://drive.google.com/file/d/1QL17wNXT9ENSQXx-kPSCqRgFA_EWJjLB/view?usp=sharing",
      },
    ],
  },
  "Email Drafts": {
    card1Content: [
      {
        text:
          "To network effectively, we have to know how to ask others for help in a way that is both clear and concise. Drafting a sample email will help you be more succcessful in gaining introductions and informational interviews.",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/rachel.88094393.png",
        name: "Rachel Serwetz",
      },
      {
        text:
          "I am doing contract work in the food & beverage industry (which is a new field for me). I actually didn’t even realize I was interested in the industry until I went through the Woken platform.",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/testimonial.bcea4f8d.svg",
        name: "Nicole",
      },
    ],
    card2Content: [
      {
        mainPoint:
          "This tab will give you prompts for various networking scenarios. Follow each prompt and fill in a draft of what you would say in your email.",
      },
      {
        mainPoint:
          "There are two main ways of reaching out to gain an informational interview -- either asking someone you know for an introduction or reaching out directly to a target contact.",
      },
      {
        mainPoint:
          "Focus on telling the person that you are looking to learn more about your options as it relates to roles and industries (rather than mentioning 'job search'). Tell them which roles and industries you're looking to learn more about.",
      },
      {
        mainPoint:
          "The more specific you can be in explaining what you want to learn and what questions you have, the more that person will want to help you.",
      },
      {
        mainPoint:
          "Focus on asking for phone calls, rather than asking questions via email.",
      },
      { mainPoint: "Keep your emails brief and concise always." },
    ],
    card3Content: [
      {
        text: "Video: Networking Part 2",
        link: "https://www.youtube.com/watch?v=awjKwju0lyc",
      },
      {
        text: "Video: Networking made easy",
        link: "https://youtu.be/fU6zyJ2qptE",
      },
      {
        text: "Book: Chapter 10 : Figuring Out What You Actually Want to Do",
        link:
          "https://drive.google.com/file/d/16r0p4Qo43hAwF2L075gudTPyQqnYcq70/view?usp=sharing",
      },
      {
        text: "Document: Effective Networking",
        link:
          "https://drive.google.com/file/d/1QL17wNXT9ENSQXx-kPSCqRgFA_EWJjLB/view?usp=sharing",
      },
    ],
  },
  Tracker: {
    card1Content: [
      {
        text:
          "Your job is a lens with which you see the world. We'll now pursue a process of networking as a way to learn more about your options. You will gain introductions and host informational calls in order to discover nuances, find patterns, learn, compare, contraast, prioritize, reflect, and narrow in deeper on which path is best for you.",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/rachel.88094393.png",
        name: "Rachel Serwetz",
      },
      {
        text:
          "I keep being so hard on myself to know what I want to do right now but I like that this platform encourages me to take my time and doesn’t push for me to have a definitive answer right away.",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/testimonial.bcea4f8d.svg",
        name: "Rachel H",
      },
    ],
    card2Content: [
      {
        mainPoint:
          "Each column on this tracker is one step in the networking process. Use the columns to guide you through the process.",
      },
      {
        mainPoint:
          "If you refer back to your contacts tab, you can review the categories and types of connections you can leverage to help you.",
      },
      {
        mainPoint:
          "Connect with people you know directly who are in your target roles and industries.",
      },
      {
        mainPoint:
          "Reach out to your personal and professional network to ask for introductions. Be broad in who you ask - you never know who they know.",
      },
      {
        mainPoint:
          "Utilize LinkedIn as a research tool. It will help you uncover alumni in your target roles or industries.",
      },
      {
        mainPoint:
          "LinkedIn can also help you discover mutual connections who can make introductions.",
      },
      {
        mainPoint:
          "Networking takes time, effort, organization, and persistence. Follow up with individuals if they don't answer right away. After 3 follow ups, forget them for now, and try again down the road.",
      },
      {
        mainPoint:
          "Start by setting a goal of holding 5 informational calls. After that, you can continue to set additional goals to land 5 more calls.",
      },
      {
        mainPoint:
          "Volume is key. The more you reach out, the more responses you'll get, and the more calls you'll have. The more calls you have, the more you will learn. ",
      },
      {
        mainPoint:
          "The faster you network, the faster you learn! Your pace is up to you, but try to set a goal to do a few reach outs every day or every week. Working this into your routine will help a lot. Leverage the calendar feature to keep you on track.",
      },
      {
        mainPoint:
          "Be very honest on your calls and aim to be as comprehensive as possible. Ask everything you are unsure about to get the answers you need.",
      },
      {
        mainPoint:
          "The call prep and call notes tabs will help you effectively prepare and run these networking calls.",
      },
      {
        mainPoint:
          "After each call, reflect on how it went and write down those reflections. Assess how to improve your next steps based on what you've learned so far.",
      },
      {
        mainPoint:
          "Through an ongoing process of networking, learning, and reflecting, you will be able to confidently narrow in on which path is right for you.",
      },
    ],
    card3Content: [
      {
        text: "Video: Networking Part 1",
        link: "https://youtu.be/ZV13UHYW49I",
      },
      {
        text: "Video: Networking Part 2",
        link: "https://www.youtube.com/watch?v=awjKwju0lyc",
      },
      {
        text: "Video: Networking Part 3",
        link: "https://www.youtube.com/watch?v=8SBf-Rz4pbQ",
      },
      {
        text: "Video: Networking made easy",
        link: "https://youtu.be/fU6zyJ2qptE",
      },
      {
        text: "Chapter 11: Your Turn: Go Learn. ",
        link:
          "https://drive.google.com/file/d/1MzsazjW200l34yuIbRpJFsLiPFYgByaU/view?usp=sharing",
      },
      {
        text: "Document: Effective Networking",
        link:
          "https://drive.google.com/file/d/1QL17wNXT9ENSQXx-kPSCqRgFA_EWJjLB/view?usp=sharing",
      },
    ],
  },
  "Call Prep": {
    card1Content: [
      {
        text:
          "Thorough call preparation will help you come across as a thoughtful professional. It will help you get the answers you need to fill in the gaps in your knowledge. What can you ask to gain a deeper understanding of the role or industry? These calls are the most. meanginful part of the exploration process to help you compare, contrast, prioritize, and reflect on what is the best path for you.",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/rachel.88094393.png",
        name: "Rachel Serwetz",
      },
      {
        text:
          "The platform helped me organize my pursuit of my career goals, reflect on my hopes for my future and how to systematically approach achieving them.",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/testimonial.bcea4f8d.svg",
        name: "Robert",
      },
    ],
    card2Content: [
      {
        mainPoint:
          "Follow each prompt to guide what you should focus on as you research and prepare for your networking calls.",
      },
      {
        mainPoint: "Take notes as you research the contact and their company.",
      },
      {
        mainPoint:
          "Reflect on what you are still not sure about as it relates to your target roles and industries. List questions you can ask to fill in your gaps in knowledge.",
      },
      {
        mainPoint:
          "For each call, spend ~30 minutes preparing. You'll present yourself as prepared and ready to use your contact's time wisely.",
      },
      {
        mainPoint:
          "Remember, the people you speak onto these calls will turn into meaningful networking connections that you can refer back to later on in your career journey.",
      },
    ],
    card3Content: [
      {
        text: "Video: Networking Call Prep",
        link: "https://www.youtube.com/watch?v=m37TzcdqFcE",
      },
      {
        text: "Book: Section III, Part 11: Your Turn: Go Learn",
        link:
          "https://drive.google.com/file/d/1QL17wNXT9ENSQXx-kPSCqRgFA_EWJjLB/view?usp=sharing",
      },
      {
        text: "Document: Effective Networking",
        link:
          "https://drive.google.com/file/d/1QL17wNXT9ENSQXx-kPSCqRgFA_EWJjLB/view?usp=sharing",
      },
    ],
  },
  "Call Notes": {
    card1Content: [
      {
        text:
          "During networking calls, you want to have your questions ready, and you want to take brief notes that you can refer back to later. Write down your reflections after each call to determine your level of interest in the role and industry. These notes will provide you with updated information you can utilize as you reflect and decide on your next steps.",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/rachel.88094393.png",
        name: "Rachel Serwetz",
      },
      {
        text:
          "Write everything down, take notes on your daily life - how certain conversations make you feel, how things at work make you feel, any thoughts you might have - WRITE IT DOWN",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/testimonial.bcea4f8d.svg",
        name: "Alanna",
      },
    ],
    card2Content: [
      {
        mainPoint:
          "Take brief notes during your calls so you can refer back to them later when you reconnect with that person.",
      },
      {
        mainPoint:
          "Use your notes to update your research tabs. How has your undestanding of your target roles and industries changed? How have your questions and level of interest changed?",
      },
      {
        mainPoint:
          "Reflect on each call to see how it impacts your level of interest in that role and industry and how it influences your next steps.",
      },
      {
        mainPoint:
          "It's important to connect with multiple individuals so that you don't rely on just one source of information. These calls will help you see patterns and gain a broad-yet-accurate understanding of each role and industry.",
      },
    ],
    card3Content: [
      {
        text: "Video: Networking Call Prep",
        link: "https://www.youtube.com/watch?v=m37TzcdqFcE",
      },
      {
        text: "Book: Chapter 11: Your Turn: Go Learn.",
        link:
          "https://drive.google.com/file/d/1MzsazjW200l34yuIbRpJFsLiPFYgByaU/view?usp=sharing",
      },
      {
        text: "Blog: Talking Fast And Loud",
        link: "https://medium.com/@getwoken/talking-fast-and-loud-7a4cf44be537",
      },
      {
        text: "Document: Effective Networking",
        link:
          "https://drive.google.com/file/d/1QL17wNXT9ENSQXx-kPSCqRgFA_EWJjLB/view?usp=sharing",
      },
    ],
  },
  "Weekly Reflections": {
    card1Content: [
      {
        text:
          "Synthesize your thoughts and feelings about what you're learning on a weekly basis. Your reflections will inform your next steps so you know what you want to learn more or less about. By staying fluid, you will soon reach total clarity and certainty about your ideal career path.",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/rachel.88094393.png",
        name: "Rachel Serwetz",
      },
      {
        text:
          "I like the reflection on calls. A lot people just work on something but never reflect. It helps me to actively write down what to say, what you didn’t like about the call. It is actually pretty powerful.",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/testimonial.bcea4f8d.svg",
        name: "Elon",
      },
    ],
    card2Content: [
      {
        mainPoint:
          "Your reflections help your coach make sure you're on the right track and provide you with feedback.",
      },
      { mainPoint: "We'll do two types of reflections every other week:" },
      {
        mainPoint:
          "We'll check in on your overall progress to see how things are going. We'll focus on areas where you were successful and areas where you can improve.",
      },
      {
        mainPoint:
          "We'll also do a pulse check on your current thoughts about each role and industry. This will help you assess your level of interest in each option and which ones you need to learn more (or less) about.",
      },
      {
        mainPoint:
          "Checking in on how things are going will help you identify next steps to push you closer towards acheiving total clarity about your career path.",
      },
      {
        mainPoint:
          "Our goal is to make sure you're staying accountable, productive, and effective as you discover your ideal career path, one that is authentic and exciting.",
      },
    ],
    card3Content: [
      {
        text: "Video: 'Reflect throughout the Process'",
        link: "https://youtu.be/yOtwtSEa5dY",
      },
      {
        text: "Book: Chapter 11: Your Turn: Go Learn.",
        link:
          "https://drive.google.com/file/d/1MzsazjW200l34yuIbRpJFsLiPFYgByaU/view?usp=sharing",
      },
      {
        text: "Blog: 'The Power of Slowing Down'",
        link:
          "https://medium.com/@getwoken/the-power-of-slowing-down-faf1d2d9c7c8",
      },
      {
        text: "Blog: 'Understanding your Higher Purpose'",
        link:
          "https://medium.com/@getwoken/understanding-your-higher-purpose-729bab86f302",
      },
      {
        text: "Blog: 'The Most Uncomfortable Key to Your Success with Woken'",
        link:
          "https://entrepreneur.nyu.edu/blog/2020/02/11/sotw-uncomfortable-key-success-woken",
      },
      {
        text: "Blog: 'Live to Work or Work to Live?'",
        link: "https://42hire.com/live-to-work-or-work-to-live-db958517380d",
      },
      {
        text: "Document: Effective Networking",
        link:
          "https://drive.google.com/file/d/1QL17wNXT9ENSQXx-kPSCqRgFA_EWJjLB/view?usp=sharing",
      },
    ],
  },
  "Learning Opportunities": {
    card1Content: [
      {
        text:
          "Experiential learning will give you direct exposure to your potential career path so that you can test out your interest and fit before you fully dive into the recruiting process",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/rachel.88094393.png",
        name: "Rachel Serwetz",
      },
      {
        text:
          "For me, the most valuable part was connecting my hobbies, interests and passions to my potential career search. It helped me target the type of industries I'd be interested in.",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/testimonial.bcea4f8d.svg",
        name: "Jenna",
      },
    ],
    card2Content: [
      {
        mainPoint:
          "Document opportunities you learn about where you can gain skills and exposure to your target roles and industries (we refer to these opportunities as experiential learning)",
      },
      {
        mainPoint:
          "Once you discover your ideal path, you can revisit these experiences to see which would be most valuable in pushing you closer to your ideal path.",
      },
    ],
    card3Content: [
      {
        text: "Book: Chapter 11: Your Turn: Go Learn. ",
        link:
          "https://drive.google.com/file/d/1MzsazjW200l34yuIbRpJFsLiPFYgByaU/view?usp=sharing",
      },
      {
        text: "Video: Experential Learning ",
        link: "https://youtu.be/scEFrF-RyhA",
      },
      {
        text: "Podcast: Wake Up to Your Amazing Career Possibilities",
        link:
          "https://blog.accepted.com/wake-up-to-your-amazing-career-possibilities-episode-354/",
      },
      {
        text:
          "Article: Considering Graduate School? First, Figure Out Your Ideal Career Path.",
        link:
          "https://blog.accepted.com/considering-graduate-school-first-figure-out-your-ideal-career-path/",
      },
      {
        text: "Blog: How I Turned a Hobby into a Career",
        link:
          "https://medium.com/@getwoken/how-i-turned-a-hobby-into-a-career-6fd35790ac7b",
      },
      {
        text: "Blog: Start Anywhere",
        link: "https://medium.com/@getwoken/start-anywhere-e25b99e3f7cf",
      },
      {
        text: "Blog: You’re not too small to swim in the big pond",
        link:
          "https://medium.com/@getwoken/you-re-not-too-small-to-swim-in-the-big-pond-703a1e8448b2",
      },
      {
        text: "Blog: To MBA or not to MBA?",
        link: "https://medium.com/@getwoken/to-mba-or-not-to-mba-2b7e1d1b1da6",
      },
    ],
  },
  "Professional Summary": {
    card1Content: [
      {
        text:
          "Your professional summary (i.e. elevator pitch or mission statement) is a useful tool for your professinoal materials as well as during networking and interviewing. It's a powerful way of concisely describing your background and strengths while also explaining how you want to leverage your skills in the future.",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/rachel.88094393.png",
        name: "Rachel Serwetz",
      },
      {
        text:
          "I found the mission statement writing is super helpful. It helped me to find the industry I want to work for and find what jobs specifically I want versus the broad picture.",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/testimonial.bcea4f8d.svg",
        name: "Karen",
      },
    ],
    card2Content: [
      {
        mainPoint:
          "Use the prompts to help you fill out your professional summary.",
      },
      {
        mainPoint:
          "Once the summary is complete, read through it and edit it before your coach reviews it.",
      },
      {
        mainPoint:
          "As you continue to clarify your direction (and for years to come), you can continuously come back to this summary and update and strengthen it.",
      },
      {
        mainPoint:
          "Your pitch is a brief summary of your background, your strengths, and your career direction. It takessome upfront preparation to create a strong pitch, but once you have a strong pitch drafted, it can beused in a multitude of beneficial ways.",
      },
      {
        mainPoint:
          "What are the benefits of creating and using a pitch?A pitch allows you to clearly outline your story and makes it much clearer to someone else as they get toknow you. It allows you to quickly and clearly get across who you are, your strengths, and your direction.",
      },
      {
        mainPoint: "When and where can my pitch be utilized?",
        subPoint: [
          "As an objective on your resume",
          "For the “about” section on your LinkedIn",
          "To introduce yourself in networking or informational interviews",
          "To introduce yourself in formal interviews",
        ],
      },
      {
        mainPoint: "When and where can my pitch be utilized?",
        subPoint: [
          "As an objective on your resume",
          "For the “about” section on your LinkedIn",
          "To introduce yourself in networking or informational interviews",
          "To introduce yourself in formal interviews",
        ],
      },
      {
        mainPoint:
          "How can I practice delivering a strong pitch?Similar to interviewing, practice is the key here. Once you draft a written version of your pitch, practiceit out loud. You don’t want to sound too rehearsed, as if you memorized the pitch verbatim. Rather,remember the goals you want to get across (your background, your strengths, and your direction), andthen in your delivery, you will be able to adjust the pitch slightly every time you deliver it. Practice with acoach, a friend, or even by videoing yourself.",
      },
      {
        mainPoint:
          "How does the pitch differ from “walk me through your resume”?If someone asks you to walk them through your resume, they want a bit longer of a response wherebyyou walk them through your chronological work experience from oldest to the most current. What’smore, they want to know why you moved from workplace A to workplace B to understand yourdirection, your logic, and your interests.",
      },
    ],
    card3Content: [
      {
        text: "Book: Chapter 13-18 : Now, How Do I Get It?",
        link:
          "https://drive.google.com/file/d/1BzoeC7iSVo1iwiiZPql-R8HUdcWisOnb/view?usp=sharing",
      },
    ],
  },
  Resume: {
    card1Content: [
      {
        text:
          "Resumes are the most standard professional tool to represent your background, your achievements, and your strengths. It's important to ensure this document is clean, comprehensive, and includes strong, specific content. (Hint: Most people think you should spend way more time on this document than you actually should.)",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/rachel.88094393.png",
        name: "Rachel Serwetz",
      },
      {
        text: "I am loving this new resume format!!!",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/testimonial.bcea4f8d.svg",
        name: "Jacob",
      },
    ],
    card2Content: [
      {
        mainPoint: "0. Review the “Top Tips for Personal Branding” Blog, linked in the “More resources” section"
      },
      {
        mainPoint: "1. Decide which format/template you want to use. Pick either template or you can use your own if you prefer.",
        subPoint: [
          "See more resources where you can find two templates. Pick either template or you can use your own if you prefer.",
          "The Canva template is a bit more design-oriented and innovative . This more design-oriented resume template is better for someone who is pivoting and needs to share more of who they are & transferable skill sets, OR someone in a more creative field.",
          "The Google Docs version is a more traditional resume format, which is better for professionals who are largely pursuing a similar track/path to prior experiences, to keep it simple & straightforward. If you're using this one, make a duplicate/copy of the google doc for yourself and retitle it with your name.",
          "Pick either template (we can help you decide) or you can use your own (though that means more formatting editing for us).",
          "How should I format my resume? There are several templates you can find online but the goal here is to keep a clean, well-aligned, and consistent resume. Too many varying fonts and font sizes will be distracting. Ensure that your places of work, school names, and role titles are clearly and quickly visible. The simpler you can keep your resume format, the more that your content will shine. If you’re in a design or arts-oriented profession, it’s okay to be creative with formatting. Ensure no font is smaller than size 9."  
        ]
      },
      {
        mainPoint: "2. Review both templates (even if you're not using them) to read the guidance and best practices within both documents and apply the guidance to your resume.",
      },
      {
        mainPoint: "3. Review the platform's checklist of best practices for writing a strong resume, make the adjustments on your resume, and check off each item as you go."
      },
      {
        mainPoint: "4. Then, send your resume to team@iamwoken.com in an editable format (Word or Pages or Google Docs or Canva)"
      },
      {
        mainPoint: "5. We will reply back with comments/suggestions and then we'll go back and forth until it's perfect!"
      },
      {
        mainPoint: "FAQs",
        subPoint: [
          "Should I have more than one resume version? We believe that since you are an individual with one unique story that creating one version of your resume is preferable to creating different versions. Creating one version of your resume can serve as a tool to push your clarity on how your various skills and interests combine into a seamless picture. Focus on figuring out your story so that it becomes easier to share with others.",
          "How can I ensure the content on my resume is strong? Start out each bullet with a strong verb and try not to repeat these verbs too often. Glancing at the left side of the document should give us a good sense of your key strengths. Ensure that each bullet highlights one major project, task, or accomplishment. Include as many metrics as you can, even if they are estimates. Be comprehensive to explain what you did, how you did it, and what the outcomes or impacts were of your work. Balance specificity with your ability to summarize your responsibilities concisely. Focus on clearly outlining what you did specifically, even if it was part of a team.",
          "Do I need a professional summary/objective on my resume? The summary clearly tells your story, who you are, your strengths, and your direction so that someone else can understand quickly what roles you fit into. Be careful not to use too many generic words in the summary - challenge yourself to be authentic when describing your unique strengths. See the “pitch” page for an example layout for how to formulate your summary."
        ]
      }
    ],
    card3Content: [
      {
        text: "Book Chapter 13-18 : Now, How Do I Get It?",
        link:
          "https://drive.google.com/file/d/1BzoeC7iSVo1iwiiZPql-R8HUdcWisOnb/view?usp=sharing",
      },
      {
        text: "Video: Resume",
        link: "https://www.youtube.com/watch?v=SFex7BnoX1Y",
      },
      {
        text: "Blog: Top Tips to Strengthen your Personal Branding Materials for your Job Search",
        link: "https://www.iamwoken.com/post/top-tips-to-strengthen-your-personal-branding-materials-presence-for-your-job-search-career"
      },
      {
        text: "Blog: Getting the Job without the “Right” Experience",
        link:
          "https://medium.com/@getwoken/getting-the-job-without-the-right-experience-5b5f5551cbfe",
      },
      {
        text: "Resume Template: Design-Oriented in Canva",
        link: "https://www.canva.com/design/DAE5rImnm9Q/h7E8NVbS_gOtfSPx4flNUg/view?utm_content=DAE5rImnm9Q&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
      },
      {
        text: "Resume Template: Standard Format in Google Docs",
        link: "https://docs.google.com/document/d/16vI-J-nm-p42jIxpIDRU6e8YGdmdYAZ058KpjAxhDP4/edit?usp=sharing"
      }
    ],
  },
  "Cover Letter": {
    card1Content: [
      {
        text:
          "Cover letters explain what interests you and why a certain path is a strong fit for you. They also add context and flavor to your resume. Don't be afraid to be completely transparent when discussing why you are interested in a role, product, company, or industry.",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/rachel.88094393.png",
        name: "Rachel Serwetz",
      },
      {
        text: "The resume tweaking and cover letter editing was valuable",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/testimonial.bcea4f8d.svg",
        name: "Sara",
      },
      {
        text:
          "83% of HR professionals say a cover letter is important for their hiring decision.",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/testimonial.bcea4f8d.svg",
        name: "Resume Lab",
      },
    ],
    card2Content: [
      {
        mainPoint:
          "Follow along as this tab guides you to fill in the key pieces of a cover letter.",
      },
      {
        mainPoint:
          "Once your cover letter is generated, read through it and make any additional edits.",
      },
      {
        mainPoint:
          "Then, email the cover letter to your coach in an editable format (Google Docs or Microsoft Word) so your coach can help you finalize this document.",
      },
      {
        mainPoint:
          "Use an example job description to help guide which work examples you include so the skills and details you include are most relevant to your ideal path.",
      },
      {
        mainPoint:
          "Do people still use cover letters?There are mixed opinions about the current relevance of cover letters. That being said, it is important to draft a strong letter (ideally with a coach’s help) before you begin a job search. That way, you have this document ready to edit for any relevant job application you come across.",
      },
      {
        mainPoint:
          "Should I create different versions of cover letters? You will need to edit the letter for any company you apply to, ensuring you tailor your fit and interest for that company. What’s more, if you are considering more than one type of role or career path, you’d want a different letter that discusses your relevant background and skills as it relates to that role or path. Ideally, spend time exploring and clarifying your career direction and then draft a strong letter that describes how your background sets you up well for that next step.",
      },
    ],
    card3Content: [
      {
        text: "Book: Chapter 13-18 : Now, How Do I Get It? ",
        link:
          "https://drive.google.com/file/d/1BzoeC7iSVo1iwiiZPql-R8HUdcWisOnb/view?usp=sharing",
      },
    ],
  },
  LinkedIn: {
    card1Content: [
      {
        text:
          "Developing a strong, comprehensive professional profile on LinkedIn will help you create a professional brand and clearly outline how your strengths can be utilized in the future.",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/rachel.88094393.png",
        name: "Rachel Serwetz",
      },
      {
        text:
          "93% of recruiters use LinkedIn to research and recruit candidates. LinkedIn allows you to have an online personal brand which makes you visible to key decision makers and recruiters.",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/testimonial.bcea4f8d.svg",
        name: "LinkedIn",
      },
    ],
    card2Content: [
      { mainPoint: "Follow the prompts to update your LinkedIn profile." },
      {
        mainPoint:
          "Once you complete the checklist and submit your LinkedIn profile, your coach will review it and provide additional feedback.",
      },
      {
        mainPoint: "What can I use LinkedIn for?",
        subPoint: [
          "Represent your skills (an open-source resume if you will)",
          "A professional newsfeed (follow key accounts, companies, and leaders that you admire!)",
          "A top job search engine",
          "A professional network and research tool (find, meet and connect with professionals)",
          "A learning/skill development tool (LinkedIn Learning)",
        ],
      },
    ],
    card3Content: [
      {
        text: "Book: Section IV: Now, How Do I Get It?",
        link:
          "https://drive.google.com/file/d/1BzoeC7iSVo1iwiiZPql-R8HUdcWisOnb/view?usp=sharing",
      },
    ],
  },
  "Interview Research": {
    card1Content: [
      {
        text:
          "In preparation for an interview, research the interviewer and the company. You should also come up with thoughtful questions about the role, company, and industry.",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/rachel.88094393.png",
        name: "Rachel Serwetz",
      },
      {
        text:
          "I have really enjoyed this part of the exploration process. It's been so wonderful to connect with various women in strategy facing roles and comparing and contrasting their roles. It's also been really great to speak to women in leadership positions and who understand what I am looking for.",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/testimonial.bcea4f8d.svg",
        name: "Isabella",
      },
    ],
    card2Content: [
      {
        mainPoint:
          "Review each prompt as a guide for what you should research and prepare before an interview.",
      },
      {
        mainPoint:
          "Read the entire company website. Write down questions that arise. Your questions can relate to your potential role, team, or department, and how each of those fit into the company.",
      },
      {
        mainPoint:
          "Review the job description and see what questions arise. Make sure you have a full, complete, and clear picture of what is expected in this role. ",
      },
      {
        mainPoint:
          "List as many questions as you need so that you can assess whether this role and company are a great fit for you.",
      },
    ],
    card3Content: [
      {
        text: "Blog: 'The Facade of Interviews'",
        link:
          "https://medium.com/@getwoken/the-facade-of-interviews-6bb1a072581f",
      },
      {
        text: "Book: Chapter 13-18 : Now, How Do I Get It?",
        link:
          "https://drive.google.com/file/d/1BzoeC7iSVo1iwiiZPql-R8HUdcWisOnb/view?usp=sharing",
      },
      {
        text: "Video: Prepare for interviews",
        link: "https://www.youtube.com/watch?v=rhw1HuCL590",
      },
      {
        text: "Video: Prepare for interviews part 2",
        link: "https://youtu.be/xaJzQhY1lbE",
      },
      {
        text: "Video: Interview practice",
        link: "https://youtu.be/D8emPdAShxU",
      },
    ],
  },
  "Behavioral Interview Prep": {
    card1Content: [
      {
        text:
          "Interview preparation and interview practice will help you walk into an interview with confidence. A robust preparation strategy includes drafting your answers ahead of time, structuring each answer, and practicing them out loud.",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/rachel.88094393.png",
        name: "Rachel Serwetz",
      },
      {
        text:
          "Thanks to WOKEN, I now know the importance of self-reflection to ask the right questions that would lead to the right answers about your career path.",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/testimonial.bcea4f8d.svg",
        name: "Yarden B",
      },
    ],
    card2Content: [
      {
        mainPoint:
          "Review our list of behavioral questions and guidance on how to structure your responses. ",
      },
      {
        mainPoint:
          "Spend time considering your answers and writing them down. ",
      },
      {
        mainPoint:
          "Think about past experiences and stories that will help you answer these kinds of questions.",
      },
      {
        mainPoint:
          "Focus on figuring out how to organize and structure your thoughts and responses. The clarity and impact of your answers will increase the more time you devote to structuring and organizing them. Remember, the key to clear communication is pausing before you answer, and using a framework, structure, or approach to organize your thoughts and avoid rambling. ",
      },
      {
        mainPoint:
          "Remember that in an interview, if you get a question that you haven't prepared for, you should always pause to consider how you can apply a structure or framework before you answer.",
      },
      {
        mainPoint:
          "These responses should take you ~1-2 minutes to explain and not longer, as they can always dig deeper and ask you follow up questions on what they want to learn more about.",
      },
    ],
    card3Content: [
      {
        text: "Blog: 'Interviews: It’s all about the spin'",
        link:
          "https://medium.com/@getwoken/interviews-it-s-all-about-the-spin-18e40435bea2",
      },
      {
        text: "Book:  Chapter 13-18 : Now, How Do I Get It?",
        link:
          "https://drive.google.com/file/d/1BzoeC7iSVo1iwiiZPql-R8HUdcWisOnb/view?usp=sharing",
      },
      {
        text: "Videos: Prepare for interviews",
        link: "https://www.youtube.com/watch?v=rhw1HuCL590",
      },
      {
        text: "Video: Prepare for interviews part 2",
        link: "https://youtu.be/xaJzQhY1lbE",
      },
      {
        text: "Video: Interview practice",
        link: "https://youtu.be/D8emPdAShxU",
      },
    ],
  },
  "Application Tracker": {
    card1Content: [
      {
        text:
          "You should be networking at least 60% of the time during your job search. Leverage this tracker to target your top companies, connect with professionals at those companies, and discover open roles that may not be posted online.",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/rachel.88094393.png",
        name: "Rachel Serwetz",
      },
      {
        text:
          "With the motivation from our conversation, I applied to two opportunities that fell in the realm of what space I wanted to be in. As the following two weeks went by, I had multiple interviews for both positions. Two weeks later, I had offers from both positions. I was ecstatic.",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/testimonial.bcea4f8d.svg",
        name: "Elizabeth",
      },
    ],
    card2Content: [
      {
        mainPoint:
          "Each column on this tracker is a step in the job search process.",
      },
      {
        mainPoint:
          "The breakdown of how you should be spending your time on the job search should look like this:",
        subPoint: [
          "60% networking (finding connections, gaining introductions, doing reach outs, holding calls, attending events or meetups)",
          "10% personal branding",
          "10% continued online research (learn about new companies, products, services, industry resources, etc.)",
          "10% online applications (1 per day maximum)",
          "10% experiential learning (gain skills, online course, side project, certifications, volunteering, etc.)",
        ],
      },
    ],
    card3Content: [
      {
        text: "Book: Chapter 13-18 : Now, How Do I Get It?",
        link:
          "https://drive.google.com/file/d/1BzoeC7iSVo1iwiiZPql-R8HUdcWisOnb/view?usp=sharing",
      },
      {
        text:
          "Guest Blog : The impact of career exploration on your job search.",
        link:
          "https://www.jazzhr.com/blog/the-impact-of-career-exploration-on-your-job-search/",
      },
      {
        text: "Video: Applications Part 1-3",
        link: "https://www.youtube.com/watch?v=drlfDh6OtqQ",
      },
      {
        text:
          "Blog: The 5 Key Things You Don’t Understand About Your Job Search",
        link:
          "https://medium.com/@getwoken/the-5-key-things-you-dont-understand-about-your-job-search-d1dc1a5cc1b3",
      },
      {
        text: "Blog : Looking for a job? Consider These Factors",
        link:
          "https://medium.com/@getwoken/looking-for-a-job-consider-these-factors-e46bafeac7ed",
      },
    ],
  },
  "Jobsearch Weekly Reflections": {
    card1Content: [
      {
        text:
          "Reflecting as part of the job search is critical to ensure you're spending time wisely, looking into paths that suit and excite you, and figuring out where you can improve to gain more traction.",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/rachel.88094393.png",
        name: "Rachel Serwetz",
      },
      {
        text:
          "Taking the time to step back, breathe, and reflect on what I really want to do has proven to be such an important first step - the rest of my career search has a newfound purpose and focus.",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/testimonial.bcea4f8d.svg",
        name: "Jeff D",
      },
      {
        text:
          "A study from Harvard Business School tested whether taking 15 minutes at the end of a work day to reflect on that day’s work improved performance and found participants tasked with daily written reflection did 22.8% better on an assessment than the control group.",
        image:
          "https://django-woken-static.s3.amazonaws.com/static/media/testimonial.bcea4f8d.svg",
        name: "Monster.com",
      },
    ],
    card2Content: [
      {
        mainPoint:
          "In the job search process, it's critical to reflect weekly to ensure you're being strategic, using your time wisely, seeing results, and consistently iterating and improving your processes.",
      },
      {
        mainPoint:
          "It's also important to reflect on what you're learning during your networking process. Networking is a great way of learning more about potential opportunities and what is the best fit for you. How can you alter your strategy of what roles and companies you're targeting based on what you're learning about which path is best for you?",
      },
      {
        mainPoint:
          "Your reflections help your coach make sure you're on the right track and provide you with feedback.",
      },
      {
        mainPoint:
          "We'll check in on your overall progress to see how things are going. We'll focus on areas where you were successful and areas where you can improve.",
      },
      {
        mainPoint:
          "Our goal is to make sure you're staying accountable, productive, and effective as you discover your ideal career path, one that is authentic and exciting.",
      },
    ],
    card3Content: [
      {
        text: "Video: 'Reflect through the Process'",
        link: "https://www.youtube.com/watch?v=rwRF8kwglbA",
      },
      {
        text: "Book: Chapter 13-18 : Now, How Do I Get It?",
        link:
          "https://drive.google.com/file/d/1BzoeC7iSVo1iwiiZPql-R8HUdcWisOnb/view?usp=sharing",
      },
      {
        text:
          "Blog: The 5 Key Things You Don’t Understand About Your Job Search",
        link:
          "https://medium.com/@getwoken/the-5-key-things-you-dont-understand-about-your-job-search-d1dc1a5cc1b3",
      },
      {
        text: "Blog: The Bottom Line of Engagement",
        link:
          "https://medium.com/@getwoken/the-bottom-line-of-engagement-4a26f59303d5",
      },
      {
        text: "Blog: The Only Three Factors for Getting a Job",
        link:
          "https://medium.com/@getwoken/the-only-three-factors-for-getting-a-job-d387998d17b9",
      },
      {
        text: "Blog:Career Paths: Taking the next step, and not settling",
        link:
          "https://medium.com/@getwoken/career-paths-taking-the-next-step-and-not-settling-e9f6956d6a2a",
      },
    ],
  },
};
export default card;
