import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "./index.css";
import App from "./App";
import store from "./store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import ReactGA from 'react-ga';
import "less/app.less";

ReactGA.initialize('UA-176764798-1');

Sentry.init({
  dsn:
    "https://18f676178e004bdc9fe7526d275b5dc8@o523472.ingest.sentry.io/5635559",
  // integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={"An error has occurred"}>
    <Provider store={store}>
      <App />
    </Provider>
  </Sentry.ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
