import React from "react";
import { Row, Col, Input, Form, Select, Button, message } from "antd";
import AUTH_API from "./../../api/auth";
import Freeformbox from "./Freeformbox";
import HELPERS from "utils/helper";
const { Option } = Select;
const { TextArea } = Input;
export default class Contentareas extends React.Component {
  state = {
    assess_id: "",
    q_57: "Past",
  };

  formRef = React.createRef();

  componentDidMount() {
    window.scrollTo(0, 0);
    AUTH_API.getAssessment()
      .then((response) => {
        console.log("get assessment", response, this.formRef);
        this.formRef.current.setFieldsValue(response.data.content_areas);
        this.props.getRef(this.formRef);
        this.setState({
          assess_id: response.data.id,
          q_57: response.data[56] ? response.data[56] : "Past",
        });
      })
      .catch((err) => console.log("err", err));
  }

  onValChange = (val, allvalues) => {
    this.props.getRef(this.formRef);
    console.log("form values", allvalues);
    AUTH_API.patchAssessment({ content_areas: allvalues }, this.state.assess_id)
      .then((response) => {
        // console.log('experiences patched!!', response);
        message.success('Your work has been saved')
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  onFinish = () => {
    this.props.next();
  };

  onFail = () => {
    message.error("Fill all the required fields first");
  };

  render() {
    return (
      <div>
        <p
          style={{
            color: "#296B70",
            font: "normal normal 600 14px/22px Montserrat",
          }}
        >
          Complete the second section of the assessment. This section focuses on
          topics you are intrigued by. Go with your gut and don’t overthink it !
        </p>
        <Form
          ref={this.formRef}
          name="contentareas"
          onValuesChange={HELPERS.debounceFormChanges(this.onValChange)}
          onFinish={this.onFinish}
          onFinishFailed={this.onFail}
        >
          <div className="disabled-div" id="34">
            <p style={{ paddingTop: "14px", paddingBottom: "20px" }}>
              34. What topics do you like to read about or listen to? List some
              favorite books, podcasts, websites, email newsletters, blogs,
              articles, etc.
            </p>
            <Freeformbox q_number={34} default_len={3} />
          </div>

          {/* ---------------------------------------- We explore --------------------------------------------------------------------------------------------- */}
          <div className="disabled-div" id="35">
            <p style={{ paddingTop: "14px", paddingBottom: "20px" }}>
              35. Think about the colleague or friend that you can dive into
              conversation with and explore various topics - whether its
              problems you want to solve, systems you want to improve, concepts
              you want to understand, ideas you want to explore, things you want
              to build or create, etc. What are some topics you like to explore
              with this person?
            </p>
            <Freeformbox q_number={35} default_len={3} />
          </div>

          {/* ---------------------------------------- I think about --------------------------------------------------------------------------------------------- */}
          <div className="disabled-div" id="36">
            <p style={{ paddingTop: "14px", paddingBottom: "20px" }}>
              36. What topics do you find yourself thinking about or researching
              on your free time?
            </p>
            <Freeformbox q_number={36} default_len={3} />
          </div>

          {/* ---------------------------------------- I help others with --------------------------------------------------------------------------------------------- */}
          <div className="disabled-div" id="37">
            <p style={{ paddingTop: "14px", paddingBottom: "20px" }}>
              37. What topics do people come to you for help or advice ?
            </p>
            <Freeformbox q_number={37} default_len={3} />
          </div>

          <div className="disabled-div" id="38">
            <p style={{ paddingTop: "14px", paddingBottom: "20px" }}>
              38. What companies do you follow or admire?
            </p>
            <Freeformbox q_number={38} default_len={3} />
          </div>

          <div className="disabled-div" id="39">
            <p style={{ paddingTop: "14px", paddingBottom: "20px" }}>
              39. What leaders or influencers do you follow?
            </p>
            <Freeformbox q_number={39} default_len={3} />
          </div>

          <div className="disabled-div" id="40">
            <p style={{ paddingTop: "14px", paddingBottom: "20px" }}>
              40. What kinds of websites, blogs, newsletters, magazines,
              journals, etc. do you read or follow? List them here.
            </p>
            <Freeformbox q_number={40} default_len={3} />
          </div>

          <div className="disabled-div" id="41">
            <p style={{ paddingTop: "14px", paddingBottom: "20px" }}>
              41. Are you in any clubs or organizations? List your memberships.
            </p>
            <Freeformbox q_number={41} default_len={3} />
          </div>

          <div className="disabled-div" id="42">
            <p style={{ paddingTop: "14px", paddingBottom: "20px" }}>
              42. What are some topics, issues, or areas that do not interest
              you at all?
            </p>
            <Freeformbox q_number={42} default_len={3} />
          </div>

          {/* ---------------------------------------- I click on articles about --------------------------------------------------------------------------------------------- */}
          <div className="disabled-div" id="43">
            <p style={{ paddingTop: "14px", paddingBottom: "20px" }}>
              43. What kinds of articles do you click on when you are reading
              the news or scrolling on social media?
            </p>
            <Freeformbox q_number={43} default_len={3} />
          </div>

          {/* ---------------------------------------- I could talk endlessly about --------------------------------------------------------------------------------------------- */}
          <div className="disabled-div" id="44">
            <p style={{ paddingTop: "14px", paddingBottom: "20px" }}>
              44. Are there topics you could talk so much about you’d bore other
              people?
            </p>
            <Freeformbox q_number={44} default_len={3} />
          </div>

          {/* ---------------------------------------- I get excited when I talk about: --------------------------------------------------------------------------------------------- */}
          <div className="disabled-div" id="45">
            <p style={{ paddingTop: "14px", paddingBottom: "20px" }}>
              45. What topics get you excited and/or animated when you talk
              about them?
            </p>
            <Freeformbox q_number={45} default_len={1} />
          </div>

          {/* ---------------------------------------- I love learning/talking about: --------------------------------------------------------------------------------------------- */}
          <div className="disabled-div" id="46">
            <p style={{ paddingTop: "14px", paddingBottom: "20px" }}>
              46. Are there any topics that you think you understand better than
              other people or topics you love learning about?
            </p>
            <Freeformbox q_number={46} default_len={3} />
          </div>

          {/* ---------------------------------------- The message was --------------------------------------------------------------------------------------------- */}
          <div className="disabled-div" id="47">
            <p style={{ paddingTop: "14px", paddingBottom: "20px" }}>
              47. Think of a book that resonated with you. What was the key
              message that resonated with you?
            </p>
            <Freeformbox q_number={47} default_len={1} />
          </div>

          {/* ---------------------------------------- I see myself helping: --------------------------------------------------------------------------------------------- */}
          <div className="disabled-div" id="48">
            <p style={{ paddingTop: "14px", paddingBottom: "20px" }}>
              48. What type of people or clients do you see yourself serving?
              What types of people or groups of people do you empathize with or
              want to help?
            </p>
            <Freeformbox q_number={48} default_len={3} />
          </div>

          <div className="disabled-div" id="49">
            <p style={{ paddingTop: "14px", paddingBottom: "20px" }}>
              49 . "Content areas" are another way of thinking about industries.
              For example, fashion, healthcare, finance, education, politics,
              sustainability, etc. are all content areas. List the top content
              areas that genuinely intrigue you.
            </p>
            <Freeformbox q_number={49} default_len={4} />
          </div>

          <div className="disabled-div" id="50">
            <p style={{ paddingTop: "14px", paddingBottom: "20px" }}>
              50. Imagine you're creating a major for yourself to study (either
              at the current moment, or, what you would've wanted to study in
              undergraduate). Be as specific as you can. Tell me the name of the
              major.
            </p>
            <p>
              My major would be
              <Form.Item
                name="50a"
                style={{
                  display: "inline-block",
                  verticalAlign: "top",
                  marginTop: "-4px",
                }}
              >
                <TextArea
                  autoSize={{ minRows: 1, maxRows: 60 }}
                  bordered={false}
                ></TextArea>
              </Form.Item>
              with a minor in
              <Form.Item
                name="50b"
                style={{
                  display: "inline-block",
                  verticalAlign: "top",
                  marginTop: "-4px",
                }}
              >
                <TextArea
                  autoSize={{ minRows: 1, maxRows: 60 }}
                  bordered={false}
                ></TextArea>
              </Form.Item>
            </p>
          </div>

          <div className="disabled-div" id="51">
            <p style={{ paddingTop: "14px", paddingBottom: "20px" }}>
              51. If you could (or if you already do) write a
              blog/paper/essay/book on any topic, what topic would you want to
              write about?
            </p>

            <div className="cont-51">
              <Form.Item name="51">
                <TextArea
                  className="text-51"
                  autoSize={{ minRows: 2, maxRows: 60 }}
                >
                  {" "}
                </TextArea>
              </Form.Item>
            </div>
          </div>

          <div className="disabled-div" id="52">
            <p style={{ paddingTop: "14px", paddingBottom: "20px" }}>
              52. What products, tools, or services do you find innovative or
              interesting?
            </p>
            <Freeformbox q_number={52} default_len={3} />
          </div>

          <div className="disabled-div" id="53">
            <p style={{ paddingTop: "14px", paddingBottom: "20px" }}>
              53. What are some challenges you've faced that you feel passionate
              about solving for others?
            </p>

            <div className="cont-53">
              <Form.Item name="53">
                <TextArea
                  className="text-53"
                  autoSize={{ minRows: 2, maxRows: 60 }}
                >
                  {" "}
                </TextArea>
              </Form.Item>
            </div>
          </div>

          <div className="disabled-div" id="54">
            <p style={{ paddingTop: "14px", paddingBottom: "20px" }}>
              54. What are some challenges you've seen others face that you feel
              passionate about solving for others?{" "}
            </p>

            <div className="cont-54">
              <Form.Item name="54">
                <TextArea
                  className="text-54"
                  autoSize={{ minRows: 2, maxRows: 60 }}
                >
                  {" "}
                </TextArea>
              </Form.Item>
            </div>
          </div>

          <div className="disabled-div" id="55">
            <p style={{ paddingTop: "14px", paddingBottom: "20px" }}>
              55. Can you think of any companies out there today that solve
              those issues?
            </p>
            <Freeformbox q_number={55} default_len={3} />
          </div>

          <div className="disabled-div" id="56">
            <p style={{ paddingTop: "14px", paddingBottom: "20px" }}>
              56. Do you typically tend to think mostly about the past, the
              present, or the future?{" "}
            </p>
            <Form.Item name="56">
              <Select
                size="large"
                style={{ width: "20%" }}
                onChange={(val) => {
                  this.setState({ q_57: val });
                }}
              >
                <Option id="1" value={"Past"}>
                  Past
                </Option>
                <Option id="2" value={"Present"}>
                  Present
                </Option>
                <Option id="3" value={"Future"}>
                  Future
                </Option>
              </Select>
            </Form.Item>
          </div>

          {/* ---------------------------------------- I think about: --------------------------------------------------------------------------------------------- */}
          <div className="disabled-div" id="57">
            <p style={{ paddingTop: "14px", paddingBottom: "20px" }}>
              57. What exactly about the {this.state.q_57} do you tend to think
              about?
            </p>

            <div className="cont-57">
              <Form.Item name="57">
                <TextArea
                  className="text-57"
                  autoSize={{ minRows: 2, maxRows: 60 }}
                >
                  {" "}
                </TextArea>
              </Form.Item>
            </div>
          </div>

          {/* ---------------------------------------- My worldview is that: --------------------------------------------------------------------------------------------- */}
          <div className="disabled-div" id="58">
            <p style={{ paddingTop: "14px", paddingBottom: "20px" }}>
              58. Do you have a worldview? Describe it. (i.e. you believe that
              all people deserve X, or you believe that we as humankind should
              do X)
            </p>
            <Freeformbox q_number={58} default_len={1} />
          </div>

          <Row>
            <Col span={6}>
              <Button
                onClick={this.props.prev}
                size="large"
                style={{ margin: "0 8px", color: "#49CDE1" }}
                block
              >
                Back
              </Button>
            </Col>
            <Col span={6}></Col>
            <Col span={6}></Col>
            <Col span={6}>
              <Form.Item name="submit-b">
                <Button htmlType="submit" size="large" type="primary" block>
                  Next
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}
