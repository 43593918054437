import React from "react";
import { Modal, Input, Form, Button, Row, Col, Popconfirm } from "antd";

export default class Renamecol extends React.Component {
  render() {
    let colName = this.props.col_name.col_name;
    return (
      <Modal
        // title="Basic Modal"
        visible={this.props.visible}
        // onOk={this.props.handleOk}
        onCancel={this.props.closeRenameModal}
        width={"30%"}
        footer={null}
      >
        <p>Rename Column</p>
        <Form
          onFinish={(val) => this.props.handleOk(val)}
          initialValues={{
            rename: colName,
          }}
        >
          <Form.Item name="rename">
            <Input />
          </Form.Item>
          <Row>
            <Col span={8}>
              <Popconfirm
                title="Are you sure you want to delete ?"
                onConfirm={() => this.props.deleteCol()}
              >
                <Button style={{ color: "#49CDE1" }}> Delete</Button>
              </Popconfirm>
            </Col>
            <Col span={8}></Col>
            <Col span={8}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ float: "right" }}
              >
                Rename
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}
