import { AlignLeftOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Input,
  Popconfirm,
  Row,
  Select,
} from "antd";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import AUTH_API from "../../../api/auth";
import "./Tracker.css";

const { Option } = Select;

export default class Companydrawer extends React.Component {
  state = {
    company: { ...this.props.record },
    contacts: [...this.props.record.contact_name],
  };

  onDelete = () => {
    console.log("delete");
    AUTH_API.deleteCompany(this.props.record.id)
      .then((res) => {
        console.log("company deleted!!", res);
        this.props.onClose();
      })
      .catch((err) => console.log("del err", err));
  };

  onChange = (val, type) => {
    console.log("val", val, typeof val);
    let company = { ...this.state.company };
    if (val) {
      console.log(val, type);
      const patch_data = {};
      let tracker_data = {};
      patch_data[type] = val;
      console.log(patch_data);
      let outside_tracker = [
        "title",
        "company_type",
        "industry",
        "email",
        "phone",
      ];
      if (outside_tracker.filter((item) => item === type).length) {
        company = { ...company, ...patch_data };
      } else {
        tracker_data = { ...company.tracker_info, ...patch_data };
        company.tracker_info = tracker_data;
      }
      console.log("Company now!", company);
      this.setState({
        company,
      });
    }
  };

  onContactChange = (val, rec, type) => {
    let target = {};
    let prev_state = [...this.state.contacts];
    let ind = prev_state.indexOf(
      prev_state.filter((item) => item.id === rec.id)[0]
    );
    target = prev_state[ind];
    let patch_data = { ...target };
    if (type === "title") {
      if (val !== "") patch_data["title"] = val;
    } else {
      patch_data.tracker_info[type] = val;
    }
    // console.log('to be patched', patch_data)
    // AUTH_API.patchContact({...patch_data}, rec.id).then(
    //   res=>{
    //     console.log('contact saved!!', res)
    //   }
    // ).catch(
    //   err=>console.log('patch err', err)
    // )
    prev_state[ind] = patch_data;
    console.log(
      "to be patched",
      prev_state,
      ind,
      prev_state.filter((item) => item.id === rec.id)
    );
    this.setState({
      contacts: prev_state,
    });
  };

  onSave = () => {
    console.log("save", this.state.company);
    AUTH_API.patchCompany({ ...this.state.company }, this.props.record.id)
      .then((res) => {
        console.log("saved!!", res);
      })
      .then((res) => {
        this.state.contacts.map((item, index) => {
          console.log("going to be patched", item);
          AUTH_API.patchContact(item, item.id)
            .then((res) => {
              console.log("contact saved!!", res);
            })
            .then((res) => {
              if (!this.state.contacts[index + 1]) {
                console.log(
                  "close here",
                  index + 1,
                  !this.state.contacts[index + 1]
                );
                this.props.onClose();
              }
              //check this
              this.props.onClose();
            })
            .catch((err) => console.log("patch err", err));
        });
      })
      .catch((err) => console.log("patch err", err));
  };

  render() {
    const COMPANY = { ...this.state.company };
    const TRACKER = { ...this.state.company.tracker_info };
    console.log("drawer!!!", this.state);

    const title = (
      <div className="drawer-title">
        <p className="contact-name-head">{this.props.record.title}</p>
        <p className="drawer-subhead">
          You can edit information about the company and role here.
        </p>
      </div>
    );

    const footer = (
      <Row style={{ padding: 20 }}>
        <Col span={8}>
          <Popconfirm
            title="Are you sure you want to delete ?"
            onConfirm={this.onDelete}
          >
            <Button
              // onClick={this.onDelete}
              style={{ color: "#49CDE1" }}
              block
              size="large"
            >
              Delete company
            </Button>
          </Popconfirm>
        </Col>
        <Col span={8}></Col>
        <Col span={8}>
          <Button type="primary" onClick={this.onSave} block size="large">
            Save changes
          </Button>
        </Col>
      </Row>
    );

    return (
      <Drawer
        title={title}
        placement="right"
        onClose={this.props.onClose}
        visible={this.props.show_drawer}
        width={640}
        footer={footer}
      >
        <div style={{ padding: "5px 10px" }}>
          <div>
            <p className="contact-head-dr">Company Info</p>
            <hr className="col-gap"></hr>
            <div style={{ padding: "5px 10px" }}>
              <Row style={{ padding: "10px 0" }}>
                <Col span={6}>
                  <p className="info-type">{"Name"}</p>
                </Col>
                <Col span={18}>
                  <Input
                    defaultValue={COMPANY.title}
                    placeholder="Enter a name"
                    size={"small"}
                    bordered={false}
                    onBlur={(val) => this.onChange(val.target.value, "title")}
                  />
                </Col>
              </Row>

              <Row style={{ padding: "10px 0" }}>
                <Col span={6}>
                  <p className="info-type">{"Industry"}</p>
                </Col>
                <Col span={18}>
                  <Select
                    key={`ind-f`}
                    onChange={(val) => this.onChange(val, "industry")}
                    defaultValue={COMPANY.industry}
                    placeholder="Select industry"
                    style={{ maxWidth: "100%", minWidth: "100%" }}
                    bordered={false}
                  >
                    {this.props.industries.map((item, ind) => (
                      <Option key={ind + "ind2"} value={item.title}>
                        {item.title}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </Row>

              <Row style={{ padding: "10px 0" }}>
                <Col span={6}>
                  <p className="info-type">{"Company size"}</p>
                </Col>
                <Col span={18}>
                  <Select
                    key={`company_size`}
                    onChange={(val) => this.onChange(val, "company_size")}
                    defaultValue={COMPANY["company_size"]}
                    placeholder="Select size"
                    style={{ maxWidth: "100%", minWidth: "100%" }}
                    bordered={false}
                  >
                    {this.props.sizes.map((item, ind) => (
                      <Option key={ind + "size"} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </Row>

              {this.props.columns.companyinfo.map(
                (col, index) =>
                  index > 2 && (
                    <Row style={{ padding: "10px 0" }}>
                      {/* {console.log(col)} */}
                      <Col span={6}>
                        <p className="info-type">{col.title}</p>
                      </Col>
                      <Col span={18}>
                        <Input
                          placeholder="Type Something"
                          defaultValue={TRACKER[col.key]}
                          size={"small"}
                          bordered={false}
                          onBlur={(val) => {
                            this.onChange(val.target.value, col.key);
                          }}
                        />
                      </Col>
                    </Row>
                  )
              )}
            </div>
          </div>

          <div>
            <p className="contact-head-dr">My Contact at Company</p>
            <hr className="col-gap"></hr>
            <div style={{ padding: "5px 10px" }}>
              <>
                {/* {console.log('contacts in tracker',COMPANY['contact_name'])} */}
                {COMPANY["contact_name"] &&
                  COMPANY["contact_name"].map((con) => (
                    <>
                      <Row style={{ padding: "10px 0" }}>
                        <Col span={6}>
                          <p className="info-type">{"Contact Name"}</p>
                        </Col>
                        <Col span={18}>
                          <Input
                            defaultValue={con.title}
                            placeholder="Type something"
                            size={"small"}
                            bordered={false}
                            onBlur={(val) => {
                              this.onContactChange(
                                val.target.value,
                                con,
                                "title"
                              );
                            }}
                          />
                        </Col>
                      </Row>

                      <Row style={{ padding: "10px 0" }}>
                        <Col span={6}>
                          <p className="info-type">{"Scheduled Call Date"}</p>
                        </Col>
                        <Col span={18}>
                          <DatePicker
                            size="small"
                            onChange={(date, dateString) =>
                              this.onContactChange(
                                dateString,
                                con,
                                "scheduled_date"
                              )
                            }
                            defaultValue={
                              con.tracker_info["scheduled_date"]
                                ? moment(con.tracker_info["scheduled_date"])
                                : null
                            }
                            bordered={false}
                          />
                        </Col>
                      </Row>

                      <Row style={{ padding: "10px 0" }}>
                        <Col span={6}>
                          <p className="info-type">{"Call Prep"}</p>
                        </Col>
                        <Col span={18}>
                          {!con.call_prep.length ? (
                            <Link
                              to={{
                                pathname: "/networking/call-prep",
                                id: con.id,
                              }}
                            >
                              <PlusOutlined
                                className="row-btn-add"
                                style={{
                                  display: "block",
                                  padding: 10,
                                  textAlign: "left",
                                }}
                              />
                            </Link>
                          ) : (
                            <Link
                              to={{
                                pathname: "/networking/call-prep",
                                id: con.id,
                              }}
                            >
                              <AlignLeftOutlined
                                className="row-btn"
                                style={{
                                  display: "block",
                                  padding: 10,
                                  textAlign: "left",
                                }}
                              />
                            </Link>
                          )}
                        </Col>
                      </Row>
                      <hr></hr>
                    </>
                  ))}
              </>
              {this.props.columns.contact_at_company.map((col, index) =>
                index > 2 ? (
                  <Row style={{ padding: "10px 0" }}>
                    <Col span={6}>
                      <p className="info-type">{col.title}</p>
                    </Col>
                    <Col span={18}>
                      <Input
                        defaultValue={TRACKER[col.key]}
                        placeholder="Type something"
                        size={"small"}
                        bordered={false}
                        onBlur={(val) => {
                          this.onChange(val.target.value, col.key);
                        }}
                      />
                    </Col>
                  </Row>
                ) : (
                  ""
                )
              )}
            </div>
          </div>

          <div>
            <p className="contact-head-dr">Role</p>
            <hr className="col-gap"></hr>
            <div style={{ padding: "5px 10px" }}>
              <Row style={{ padding: "10px 0" }}>
                {/* {console.log(col)} */}
                <Col span={6}>
                  <p className="info-type">{"Source"}</p>
                </Col>
                <Col span={18}>
                  <Select
                    key={`source-for-`}
                    mode="tags"
                    maxTagCount={1}
                    onChange={(val) => this.onChange(val, "source")}
                    defaultValue={TRACKER["source"] ? TRACKER["source"] : []}
                    placeholder="Select Source"
                    style={{ maxWidth: "100%", minWidth: "100%" }}
                    className="at-select"
                    bordered={false}
                    dropdownClassName="option-style"
                  >
                    <Option key="s11" value="networking">
                      Found via networking
                    </Option>
                    <Option key="s22" value="online">
                      Found online
                    </Option>
                  </Select>
                </Col>
              </Row>

              <Row style={{ padding: "10px 0" }}>
                {/* {console.log(col)} */}
                <Col span={6}>
                  <p className="info-type">{"Title"}</p>
                </Col>
                <Col span={18}>
                  <Select
                    key={`role-for223`}
                    onChange={(val) => this.onChange(val, "role_title")}
                    defaultValue={TRACKER["role_title"]}
                    placeholder="Select role"
                    style={{ maxWidth: "100%", minWidth: "100%" }}
                    bordered={false}
                  >
                    {this.props.roles.map((item) => (
                      <Option value={item.title}>{item.title}</Option>
                    ))}
                  </Select>
                </Col>
              </Row>

              <Row style={{ padding: "10px 0" }}>
                {/* {console.log(col)} */}
                <Col span={6}>
                  <p className="info-type">{"Job posting link"}</p>
                </Col>
                <Col span={18}>
                  <Input
                    placeholder="Type Something"
                    defaultValue={TRACKER["job_link"]}
                    size={"small"}
                    bordered={false}
                    onBlur={(val) => {
                      this.onChange(val.target.value, "job_link");
                    }}
                  />
                </Col>
              </Row>

              {this.props.columns.role.map(
                (col, index) =>
                  index > 2 && (
                    <Row style={{ padding: "10px 0" }}>
                      {/* {console.log(col)} */}
                      <Col span={6}>
                        <p className="info-type">{col.title}</p>
                      </Col>
                      <Col span={18}>
                        <Input
                          placeholder="Type Something"
                          defaultValue={TRACKER[col.key]}
                          size={"small"}
                          bordered={false}
                          onBlur={(val) => {
                            this.onChange(val.target.value, col.key);
                          }}
                        />
                      </Col>
                    </Row>
                  )
              )}
            </div>
          </div>

          <div>
            <p className="contact-head-dr">Applications & Interviews</p>
            <hr className="col-gap"></hr>
            <div style={{ padding: "5px 10px" }}>
              {this.props.columns.application_interviews.map((col, index) =>
                index > 3 ? (
                  <Row style={{ padding: "10px 0" }}>
                    {/* {console.log(col)} */}
                    <Col span={6}>
                      <p className="info-type">{col.title}</p>
                    </Col>
                    <Col span={18}>
                      <Input
                        placeholder="Type Something"
                        defaultValue={TRACKER[col.key]}
                        size={"small"}
                        bordered={false}
                        onBlur={(val) => {
                          this.onChange(val.target.value, col.key);
                        }}
                      />
                    </Col>
                  </Row>
                ) : (
                  <Row style={{ padding: "10px 0" }}>
                    {/* {console.log(col)} */}
                    <Col span={6}>
                      <p className="info-type">{col.title}</p>
                    </Col>
                    <Col span={18}>
                      <DatePicker
                        size="small"
                        onChange={(date, dateString) =>
                          this.onChange(dateString, col.key)
                        }
                        defaultValue={
                          TRACKER[col.key] ? moment(TRACKER[col.key]) : null
                        }
                        bordered={false}
                      />
                    </Col>
                  </Row>
                )
              )}
            </div>
          </div>

          <div>
            <p className="contact-head-dr">Level of Interest</p>
            <hr className="col-gap"></hr>
            <div style={{ padding: "5px 10px" }}>
              {this.props.columns.interest_level.map((col, index) =>
                index > 1 ? (
                  <Row style={{ padding: "10px 0" }}>
                    {/* {console.log(col)} */}
                    <Col span={6}>
                      <p className="info-type">{col.title}</p>
                    </Col>
                    <Col span={18}>
                      <Input
                        placeholder="Type Something"
                        defaultValue={TRACKER[col.key]}
                        size={"small"}
                        bordered={false}
                        onBlur={(val) => {
                          this.onChange(val.target.value, col.key);
                        }}
                      />
                    </Col>
                  </Row>
                ) : (
                  <Row style={{ padding: "10px 0" }}>
                    {/* {console.log(col)} */}
                    <Col span={6}>
                      <p className="info-type">{col.title}</p>
                    </Col>
                    <Col span={18}>
                      <Select
                        key={`roleu-${col.key}`}
                        onSelect={(val) => this.onChange(val, col.key)}
                        defaultValue={
                          TRACKER[col.key] ? TRACKER[col.key] : null
                        }
                        placeholder="Select Interest Level"
                        style={{ maxWidth: "100%", minWidth: "100%" }}
                        className="at-select"
                        bordered={false}
                        dropdownClassName="option-style"
                      >
                        {Array.from(Array(10).keys()).map((item, index) => (
                          <Option key={index + "role3"} value={item + 1}>
                            {item + 1}
                          </Option>
                        ))}
                        ;
                      </Select>
                    </Col>
                  </Row>
                )
              )}
            </div>
          </div>

          <div>
            <p className="contact-head-dr">After Call</p>
            <hr className="col-gap"></hr>
            <div style={{ padding: "5px 10px" }}>
              {this.props.columns.aftercall.map((col, index) => (
                <Row style={{ padding: "10px 0" }}>
                  {/* {console.log(col)} */}
                  <Col span={6}>
                    <p className="info-type">{col.title}</p>
                  </Col>
                  <Col span={18}>
                    <Input
                      placeholder="Type Something"
                      defaultValue={TRACKER[col.key]}
                      size={"small"}
                      bordered={false}
                      onBlur={(val) => {
                        this.onChange(val.target.value, col.key);
                      }}
                    />
                  </Col>
                </Row>
              ))}
            </div>
          </div>
        </div>
      </Drawer>
    );
  }
}
