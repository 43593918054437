import {Card, Col, Form, Input, message, Row, Select, Slider} from "antd";
import React from "react";
import HELPERS from "utils/helper";
import AUTH_API from "./../../../api/auth";
import "./CallNotes.css";
import Editableq from "./Editableq";
import {debounce} from "lodash";

const { TextArea } = Input;
const { Option } = Select;

export default class CallNotes extends React.Component {
  formRef = React.createRef();
  RefformRef = React.createRef();

  state = {
    hide: false,
    loading: true,
    submitted: false,
    contacts: [],
    current: 0,
    notes: "",
    bKeys: [],
    Api_cards: [],
    // props:this.props.location ? this.props.location.id : 0
  };

  componentDidMount() {
    const temp = { ...this.props };
    console.log("location props in componentdidmount:", temp.location.id);
    AUTH_API.getAllContacts()
      .then((response) => {
        console.log("get api response!!", response);
        const all_contacts = [...response.data];
        const contacts = [];
        all_contacts.map((item) => {
          contacts.push({
            id: item.id,
            name: item.title,
            roles: item.roles,
            ind: item.industries,
          });
        });
        console.log("contacts", contacts);
        // this.setState({contacts:contacts,loading:false})

        // const id = this.state.contacts[0].id;
        const id = this.props.location.id
          ? this.props.location.id
          : contacts[0].id;
        AUTH_API.getContact(id)
          .then((response) => {
            console.log("get single contact res in get api", response);
            const form_values =
              response.data.call_prep.length > 0
                ? response.data.call_prep[0]
                : [];

            const refForm_values =
              response.data.reflections.length > 0
                ? response.data.reflections[0]
                : [];
            // const refForm_values = response.data.reflections[0]
            this.setState({
              contacts: contacts,
              loading: false,
              Api_cards: Object.keys(form_values),
              notes: response.data.notes,
              current: response.data,
              bKeys: Object.keys(refForm_values),
            });
            this.formRef.current.setFieldsValue({
              ...form_values,
            });
            this.RefformRef.current.setFieldsValue({
              ...refForm_values,
            });
          })
          .catch((err) => {
            console.log("error", err);
          });
      })
      .catch((err) => console.log("no contacts found!", err));
  }
  // static getDerivedStateFromProps(props, current_state) {
  //     if(props.location){
  //         if (current_state.current !== props.location.id) {
  //             return {
  //                 current: props.location.id,
  //             }
  //         }
  //     }
  //     return null
  // }

  // componentDidUpdate(prevProps, prevState) {
  //     if(this.props.location){
  //         if (this.props.location.id !== prevState.current) {
  //         this.setState({ current: this.props.location.id });
  //         }
  //     }
  //  }

  greencards = (values) => {
    const gKeys = Object.keys(values);
    // console.log("gKeys",gKeys)
    this.setState({ gKeys: gKeys });
  };

  OnContactChange = (val) => {
    this.setState({ Api_cards: [], bKeys: [] });
    const id = val;
    this.formRef.current.resetFields();
    this.RefformRef.current.resetFields();
    AUTH_API.getContact(id)
      .then((response) => {
        console.log(
          "get single contact res in patch api",
          response,
          this.state.notes
        );
        this.setState({ notes: response.data.notes, current: response.data });
        if (response.data.call_prep.length > 0) {
          const form_values = response.data.call_prep[0];
          this.formRef.current.setFieldsValue({
            ...form_values,
          });
          this.setState({ Api_cards: Object.keys(form_values) });
        }
        if (response.data.reflections.length > 0) {
          const refForm_values = response.data.reflections[0];
          this.RefformRef.current.setFieldsValue({
            ...refForm_values,
          });
          this.setState({ bKeys: Object.keys(refForm_values) });
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  OnFormChange = (change_values, all_values) => {
    console.log("changed values:", change_values, "all_values:", all_values);
    const id = this.state.current.id;
    console.log("selected contact id is:", id);
    const data = {
      call_prep: [all_values],
    };
    AUTH_API.patchContact(data, id, "CN")
      .then((response) => {
        console.log("patch res", response);
        message.success("Your work has been saved")
        this.setState({
          Api_cards: Object.keys(response.data.call_prep[0]),
          loading: false,
        });
      })
      .catch((err) => {
        console.log("not patched", err);
      });
  };

  RefFormchange = (change_values, all_values) => {
    console.log("changed values:", change_values, "all_values:", all_values);
    const id = this.state.current.id;
    console.log("selected contact id is:", id);
    const data = {
      reflections: [all_values],
    };
    AUTH_API.patchContact(data, id, "CN")
      .then((response) => {
        console.log("patch res", response);
        message.success("Your work has been saved")
        this.setState({ bKeys: Object.keys(response.data.reflections[0]) });
      })
      .catch((err) => {
        console.log("not patched", err);
      });
  };

  updateAndSaveNotes = (e) => {
    // alert("update: " + JSON.stringify(e));
    this.setState({ notes: e.target.value })
    this.patchNotes(e);  
  }

  patchNotes = HELPERS.debounce((e) => {
    const id = this.state.current.id;
    const data = {
      notes: this.state.notes,
    };
    AUTH_API.patchContact(data, id, "CN")
      .then((response) => {
        console.log("patch res", response);
        message.success("Your work has been saved")
      })
      .catch((err) => {
        console.log("not patched", err);
      });
  }, 1000);

  hide_res = () => {
    this.setState({ hide: !this.state.hide });
  };

  onKeyPressed = () => {
    HELPERS.resetPing();
  }

  addNew = (index) => {
    const temp = this.state.additional_q;
    console.log("index is:", index);
    temp[index - 1].push("1");
    this.setState({ additional_q: temp });
  };
  onSubmit = () => {
    this.setState({ ...this.state, submitted: true });
  };

  render() {
    console.log("state", this.state);
    return (
      <>
        {!this.state.loading ? (
          <div>
            <p className="email-head">
              Track notes from your networking calls to refer back to later. Be
              honest and authentic to get your questions answered.
            </p>
            <div style={{ display: "flex", margin: "30px 0" }}>
              {this.state.loading === false ? (
                <Select
                  className="select-caller"
                  listHeight={120}
                  onChange={this.OnContactChange}
                  defaultValue={this.state.current.id}
                >
                  {this.state.contacts.map((item, index) => (
                    <Option value={item.id}>{item.name}</Option>
                  ))}
                </Select>
              ) : (
                ""
              )}
              {console.log(
                "roles and ind",
                this.state.current.roles,
                this.state.current.industries
              )}
              {this.state.current.roles && this.state.current.roles.length ? (
                <div className="caller-tags-div">
                  <div className="tag-head">ROLE</div>
                  <div style={{ display: "flex" }}>
                    {this.state.loading === false ? (
                      this.state.current.roles ? (
                        <div className="role-tag" style={{ maxWidth: "100%" }}>
                          {this.state.current.roles}
                        </div>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.current.industries &&
              this.state.current.industries.length ? (
                <div className="caller-tags-div">
                  <div className="tag-head">INDUSTRY</div>
                  <div style={{ display: "flex" }}>
                    {this.state.loading === false ? (
                      this.state.current.industries ? (
                        <div className="ind-tag" style={{ maxWidth: "100%" }}>
                          {this.state.current.industries}
                        </div>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <Row>
              <Form
                name="call-prp-form"
                ref={this.formRef}
                onValuesChange={HELPERS.debounceFormChanges(this.OnFormChange)}
                style={{ display: "flex", width: "100%" }}
              >
                <Col span={this.state.hide ? "4" : "8"}>
                  <button
                    className="hide-research"
                    style={{ display: this.state.hide ? "none" : "block" }}
                    onClick={() => this.hide_res()}
                  >
                    <span style={{ paddingRight: "20px", fontSize: "24px" }}>
                      &#8250;
                    </span>
                    Hide research
                  </button>
                  <button
                    className="hide-research"
                    style={{ display: this.state.hide ? "block" : "none" }}
                    onClick={() => this.hide_res()}
                  >
                    <span style={{ paddingRight: "20px", fontSize: "24px" }}>
                      &#8249;
                    </span>
                    Show research
                  </button>
                  <div style={{ display: this.state.hide ? "none" : "block" }}>
                    <Card.Grid
                      bordered={false}
                      hoverable={false}
                      className={
                        this.state.Api_cards.includes("background-notes")
                          ? "notes-card-focus-within"
                          : "notes-card"
                      }
                    >
                      <p className="notes-card-heading">
                        What do you know about their{" "}
                        <strong>background </strong>?
                      </p>
                      <p className="notes-card-subhead">
                        Key info about their role, company, industry and path.
                      </p>
                      <Form.Item name="background-notes" style={{ margin: 0 }}>
                        <TextArea
                          autoSize={{ minRows: 6, maxRows: 60 }}
                          style={{ padding: "0" }}
                          bordered={false}
                          rows={7}
                        ></TextArea>
                      </Form.Item>
                    </Card.Grid>
                    <Card.Grid
                      bordered={false}
                      hoverable={false}
                      className={
                        this.state.Api_cards.includes("company-notes")
                          ? "notes-card-focus-within"
                          : "notes-card"
                      }
                    >
                      <p className="notes-card-heading">
                        What do you know about their <strong>company </strong>?
                      </p>
                      <p className="notes-card-subhead">
                        Read and review their full website. Key info about
                        company overview/org structure, target department,
                        target roles, the culture, etc.
                      </p>
                      <Form.Item name="company-notes" style={{ margin: 0 }}>
                        <TextArea
                          autoSize={{ minRows: 6, maxRows: 60 }}
                          style={{ padding: "0" }}
                          bordered={false}
                          rows={6}
                        ></TextArea>
                      </Form.Item>
                    </Card.Grid>
                    <Card.Grid
                      bordered={false}
                      hoverable={false}
                      className={
                        this.state.Api_cards.includes("role-notes")
                          ? "notes-card-focus-within"
                          : "notes-card"
                      }
                    >
                      <p className="notes-card-heading">
                        What do you know about their <strong>role </strong>?
                      </p>
                      <Form.Item name="role-notes" style={{ margin: 0 }}>
                        <TextArea
                          autoSize={{ minRows: 6, maxRows: 60 }}
                          style={{ padding: "0" }}
                          bordered={false}
                          rows={8}
                        ></TextArea>
                      </Form.Item>
                    </Card.Grid>
                    <Card.Grid
                      bordered={false}
                      hoverable={false}
                      className={
                        this.state.Api_cards.includes("industry-notes")
                          ? "notes-card-focus-within"
                          : "notes-card"
                      }
                    >
                      <p className="notes-card-heading">
                        What do you know about their <strong>industry </strong>?
                      </p>
                      <Form.Item name="industry-notes" style={{ margin: 0 }}>
                        <TextArea
                          autoSize={{ minRows: 6, maxRows: 60 }}
                          style={{ padding: "0" }}
                          bordered={false}
                          rows={8}
                        ></TextArea>
                      </Form.Item>
                    </Card.Grid>
                    <Card.Grid
                      bordered={false}
                      hoverable={false}
                      className={
                        this.state.Api_cards.includes("recruiting-notes")
                          ? "notes-card-focus-within"
                          : "notes-card"
                      }
                    >
                      <p className="notes-card-heading">
                        What do you know about their{" "}
                        <strong>recruiting process </strong>?
                      </p>
                      <Form.Item name="recruiting-notes" style={{ margin: 0 }}>
                        <TextArea
                          autoSize={{ minRows: 6, maxRows: 60 }}
                          style={{ padding: "0" }}
                          bordered={false}
                          rows={8}
                        ></TextArea>
                      </Form.Item>
                    </Card.Grid>
                    <Card.Grid
                      bordered={false}
                      hoverable={false}
                      className={
                        this.state.Api_cards.includes("opportunity-notes")
                          ? "notes-card-focus-within"
                          : "notes-card"
                      }
                    >
                      <p className="notes-card-heading">
                        What do you know about{" "}
                        <strong>learning/training opportunity </strong> for this
                        role ?
                      </p>
                      <Form.Item name="opportunity-notes" style={{ margin: 0 }}>
                        <TextArea
                          autoSize={{ minRows: 6, maxRows: 60 }}
                          style={{ padding: "0" }}
                          bordered={false}
                          rows={8}
                        ></TextArea>
                      </Form.Item>
                    </Card.Grid>
                  </div>
                </Col>
                <Col span={8}>
                  <h3 className="notes-head">
                    What questions you want to ask in the call:
                  </h3>
                  <div className="ques_box">
                    <h4 className="q-box-head">
                      Background
                      {/* <Popover placement="bottom"  content="Add a new question" trigger="hover">
                                        <PlusCircleFilled className='add_q_btn'
                                            onClick={()=>this.addNew(1)}/>
                                    </Popover> */}
                    </h4>
                    <div className="scrollable-q">
                      {/* <div className='ques-checkbox-div'>
                                        <input className='ques-checkbox' type='checkbox'></input><span className="checkmark"></span>
                                        <Input suffix={<EditOutlined className='ques-editable' />} className='ques-input-edit'
                                            bordered={false} 
                                        />
                                    </div>
                                    {this.state.additional_q[0].map((item, index)=>{
                                    return( <Editableq key={index} />)
                                    })} */}
                      <Editableq
                        q_number="back-call"
                        obj={this.state.Api_cards}
                      />
                    </div>
                  </div>
                  <div className="ques_box">
                    <h4 className="q-box-head">Company</h4>
                    <div className="scrollable-q">
                      <Editableq q_number="company-call" />
                    </div>
                  </div>
                  <div className="ques_box">
                    <h4 className="q-box-head">Role</h4>
                    <div className="scrollable-q">
                      <Editableq q_number="role-call" />
                    </div>
                  </div>
                  <div className="ques_box">
                    <h4 className="q-box-head">Industry</h4>
                    <div className="scrollable-q">
                      <Editableq q_number="ind-call" />
                    </div>
                  </div>
                  <div className="ques_box">
                    <h4 className="q-box-head">Recruiting Preocess</h4>
                    <div className="scrollable-q">
                      <Editableq q_number="re-call" />
                    </div>
                  </div>
                  <div className="ques_box">
                    <h4 className="q-box-head">Learning Opportunities</h4>
                    <div className="scrollable-q">
                      <Editableq q_number="lo-call" />
                    </div>
                  </div>
                </Col>
                <Col span={this.state.hide ? "12" : "8"}>
                  <h3 className="notes-head">Place for your notes:</h3>
                  <div className="notes_div">
                    <TextArea
                      autoSize={{ minRows: 91, maxRows: 1000 }}
                      onBlur={this.patchNotes}
                      onChange={this.updateAndSaveNotes}
                      value={this.state.notes}
                      style={{ padding: "20px", height: "1945px" }}
                      bordered={false}
                      rows={30}
                      onKeyDown={this.onKeyPressed}
                    ></TextArea>
                  </div>
                </Col>
              </Form>
            </Row>
            <Row>
              <h3 className="ref_head">My reflections about this call</h3>
              <Form
                style={{ width: "100%" }}
                ref={this.RefformRef}
                name="reflectionForm"
                onValuesChange={HELPERS.debounceFormChanges(this.RefFormchange)}
              >
                <Card bordered={false}>
                  <Card.Grid
                    bordered={false}
                    hoverable={false}
                    className={
                      this.state.bKeys.includes("1")
                        ? "research-card-low-focus-within"
                        : "research-card-low"
                    }
                  >
                    <p className="card-heading-low">What did I like?</p>
                    <Form.Item style={{ margin: "0" }} name={1}>
                      <TextArea
                        autoSize={{ minRows: 6, maxRows: 60 }}
                        className="text-underline"
                        bordered={false}
                        autoSize={{ minRows: 1, maxRows: 13 }}
                        onChange={() => {
                          console.log("changed");
                        }}
                      ></TextArea>
                    </Form.Item>
                  </Card.Grid>

                  <Card.Grid
                    bordered={false}
                    hoverable={false}
                    className={
                      this.state.bKeys.includes("2")
                        ? "research-card-low-focus-within"
                        : "research-card-low"
                    }
                  >
                    <p className="card-heading-low">What did I dislike?</p>
                    <Form.Item style={{ margin: "0" }} name={2}>
                      <TextArea
                        autoSize={{ minRows: 6, maxRows: 60 }}
                        className="text-underline"
                        bordered={false}
                        autoSize={{ minRows: 1, maxRows: 13 }}
                      ></TextArea>
                    </Form.Item>
                  </Card.Grid>

                  <Card.Grid
                    bordered={false}
                    hoverable={false}
                    className="half-card-container"
                  >
                    <Card
                      bordered={false}
                      className="cn-low-card research-card-low half-card-low"
                      style={{ padding: "24px" }}
                    >
                      <p className="card-heading-low">
                        My current level of interest in this role:
                      </p>
                      <Form.Item
                        style={{ margin: "0" }}
                        name="interest_level_role"
                      >
                        <Slider
                          max={10}
                          tooltipVisible
                          style={{ marginTop: "45px" }}
                        />
                      </Form.Item>

                      <Row justify="space-between">
                        <Col span={12}>
                          <p
                            style={{
                              textAlign: "left",
                              color: "#898E8E",
                              font: "normal normal bold 12px Montserrat",
                              marginTop: "-5px",
                            }}
                          >
                            No interest
                          </p>
                        </Col>
                        <Col span={12}>
                          <p
                            style={{
                              textAlign: "right",
                              color: "#898E8E",
                              font: "normal normal bold 12px Montserrat",
                              marginTop: "-5px",
                            }}
                          >
                            Very high interest
                          </p>
                        </Col>
                      </Row>
                    </Card>

                    <Card
                      bordered={false}
                      className="cn-low-card research-card-low half-card-low "
                      style={{ marginTop: "24px", padding: "24px" }}
                    >
                      <p className="card-heading-low">
                        My current level of interest in this industry:
                      </p>
                      <Form.Item
                        style={{ margin: "0" }}
                        name="interest_level_ind"
                      >
                        <Slider
                          max={10}
                          tooltipVisible
                          style={{ marginTop: "45px" }}
                        />
                      </Form.Item>

                      <Row justify="space-between">
                        <Col span={12}>
                          <p
                            style={{
                              textAlign: "left",
                              color: "#898E8E",
                              font: "normal normal bold 12px Montserrat",
                              marginTop: "-5px",
                            }}
                          >
                            No interest
                          </p>
                        </Col>
                        <Col span={12}>
                          <p
                            style={{
                              textAlign: "right",
                              color: "#898E8E",
                              font: "normal normal bold 12px Montserrat",
                              marginTop: "-5px",
                            }}
                          >
                            Very high interest
                          </p>
                        </Col>
                      </Row>
                    </Card>
                  </Card.Grid>
                </Card>
              </Form>
            </Row>
          </div>
        ) : (
          <div
            style={{
              margin: 30,
              textAlign: "center",
              font: "300 20px / 12px Montserrat",
              opacity: "0.6",
            }}
          >
            Add some contacts first!
          </div>
        )}
      </>
    );
  }
}
