import React from "react";
import {Row, Col, Input, Button, Form, message} from "antd";
import "./email.css";
import AUTH_API from "./../../../api/auth";
import AddEmail from "./AddEmail";
import HELPERS from "../../../utils/helper";

const { TextArea } = Input;

export default class Email extends React.Component {
  IntroForm = React.createRef();
  CallForm = React.createRef();
  LkForm = React.createRef();

  state = {
    card_id: 99,
    e_card: [],
    submitted: false,
    Api_response: [],
    intro_obj: "",
    call_obj: "",
    lk_obj: "",
  };
  componentDidMount() {
    AUTH_API.getAllEmailDrafts()
      .then((response) => {
        console.log(response);
        const intro_obj = response.data.filter(
          (item) => item.title === "intro"
        )[0];
        const call_obj = response.data.filter(
          (item) => item.title === "phone call"
        )[0];
        const lk_obj = response.data.filter(
          (item) => item.title === "linkedIn"
        )[0];
        const e_card = response.data.filter(
          (item) =>
            item.id !== intro_obj.id &&
            item.id !== call_obj.id &&
            item.id !== lk_obj.id
        );
        console.log("ids:", intro_obj, call_obj, lk_obj, e_card);
        this.setState({
          Api_response: [...response.data],
          intro_obj: intro_obj,
          call_obj: call_obj,
          lk_obj: lk_obj,
          e_card: e_card,
        });
        this.IntroForm.current.setFieldsValue({
          ...intro_obj,
        });
        this.CallForm.current.setFieldsValue({
          ...call_obj,
        });
        this.LkForm.current.setFieldsValue({
          ...lk_obj,
        });
        console.log(this.state);
      })
      .catch((err) => console.log("email drafts get Api error!", err));
  }
  onFormChange = HELPERS.debounceFormChanges((changed_values, all_values, id) => {
    console.log(
      "changed_values:",
      changed_values,
      "all values:",
      all_values,
      "id is:",
      id
    );
    const data = all_values;
    AUTH_API.patchEmailDrafts(data, id)
      .then((response) => {
        console.log("patch Api data", response);
        message.success('Your work has been saved')
      })
      .catch((err) => console.log("email drafts patch Api error!", err));
  });

  addNew = () => {
    // const temp = this.state.e_card;
    // temp.push({
    //     id:this.state.card_id,
    //     title:'Enter Email Scenario Title Here',
    // });
    // this.setState({e_card: temp,card_id:this.state.card_id + 1});
    const data = {
      title: "Enter Email Scenario Title Here ",
      description: "Describe the scenario for this email template",
    };
    AUTH_API.postEmailDraft(data)
      .then((response) => {
        console.log("post Api data", response);
        const newcards = this.state.e_card;
        newcards.push(response.data);
        console.log("new cards after add:", newcards);
        this.setState({ e_card: newcards, card_id: this.state.card_id + 1 });
      })
      .catch((error) => console.log("post API error", error));
  };
  onSubmit = () => {
    this.props.onSubmit();
    this.setState({ ...this.state, submitted: true });
  };
  deleteDraft = (id) => {
    console.log("index is:", id);
    const newcards = this.state.e_card.filter((item) => item.id !== id);
    console.log("new cards:", newcards);
    this.setState({ e_card: newcards });
    AUTH_API.deleteEmailDraft(id)
      .then((response) => {
        console.log("draft deleted", response);
      })
      .catch((err) => console.log("delete API error!", err));
    console.log(this.state.e_card);
  };



  render() {
    console.log("email rendered", this.state.e_card);
    return (
      <div>
        <p className="email-head">Draft emails based on scenarios below. </p>
        <p className="email-subhead">
          Be specific in what you are hoping to learn more about, ask for phone
          calls or introductions, don't mention the phrase "job search" and stay
          brief!
        </p>

        <Form
          name="intro"
          ref={this.IntroForm}
          onValuesChange={HELPERS.debounceFormChanges(( c, a) =>
            this.onFormChange(c, a, this.state.intro_obj.id))
          }
        >
          <h3 className="e-card-head">INTRODUCTION TO CONNECTIONS</h3>
          <p className="e-card-subhead">
            Ask your contact for introduction(s) to people in your target
            roles/industries
          </p>

          <div className="e-card">
            <Row className="e-row">
              <Col span={2}>
                {" "}
                <p className="to">To :</p>
              </Col>
              <Col span={22}>
                <Form.Item name="email_to">
                  <Input className="person" bordered={false} />
                </Form.Item>
              </Col>
            </Row>
            <Row className="e-row">
              <Col span={2}>
                {" "}
                <p className="to">Subject</p>
              </Col>
              <Col span={22}>
                <Form.Item name="subject">
                  <Input className="person" bordered={false} />
                </Form.Item>
              </Col>
            </Row>
            <div className="e-draft">
              <Form.Item name="email_text">
                <TextArea className="intro-draft" rows={9} bordered={false} />
              </Form.Item>
            </div>
          </div>
        </Form>

        <Form
          name="callForm"
          ref={this.CallForm}
          onValuesChange={(c, a) =>
            this.onFormChange(c, a, this.state.call_obj.id)
          }
        >
          <h3 className="e-card-head">SET UP A PHONE CALL</h3>
          <p className="e-card-subhead">
            Ask your contact to set up a phone call to learn more about their
            role, company and/or industry
          </p>

          <div className="e-card">
            <Row className="e-row">
              <Col span={2}>
                {" "}
                <p className="to">To :</p>
              </Col>
              <Col span={22}>
                <Form.Item name="email_to">
                  <Input className="person" bordered={false} />
                </Form.Item>
              </Col>
            </Row>
            <Row className="e-row">
              <Col span={2}>
                {" "}
                <p className="to">Subject</p>
              </Col>
              <Col span={22}>
                <Form.Item name="subject">
                  <Input className="sub-c" bordered={false} />
                </Form.Item>
              </Col>
            </Row>
            <div className="e-draft">
              <Form.Item name="email_text">
                <TextArea className="intro-draft" rows={9} bordered={false} />
              </Form.Item>
            </div>
          </div>
        </Form>

        <Form
          name="lkForm"
          ref={this.LkForm}
          onValuesChange={(c, a) =>
            this.onFormChange(c, a, this.state.lk_obj.id)
          }
        >
          <h3 className="e-card-head">LINKEDIN INMAIL DRAFT</h3>
          <p className="e-card-subhead">
            Ask your contact to set up a phone call to learn more about their
            career path
          </p>

          <div className="e-card">
            <Row className="e-row">
              <Col span={2}>
                {" "}
                <p className="to">To :</p>
              </Col>
              <Col span={22}>
                <Form.Item name="email_to">
                  <Input className="person" bordered={false} />
                </Form.Item>
              </Col>
            </Row>
            <Row className="e-row">
              <Col span={2}>
                <p className="to">Subject</p>
              </Col>
              <Col span={22}>
                <Form.Item name="subject">
                  <Input className="sub-c" bordered={false} />
                </Form.Item>
              </Col>
            </Row>
            <div className="e-draft">
              <Form.Item name="email_text">
                <TextArea className="intro-draft" rows={4} bordered={false} />
              </Form.Item>
            </div>
          </div>

        {this.state.e_card.map((item, index) => {
          return (
            <AddEmail
              key={item.id}
              deleteDraft={(id) => this.deleteDraft(id)}
              obj={item}
            />
          );
        })}
        </Form>

        <div
          style={{ textAlign: "left" }}
          onClick={() => {
            this.addNew();
          }}
        >
          <p style={{ color: "#32AAB0", cursor: "pointer" }}>
            &#43; &nbsp; Add a New Email Draft
          </p>
        </div>
        <div style={{ textAlign: "right", margin: "80px 0" }}>
          <Button
            size="large"
            type="primary"
            onClick={() => {
              this.onSubmit();
            }}
            block
            style={{ width: "25%" }}
          >
            Submit to Coach
          </Button>
        </div>
      </div>
    );
  }
}
