import React from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Input, Popconfirm } from "antd";
import "./Researchroles.css";
import Testcards from "./Testcards";
import { updateQuickReflection } from "slices/dashboardSlice";
import {
  selectTargetRoles,
  addRoleAsync,
  deleteRoleAsync,
} from "slices/targetRolesSlice";
import HELPERS from "utils/helper";

class ResearchRolesSteps extends React.Component {
  state = {
    current: 0,
    add_key: 999,
    submitted: false,
    steps: [
      {
        title: "UX DESIGNER",
        key: 9999,
        content: <Testcards key={9999} rolename="UX DESIGNER" />,
        color: "",
      },
    ],
    loading: true,
  };

  yellowstep = (index) => {
    console.log("yellow", index, this.state.steps[index]);
    const newsteps = [...this.state.steps];
    console.log("newsteps:", newsteps);
    const step = { ...newsteps[index] };
    step.color = "#ffca3e";
    newsteps[index] = step;
    // this.setState({steps:newsteps});
    console.log("newsteps", newsteps, "state steps", this.state.steps);
    this.setState({ steps: newsteps });

    // this.setState({...this.state.steps,})
  };

  updateSteps = (prevProps) => {
    if (
      !prevProps ||
      prevProps.roles.filter((role) => role.learn_more === true).length !==
        this.props.roles.filter((role) => role.learn_more === true).length
    ) {
      console.log("tr !!", this.props.roles);
      const req_roles = this.props.roles.filter(
        (item) => item.learn_more === true
      );
      req_roles.sort((a, b) => {
        //sort acc to priority
        return a.priority - b.priority;
      });
      const roles = req_roles.map((item, index) => {
        return {
          title: item.title,
          content: (
            <Testcards
              key={item.id}
              id={item.id}
              yellowstep={() => this.yellowstep(index)}
              rolename={item.title}
            />
          ),
          key: item.id,
          color:
            item.research_questions.length > 0 ||
            item.additional_questions.length > 0 ||
            item.reflections.length > 0
              ? "#ffca3e"
              : "",
        };
      });

      HELPERS.log("sorted role", roles);
      let current = this.state.current;

      while (current > 0 && current >= roles.length) --current;

      this.setState({ steps: roles, loading: false, current: current });
    }
  };

  componentDidUpdate(prevProps) {
    this.updateSteps(prevProps);
  }

  componentDidMount() {
    this.updateSteps();
  }

  next() {
    const current = this.state.current + 1;
    this.setState({ ...this.state, current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ ...this.state, current });
  }

  //adds new step in stepper
  addNewRole = (e) => {
    if (
      e.target.value !== "" &&
      !this.state.steps.some((step) => step.title === e.target.value)
    ) {
      const data = {
        title: e.target.value,
        priority: this.state.steps.length + 1,
        learn_more: true,
        selected_for: [],
        research_questions: [],
        additional_questions: [],
        reflections: [],
      };
      this.props.addRoleAsync(data);
    }
  };

  //take care of title here
  deleteRole = (key) => {
    if (this.state.steps.length > 1) {
      this.props.deleteRoleAsync(key);
    }
  };

  onSubmit = () => {
    this.props.updateQuickReflection({
      stepId: this.props.stepId,
      show: true,
    });
    this.setState({ ...this.state, submitted: true, current: 0 });
  };

  onChange = (current) => {
    // console.log('onChange:', current);
    this.setState({ current });
  };

  render() {
    console.log("rrsteps rendered", this.state.steps);

    if (this.state.steps.length === 0) {
      return null;
    }

    const { current } = this.state;
    var total = 100 / this.state.steps.length;
    return (
      <>
        {!this.state.loading ? (
          <div
            style={
              this.props.fade ? {} : { opacity: "0.4", pointerEvents: "none" }
            }
          >
            {/* stepper after submission */}
            {this.state.submitted ? (
              <Row style={{ padding: "2% 0 3%", overflow: "scroll" }}>
                <Col span={22}>
                  <div className="line-between">&nbsp;</div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {this.state.steps.map((item, index) => {
                      return (
                        <div
                          className="item-div-submit"
                          // style={{ maxWidth: total + "%" }}
                        >
                          {/* {index === 0 ? <input name='vd' checked className='cr' type='radio' id={index} onClick={()=>{this.setState({...this.state, current: index})}} />
                                    : */}
                          <input
                            name="vd"
                            className="cr"
                            type="radio"
                            id={index}
                            onClick={() => {
                              this.setState({ ...this.state, current: index });
                            }}
                            checked={index === this.state.current}
                          />

                          <label for={index} className="cr-label">
                            <span className="cr-span-submit">{index + 1}</span>
                            <div className="span-name"> {item.title}</div>
                          </label>
                          {/* {(index+1) !== this.state.steps.length && <hr className='hr-gap'/>} */}
                        </div>
                      );
                    })}
                  </div>
                </Col>
                <Col span={2}>
                  {console.log("input rendered")}
                  {React.createElement(() => (
                    <Input
                      onPressEnter={this.addNewRole}
                      placeholder="Add new"
                      bordered={false}
                      style={{
                        margin: "0 0 0 0px",
                        padding: "3px 0 0 8px",
                        color: "rgb(147 158 171)",
                      }}
                    />
                  ))}
                </Col>
              </Row>
            ) : (
              // <div style={{padding:'2% 0 6% 0', fontSize:14, display:'flex'}}>
              //             <Steps current={current} style={{width:'87%'}} onChange={this.onChange} >
              //                 {this.state.steps.map(item => (<Step style={{fontSize:12}} key={item.title} title={item.title} />))}
              //             </Steps>
              //         <hr style={{width:'2%', marginLeft:'7px', marginRight:'7px'}}></hr>
              //             <span style={{border:'1px solid #00000042', borderRadius:'30px', width:'30px', margin:'3px 0px 3px 0px'}}></span>

              //             {/* input for adding step */}
              //             <Input onPressEnter={this.addNewRole} placeholder="Type new" bordered={false}
              //             style={{margin:'0 0 0 0px', padding:'0 0 0 8px', color:'rgb(147 158 171)',width:'8%'}}/>
              // </div>
              <Row style={{ padding: "2% 0 3%", overflow: "scroll" }}>
                <Col span={22}>
                  <div className="line-between">&nbsp;</div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {this.state.steps.map((item, index) => {
                      return (
                        <div
                          className="item-div"
                          // style={{ maxWidth: total + "%" }}
                        >
                          {/* {index === 0 ? <input name='vd' checked className='cr' type='radio' id={index} onClick={()=>{this.setState({...this.state, current: index})}} />
                                    : */}
                          <input
                            name="vd"
                            className="cr"
                            type="radio"
                            id={index}
                            onClick={() => {
                              this.setState({ ...this.state, current: index });
                            }}
                            checked={index === this.state.current}
                          />

                          <label for={index} className="cr-label">
                            <span
                              className="cr-span"
                              style={{
                                backgroundColor: this.state.steps[index].color,
                                color: this.state.steps[index].color
                                  ? "white"
                                  : "",
                                border: this.state.steps[index].color
                                  ? "0px"
                                  : "",
                              }}
                            >
                              {index + 1}
                            </span>
                            <div className="span-name"> {item.title}</div>
                          </label>
                          {/* {(index+1) !== this.state.steps.length && <hr className='hr-gap'/>} */}
                        </div>
                      );
                    })}
                  </div>
                </Col>
                <Col span={2}>
                  {React.createElement(() => (
                    <Input
                      onPressEnter={this.addNewRole}
                      placeholder="Add new"
                      bordered={false}
                      style={{
                        margin: "0 0 0 0px",
                        padding: "3px 0 0 8px",
                        color: "rgb(147 158 171)",
                      }}
                    />
                  ))}
                </Col>
              </Row>
            )}

            {/* single role rendered here */}
            <div className="steps-content">
              {this.state.steps[current].content}
            </div>

            {this.state.submitted ? (
              <div className="steps-action">
                <Popconfirm
                  title="Are you sure you want to delete?"
                  onConfirm={() => {
                    this.deleteRole(this.state.steps[current].key);
                  }}
                >
                  <Button
                    size="large"
                    className="del-submitted"
                    style={{ color: "#49CDE1" }}
                    block
                  >
                    Delete Role
                  </Button>
                </Popconfirm>
              </div>
            ) : (
              <div className="steps-action">
                <Row>
                  <Col span={5} style={{ paddingTop: "5%" }}>
                    {current > 0 && (
                      <Button
                        size="large"
                        style={{ margin: "0 8px", color: "#49CDE1" }}
                        onClick={() => this.prev()}
                        block
                      >
                        Previous Role
                      </Button>
                    )}
                    {/* {current === 1 && (
                                <Button size= 'large' style={{ margin: '0 8px'}}  block>
                                    Back
                                </Button>
                            )} */}
                  </Col>
                  <Col span={8}></Col>

                  <Col
                    span={11}
                    style={{ textAlign: "right", padding: "5% 4% 5% 1%" }}
                  >
                    {current < this.state.steps.length - 1 && (
                      <Row gutter={32}>
                        <Col span={12}>
                          <Popconfirm
                            title="Are you sure you want to delete?"
                            onConfirm={() => {
                              this.deleteRole(this.state.steps[current].key);
                            }}
                          >
                            <Button
                              size="large"
                              style={{ margin: "0 8px", color: "#49CDE1" }}
                              block
                            >
                              Delete
                            </Button>
                          </Popconfirm>
                        </Col>

                        <Col span={12}>
                          <Button
                            size="large"
                            type="primary"
                            onClick={() => this.next()}
                            block
                          >
                            Next Role
                          </Button>
                        </Col>
                      </Row>
                    )}
                    {current === this.state.steps.length - 1 && (
                      <Row gutter={16}>
                        <Col span={12}>
                          <Popconfirm
                            title="Are you sure you want to delete?"
                            onConfirm={() => {
                              this.deleteRole(this.state.steps[current].key);
                            }}
                          >
                            <Button
                              size="large"
                              style={{ margin: "0 8px", color: "#49CDE1" }}
                              block
                            >
                              Delete
                            </Button>
                          </Popconfirm>
                        </Col>

                        <Col span={12}>
                          <Button
                            size="large"
                            type="primary"
                            onClick={() => {
                              this.onSubmit();
                            }}
                            block
                          >
                            Submit All Roles
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  roles: selectTargetRoles(state),
});

const mapDispatchToProps = () => ({
  addRoleAsync,
  deleteRoleAsync,
  updateQuickReflection,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(ResearchRolesSteps);
