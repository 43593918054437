import { DeleteTwoTone } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Slider,
} from "antd";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";

import {
  addIndustryAsync,
  deleteIndustryAsync,
  selectTargetIndustries,
  updateIndustryAsync,
} from "slices/targetIndustriesSlice";
import {
  addRoleAsync,
  deleteRoleAsync,
  selectTargetRoles,
  updateRoleAsync,
} from "slices/targetRolesSlice";
import HELPERS from "utils/helper";
import AUTH_API from "./../../../api/auth";
import SelectInterest from "../../Common/Selectinterest";
const { TextArea } = Input;

const Template = (props) => (
  <div className="">
    <Row align="middle" style={{ marginBottom: 15 }}>
      <Col span={6}>
        <Input
          className="input-edit"
          onPressEnter={(e) => props.onChangeText(e, props.index)}
          onBlur={(e) => props.onChangeText(e, props.index)}
          style={{ margin: 0, paddingTop: 2 }}
          bordered={false}
          defaultValue={props.title}
          title={props.title}
        ></Input>
      </Col>
      <Col span={2}>
        {/* <Form.Item
          noStyle
          initialValue={1}
          name={["reflections", props.type, `${props.id}`, "interest_level"]}
        > */}
        <SelectInterest
          type={props.type}
          id={props.id}
          selectedValue={props.interestLevel ? props.interestLevel : 1}
          key={`select-${props.type}-${props.id}-${props.title}`}
          name={["reflections", props.type, `${props.id}`, "interest_level"]}
          initialValue={1}
        />
        {/* </Form.Item> */}
      </Col>
      <Col span={1}></Col>
      <Col span={14}>
        <Form.Item
          name={["reflections", props.type, `${props.id}`, "reflection"]}
          initialValue=""
          noStyle
        >
          <TextArea style={{ height: 51, fontSize: 14 }} />
        </Form.Item>
      </Col>
      <Col span={1}>
        <Popconfirm
          title={`Are you sure you want to delete this ${props.type} from your target ${props.type} list?`}
          onConfirm={() => {
            props.toggleDelete(props.type, props.id);
          }}
        >
          <DeleteTwoTone
            style={{
              fontSize: 18,
              cursor: "pointer",
              marginLeft: "auto",
              marginRight: "auto",
              width: "100%",
            }}
          />
        </Popconfirm>
      </Col>
    </Row>
  </div>
);

class ReflectionAboutRoles extends Component {
  formRef = React.createRef();

  syncForm = () => {
    console.log(
      this.formRef.current.getFieldValue("stress_level"),
      this.props.data.stress_level
    );
    if (
      this.formRef.current
      // && this.formRef.current.getFieldValue("stress_level") !==
      //   this.props.data.stress_level
    ) {
      HELPERS.log("We need change, about roles", this.props.data);
      this.formRef.current.setFieldsValue(this.props.data);
    }
  };

  componentDidMount = () => {
    console.log("in didmnt roles");
    this.syncForm();
  };

  componentDidUpdate = () => {
    HELPERS.log("We got update", this.props.data);
    this.syncForm();
  };

  getRoles(all) {
    return this.props.roles
      ? this.props.roles
          .filter((item) => all === true || item.learn_more === true)
          .sort((a, b) => a.priority - b.priority)
      : [];
  }

  addNewRole = () => {
    const data = {
      title: `Enter New Role`,
      priority: this.getRoles(true).length + 1,
      learn_more: true,
      selected_for: [],
      research_questions: [],
      additional_questions: [],
      reflections: [],
    };
    this.props.addRoleAsync(data);
  };

  getIndustries = (all) => {
    return this.props.industries
      ? this.props.industries
          .filter((item) => all === true || item.learn_more === true)
          .sort((a, b) => a.priority - b.priority)
      : [];
  };

  addNewIndustry = () => {
    const data = {
      title: `Enter New Industry`,
      priority: this.getIndustries(true).length + 1,
      learn_more: true,
      selected_for: [],
      research_questions: [],
      additional_questions: [],
      reflections: [],
    };
    const formdata = HELPERS.converToFormData(data);
    this.props.addIndustryAsync(formdata);
  };

  toggleDelete = (type, id) => {
    if (type === "role") {
      this.props.deleteRoleAsync(id);
    } else {
      this.props.deleteIndustryAsync(id);
    }
  };

  getRoleInterestLevel = (roleId) =>
    this.props.data &&
    this.props.data.reflections.role &&
    this.props.data.reflections.role[roleId]
      ? this.props.data.reflections.role[roleId].interest_level
      : 1;

  getIndustryInterestLevel = (industryId) =>
    this.props.data &&
    this.props.data.reflections.industry &&
    this.props.data.reflections.industry[industryId]
      ? this.props.data.reflections.industry[industryId].interest_level
      : 1;

  updateRoleTitle = (e, index) => {
    HELPERS.log(e.target.value, index, this.getRoles()[index].title);
    if (this.getRoles()[index].title !== e.target.value) {
      this.props.updateRoleAsync(this.getRoles()[index].id, {
        title: e.target.value,
      });
    }
  };

  updateIndustryTitle = (e, index) => {
    HELPERS.log(e.target.value, index, this.getIndustries()[index].title);
    if (this.getIndustries()[index].title !== e.target.value) {
      this.props.updateIndustryAsync(this.getIndustries()[index].id, {
        title: e.target.value,
      });
      message.success('Your work has been saved');
    }
  };

  roles = () => {
    return this.getRoles().map((role, index) => {
      return (
        <Template
          type="role"
          id={role.id + role.title}
          title={role.title}
          interestLevel={this.getRoleInterestLevel(role.id)}
          onChangeText={this.updateRoleTitle}
          index={index}
          toggleDelete={this.toggleDelete}
          key={`reflection-role-${index}-${role.title}-${role.id}`}
        />
      );
    });
  };

  industries = () => {
    return this.getIndustries().map((industry, index) => {
      return (
        <Template
          type="industry"
          id={industry.id + industry.title}
          title={industry.title}
          interestLevel={this.getIndustryInterestLevel(industry.id)}
          onChangeText={this.updateIndustryTitle}
          index={index}
          toggleDelete={this.toggleDelete}
          key={`reflection-industry-${index}-${industry.title}-${industry.id}`}
        />
      );
    });
  };

  onFinish = (values) => {
    HELPERS.log("on submit roles reflections", values);
    this.props.onSubmit(this.props.reflectionId, values);
  };

  isOverdue = () => {
    // console.log(this.props.data);
    const releasedOn = moment(this.props.data.released_on).add(7, "days");
    console.log("released on", releasedOn);
    return moment(new Date()).isAfter(releasedOn);
  };

  OnFormChange = (change_values, all_values) => {
    // if (!this.props.data.completed) {
      const currentStep = HELPERS.getCurrentStep();
      const id = all_values.id;

      AUTH_API.patchReflectionsProcess(all_values, id, currentStep)
        .then((response) => {
          console.log(response.data);
          message.success("Your work has been saved successfully");
        })
        .catch((error) => {
          message.error(
            "We could not save your work due to an error. Kindly be patient as we work to resolve it. "
          );
          console.log("Reflections About Process NOT Patched", error);
        });
    // }
  };

  render() {
    return (
      <Form
        ref={this.formRef}
        name="weekly-reflection-for-roles-and-industries"
        onFinish={this.onFinish}
        onValuesChange={HELPERS.debounceFormChanges(this.OnFormChange)}
      >
        <p className="contacts-head">
          Take a moment to pause, reflect, and re-prioritize the roles and
          industries according to your current level of interest.
        </p>
        <Row>
          <Col>
            <img
              style={{ marginLeft: -20 }}
              alt=""
              src={
                "https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/noun_think_2157497.ad0d2dc4.svg"
              }
              width="100"
              height="50"
            />
          </Col>
          <p
            style={{
              fontSize: 20,
              marginLeft: -5,
              marginTop: 25,
              font: "normal normal bold 20px/12px Montserrat",
              color: "#6A7ACB",
              textAlign: "left",
            }}
          >
            Reflection about Roles
          </p>
        </Row>
        <Row>
          <p
            style={{
              color: "#898E8E",
              font: "normal normal bold 14px/44px Montserrat",
            }}
          >
            My level of interest in the role:
          </p>
          <p
            style={{
              color: "#898E8E",
              font: "normal normal bold 14px/44px Montserrat",
              marginLeft: 240,
            }}
          >
            List any open, unanswered questions you have about each role.
          </p>
        </Row>
        {this.roles()}

        <Button
          style={{
            borderRadius: 4,
            background: "#F9FAFC 0% 0% no-repeat padding-box",
            width: 266,
            height: 52,
            fontSize: 17,
          }}
          type="dashed"
          onClick={this.addNewRole}
        >
          + Add a New Role
        </Button>
        <Row style={{ marginTop: 100 }}>
          <Col>
            <img
              style={{ marginLeft: -20 }}
              alt=""
              src={
                "https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/noun_think_2157497.ad0d2dc4.svg"
              }
              width="100"
              height="50"
            />
          </Col>
          <p
            style={{
              fontSize: 20,
              marginLeft: -5,
              marginTop: 25,
              font: "normal normal bold 20px/12px Montserrat",
              color: "#6A7ACB",
              textAlign: "left",
            }}
          >
            Reflection about industries
          </p>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <p
            style={{
              color: "#898E8E",
              font: "normal normal bold 14px/44px Montserrat",
            }}
          >
            My level of interest in the industry:
          </p>
          <p
            style={{
              color: "#898E8E",
              font: "normal normal bold 14px/44px Montserrat",
              marginLeft: 240,
            }}
          >
            List any open, unanswered questions you have about each industry.
          </p>
        </Row>
        {this.industries()}
        <Button
          style={{
            borderRadius: 4,
            background: "#F9FAFC 0% 0% no-repeat padding-box",
            width: 266,
            height: 52,
            fontSize: 17,
          }}
          type="dashed"
          onClick={this.addNewIndustry}
        >
          + Add a New industry
        </Button>
        <p
          style={{
            marginTop: 100,
            textAlign: "left",
            font: "normal normal bold 20px/12px Montserrat",
          }}
        >
          {" "}
          Additional Reflections
        </p>
        <div className="">
          <p
            style={{
              marginTop: 40,
              textAlign: "left",
              font: "normal normal normal 16px/44px Montserrat",
              color: "#414141",
            }}
          >
            How stressed are you about figuring out your next career step?
          </p>
          <Form.Item name="stress_level" noStyle initialValue={0}>
            <Slider style={{ width: "50%" }} min={0} max={10} />
          </Form.Item>
          <Row style={{ width: "50%" }}>
            <Col span={12}>
              <p style={{ textAlign: "left", color: "#898E8E" }}>
                I’m very relaxed
              </p>
            </Col>
            <Col span={12}>
              <p style={{ textAlign: "right", color: "#898E8E" }}>
                I’m feeling extremely stressed
              </p>
            </Col>
          </Row>
        </div>
        <div className="">
          <p
            style={{
              marginTop: 40,
              textAlign: "left",
              font: "normal normal normal 16px/44px Montserrat",
              color: "#414141",
            }}
          >
            What is your level of clarity on your ideal career path?
          </p>
          <Form.Item name="clarity_level" noStyle initialValue={0}>
            <Slider style={{ width: "50%" }} min={0} max={10} />
          </Form.Item>
          <Row style={{ width: "50%" }}>
            <Col span={12}>
              <p style={{ textAlign: "left", color: "#898E8E" }}>
                I have no idea what I want
              </p>
            </Col>
            <Col span={12}>
              <p style={{ textAlign: "right", color: "#898E8E" }}>
                I know exactly what I want
              </p>
            </Col>
          </Row>
        </div>
        <div className="">
          <p
            style={{
              marginTop: 40,
              textAlign: "left",
              font: "normal normal normal 16px/44px Montserrat",
              color: "#414141",
            }}
          >
            How confident are you in how to approach the career exploration
            process?
          </p>
          <Form.Item name="confidence_level" noStyle initialValue={0}>
            <Slider style={{ width: "50%" }} min={0} max={10} />
          </Form.Item>
          <Row style={{ width: "50%" }}>
            <Col span={12}>
              <p style={{ textAlign: "left", color: "#898E8E" }}>
                I have no clue what to do
              </p>
            </Col>
            <Col span={12}>
              <p style={{ textAlign: "right", color: "#898E8E" }}>
                I know exactly what to do
              </p>
            </Col>
          </Row>
        </div>
        {console.log(
          "is overdue",
          this.props.data.completed,
          this.isOverdue(),
          moment(this.props.data.released_on).add(7, "days"),
          new Date()
        )}
        <Button
          style={{
            width: 255,
            height: 42,
            marginTop: 40,
            marginLeft: "80%",
            background: "#49CDE1 0% 0% no-repeat padding-box",
            font: "normal normal bold 16px/44px Montserrat",
            paddingTop: 0,
          }}
          type="primary"
          htmlType="submit"
          // disabled={this.props.data.completed || this.isOverdue()}
          disabled={this.props.data.completed}
        >
          Submit to Coach
        </Button>
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  roles: selectTargetRoles(state),
  industries: selectTargetIndustries(state),
});

const mapDispatchToProps = () => ({
  addRoleAsync,
  addIndustryAsync,
  deleteRoleAsync,
  deleteIndustryAsync,
  updateRoleAsync,
  updateIndustryAsync,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(ReflectionAboutRoles);
