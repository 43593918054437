import {Button, Col, Form, Input, Modal, Row, Slider, Radio, message} from "antd";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector,} from "react-redux";
import AUTH_API from "../../api/auth";


const {TextArea} = Input;

export default function Success(props) {
    const formRef = React.createRef();
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false)

    useEffect(()=> {
        setVisible(props.visible)
    }, [props.visible])



    const handleSubmit = (data) => {
        AUTH_API.postFeedback(data).then(()=>
            message.success('Thank you for submitting your feedback.')
        )
    };
    const handleClose = () => {
        setVisible(false);
    }
    return (
        <Modal
            title=""
            visible={visible}
            onOk={props.handleOk}
            onCancel={() => handleClose()}
            footer={null}
            width="75%"
            style={{textAlign: "center", transformOrigin: "top ", top: 20}}
            closable={true}
            maskClosable={false}
            destroyOnClose={true}
        >
            <div style={{}}>
                <div
                    style={{
                        background: "#49D0BE 0% 0% no-repeat padding-box",
                        textAlign: "center",
                        margin: "-24px",
                    }}
                >
                    <img
                        style={{padding: "50px 0 0 0"}}
                        alt=""
                        src="https://django-woken-static.s3.amazonaws.com/static/media/tick.b9936225.svg"
                    ></img>
                    <p
                        style={{
                            font: "normal normal bold 24px/12px Montserrat",
                            color: "white",
                            padding: "30px 0",
                        }}
                    >
                        Please Provide Us With Your Feedback !
                    </p>
                </div>
                <Form
                    ref={formRef}
                    name="quick-reflection"
                    initialValues={{
                        satisfaction: 5,
                        feedback: '',
                    }}
                    onFinish={handleSubmit}
                >
                    <Row>
                        <Col span={4}></Col>
                        <Col span={16}>
                            <p className="slider-question" style={{paddingTop: 40}}>
                                How satisfied are you with WOKEN?
                            </p>
                            <Form.Item noStyle name="satisfaction">
                                <Radio.Group  style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(10, 1fr)"
                                }}>
                                    {[...Array(10)].map((x, i) =>
                                        <Radio value={i + 1}>{i + 1}</Radio>
                                    )}
                                </Radio.Group>
                            </Form.Item>
                            <Row justify="space-between">
                                <Col span={8}>
                                    <p
                                        style={{
                                            textAlign: "left",
                                            color: "#898E8E",
                                            font: "normal normal bold 12px Montserrat",
                                        }}
                                    >
                                        I’m not satisfied
                                    </p>
                                </Col>
                                <Col span={8}>
                                    <p
                                        style={{
                                            textAlign: "left",
                                            color: "#898E8E",
                                            font: "normal normal bold 12px Montserrat",
                                        }}
                                    >
                                        I’m moderately satisfied
                                    </p>
                                </Col>
                                <Col span={8}>
                                    <p
                                        style={{
                                            textAlign: "right",
                                            color: "#898E8E",
                                            font: "normal normal bold 12px Montserrat",
                                        }}
                                    >
                                        I’m extremely satisfied
                                    </p>
                                </Col>
                            </Row>

                            <p className="slider-question">
                                What is one thing that would improve your experience with WOKEN?
                            </p>
                            <Form.Item name="feedback"  rules={[{ required: true, message: 'This field is required' }]}>
                                <TextArea >
                                </TextArea>
                            </Form.Item>
                            <Button
                                type="primary"
                                style={{
                                    margin: "50px 0 60px 0",
                                    width: "200px",
                                    textAlign: "center",
                                }}
                                size="large"
                                htmlType="submit"
                            >
                                Submit
                            </Button>
                        </Col>
                        <Col span={4}></Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    );
}
