import { createSlice } from "@reduxjs/toolkit";
import AUTH_API from "api/auth";
import HELPERS from "utils/helper";
import {message } from 'antd';

export const assessmentSlice = createSlice({
  name: "assessment",
  initialState: {
    fetched: false,
    assessment: null,
  },
  reducers: {
    updateAssessment: (state, action) => {
      state.assessment = action.payload;
    },
  },
});

export const { updateAssessment } = assessmentSlice.actions;

export const fetchAssessmentAsync = () => (dispatch) => {
  HELPERS.log("Executing fetchAssessmentAsync...");
  AUTH_API.getAssessment().then((resp) => {
    HELPERS.log("Received response for fetchAssessmentAsync", resp.data);
    dispatch(updateAssessment(resp.data));
  });
};

export const updateAssessmentAsync = (key, data) => (dispatch) => {
  AUTH_API.patchAssessment(data, key)
    .then((response) => {
      HELPERS.log("Received response for updateAssessmentAsync", response.data);
      alert()
      message.success('Your work has been saved')
      dispatch(updateAssessment(response.data));
    })
    .catch((err) => console.log("error", err));
};

export const selectAssessment = (state) => state.assessment.assessment;
export const selectAssessmentFetched = (state) => state.assessment.fetched;

export default assessmentSlice.reducer;
