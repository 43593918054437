const STEP_KEY = {
  Onboarding: "ON",
  Assessment: "AS",
  TargetRoles: "TR",
  TargetIndustries: "TI",
  ResearchRoles: "RR",
  ResearchIndustries: "RI",
  Contacts: "CO",
  EmailDrafts: "ED",
  Tracker: "NT",
  CallPrep: "CP",
  CallNotes: "CN",
  NetworkingReflections: "NF",
  LearningOpportunities: "LO",
  ProfessionalSummary: "PS",
  Resume: "RE",
  CoverLetter: "CL",
  Linkedin: "LK",
  ApplicationTracker: "AT",
  InterviewResearch: "IR",
  InterviewPractice: "BP",
  Reflections: "JR",
};

const order = [
  STEP_KEY.Assessment,
  STEP_KEY.TargetRoles,
  STEP_KEY.TargetIndustries,
  STEP_KEY.ResearchRoles,
  STEP_KEY.ResearchIndustries,
  STEP_KEY.Contacts,
  STEP_KEY.EmailDrafts,
  STEP_KEY.Tracker,
  STEP_KEY.CallPrep,
  STEP_KEY.CallNotes,
  STEP_KEY.NetworkingReflections,
  STEP_KEY.LearningOpportunities,
  STEP_KEY.ProfessionalSummary,
  STEP_KEY.Resume,
  STEP_KEY.CoverLetter,
  STEP_KEY.Linkedin,
  STEP_KEY.ApplicationTracker,
  STEP_KEY.InterviewResearch,
  STEP_KEY.InterviewPractice,
  STEP_KEY.Reflections,
];

const canBeNotified = (stepKey) => order.indexOf(stepKey) !== -1;

const stepOrder = (stepKey) => order.indexOf(stepKey);

const subSteps = {
  ONBOARDING: [
    {
      title: "Commitment",
      linkTo: { pathname: "/onboarding", stepProps: { curr: 4 } },
      key: "1",
    },
    {
      title: "Accountability",
      linkTo: { pathname: "/onboarding", stepProps: { curr: 5 } },
      key: "2",
    },
    {
      title: "Assessment",
      linkTo: "/assessment",
      key: "3",
      stepKey: STEP_KEY.Assessment,
    },
    {
      title: "Target Roles",
      linkTo: "/target-roles",
      key: "4",
      stepKey: STEP_KEY.TargetRoles,
    },
    {
      title: "Target Industries",
      linkTo: "/target-industries",
      key: "5",
      stepKey: STEP_KEY.TargetIndustries,
    },
  ],
  EXPLORATION_RESEARCH: [
    {
      title: "Roles",
      key: "6",
      linkTo: "/research-roles",
      stepKey: STEP_KEY.ResearchRoles,
    },
    {
      title: "Industries",
      key: "7",
      linkTo: "/research-industries",
      stepKey: STEP_KEY.ResearchIndustries,
    },
  ],
  EXPLORATION_NETWORKING: [
    {
      title: "Contacts",
      key: "8",
      linkTo: "/networking/contacts",
      stepKey: STEP_KEY.Contacts,
    },
    {
      title: "Email Drafts",
      key: "9",
      linkTo: "/networking/email-drafts",
      stepKey: STEP_KEY.EmailDrafts,
    },
    {
      title: "Tracker",
      key: "10",
      linkTo: "/networking/tracker",
      stepKey: STEP_KEY.Tracker,
    },
    {
      title: "Call Prep",
      key: "11",
      linkTo: "/networking/call-prep",
      stepKey: STEP_KEY.CallPrep,
    },
    {
      title: "Call Notes",
      key: "12",
      linkTo: "/networking/call-notes",
      stepKey: STEP_KEY.CallNotes,
    },
    {
      title: "Reflections",
      key: "13",
      linkTo: "/networking/reflections",
      stepKey: STEP_KEY.NetworkingReflections,
    },
  ],
  EXPLORATION_EXPERIENCES: [
    {
      title: "Learning Opportunities",
      key: "14",
      linkTo: "/learning-opportunities",
      stepKey: STEP_KEY.LearningOpportunities,
    },
  ],
  EXECUTION_MATERIALS: [
    {
      title: "Professional Summary",
      key: "15",
      linkTo: "/materials/professional-summary",
      stepKey: STEP_KEY.ProfessionalSummary,
    },
    {
      title: "Resume",
      key: "16",
      linkTo: "/materials/resume",
      stepKey: STEP_KEY.Resume,
    },
    {
      title: "Cover Letter",
      key: "17",
      linkTo: "/materials/cover-letter",
      stepKey: STEP_KEY.CoverLetter,
    },
    {
      title: "Linkedin",
      key: "18",
      linkTo: "/materials/linkedin",
      stepKey: STEP_KEY.Linkedin,
    },
  ],
  EXECUTION_JOB_SEARCH: [
    {
      title: "Application Tracker",
      key: "19",
      linkTo: "/job-search/application-tracker",
      stepKey: STEP_KEY.ApplicationTracker,
    },
    {
      title: "Interview Research",
      key: "20",
      linkTo: "/job-search/interview-research",
      stepKey: STEP_KEY.InterviewResearch,
    },
    {
      title: "Interview Practice",
      key: "21",
      linkTo: "/job-search/interview-prep",
      stepKey: STEP_KEY.InterviewPractice,
    },
    {
      title: "Reflections",
      key: "22",
      linkTo: "/job-search/reflections",
      stepKey: STEP_KEY.Reflections,
    },
  ],
};

const steps = [
  {
    title: "ONBOARDING",
    key: "sub1",
    subSteps: [
      {
        steps: subSteps.ONBOARDING,
      },
    ],
  },
  {
    title: "EXPLORATION",
    key: "sub2",
    subSteps: [
      {
        title: "RESEARCH",
        steps: subSteps.EXPLORATION_RESEARCH,
      },
      {
        title: "NETWORKING",
        steps: subSteps.EXPLORATION_NETWORKING,
      },
      {
        title: "EXPERIENCES",
        steps: subSteps.EXPLORATION_EXPERIENCES,
      },
    ],
  },
  {
    title: "EXECUTION",
    key: "sub3",
    subSteps: [
      {
        title: "MATERIALS",
        steps: subSteps.EXECUTION_MATERIALS,
      },
      {
        title: "JOB SEARCH",
        steps: subSteps.EXECUTION_JOB_SEARCH,
      },
    ],
  },
];

export { STEP_KEY, subSteps, stepOrder, canBeNotified };

export default steps;
