import React from "react";
import { Row, Col } from "antd";
import { useSelector } from "react-redux";

import "bootstrap/dist/css/bootstrap.min.css";

import Targetrolescard from "./Targetrolescard";
import Targetindustriescard from "./Targetindustries";
import Timeline from "./Timeline";
import MyTasks from "./MyTasks";
import Calendar from "../Calendar";
import { selectProfile } from "slices/dashboardSlice";

const WelcomeMessage = () => {
  const profile = useSelector(selectProfile);

  return (
    <div
      style={{
        fontSize: 30,
        color: "white",
        textAlign: "left",
        padding: "2% 1%",
      }}
    >
      Welcome, {profile ? profile.first_name : "User"}!
    </div>
  );
};

const DashboardComp = () => (
  <div>
    <WelcomeMessage />
    <Row>
      <Col span={15}>
        <Row>
          <Col span={12}>
            {/* //Target roles list */}
            <Targetrolescard />
          </Col>
          <Col span={12}>
            {/* //Target industries list */}
            <Targetindustriescard />
          </Col>
        </Row>

        {/* //Timeline */}
        <Timeline />
      </Col>

      {/* //Tasks */}
      <Col span={8} style={{ paddingTop: "4%" }}>
        <MyTasks />
        <Calendar />
      </Col>
      <Col span={1}></Col>
    </Row>
  </div>
);

export default DashboardComp;
