import React from "react";
export const CONTENT1 = (
  <div className="popover-content">
    Friends who are well connected who <br />
    might be able to make introductions.
  </div>
);

export const CONTENT2 = (
  <div className="popover-content">
    Second-degree, loose connections, <br />
    friends of friends, met once or twice
  </div>
);

export const CONTENT3 = (
  <div className="popover-content">
    Who have you worked with in prior jobs <br />
    or internships? i.e. peer colleagues, bosses,
    <br /> direct reports, other teams
  </div>
);

export const CONTENT4 = (
  <div className="popover-content">
    Think of your hobbies or social activities
    <br /> - are you in any groups or interests-based
    <br /> clubs? (athletic, religious, food, travel, etc.)
  </div>
);

export const CONTENT5 = (
  <div className="popover-content">
    Locate your university's alumni database
    <br /> and list alumni in relevant roles, industries
    <br /> or companies
  </div>
);

export const CONTENT6 = (
  <div className="popover-content">
    Think about direct or indirect connections
    <br /> who are or have been in roles you're interested in
  </div>
);

export const CONTENT7 = (
  <div className="popover-content">
    Think about direct or indirect connections
    <br /> who are or have been in industries you're interested in
  </div>
);

export const CONTENT8 = (
  <div className="popover-content">
    Think about direct or indirect connections
    <br /> who work or have worked at companies you're
    <br /> interested in
  </div>
);

export const CONTENT9 = (
  <div className="popover-content">
    List internal recruiters at companies of <br />
    interest or agency recruiters (whether they <br />
    are direct or indirect connections)
  </div>
);

export const CONTENT10 = (
  <div className="popover-content">
    Find cold connections on LinkedIn who
    <br /> are in roles or companies that you find <br />
    interesting
  </div>
);
