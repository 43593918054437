import { configureStore } from "@reduxjs/toolkit";
import dashboardReducer from "./slices/dashboardSlice";
import targetRolesReducer from "./slices/targetRolesSlice";
import targetIndustriesReducer from "./slices/targetIndustriesSlice";
import assessmentReducer from "./slices/assessmentSlice";
import weeklyReflectionReducer from "./slices/weeklyReflectionSlice";

export default configureStore({
  reducer: {
    dashboard: dashboardReducer,
    targetRoles: targetRolesReducer,
    targetIndustries: targetIndustriesReducer,
    assessment: assessmentReducer,
    weeklyReflection: weeklyReflectionReducer,
  },
});
