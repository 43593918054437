import React from "react";
import "./Onboardingbase.css";
import { Layout } from "antd";
// import "antd/dist/antd.css";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";

const { Sider } = Layout;
const { Content } = Layout;

export default class Onboarding extends React.Component {
  state = {
    current: this.props.location.stepProps
      ? this.props.location.stepProps.curr
      : this.props.authStep
      ? this.props.authStep
      : this.props.stateup
      ? this.props.stateup
      : 1,
    // current:3,
    phone: "999999999",
    c_c: "+8",
  };

  next = () => {
    const current = this.state.current + 1;
    this.setState({ current });
  };

  next1 = (phone, c_c) => {
    const current = this.state.current + 1;
    this.setState({ current: current, phone: phone, c_c: c_c });
  };

  prev = () => {
    const current = this.state.current - 1;
    this.setState({ current });
  };

  render() {
    // console.log(this.props.location.stepProps ? this.props.location.stepProps.curr:"undef");
    switch (this.state.current) {
      case 1:
        this.current_step = (
          <Step1 onclicknext={(phone, c_c) => this.next1(phone, c_c)} />
        );
        break;
      case 2:
        this.current_step = (
          <Step2
            onclicknext={() => this.next()}
            phone={this.state.phone}
            c_c={this.state.c_c}
          />
        );
        break;
      case 3:
        this.current_step = <Step3 onclicknext={() => this.next()} />;
        break;
      case 4:
        this.current_step = (
          <Step4
            onclicknext={() => this.next()}
            onclickback={() => this.prev()}
          />
        );
        break;
      case 5:
        this.current_step = <Step5 onclickback={() => this.prev()} />;
        break;
      default:
        this.current_step = <div className="h"> default me</div>;
        break;
    }

    return (
      <Layout className="parent-comp">
        <Sider
          width="33%"
          style={{
            backgroundImage:
              "linear-gradient(to bottom right, #90D6DA, #00949A)",
            // maxHeight: '100vh',
            overflowY: "hidden",
          }}
        >
          <p
            style={{
              fontWeight: "bold",
              color: "white",
              fontSize: "40px",
              padding: "20% 0",
              margin: 0,
            }}
          >
            WOKEN
          </p>
          <p
            style={{
              fontWeight: "bold",
              color: "white",
              fontSize: "40px",
              padding: "10% 10%",
            }}
          >
            Helping you find a job you will
            <span style={{ color: "#296B70", fontStyle: "italic" }}>
              &nbsp;love
            </span>
            .
          </p>
          <p
            style={{
              fontSize: "10",
              color: "white",
              fontFamily: "Montserrat",
              fontWeight: "bold",
              padding: "120px",
            }}
          >
            Step {this.state.current} of 5
          </p>
        </Sider>

        <Layout style={{ backgroundColor: "white" }}>
          <Content style={{ backgroundColor: "white", height: "100vh" }}>
            {this.current_step}
          </Content>
        </Layout>
      </Layout>
    );
  }
}
