import React, { useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import {updateTaskAsync, deleteTaskAsync, setStepGoalAsync} from "../../slices/dashboardSlice";
import { DatePicker, Popconfirm} from "antd";
import './MyTasks.css'
import {EditOutlined} from "@ant-design/icons";

const spinner =
  "https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/spinner.svg";
const weeklyReflection =
  "https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/noun_think_2157497.ad0d2dc4.svg";

const phone =
  "https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/noun_call_2442970.svg";

const TaskComp = (props) => {
  const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
  const dispatch = useDispatch();
  const [date, setDate] = useState(props.date);

  const openDatePicker = () => {
    setDatePickerIsOpen(!datePickerIsOpen);
  };
  const handleChange = (date, dateString) => {
    console.log("changed date:", date, dateString);
    setDate(dateString);
    setDatePickerIsOpen(!datePickerIsOpen);
    var timeZoneFormatted = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
    const task = {
      due_date: date,
    };
    props.id > 0 ?
    dispatch(updateTaskAsync(props.id, task))
        : dispatch(setStepGoalAsync(
                props.stepId,
                props.stepKey,
                `${dateString}`,
                props.stepName
            ));
  };

  return (
    <li
      key={props.id}
      className={
        props.title === "Schedule Call with Coach"
          ? "call"
          : props.weeklyReflection
          ? "weekly-reflection"
          : props.status === "ongoing"
          ? "ongoing"
          : props.status === "just-released"
          ? "botom-purple"
          : ""
      }
    >
      {props.status === "just-released" ? (
        <span
          className="just-released"
          style={{ marginLeft: 0, marginRight: "10px" }}
        >
          NEW
        </span>
      ) : props.title !== "Schedule Call with Coach" ? (
        <img
          src={props.weeklyReflection ? weeklyReflection : spinner}
          className="spinner"
        />
      ) : (
        <img src={phone} className="phone" />
      )}

      <span className="title">{props.title} </span>
      {
        props.id && props.hasDelete &&
        <EditOutlined
            style={{marginRight: '20px'}}
            className="editable drag-hover"
            onClick={props.onEdit}
        />
      }

        <label
            htmlFor={`start-date-${props.id}`}
            className="due-date"
            onClick={() => openDatePicker()}
            style={{cursor: "pointer"}}
            // hidden={props.id < 1}
        >
          {
            date ? moment(date).format("DD MMM") : 'Due Date'
          }
        </label>
      {
        props.id && props.hasDelete &&
        <div style={{float: "right", paddingRight: '10px'}}>
          <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => {
               dispatch(deleteTaskAsync(props.id))
              }}
          >
            <span style={{cursor: "pointer"}} className="drag-hover">
                <img src="https://django-woken-static.s3.amazonaws.com/static/media/delete.037bee2b.svg"/>
              </span>
          </Popconfirm>
        </div>
      }
      <DatePicker
        id={`start-date-${props.id}`}
        onClickOutside={openDatePicker}
        open={datePickerIsOpen}
        style={{ visibility: "hidden", width: "0", float: "right" }}
        defaultValue={date && moment(date)}
        onChange={(date, dateString) => handleChange(date, dateString)}
      />
    </li>
  );
};

export default TaskComp;
