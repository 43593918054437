import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import Singleroleselect from "./Singleroleselect";
import { selectTargetRoles, updateRoleAsync } from "slices/targetRolesSlice";
import {message} from "antd";

class TargetRoleSelect extends React.Component {
  roles = () => {
    let res = [];

    if (this.props.roles && this.props.roles.length > 0) {
      res = [...this.props.roles];
      res.sort((a, b) => {
        //sort acc to no. of time selected
        const alen = a.selected_for.length;
        const blen = b.selected_for.length;
        return blen - alen;
      });
    }
    return res;
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  onCheck = (e, id) => {
    const data = {
      learn_more: e,
    };
    this.props.updateRoleAsync(id, data, "TR");
    message.success('Your work has been saved');
  };

  render() {
    return (
      <div>
        <p
          style={{
            color: "#296B70",
            font: "normal normal 600 14px/22px Montserrat",
          }}
        >
          Below is a summary of your selections from previous page. Select the
          job titles you want to learn more about.{" "}
        </p>
        <p
          style={{
            paddingBottom: "10px",
            font: "normal normal 100 14px/22px Montserrat",
            color: "#296B70",
          }}
        >
          Your learning process will evolve organically, so don’t feel that any
          options you exclude are off the table!
        </p>
        <p
        style={{
          paddingBottom: "20px",
          font: "normal normal 100 14px/22px Montserrat",
          color: "#296B70",
        }}
        
        >
            Please limit your choices to 10 roles. This does not mean you are closing yourself off to any options, but we want to focus your research on what you think is most interesting, just as a starting point. You can edit or adjust your list after this step.
         
        </p>
        <Row>
          <Col span={15}>
            <Row gutter={8}>
              <Col
                span={7}
                style={{ textAlign: "center" }}
                className="select-heading"
              >
                # times selected
              </Col>
              <Col span={7} className="select-heading">
                Role
              </Col>
              <Col span={9} className="select-heading">
                I want to learn more
              </Col>
            </Row>
            <p className="separator"></p>
            {/* roles list mapping here */}
            {console.log(this.roles())}
            {this.roles().map((item) =>
              item.selected_for.length && item.title && item.id ? (
                <Singleroleselect
                  id={item.id}
                  key={item.id}
                  count={item.selected_for.length}
                  name={item.title}
                  onCheck={(e, id) => this.onCheck(e, id)}
                  checked={item.learn_more}
                />
              ) : (
                ""
              )
            )}
          </Col>
          <Col span={9}></Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  roles: selectTargetRoles(state),
});

const mapDispatchToProps = () => ({
  updateRoleAsync,
});

export default connect(mapStateToProps, mapDispatchToProps())(TargetRoleSelect);
