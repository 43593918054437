const TIMELINE_AREAS = {
  EXPLORATION: 0,
  JOB_SEARCH: 1,
  REMAINING: 2,
};

const getWidth = (area, commitment) => {
  let width = 25;

  if (commitment > 0) {
    switch (area) {
      case TIMELINE_AREAS.EXPLORATION:
        width =
          commitment <= 5
            ? 31
            : commitment <= 10
            ? 22.222222
            : commitment <= 20
            ? 11.111111
            : 8.333333;
        break;
      case TIMELINE_AREAS.JOB_SEARCH:
        width = commitment <= 20 ? 44 : 33.333333;
        break;
      case TIMELINE_AREAS.REMAINING:
        width =
          100 -
          getWidth(TIMELINE_AREAS.EXPLORATION, commitment) -
          getWidth(TIMELINE_AREAS.JOB_SEARCH, commitment);
        break;
      default:
        break;
    }
  }

  return width;
};

function getColSpan() {}

//will use object to get months using modulo
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const getMonths = (joinedOn) => {
  let m = [];
  let joined = joinedOn.month() + 1;

  if (joined < 2) {
    m = [months[months.length - 1], ...months.slice(joined - 1, joined + 6)];
  } else if (joined < 6) {
    m = months.slice(joined - 2, joined + 7);
  } else {
    m = months.slice(joined - 2);
    m = [...m, ...months.slice(0, 9 - m.length)];
  }

  return m;
};

const monthsBeforeToday = (month, joinedOn) => {
  return getMonths(joinedOn).indexOf(months[month]);
};

export { TIMELINE_AREAS, getWidth, getColSpan, getMonths, monthsBeforeToday };
