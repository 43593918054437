import { DownOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Layout, Menu, message, Row } from "antd";
import COACH_API from "api/coach";
import "bootstrap/dist/css/bootstrap.min.css";
import WeeklyReflections from "Components/Common/WeeklyReflections/WeeklyReflections";
import JobSearchBase from "Components/JobSearch/JobSearchbase";
import Learningbase from "Components/Learning/Learningbase";
import Materialsbase from "Components/Materials/Materialsbase";
import Midwaycall from "Components/Modals/Midwaycall";
import NewStep from "Components/Modals/NewStep";
import Profile from "Components/Modals/Profile";
import Skipstep from "Components/Modals/Skipstep";
import QuickReflection from "Components/Modals/Success";
import FeedbackModal from "Components/Modals/Feedback";
import ThankYou from "Components/Modals/Thanks";
import { CalendarBase } from "Components/Scheduler/CalendarBase";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect, Route, useHistory } from "react-router-dom";
import { fetchIndustriesAsync } from "slices/targetIndustriesSlice";
import { fetchRolesAsync } from "slices/targetRolesSlice";
import HELPERS, { WEEKLY_REFLECTION_TYPES } from "utils/helper";
import {
  fetchDataAsync,
  selectFetched,
  selectProfile,
  selectQuickReflectionInfo,
  selectShowThankYou,
  selectStepReflections,
  setShowThankYou,
} from "../../slices/dashboardSlice";
import steps, { canBeNotified, stepOrder } from "../../utils/steps";
import DashboardComp from "../Dashboard/DashboardComp";
import Assessment from "./../Assessment/Assessment";
import Networkingbase from "./../Networking/Networkingbase";
import Researchindustries from "./../Researchindustries/Researchindustries";
import Researchroles from "./../Researchroles/Researchroles";
import Targetindustries from "./../Targetindustries/Targetindustries";
import Targetroles from "./../Targetroles/Targetroles";
// import "antd/dist/antd.css";
import "./Baselayout.css";
import TIdropdown from "./TIdropdown";
import TRdropdown from "./TRdropdown";

const { Sider, Content } = Layout;
const { SubMenu } = Menu;

const menu = (
  <Menu>
    <Menu.Item>
      {/* <a target="_blank" rel="noopener noreferrer" href=""> */}
      Videos
      {/* </a> */}
    </Menu.Item>
    <Menu.Item>
      {/* <a target="_blank" rel="noopener noreferrer" href=""> */}
      Woken Book
      {/* </a> */}
    </Menu.Item>
    <Menu.Item>
      {/* <a target="_blank" rel="noopener noreferrer" href=""> */}
      Woken Blog
      {/* </a> */}
    </Menu.Item>
  </Menu>
);

const resources = (
  <Menu>
    <Menu.Item>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.youtube.com/channel/UC-odYAm60szKcSnZzcmWA6w?view_as=subscriber"
      >
        Videos
      </a>
    </Menu.Item>
    <Menu.Item>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://drive.google.com/file/d/1r0_pYv6MpTNXKBxlhK0aU9TjadZzMyOO/view"
      >
        Woken Book
      </a>
    </Menu.Item>
    <Menu.Item>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://getwoken.medium.com"
      >
        Woken Blogs
      </a>
    </Menu.Item>
  </Menu>
);

const profileComp = (props) => (
  <Menu>
    {props.impersonated && (
      <Menu.Item onClick={() => props.showProfileModal(true)}>
        {/* <a target="_blank" rel="noopener noreferrer" href=""> */}
        Edit Profile
        {/* </a> */}
      </Menu.Item>
    )}
    <Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="/privacy-policy">
        Privacy Policy
      </a>
    </Menu.Item>
    {props.impersonated && (
      <Menu.Item
        onClick={() => {
          localStorage.removeItem("token");
          localStorage.removeItem("isCoach");
          window.heap.resetIdentity();
          setTimeout(function(){window.location.href = "/signin";}, 6000);
        }}
      >
        <a>Logout</a>
      </Menu.Item>
    )}
  </Menu>
);

const UserDropDown = (props) => {
  return (
    <Dropdown overlay={profileComp(props)} placement="bottomLeft">
      <Button type="text" style={{ color: "white" }}>
        {props.full_name} <DownOutlined />
      </Button>
    </Dropdown>
  );
};

//THIS OBJECT MAPS RELATIVE URL WITH DEFAULT SELECTED MENU
const curr_active_map = {
  "/dashboard": "0",
  "/assessment": "3",
  "/target-roles": "4",
  "/target-industries": "5",
  "/research-roles": "6",
  "/research-industries": "7",
  "/networking/contacts": "8",
  "/networking/email-drafts": "9",
  "/networking/tracker": "10",
  "/networking/call-prep": "11",
  "/networking/call-notes": "12",
  "/networking/reflections": "13",
  "/learning-opportunities": "14",
  "/materials/professional-summary": "15",
  "/materials/resume": "16",
  "/materials/cover-letter": "17",
  "/materials/linkedin": "18",
  "/job-search/application-tracker": "19",
  "/job-search/interview-research": "20",
  "/job-search/interview-prep": "21",
  "/job-search/reflections": "22",
};
const curr_active_map_filter = {
  "/dashboard": "0",
  "/assessment": "3",
  "/target-roles": "4",
  "/target-industries": "5",
  "/research-roles": "6",
  "/research-industries": "7",
  "/networking/contacts": "8",
  "/networking/email-drafts": "9",
  // "/networking/tracker": "10",
  "/networking/call-prep": "11",
  "/networking/call-notes": "12",
  "/networking/reflections": "13",
  "/learning-opportunities": "14",
  "/materials/professional-summary": "15",
  "/materials/resume": "16",
  "/materials/cover-letter": "17",
  "/materials/linkedin": "18",
  // "/job-search/application-tracker": "19",
  "/job-search/interview-research": "20",
  // "/job-search/interview-prep": "21",
  "/job-search/reflections": "22",
};

const Baselayout = (props) => {
  const [timeCounter, setTimeCounter] = useState(0);
  const [saved, setSaved] = useState(false);
  const [value, setValue] = useState(0);
  const [mode, setMode] = useState("inline");
  const [collapsed, setCollapsed] = useState(false);
  const [visible, setVisible] = useState(false);
  const [showNewStepModal, setShowNewStepModal] = useState(true);
  const [selected, setSelected] = useState(
    curr_active_map[window.location.pathname]
  );
  const showFeedback = useSelector(state => state.dashboard.show_feedback)
  const [pathName, setPathName] = useState(window.location.pathname);
  const stepReflections = useSelector(selectStepReflections);
  const quickReflectionInfo = useSelector(selectQuickReflectionInfo);
  const showThankYou = useSelector(selectShowThankYou);
  const profile = useSelector(selectProfile);
  const history = useHistory();
  const full_name = () =>
    profile ? `${profile.first_name} ${profile.last_name}` : "User";

  const impersonated = () => (profile ? profile.impersonated : false);

  const [visibleProfileModal, setVisibleProfileModal] = useState(false);
  useEffect(() => {
    console.log("impersonated in use effect", impersonated(), profile);
    if (!curr_active_map_filter[window.location.pathname]) {
      //incorrect routes handled
      history.push("/");
    }
    if (window.location.pathname.endsWith("/dashboard") && collapsed) {
      HELPERS.log("I am not supposed to be collapsed!");
      setCollapsed(false);
    }

    if (!impersonated() && !localStorage.getItem("isCoach")) {
      console.log("impersonated!!");
      HELPERS.ping();
      if (saved) {
        message.success("Your work has been saved!");
      }
      // HELPERS.changesSaved(() => message.success("Your work has been saved!"));
    } else {
      console.log(" not impersonated!!");
      HELPERS.log("PING: Stop pinging.");
      HELPERS.stopPing();
    }
  }, []);
  const changeMode = (value) => {
    setMode(value ? "vertical" : "inline");
  };

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const showProfileModal = (visible) => {
    setVisibleProfileModal(visible);
  };

  const isLocked = (step) =>
    stepReflections[step] ? stepReflections[step].status === "LO" : false;

  const isOngoing = (step) =>
    stepReflections[step] ? stepReflections[step].status === "ON" : false;

  const justLaunched = (step) =>
    stepReflections[step]
      ? !isLocked(step) && stepReflections[step].notified === false
      : false;
  const goToOnboarding = () => {
    console.log("going to onboarding");
    // props.history.push({
    //   pathname: '/onboarding',
    //   state: { curr: 4 }
    // })
    return (
      <Redirect to={{ pathname: "/onboarding", stepProps: { curr: 3 } }} />
    );
  };

  const renderMenu = () => (
    <Menu
      style={{
        paddingLeft: "30px",
        fontSize: "small",
        height: "100%",
      }}
      defaultOpenKeys={["sub1", "sub2", "sub3", "sub4", "sub5"]}
      mode={mode}
      selectedKeys={[curr_active_map[window.location.pathname]]} //CURRENT RELATIVE URL USED AS KEY TO INDEX DEFAULT KEY
    >
      {steps.map((step, i) => {
        const subSteps = [];
        let stepLocked = true;

        step.subSteps.forEach((subStep) => {
          let subStepLocked = !subStep.steps.some(
            (step) => isLocked(step.stepKey) === false
          );

          // If any sub step is not locked then consider step not locked
          if (!subStepLocked && stepLocked) {
            stepLocked = false;
          }

          if (subStep.title) {
            subSteps.push(
              <p
                key={`sub-steps-heading-${subStep.title}`}
                className={subStepLocked ? "locked" : ""}
                style={{ padding: "6% 15%", fontSize: 13, fontWeight: "bold" }}
              >
                {subStep.title}
              </p>
            );
          }

          subStep.steps.forEach((step) => {
            subSteps.push(
              <Menu.Item
                key={step.key}
                className={[
                  step.stepKey && isLocked(step.stepKey) ? "locked" : "",
                  justLaunched(step.stepKey) ? "just-launched" : "",
                  !justLaunched(step.stepKey) && isOngoing(step.stepKey)
                    ? "ongoing"
                    : "",
                ].join(" ")}
                title={
                  step.stepKey && isLocked(step.stepKey)
                    ? "This step is locked. We know its hard to focus on one thing at a time, but we aim to make your life easier by helping you do just that. Try to not think a few steps ahead as this is a proven, ordered process. Each step will set you up for the next. Let us know if you have questions on how to achieve this step or the purpose, and we're happy to clarify."
                    : step.title
                }
              >
                {step.stepKey === "AS" &&
                isLocked(step.stepKey) &&
                justLaunched(step.stepKey)
                  ? goToOnboarding()
                  : ""}
                <Link to={step.linkTo}>
                  &nbsp;&nbsp;&nbsp;{step.title}
                  {justLaunched(step.stepKey) ? (
                    <span className="just-released">NEW</span>
                  ) : null}
                </Link>
              </Menu.Item>
            );
          });
        });

        return (
          <SubMenu
            style={{ borderRight: "3px !important" }}
            key={step.key}
            title={step.title}
            className={[
              stepLocked ? "locked" : "",
              step.title.toLowerCase(),
            ].join(" ")}
          >
            {subSteps}
          </SubMenu>
        );
      })}
    </Menu>
  );
  console.log(
    "$$woken$$",
    "base rendered",
    "fetched",
    props.fetched,
    "impersonated",
    impersonated(),
    // props,
    showNewStepModal,
    { mode, collapsed, visible, selected, pathName, stepReflections },
    window.location.pathname
  );
  const d = new Date();
  console.log(d.getMonth() + 1);

  const notifiedNewStep = (item) => {
    let temp = { ...showNewStepModal };
    temp[item] = false;
    console.log("close!!", temp);
    setShowNewStepModal(temp);
    // setValue(value+1)
  };

  const newStepAvailable = () => {
    let newStep = [];

    for (let stepReflection in stepReflections) {
      if (justLaunched(stepReflection)) {
        if (
          canBeNotified(stepReflection)
          // && (newStep === null || stepOrder(stepReflection) < stepOrder(newStep))
        ) {
          newStep.push(stepReflection);
        }
      }
    }
    if (typeof showNewStepModal !== "object" && newStep.length) {
      console.log("is object!!");
      let temp = {};
      newStep.forEach((item) => {
        temp[item] = true;
      });
      setShowNewStepModal(temp);
    }
    console.log("new Step", newStep);
    // return newStep;
  };

  const stopImpersonating = () => {
    COACH_API.stopImpersonate()
      .then(() => {
        // window.close();
        window.location.href = "/coach";
      })
      .catch(() => {
        message.error("Something went wrong. Try reloading the page!");
      });
  };

  return (
    <>
      {console.log("time??")}

      {props.fetched ? (
        <div
          className="headerimage"
          style={{ backgroundColor: "#FCFCFC", height: "100vh" }}
        >
          <Row>
            <Col span={5}></Col>
            <Col span={19}>
              <Row style={{ paddingTop: "2%" }}>
                <Col span={9}>
                  <div style={{ textAlign: "left" }}>
                    <Link
                      to={{
                        pathname: "/dashboard",
                        stepProps: { collapsed: false },
                      }}
                    >
                      <Button
                        type="text"
                        style={{ fontWeight: "bold", color: "white" }}
                        onClick={() => {
                          setSelected("0");
                        }}
                      >
                        Dashboard
                      </Button>
                    </Link>
                    <Link to="/calendar">
                      <Button
                        type="text"
                        style={{ fontWeight: "bold", color: "white" }}
                      >
                        Calendar
                      </Button>
                    </Link>
                    <Dropdown overlay={resources} placement="bottomRight">
                      <Button
                        type="link"
                        style={{ fontWeight: "bold", color: "white" }}
                      >
                        Resources
                        <DownOutlined />
                      </Button>
                    </Dropdown>
                  </div>
                </Col>
                <Col span={15}>
                  <div style={{ float: "right" }}>
                    <TRdropdown />
                    <TIdropdown />
                    <UserDropDown
                      {...props}
                      full_name={full_name()}
                      showProfileModal={showProfileModal}
                      impersonated={!impersonated()}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <div
            className="impersonating-wrapper"
            style={{
              display: impersonated() ? "block" : "none",
            }}
          >
            <span>
              Impersonate Mode on.{" "}
              <span onClick={stopImpersonating}>Stop impersonating</span>
            </span>
          </div>
          {newStepAvailable()}
          <Layout style={{ backgroundColor: "#f5f5f500" }}>
            <Sider
              width="350px"
              style={{ backgroundColor: "#f5f5f500", padding: "6% 0 0" }}
              collapsed={collapsed}
            >
              {renderMenu()}
            </Sider>

            <Layout style={{ backgroundColor: "#f5f5f500" }}>
              <Content style={{ backgroundColor: "#f5f5f500" }}>
                <div>
                  <Route
                    path="/calendar"
                    exact
                    render={(props) => (
                      <CalendarBase toggle={toggle} collapsed={collapsed} />
                    )}
                  />
                  <Route
                    path="/dashboard"
                    exact
                    render={(props) => (
                      <DashboardComp
                        toggle={toggle}
                        collapsed={collapsed}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/assessment"
                    exact
                    render={(props) => (
                      <Assessment
                        toggle={toggle}
                        collapsed={collapsed}
                        impersonated={!impersonated()}
                        stepsStatus={stepReflections}
                      />
                    )}
                  />
                  <Route
                    path="/target-roles"
                    exact
                    render={(props) => (
                      <Targetroles
                        toggle={toggle}
                        collapsed={collapsed}
                        impersonated={!impersonated()}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/target-industries"
                    exact
                    render={(props) => (
                      <Targetindustries
                        toggle={toggle}
                        impersonated={!impersonated()}
                        collapsed={collapsed}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/research-roles"
                    exact
                    render={(props) => (
                      <Researchroles
                        toggle={toggle}
                        impersonated={!impersonated()}
                        collapsed={collapsed}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/research-industries"
                    exact
                    render={(props) => (
                      <Researchindustries
                        toggle={toggle}
                        impersonated={!impersonated()}
                        collapsed={collapsed}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/networking/contacts"
                    exact
                    render={(props) => (
                      <Networkingbase
                        toggle={toggle}
                        impersonated={!impersonated()}
                        collapsed={collapsed}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/networking/email-drafts"
                    exact
                    render={(props) => (
                      <Networkingbase
                        current_step={1}
                        toggle={toggle}
                        impersonated={!impersonated()}
                        collapsed={collapsed}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/networking/tracker"
                    exact
                    render={(props) => (
                      <Networkingbase
                        current_step={2}
                        toggle={toggle}
                        impersonated={!impersonated()}
                        collapsed={collapsed}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/networking/call-prep"
                    exact
                    render={(props) => (
                      <Networkingbase
                        current_step={3}
                        toggle={toggle}
                        impersonated={!impersonated()}
                        collapsed={collapsed}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/networking/call-notes"
                    exact
                    render={(props) => (
                      <Networkingbase
                        current_step={4}
                        toggle={toggle}
                        impersonated={!impersonated()}
                        collapsed={collapsed}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/networking/reflections"
                    exact
                    render={(props) => (
                      <WeeklyReflections
                        current_step={0}
                        toggle={toggle}
                        collapsed={collapsed}
                        impersonated={!impersonated()}
                        type={WEEKLY_REFLECTION_TYPES.NETWORKING}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/learning-opportunities"
                    exact
                    render={(props) => (
                      <Learningbase
                        current_step={0}
                        toggle={toggle}
                        collapsed={collapsed}
                        impersonated={!impersonated()}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/materials/professional-summary"
                    exact
                    render={(props) => (
                      <Materialsbase
                        // current_step={4}
                        toggle={toggle}
                        collapsed={collapsed}
                        impersonated={!impersonated()}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/materials/resume"
                    exact
                    render={(props) => (
                      <Materialsbase
                        current_step={1}
                        toggle={toggle}
                        collapsed={collapsed}
                        impersonated={!impersonated()}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/materials/cover-letter"
                    exact
                    render={(props) => (
                      <Materialsbase
                        current_step={2}
                        toggle={toggle}
                        impersonated={!impersonated()}
                        collapsed={collapsed}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/materials/linkedin"
                    exact
                    render={(props) => (
                      <Materialsbase
                        current_step={3}
                        toggle={toggle}
                        impersonated={!impersonated()}
                        collapsed={collapsed}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/job-search/application-tracker"
                    exact
                    render={(props) => (
                      <JobSearchBase
                        toggle={toggle}
                        impersonated={!impersonated()}
                        collapsed={collapsed}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/job-search/interview-research"
                    exact
                    render={(props) => (
                      <JobSearchBase
                        current_step={1}
                        toggle={toggle}
                        impersonated={!impersonated()}
                        collapsed={collapsed}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/job-search/interview-prep"
                    exact
                    render={(props) => (
                      <JobSearchBase
                        current_step={2}
                        toggle={toggle}
                        impersonated={!impersonated()}
                        collapsed={collapsed}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/job-search/reflections"
                    exact
                    render={(props) => (
                      <WeeklyReflections
                        current_step={0}
                        toggle={toggle}
                        collapsed={collapsed}
                        impersonated={!impersonated()}
                        type={WEEKLY_REFLECTION_TYPES.JOB_SEARCH}
                        {...props}
                      />
                    )}
                  />
                  {/* <Route
                path="/"
                // exact
                render={() => (
                  <Redirect to='/dashboard'/>
                )}
              /> */}
                </div>
              </Content>
            </Layout>
          </Layout>

          {!impersonated() &&
            typeof showNewStepModal === "object" &&
            Object.keys(showNewStepModal)
              .sort((a, b) => stepOrder(b) - stepOrder(a))
              .map((item, index) => (
                <NewStep
                  visible={showNewStepModal[item]}
                  stepKey={item}
                  onClose={() => notifiedNewStep(item)}
                  mask={index === 0}
                />
              ))}
          {console.log(
            "quickrefinfo",
            quickReflectionInfo,
            window.location.pathname
          )}
          <QuickReflection
            visible={quickReflectionInfo.show}
            stepId={quickReflectionInfo.stepId}
            path={window.location.pathname}
          />
          <FeedbackModal
              visible ={showFeedback}
          ></FeedbackModal>
          <ThankYou visible={showThankYou} onClose={props.closeThankYou} />

          {visibleProfileModal && (
            <Profile
              visible={visibleProfileModal}
              handleCancel={() => showProfileModal(false)}
            />
          )}
          {!isLocked("MI") && Object.keys(stepReflections).length > 0 && (
            <Midwaycall visible={true} obj={stepReflections["MI"]} />
          )}
          {!isLocked("NP") &&
            Object.keys(stepReflections).length > 0 &&
            Object.keys(stepReflections).includes("NP") && (
              <Skipstep visible={true} obj={stepReflections["NP"]} />
            )}
        </div>
      ) : (
        <LoadingOutlined
          style={{ fontSize: 50, color: "#7fced2", marginTop: "50px" }}
        />
      )}
    </>
  );
};

const BaselayoutContainer = ({ props }) => {
  const fetched = useSelector(selectFetched);
  const dispatch = useDispatch();


  useEffect(() => {
    // console.log('$$woken$$', 'dashboard current state', { tasks, fetched });
    if (!fetched) {
      // console.log('$$woken$$', 'Dispatching fetchDataAsync...');
      dispatch(fetchDataAsync());
      dispatch(fetchRolesAsync());
      dispatch(fetchIndustriesAsync());
      // window.setInterval(function(){
      //   message.success('Your work has been saved!')
      // }, 60000)
      // HELPERS.changesSaved(()=>message.success('Your work has been saved!'))
    }
  }, []);

  const closeThankYou = () => {
    dispatch(setShowThankYou(false));
  };

  return (
    <Baselayout fetched={fetched} closeThankYou={closeThankYou} {...props} />
  );
};

export default BaselayoutContainer;
