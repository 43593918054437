import HELPERS from "utils/helper";

const COACH_API = {
  impersonate: (clientId) => {
    return HELPERS.secureRequest({
      url: `coachlistview/${clientId}/impersonate/`,
      method: "POST",
    });
  },
  stopImpersonate: () => {
    return HELPERS.secureRequest({
      url: `coachlistview/stopimpersonate/`,
      method: "POST",
    });
  },
  cohortInfo: () => {
    return HELPERS.secureRequest({
      url: `avgusercohertinfo/cohertinfo/`,
      method: "GET",
    });
  },
  userDrillDown: (userId) => {
    return HELPERS.secureRequest({
      url: `coachdrilldownview/${userId}/`,
      method: "GET",
    });
  },
};

export default COACH_API;
