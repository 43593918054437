import {Card, Col, Form, Input, message, Row, Select} from "antd";
import React from "react";
import { Link } from "react-router-dom";
import HELPERS from "utils/helper";
import AUTH_API from "./../../../api/auth";
import "./CallPrep.css";
import Editableq from "./Editableq";

const { TextArea } = Input;
const { Option } = Select;

export default class CallPrep extends React.Component {
  formRef = React.createRef();
  state = {
    additional_q: [[], [], [], [], [], []],
    loading: true,
    submitted: false,
    contacts: [],
    current: 0,
    Api_cards: [],
  };

  takesNotes = (e) => {
    this.props.takesNotes(this.state.contacts[this.state.current].id);
    // window.location.href = "/networking/call-notes"
  };

  componentDidMount() {
    const temp = { ...this.props };
    console.log("location props in componentdidmount:", temp.location.id);
    AUTH_API.getAllContacts()
      .then((response) => {
        console.log("get api response!!", response);
        const all_contacts = [...response.data];
        const contacts = [];
        all_contacts.map((item) => {
          contacts.push({
            id: item.id,
            name: item.title,
            roles: item.roles,
            ind: item.industries,
          });
        });
        console.log("contacts", contacts);
        // this.setState({contacts:contacts,loading:false})

        const id = this.props.location.id
          ? this.props.location.id
          : contacts[0].id;
        AUTH_API.getContact(id)
          .then((response) => {
            console.log("get single contact res in get api", response);
            const form_values =
              response.data.call_prep.length > 0
                ? response.data.call_prep[0]
                : [];
            this.setState({
              contacts: contacts,
              loading: false,
              Api_cards: Object.keys(form_values),
              current: response.data,
            });
            this.formRef.current.setFieldsValue({
              ...form_values,
            });
          })
          .catch((err) => {
            console.log("error", err);
          });
      })
      .catch((err) => console.log("no contacts found!", err));
  }

  addNew = (index) => {
    const temp = this.state.additional_q;
    console.log("index is:", index);
    temp[index - 1].push("1");
    this.setState({ additional_q: temp });
  };
  OnContactChange = (val) => {
    this.setState({ Api_cards: [] });
    const id = val;
    this.formRef.current.resetFields();
    AUTH_API.getContact(id)
      .then((response) => {
        console.log("get single contact res in patch api", response);
        if (response.data.call_prep.length > 0) {
          const form_values =
            response.data.call_prep.length > 0
              ? response.data.call_prep[0]
              : [];
          this.formRef.current.setFieldsValue({
            ...form_values,
          });
          this.setState({
            Api_cards: Object.keys(form_values),
            current: response.data,
          });
          console.log("state change ", this.state.Api_cards);
        } else this.setState({ current: response.data });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  OnFormChange = (change_values, all_values) => {
    console.log("changed values:", change_values, "all_values:", all_values);
    const id = this.state.current.id;
    console.log("selected contact id is:", id);
    const data = {
      call_prep: [all_values],
    };
    AUTH_API.patchContact(data, id, "CP")
      .then((response) => {
        console.log("patch res", response);
        message.success("Your work has been saved")
        this.setState({
          Api_cards: Object.keys(response.data.call_prep[0]),
          loading: false,
        });
      })
      .catch((err) => {
        console.log("not patched", err);
      });
  };

  render() {
    // this.state.loading===false?console.log(this.state.contacts[this.state.current]):console.log("...loading")
    console.log(this.state, "Api cards");
    const linkTo = {
      pathname: "/networking/call-notes",
      id: this.state.current ? this.state.current.id : "",
    };
    return (
      <>
        {!this.state.loading ? (
          <div>
            <p className="email-head">
              Research what you can about your contact and their career so you
              can come up with clear questions to learn more.{" "}
            </p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p className="email-subhead">
                Jot down your honest questions so you can fill in the gaps in
                your knowledge about your target roles and industries.
              </p>
              <Link to={linkTo}>
                <button className="take-notes-btn">Take notes</button>
              </Link>
            </div>
            <div style={{ display: "flex", marginBottom: "30px" }}>
              {this.state.loading === false ? (
                <Select
                  className="select-caller"
                  listHeight={120}
                  onChange={this.OnContactChange}
                  defaultValue={this.state.current.id}
                >
                  {this.state.contacts.map((item, index) => (
                    <Option value={item.id}>{item.name}</Option>
                  ))}
                </Select>
              ) : (
                ""
              )}
              {this.state.current.roles && this.state.current.roles.length ? (
                <div className="caller-tags-div">
                  <div className="tag-head">ROLE</div>
                  <div style={{ display: "flex" }}>
                    {this.state.loading === false ? (
                      // (this.state.contacts[this.state.current].roles).length > 0 ?
                      //     (this.state.contacts[this.state.current].roles).map((item)=>
                      //         <div className='role-tag'>{item}</div>
                      //     )
                      //     :""
                      this.state.current.roles.length > 0 ? (
                        <div className="role-tag" style={{ maxWidth: "100%" }}>
                          {this.state.current.roles}
                        </div>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.current.industries &&
              this.state.current.industries.length ? (
                <div className="caller-tags-div">
                  <div className="tag-head">INDUSTRY</div>
                  <div style={{ display: "flex" }}>
                    {this.state.loading === false ? (
                      this.state.current.industries ? (
                        <div className="ind-tag" style={{ maxWidth: "100%" }}>
                          {this.state.current.industries}
                        </div>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <Row>
              <Form
                name="call-prp-form"
                ref={this.formRef}
                onValuesChange={HELPERS.debounceFormChanges(this.OnFormChange)}
                style={{ display: "flex" }}
              >
                <Col span="14">
                  <h3 className="notes-head">Your research notes:</h3>
                  <Card.Grid
                    bordered={false}
                    hoverable={false}
                    className={
                      this.state.Api_cards.includes("background-notes")
                        ? "notes-card-focus-within"
                        : "notes-card"
                    }
                  >
                    <p className="notes-card-heading">
                      What do you know about their <strong>background </strong>?
                    </p>
                    <p className="notes-card-subhead">
                      Key info about their role, company, industry and path.
                    </p>
                    <Form.Item name="background-notes" style={{ margin: "0" }}>
                      <TextArea
                        autoSize={{ minRows: 7, maxRows: 60 }}
                        style={{ padding: "0" }}
                        bordered={false}
                        rows={7}
                      ></TextArea>
                    </Form.Item>
                  </Card.Grid>
                  <Card.Grid
                    bordered={false}
                    hoverable={false}
                    className={
                      this.state.Api_cards.includes("company-notes")
                        ? "notes-card-focus-within"
                        : "notes-card"
                    }
                  >
                    <p className="notes-card-heading">
                      What do you know about their <strong>company </strong>?
                    </p>
                    <p className="notes-card-subhead">
                      Read and review their full website. Key info about company
                      overview/org structure, target department, target roles,
                      the culture, etc.
                    </p>
                    <Form.Item name="company-notes" style={{ margin: "0" }}>
                      <TextArea
                        autoSize={{ minRows: 7, maxRows: 60 }}
                        style={{ padding: "0" }}
                        bordered={false}
                        rows={6}
                      ></TextArea>
                    </Form.Item>
                  </Card.Grid>
                  <Card.Grid
                    bordered={false}
                    hoverable={false}
                    className={
                      this.state.Api_cards.includes("role-notes")
                        ? "notes-card-focus-within"
                        : "notes-card"
                    }
                  >
                    <p className="notes-card-heading">
                      What do you know about their <strong>role </strong>?
                    </p>
                    <Form.Item name="role-notes" style={{ margin: "0" }}>
                      <TextArea
                        autoSize={{ minRows: 7, maxRows: 60 }}
                        style={{ padding: "0" }}
                        bordered={false}
                        rows={8}
                      ></TextArea>
                    </Form.Item>
                  </Card.Grid>
                  <Card.Grid
                    bordered={false}
                    hoverable={false}
                    className={
                      this.state.Api_cards.includes("industry-notes")
                        ? "notes-card-focus-within"
                        : "notes-card"
                    }
                  >
                    <p className="notes-card-heading">
                      What do you know about their <strong>industry </strong>?
                    </p>
                    <Form.Item name="industry-notes" style={{ margin: "0" }}>
                      <TextArea
                        autoSize={{ minRows: 7, maxRows: 60 }}
                        style={{ padding: "0" }}
                        bordered={false}
                        rows={8}
                      ></TextArea>
                    </Form.Item>
                  </Card.Grid>
                  <Card.Grid
                    bordered={false}
                    hoverable={false}
                    className={
                      this.state.Api_cards.includes("recruiting-notes")
                        ? "notes-card-focus-within"
                        : "notes-card"
                    }
                  >
                    <p className="notes-card-heading">
                      What do you know about their{" "}
                      <strong>recruiting process </strong>?
                    </p>
                    <Form.Item name="recruiting-notes" style={{ margin: "0" }}>
                      <TextArea
                        autoSize={{ minRows: 7, maxRows: 60 }}
                        style={{ padding: "0" }}
                        bordered={false}
                        rows={8}
                      ></TextArea>
                    </Form.Item>
                  </Card.Grid>
                  <Card.Grid
                    bordered={false}
                    hoverable={false}
                    className={
                      this.state.Api_cards.includes("opportunity-notes")
                        ? "notes-card-focus-within"
                        : "notes-card"
                    }
                  >
                    <p className="notes-card-heading">
                      What do you know about{" "}
                      <strong>learning/training opportunity </strong> for this
                      role ?
                    </p>
                    <Form.Item name="opportunity-notes" style={{ margin: "0" }}>
                      <TextArea
                        autoSize={{ minRows: 7, maxRows: 60 }}
                        style={{ padding: "0" }}
                        bordered={false}
                        rows={8}
                      ></TextArea>
                    </Form.Item>
                  </Card.Grid>
                </Col>
                <Col span={10}>
                  <h3 className="notes-head">
                    What questions you want to ask in the call:
                  </h3>
                  <div className="ques_box">
                    <h4 className="q-box-head">
                      Background
                      {/* <Popover placement="bottom"  content="Add a new question" trigger="hover">
                                        <PlusCircleFilled className='add_q_btn'
                                            onClick={()=>this.addNew(1)}/>
                                    </Popover> */}
                    </h4>
                    <div className="scrollable-q">
                      {/* <div className='ques-checkbox-div'>
                                        <input className='ques-checkbox' type='checkbox'></input><span className="checkmark"></span>
                                        <Input suffix={<EditOutlined className='ques-editable' />} className='ques-input-edit'
                                            bordered={false} 
                                        />
                                    </div>
                                    {this.state.additional_q[0].map((item, index)=>{
                                    return( <Editableq key={index} />)
                                    })} */}
                      <Editableq q_number="back-call" />
                    </div>
                  </div>
                  <div className="ques_box">
                    <h4 className="q-box-head">Company</h4>
                    <div className="scrollable-q">
                      <Editableq q_number="company-call" />
                    </div>
                  </div>
                  <div className="ques_box">
                    <h4 className="q-box-head">Role</h4>
                    <div className="scrollable-q">
                      <Editableq q_number="role-call" />
                    </div>
                  </div>
                  <div className="ques_box">
                    <h4 className="q-box-head">Industry</h4>
                    <div className="scrollable-q">
                      <Editableq q_number="ind-call" />
                    </div>
                  </div>
                  <div className="ques_box">
                    <h4 className="q-box-head">Recruiting Preocess</h4>
                    <div className="scrollable-q">
                      <Editableq q_number="re-call" />
                    </div>
                  </div>
                  <div className="ques_box">
                    <h4 className="q-box-head">Learning Opportunities</h4>
                    <div className="scrollable-q">
                      <Editableq q_number="lo-call" />
                    </div>
                  </div>
                </Col>
              </Form>
            </Row>
          </div>
        ) : (
          <div
            style={{
              margin: 30,
              textAlign: "center",
              font: "300 20px / 12px Montserrat",
              opacity: "0.6",
            }}
          >
            Add some contacts first!
          </div>
        )}{" "}
      </>
    );
  }
}
