import PrivacyPolicyContent from "Components/Staticcontent/PrivacyPolicyContent";
import React, { useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import ReactHeap from "reactjs-heap";
import "./App.css";
// import Testingbase from './Components/AAtesting/Testingbase';
import Baselayout from "./Components/Baselayout/Baselayout";
import CoachDBlayout from "./Components/Coachdashboard/CoachDBlayout";
import Onboarding from "./Components/Onboarding/Onboardingbase";
import PrivateRoute from "./Components/Privateroute";
import Signin from "./Components/Signin/Signin";
import useGoogleAnalytics from "./Components/UseGoogleAnalytics";
// ReactHeap.initialize("1859341748");



function Routes() {
  useGoogleAnalytics();
 
  console.log = console.warn = console.error = () => {}
  const [constructorHasRun, setConstructorHasRun] = useState(false);
  const isAuthenticated = localStorage.getItem("token");
  const isCoach = localStorage.getItem("isCoach");
  const isImpersonating = localStorage.getItem("impersonating");
  console.log(localStorage.getItem("token"), typeof isAuthenticated);

  console.log(
    "is impersonating?",
    isImpersonating,
    localStorage.getItem("impersonating")
  );


  return (
    <Switch>
            <Route path="/signin" exact component={Signin} />

            <Route
              path="/onboarding"
              component={(props) => (
                <Onboarding {...props} authStep={isAuthenticated ? 3 : null} />
              )}
            />
            <Route
              path="/privacy-policy"
              exact
              component={() => (
                <div className="pp-parent">
                  <PrivacyPolicyContent />
                </div>
              )}
            />
            {/* ------------------------------------- check here -------------------------------------------------------------------------------------------------- */}

            <Route
              path="/coach"
              exact
              render={
                isAuthenticated
                  ? isCoach //lets coach to access coach dashboard
                    ? () => <Redirect to="/coach/dashboard/" />
                    : () => <Redirect to="/" />
                  : () => <Redirect to="/signin" />
              }
            />

            <Route
              path="/understand"
              exact
              render={() => <Redirect to="/coach/dashboard/" />}
            />
            {/* <Route path="/coach" component={CoachDBlayout}/> */}

            <Route
              path="/"
              exact
              render={
                // isCoach
                // && !isImpersonating
                //   ?
                //   () => <Redirect to="/coach/dashboard" />
                //   :
                () => <Redirect to="/dashboard" />
              }
            />

            {/* to avoid client from accessing coach */}
            {/* <Route path="/coach" component={isCoach?CoachDBlayout:()=><Redirect to='/dashboard'/>}/> */}
            {/* ---------------------------------------------------- */}

            <Route path="/coach" component={CoachDBlayout} />
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              fallback="/signin/"
              path="/"
              component={Baselayout}
            />
          </Switch>
  )
}






function App() {
  // window.onbeforeunload = function (e) {

  //   window.localStorage.unloadTime = JSON.stringify(new Date());

  //   };

  //   window.onload = function () {
  //   let loadTime = new Date();
  //   let unloadTime = new Date(JSON.parse(window.localStorage.unloadTime));
  //   let refreshTime = loadTime.getTime() - unloadTime.getTime();

  //   if(refreshTime>9000)//3000 milliseconds
  //   {
  //   // window.localStorage.removeItem("token");
  //   //console.log('removing it!')
  //   localStorage.clear()
  //   }

  //   };

  // if(isImpersonating && window.location.pathname !== "/onboarding"){
  //   console.log('should remove impersonation or not',window.location.pathname)
  //   window.onunload = function (e) {
  //     localStorage.removeItem('impersonating');
  //     window.location.href="/coach"
  // };
  // }
  // const constructor = () => {
  //   if (constructorHasRun) return;
  //   console.log(
  //     "Inline constructor() runs 1st!!_____________________________________________________!!!!"
  //   );
  //   window.onbeforeunload = function (e) {
  //     window.localStorage.unloadTime = JSON.stringify(new Date());
  //   };

  //   window.onload = function () {
  //     let loadTime = new Date();
  //     console.log(window.localStorage.unloadTime)
  //     if (window.localStorage.unloadTime){
  //       let unloadTime = new Date(JSON.parse(window.localStorage.unloadTime));
  //     let refreshTime = loadTime.getTime() - unloadTime.getTime();

  //     if (refreshTime > 600000) {
  //       //3000 milliseconds
  //       // window.localStorage.removeItem("token");
  //       console.log("removing it!");
  //       localStorage.clear();
  //     } else {
  //       console.log("Ok, go ahead!");
  //     }
  //     }

  //     setConstructorHasRun(true);
  //   };
  // };

  // constructor();

  return (
    <div className="App">
      {
        // constructorHasRun &&
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      }
    </div>
  );
}

export default App;
