import { Card } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { selectStepReflections } from "slices/dashboardSlice";
import DraggablecompTR from "../Common/DraggablecompTR";

const Targetrolescard = (props) => {

  const stepReflections = useSelector(selectStepReflections);

  const isDone = (step) => 
    stepReflections[step] ? stepReflections[step].status === "DO" || stepReflections[step].status === "RE" : false;
   
  return (
    <Card
      bordered={false}
      style={{
        margin: "20",
        boxShadow: "0px 3px 6px #00000029",
        borderRadius: "13px",
        textAlign: "left",
        margin: "20px",
        height: "96%",
      }}
    >

      <div className="h" style={{ fontWeight: "bold", textAlign: "left" }}>
        My Target Roles
      </div>
      <div style={{ padding: "40px 0 0" }}>
        <DraggablecompTR isDone={isDone('TR')}/>
      </div>
    </Card>
  );
};

export default Targetrolescard;
