import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, Row, Col } from "antd";
import { connect } from "react-redux";
import Moment from "moment";
import { LeftCircleFilled, RightCircleFilled } from "@ant-design/icons";
// import "antd/dist/antd.css";
import Assessmentstart from "./Assessmentstart";
import Assessmentsteps from "./Assessmentsteps";
import DueDate from "./../Common/DueDate";
import Stepinfo from "./../Modals/Stepinfo";
import HELPERS from "utils/helper";
import {
  selectAssessmentStepInfo,
  setStepNotifiedAsync,
} from "slices/dashboardSlice";
import { selectAssessment, fetchAssessmentAsync } from "slices/assessmentSlice";

const Assessment = (props) => {
  const [assessmentComp, setAssessmentComp] = useState(
    <Assessmentstart
      start={() => {
        setAssessmentComp(<Assessmentsteps backToStart={() => startComp()} />);
      }}
    />
  );

  const trStatus = (props.stepsStatus.TR == undefined ?  "" : props.stepsStatus.TR.status);
  const isTargetRolesLocked = trStatus === "LO";

  const stepInfo = useSelector(selectAssessmentStepInfo);
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const [showInfo, setShowInfo] = useState(null);
  const [setTarget, setSetTarget] = useState(null);
  const [currStep, setCurrStep] = useState(0);

  // useEffect(() => {
  //   if (stepInfo && !stepInfo.notified) {
  //     dispatch(setStepNotifiedAsync(stepInfo.id, stepInfo.step));
  //   }
  // });

  const notifyStep = () => {
    console.log("impersonated??", props.impersonated);
    if (stepInfo && !stepInfo.notified && props.impersonated) {
      console.log("not impersonating", props.impersonated);
      dispatch(setStepNotifiedAsync(stepInfo.id, stepInfo.step));
    }
  };

  const newReleased = () => stepInfo && !stepInfo.notified;

  const getShowInfo = () =>
    showInfo === true || (showInfo === null && newReleased());

  const targetSet = () =>
    setTarget === true ||
    (setTarget === null && !!stepInfo && stepInfo.complete_by === null && stepInfo.status != 'DO');

  const setStep = (val) => {
    setCurrStep(val);
  };

  //sets date at header and makes setdate component disappear after 6sec
  const onDateSet = () => {
    console.log("DONE !");
    // setTimeout(() => {
    setSetTarget(false);
    // }, 6000);
  };

  //opens set date box (pink colored) if date in header is clicked
  const setDateAgain = () => {
    // console.log('set!');
    setSetTarget(true);
  };

  //close button handling
  const onCloseClick = () => {
    setSetTarget(false);
  };

  const startComp = () => {
    console.log("startcomp");
    setAssessmentComp(
      <Assessmentstart
        start={() => {
          setAssessmentComp(<Assessmentsteps backToStart={startComp()} />);
        }}
      />
    );
  };

  const onCollapse = () => {
    setCollapsed(!collapsed);
    props.toggle();
  };

  const stepId = () => (!!stepInfo ? stepInfo.id : -1);

  HELPERS.log(
    "assessment rendered",
    props.impersonated,
    stepInfo,
    stepInfo && stepInfo.complete_by === null,
    setTarget
  );
  return (
    <div style={{ padding: "4% 0" }}>
      <div className="collapse-btn" onClick={onCollapse}>
        {collapsed ? (
          <RightCircleFilled className="make-white" />
        ) : (
          <LeftCircleFilled className="make-white" />
        )}
      </div>

      <Card
        bordered={false}
        style={{
          margin: "20px !important",
          boxShadow: "0px 3px 6px #00000029",
          borderRadius: "13px",
          textAlign: "left",
          marginRight: "6%",
          marginLeft: "20px",
          padding: "0 2%",
        }}
      >
        <div>
          <Row style={{ padding: "4% 0% 3% 0%" }}>
            <Col
              span={18}
              style={{
                font: "normal normal 300 30px/12px Montserrat",
                display: "flex",
                alignItems: "center",
              }}
            >
              Assessment&nbsp;
              <span
                className={`just-released ${newReleased() ? "" : "hidden"}`}
              >
                NEW
              </span>
              {stepInfo && (
                // stepInfo.complete_by !== null &&
                <span
                  style={{
                    fontSize: "14px",
                    cursor: "pointer",
                    position: "relative",
                    marginLeft: "20px",
                    whiteSpace: "nowrap",
                  }}
                  onClick={setDateAgain}
                >
                  <img
                    src="https://django-woken-static.s3.amazonaws.com/static/media/calendaricon.c713d4db.svg"
                    style={{ position: "relative", top: "-3px" }}
                  ></img>
                  &nbsp;&nbsp;
                  {stepInfo.complete_by ? "Due date:" : "Set Due Date"}&nbsp;
                  {stepInfo &&
                    stepInfo.complete_by &&
                    Moment(stepInfo.complete_by).format("YYYY-MM-DD")}
                </span>
              )}
            </Col>
            <Col
              span={6}
              style={{
                textAlign: "right",
                fontSize: "26px",
                marginTop: "-20px",
              }}
            >
              <a
                href="mailto: team@iamwoken.com?subject=WOKEN client inquiry "
                target="_blank"
              >
                <img
                  style={{ padding: "0 10px" }}
                  src="https://django-woken-static.s3.amazonaws.com/static/media/mail.5c666ac1.svg"
                />
              </a>
              <span onClick={() => setShowInfo(true)}>
                <img
                  style={{ cursor: "pointer" }}
                  src="https://django-woken-static.s3.amazonaws.com/static/media/question.74f30bbb.svg"
                />
              </span>
            </Col>
          </Row>

          <p
            style={{
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              border: "1px solid #F0F0F0",
            }}
          ></p>
        </div>

        {/* starts here__________________________________________________________________________________ */}

        {console.log("assessmenmt props", props.assessment)}
        {getShowInfo() ? (
          <Stepinfo
            stepname="Assessment"
            closeInfo={() => {
              notifyStep();
              setShowInfo(false);
            }}
          />
        ) : targetSet() ? (
          <div>
            <DueDate
              stepName="Assessment"
              stepKey={stepInfo.step}
              onDateSet={onDateSet}
              onCloseClick={onCloseClick}
              stepId={stepInfo.id}
              targetDate={
                stepInfo.complete_by !== null
                  ? Moment(stepInfo.complete_by)
                  : null
              }
            />{" "}
            <Assessmentsteps
              stepId={stepInfo.id}
              setStep={setStep}
              currStep={currStep}
              all_assess={props.assessment}
              trLocked={isTargetRolesLocked}
            />
          </div>
        ) : (
          <Assessmentsteps
            style={{ opacity: "0.4", display: "none" }}
            fade={!targetSet()}
            stepId={stepId()}
            setStep={setStep}
            currStep={currStep}
            all_assess={props.assessment}
            trLocked={isTargetRolesLocked}
          />
        )}
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  assessment: selectAssessment(state),
});

const mapDispatchToProps = () => ({
  fetchAssessmentAsync,
});

export default connect(mapStateToProps, mapDispatchToProps())(Assessment);
