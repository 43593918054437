import {
  CloseOutlined,
  DeleteOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  TimePicker,
  Popconfirm,
} from "antd";
import moment from "moment";
import React, { useState } from "react";
import AddToCalendar from "react-add-to-calendar";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useDispatch, useSelector } from "react-redux";
import HELPERS from "utils/helper";
import {
  addTaskAsync,
  deleteTaskAsync,
  selectProfile,
  selectTasks,
  updateTaskAsync,
} from "../../slices/dashboardSlice";
import "./Scheduler.css";

const localizer = momentLocalizer(moment);
const { TextArea } = Input;
const DragAndDropCalendar = withDragAndDrop(Calendar);

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.

export default function Scheduler() {
  const [modalVisible, setModalVisible] = useState(false);
  const [eventId, setEventId] = useState("");
  const [draggedEvent, setdraggedEvent] = useState("");
  const tasks = useSelector(selectTasks);
  const profile = useSelector(selectProfile);
  const dispatch = useDispatch();
  const [addForm] = Form.useForm();

  const [addToCal, setAddToCal] = useState("");
  const myEventsList = [];

  const CustomToolbar = (toolbar) => {
    HELPERS.log("Toolbar", toolbar);
    const goToBack = () => {
      toolbar.onNavigate("PREV");
    };
    const goToNext = () => {
      toolbar.onNavigate("NEXT");
    };
    const label = () => {
      return toolbar.label + "    " + toolbar.date.getFullYear();
    };

    return (
      <div style={{ padding: "30px 0", textAlign: "left" }}>
        <div style={{ display: "inline-block" }}>
          <LeftOutlined
            style={{
              opacity: "88%",
              fontSize: "24px",
              color: "#AEB7C2",
              padding: "0 10px",
            }}
            onClick={goToBack}
          />
          <RightOutlined
            style={{
              opacity: "88%",
              fontSize: "24px",
              color: "#AEB7C2",
              padding: "0 10px",
            }}
            onClick={goToNext}
          />
        </div>
        <label className="toolbar-label">{label()}</label>
      </div>
    );
  };

  const showModal = (start, end) => {
    let duration = moment.duration(moment(end).diff(moment(start)));
    setModalVisible(true);
    addForm.setFieldsValue({
      title: "New Task",
      date: moment(start),
      time: moment(start),
      duration: duration.asMinutes() - (duration.asMinutes() % 30),
      description: "",
    });

    setAddToCal({
      title: "New Task",
      description: "",
      location: "",
      startTime: start,
      endTime: end,
    });
  };

  const editEvent = (event) => {
    if (event.id < 1) return;

    setEventId(event.id);

    addForm.setFieldsValue({
      title: event.title,
      date: moment(event.start),
      time: moment(event.start),
      duration: event.duration,
      description: event.description,
    });
    setModalVisible(true);

    let start_date = moment(event.start);
    console.log("start!!!!!!", start_date);

    setAddToCal({
      title: event.title,
      description: event.description,
      location: "",
      startTime: event.start,
      endTime: start_date.add(event.duration, "minutes").toDate(),
    });
  };

  tasks.forEach((task) => {
    let dueDate = moment(task.due_date);
    let duration = task.duration === 0 ? 60 : task.duration;
    let start = dueDate.toDate();
    let end = dueDate.add(duration, "minutes").toDate();

    myEventsList.push({
      title: task.title,
      start,
      end,
      allDay: false,
      description: task.description ? task.description : "",
      id: task.id,
      duration: duration,
      coach: task.weekly_reflection > 0 || task.step_reflection > 0,
    });
  });

  if (profile && profile.daily_commitment) {
    var weekStart = moment().startOf("week");
    var days = {};
    days["sunday"] = moment(weekStart).add(0, "days");
    days["monday"] = moment(weekStart).add(1, "days");
    days["tuesday"] = moment(weekStart).add(2, "days");
    days["wednesday"] = moment(weekStart).add(3, "days");
    days["thursday"] = moment(weekStart).add(4, "days");
    days["friday"] = moment(weekStart).add(5, "days");
    days["saturday"] = moment(weekStart).add(6, "days");
    HELPERS.log("Weekdays", days);

    profile.daily_commitment.forEach((commitment) => {
      try {
        if (commitment.checked) {
          let time = commitment.time[0];
          time = time.split(" ");
          let hours = parseInt(time[0].split(":")[0]);
          let mins = parseInt(time[0].split(":")[1]);
          if (time[1].toUpperCase() !== "AM") {
            hours = 12 + (hours % 12);
          }

          HELPERS.log("The time is", commitment.time[0], hours, mins);

          let start = days[commitment.day].clone().set({
            hour: hours,
            minute: mins,
          });

          time = commitment.time[1];
          time = time.split(" ");
          hours = parseInt(time[0].split(":")[0]);
          mins = parseInt(time[0].split(":")[1]);
          if (time[1].toUpperCase() !== "AM") {
            hours = 12 + (hours % 12);
          }

          HELPERS.log("The time is", commitment.time[1], hours, mins);
          let end = days[commitment.day].clone().set({
            hour: hours,
            minute: mins,
          });

          myEventsList.push({
            title: "Focus on WOKEN",
            start: start.toDate(),
            end: end.toDate(),
            allDay: false,
            id: -1,
          });
        }
      } catch (error) {
        console.warn(error);
      }
    });
  }
  HELPERS.log("Event list", myEventsList);

  const onFinish = (values) => {
    var timeZoneFormatted = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
    const task = {
      title: values.title,
      due_date: `${values.date.format("YYYY-MM-DD")}T${values.time.format(
        "HH:mm:ss"
      )}${timeZoneFormatted}`,
      duration: values.duration,
      description: values.description,
    };
    let add = true;
    for (var index = 0; index < tasks.length; ++index) {
      if (tasks[index].id === eventId) {
        dispatch(updateTaskAsync(eventId, task));
        add = false;
        break;
      }
    }
    if (add) {
      dispatch(addTaskAsync(task));
    }
    setModalVisible(false);
    setEventId("");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleSelect = ({ start, end }) => {
    console.log(start, end);
    showModal(start, end);
  };

  const handleCancel = () => {
    setModalVisible(false);
    setEventId("");
  };

  const handleDeleteTask = () => {
    dispatch(deleteTaskAsync(eventId));
    setModalVisible(false);
    setEventId("");
  };

  const moveEvent = ({ event, start, end, isAllDay: droppedOnAllDaySlot }) => {
    let duration = moment.duration(end - start);
    const due_date = start;

    const task = {
      ...event,
      due_date: due_date,
      duration: duration.asMinutes() - (duration.asMinutes() % 30),
    };
    let add = true;
    for (var index = 0; index < tasks.length; ++index) {
      if (tasks[index].id === event.id) {
        dispatch(updateTaskAsync(event.id, task));
        add = false;
        break;
      }
    }
    if (add) {
      dispatch(addTaskAsync(task));
    }

    // const { events } = this.state

    // let allDay = event.allDay

    // if (!event.allDay && droppedOnAllDaySlot) {
    //   allDay = true
    // } else if (event.allDay && !droppedOnAllDaySlot) {
    //   allDay = false
    // }

    // const nextEvents = events.map(existingEvent => {
    //   return existingEvent.id == event.id
    //     ? { ...existingEvent, start, end }
    //     : existingEvent
    // })

    // this.setState({
    //   events: nextEvents,
    // })

    // alert(`${event.title} was dropped onto ${updatedEvent.start}`)
  };

  const resizeEvent = ({ event, start, end }) => {
    // const { events } = this.state

    // const nextEvents = events.map(existingEvent => {
    //   return existingEvent.id == event.id
    //     ? { ...existingEvent, start, end }
    //     : existingEvent
    // })

    // this.setState({
    //   events: nextEvents,
    // })

    //alert(`${event.title} was resized to ${start}-${end}`)
    let duration = moment.duration(end - start);
    const due_date = start;

    const task = {
      ...event,
      due_date: due_date,
      duration: duration.asMinutes() - (duration.asMinutes() % 30),
    };
    let add = true;
    for (var index = 0; index < tasks.length; ++index) {
      if (tasks[index].id === event.id) {
        dispatch(updateTaskAsync(event.id, task));
        add = false;
        break;
      }
    }
    if (add) {
      dispatch(addTaskAsync(task));
    }
  };

  const handleDragStart = (event) => {
    setdraggedEvent(event);
  };

  let formats = {
    dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
      localizer.format(start, "DD MMMM", culture) +
      " — " +
      localizer.format(end, "DD MMMM", culture),
  };

  const onFormChange = (val, allVal) => {
    let start_date = allVal.date;
    var timeZoneFormatted = new Date().toString().match(/([-\+][0-9]+)\s/)[1];

    let temp1 = moment(
      `${allVal.date.format("YYYY-MM-DD")}T${allVal.time.format(
        "HH:mm:ss"
      )}${timeZoneFormatted}`
    );
    let end_time = temp1.add(allVal.duration, "minutes");
    console.log(
      allVal,
      `${allVal.date.format("YYYY-MM-DD")}T${allVal.time.format(
        "HH:mm:ss"
      )}${timeZoneFormatted}`,
      `${end_time.format("YYYY-MM-DD")}T${end_time.format(
        "HH:mm:ss"
      )}${timeZoneFormatted}`
    );
    setAddToCal({
      title: allVal.title,
      description: allVal.description,
      location: "",
      startTime: `${allVal.date.format("YYYY-MM-DD")}T${allVal.time.format(
        "HH:mm:ss"
      )}${timeZoneFormatted}`,
      endTime: `${end_time.format("YYYY-MM-DD")}T${end_time.format(
        "HH:mm:ss"
      )}${timeZoneFormatted}`,
    });
    console.log(
      allVal,
      `${allVal.date.format("YYYY-MM-DD")}T${allVal.time.format(
        "HH:mm:ss"
      )}${timeZoneFormatted}`
    );
  };

  return (
    <>
      <Calendar
        formats={formats}
        selectable
        localizer={localizer}
        events={myEventsList}
        // onEventDrop={moveEvent}
        // resizable
        // onEventResize={resizeEvent}
        // onDragStart={console.log}
        // handleDragStart={handleDragStart}
        eventPropGetter={(event) => ({
          style: { backgroundColor: event.coach ? "#3DA2BA" : "#F39380" },
        })}
        startAccessor="start"
        endAccessor="end"
        defaultView="week"
        style={{ background: "white", marginTop: "7%" }}
        onSelectSlot={handleSelect}
        onSelectEvent={(event) => editEvent(event)}
        toolbar={true}
        components={{
          toolbar: CustomToolbar,
        }}
      />
      {console.log("current!!!", eventId, tasks)}
      <Modal
        title=""
        visible={modalVisible}
        onCancel={handleCancel}
        footer={false}
        destroyOnClose={true}
        closeIcon={<CloseOutlined className="close-icon" />}
        className="edit-task-modal"
      >
        <Form
          name="add-task-form"
          initialValues={{}}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={addForm}
          layout="vertical"
          onValuesChange={onFormChange}
        >
          <Form.Item
            label="Title"
            name="title"
            className="edit-task-items"
            rules={[{ required: true, message: "Please input your title!" }]}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item
            name="description"
            className="edit-task-items"
            label="Additional Notes"
          >
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item name="date" className="edit-task-items" label="Date">
            <DatePicker style={{ margin: "0" }} />
          </Form.Item>
          <Form.Item name="time" className="edit-task-items" label="Time">
            <TimePicker
              use12Hours
              format="h:mm a"
              style={{ margin: "0 " }}
              // bordered={false}
            />
          </Form.Item>
          {/* <div style={{paddingBottom:20}}>
            Add to <a style={{color:'#49CDE1'}}>Google Calendar</a>
          </div> */}

          <Form.Item
            label="Duration (minutes)"
            name="duration"
            className="edit-task-items"
          >
            <InputNumber min={30} max={60 * 24} step={30} />
          </Form.Item>
          <div style={{ marginBottom: "20px" }}>
            {/* <span className="add-to-calendar">Add to </span>
            <a
              className="google-calendar"
              href="http://localhost:8000/accounts/google/login/?process=login"
              target="_blank"
            >
              Google Calendar
            </a> */}
            {React.createElement(() => (
              <AddToCalendar
                event={addToCal}
                listItems={[{ google: "Add to Google Calendar" }]}
                optionsOpen={true}
              />
            ))}
          </div>
          <Popconfirm
            title="Are you sure you want to delete?"
            onConfirm={() => {
              handleDeleteTask();
            }}
          >
            <Form.Item style={{ maxWidth: "100px", display: "inline-block" }}>
              <Button
                className="delete-task-btn"
                icon={
                  <DeleteOutlined
                    style={{
                      color: "#49CDE1",
                      position: "relative",
                      top: "-2px",
                    }}
                  />
                }
              >
                Delete
              </Button>
            </Form.Item>
          </Popconfirm>
          <Form.Item
            style={{
              maxWidth: "100px",
              display: "inline-block",
              float: "right",
            }}
          >
            <Button
              type="primary"
              className="save-task-btn"
              htmlType="submit"
              style={{ float: "right" }}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
